import axios from 'axios';

import jwt_decode from "jwt-decode";

const apiScopeUrl = process.env.REACT_APP_B2C_SCOPE_API_URL;
const reactRedirectUri = process.env.REACT_APP_B2C_REDIRECT_URL;
const clientId = 'ac17a0b3-cad1-434d-bbe6-5dff70a261f8';
const clientSecret = 'kK_.WKUAIhH4rWVJn_WsR.41MYKTS--_9A';
export const authPolicy = 'b2c_1_webapp_signup_signin';
const msAuthUrl = 'https://rsrgmm.b2clogin.com/rsrgmm.onmicrosoft.com/oauth2/v2.0/';

export const isAuthenticated = async () => {
  const userString = localStorage.getItem('user');

  if (!userString) {
    return false;
  }

  const user = JSON.parse(userString);
  if (Math.round(new Date().getTime() / 1000) >= user.expiresOn) {
    const userToken = await refreshAccessToken(user.refreshToken);
    setUserToken(userToken);
  }

  return true;
};

export const getLoginUrl = (useDefaultUrl: boolean) => {
  var state = window.location.href;
  if (useDefaultUrl) {
    state = reactRedirectUri!;
  }

  return (
    `${msAuthUrl}/authorize` +
    `?p=${authPolicy}` +
    `&client_id=${clientId}` +
    '&nonce=defaultNonce' +
    `&state=${state}` +
    `&redirect_uri=${reactRedirectUri}` +
    `&scope=${apiScopeUrl}` +
    '&response_type=code' +
    '&prompt=login'
  );
};

export const getAccessToken = async (code: string) => {
  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('client_id', clientId);
  params.append('scope', `${apiScopeUrl} offline_access`);
  params.append('code', code);
  params.append('redirect_uri', reactRedirectUri!);
  params.append('client_secret', clientSecret);

  const result = await axios.post(`${msAuthUrl}token?p=${authPolicy}`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return result.data;
};

export const refreshAccessToken = async (refreshToken: string) => {
  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('client_id', clientId);
  params.append('scope', `${apiScopeUrl} offline_access`);
  params.append('refresh_token', refreshToken);
  params.append('redirect_uri', reactRedirectUri!);
  params.append('client_secret', clientSecret);

  const result = await axios.post(`${msAuthUrl}token?p=${authPolicy}`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return result.data;
};

export const setUserToken = (userToken: any) => {
  const decoded = jwt_decode(userToken.access_token) as any;
  const user = JSON.stringify({
    firstName: decoded.given_name,
    lastName: decoded.family_name,
    email: decoded.emails[0],
    accessToken: userToken.access_token,
    refreshToken: userToken.refresh_token,
    expiresOn: userToken.expires_on,
  });

  localStorage.setItem('user', user);
};

export const getCachedAccessToken = async () => {
  const user = JSON.parse(localStorage.getItem('user')!);
  if (Math.round(new Date().getTime() / 1000) >= user.expiresOn) {
    const userToken = await refreshAccessToken(user.refreshToken);
    setUserToken(userToken);
  }

  return user.accessToken;
};

export const logoutUser = (useDefaultUrl: boolean = false) => {
  localStorage.clear();
  window.location.href = getLoginUrl(useDefaultUrl);
};

export const getCachedUser = () => {
  return JSON.parse(localStorage.getItem('user')!);
};
