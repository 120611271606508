import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBCol, MDBRow, MDBCard, MDBCardBody } from 'mdbreact';

import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import ProtectedRoute from '../../../hocs/ProtectedRoute';
import useModalState from '../../../hooks/useModalState';
import { translate } from '../../../services/translation.service';
import { getAlarm } from '../../../store/api/alarms.service';
import {
  updateItem,
  addItemByFieldId,
  selectSelectedMachineAlarmById,
  selectSelectedMachineName,
  setDisplayInfoMessage,
} from '../Machines/machines.slice';
import {
  updateItemChecked,
  updateGlobalItemChecked,
  updateNotificationChecked,
  updateGlobalNotificationsChecked,
  addRule,
  addNotification,
  setAlarm,
  editRule,
  editNotification,
  deleteRule,
  deleteNotification,
  bulkDeleteRules,
  bulkDeleteNotifications,
  clearAlarmState,
  selectAlarmRules,
  selectAlarm,
  selectTransformedNotifications,
  closeAlarmModal,
  selectAlarmModal,
} from './alarm.slice';
import { createTemporaryId } from '../../../utils/adminPages.utility';
import { AuthConstants } from '../../../constants/auth.constants';
import { AlarmModel } from '../../../models/data/machine/alarms.model';
import { RuleModel } from '../../../models/data/machine/alarms.model';
import { NotificationProps } from '../../../models/props/admin/notificationsPopup.props';
import GenericTable from '../../../components/genericTable/genericTable';
import BackToPage from '../../../components/backToPage/BackToPage';
import AlarmDetail from '../components/alarmDetail/AlarmDetail';
import NotificationPopup from './components/NotificationPopup/NotificationPopup';
import RulePopup from './components/RulePopup/RulePopup';
import { getMachineById } from '../../../store/api/machines.service';
import AppGenericPopup from '../../../common/appGenericPopup/AppGenericPopup';
import { selectFormattedAttributes } from '../Attributes/attributes.slice';
import { getAttributesByMachines } from '../../../store/api/attributes.service';
import { uncheckTableGlobalCheckedById } from '../../../store/slices/tableSlice';

const AlarmDetails: React.FC = () => {
  const navigate = useNavigate();
  const { machineId: machineIdParam, alarmId: alarmIdParam } = useParams<{ machineId: string; alarmId: string }>();

  const { machineId, alarmId } = useMemo(
    () => ({ machineId: +machineIdParam!, alarmId: +alarmIdParam! }),
    [machineIdParam, alarmIdParam]
  );

  const [isRulePopupOpen, setIsRulePopupOpen] = useState<boolean>(false);
  const [isNotificationPopup, setIsNotificationPopup] = useState<boolean>(false);
  const [editedRule, setEditedRule] = useState();
  const [editedNotification, setEditedNotification] = useState<any>();

  const dispatch = useAppDispatch();
  const { setModal } = useModalState();

  const alarm = useAppSelector(selectAlarm);
  const rules = useAppSelector(selectAlarmRules);
  const { isOpen, text } = useAppSelector(selectAlarmModal);
  const notifications = useAppSelector(selectTransformedNotifications);
  const selectedMachineName = useAppSelector(selectSelectedMachineName);
  const attributesDropdownData = useAppSelector(selectFormattedAttributes);
  const hasAlarmData = useAppSelector((state) => selectSelectedMachineAlarmById(state, alarmId));

  const isAlarmNew = !alarmId;

  useEffect(() => {
    async function loadAlarmAndAttributes() {
      await dispatch(
        getAttributesByMachines({
          getOnlyNumericAttributes: false,
          machineIds: [machineId],
        })
      );

      if (alarmId && alarmId > 0) {
        dispatch(getAlarm({ alarmId }));
        return;
      }

      if (alarmId && hasAlarmData) {
        dispatch(setAlarm({ alarm: hasAlarmData }));
        return;
      }
    }

    loadAlarmAndAttributes();
  }, [dispatch, alarmId, hasAlarmData, machineId]);

  useEffect(() => {
    return () => {
      dispatch(closeAlarmModal({}));
      dispatch(clearAlarmState({}));
      dispatch(uncheckTableGlobalCheckedById('alarmRulesDetail'));
      dispatch(uncheckTableGlobalCheckedById('alarmNotifications'));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!selectedMachineName) {
      dispatch(getMachineById({ machineId: +machineId }));
    }
  }, [machineId, selectedMachineName, dispatch]);

  const handleSubmitAlarm = () => {
    if (rules.length === 0) {
      toast.warn(translate('pages.admin.ruleIsRequired'));
      return;
    }

    if (isAlarmNew) {
      const alarmData = { ...alarm, id: createTemporaryId() } as AlarmModel;

      dispatch(addItemByFieldId({ fieldId: 'alarms', item: alarmData }));

      navigate(`/admin/machine/${machineId}`);
      return;
    }

    dispatch(updateItem({ fieldId: 'alarms', item: alarm }));
    navigate(`/admin/machine/${machineId}`);
  };

  const handleItemToggleChecked = useCallback(
    (itemId: string | number | undefined) => dispatch(updateItemChecked({ itemId })),
    [dispatch]
  );

  const handleNotificationToggleChecked = useCallback(
    (itemId: string | number | undefined) => {
      const notificationItem = notifications.find((notification) => notification.id.toString() === itemId);
      const notificationItemType = notificationItem?.type?.toLowerCase() + 's';

      dispatch(updateNotificationChecked({ fieldId: notificationItemType, itemId }));
    },
    [dispatch, notifications]
  );

  const handleItemGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) =>
      dispatch(updateGlobalItemChecked({ isChecked: !!event.currentTarget.checked })),
    [dispatch]
  );

  const handleNotificationGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) =>
      dispatch(updateGlobalNotificationsChecked({ isChecked: !!event.currentTarget.checked })),
    [dispatch]
  );

  const onCreateRuleClick = () => {
    setIsRulePopupOpen(true);
  };

  const handleCreateRuleSaveClick = (rule: RuleModel) => {
    dispatch(
      addRule({
        rule: { ...rule, id: createTemporaryId() },
        text: translate('common.duplicateAlarmRuleModalText'),
      })
    );
  };

  const handleEditRuleClick = (ruleId: number | string) => {
    const editedRule = rules.find((r) => r.id === ruleId);

    if (editedRule) {
      setEditedRule(editedRule);
      setIsRulePopupOpen(true);
    }
  };

  const handleEditNotificationClick = (notificationId: number | string) => {
    const editedNotification = notifications.find((n) => n.id === notificationId);

    if (editedNotification) {
      setEditedNotification(editedNotification);
      setIsNotificationPopup(true);
    }
  };

  const handleEditRuleSaveClick = (rule: RuleModel) => {
    dispatch(editRule({ text: translate('common.duplicateAlarmRuleModalText'), rule }));
  };

  const handleEditNotificationSaveClick = (notification: NotificationProps) => {
    dispatch(
      editNotification({
        text: translate('common.duplicateNotificationModalText'),
        updatedNotification: notification,
        originalNotification: { ...editedNotification, type: editedNotification?.type?.toLowerCase() + 's' },
      })
    );
  };

  const handleDeleteRuleClick = (ruleId: number | string) => {
    dispatch(deleteRule({ ruleId }));
  };

  const handleNotificationDelete = useCallback(
    (itemId: string | number | undefined) => {
      const notificationItem = notifications.find((notification) => notification.id === itemId);
      const notificationItemType = notificationItem?.type?.toLowerCase() + 's';

      setModal(true, () => dispatch(deleteNotification({ fieldId: notificationItemType, itemId })));
    },
    [dispatch, notifications, setModal]
  );

  const handleBulkDeleteItemsClick = useCallback(
    (fieldId: string) => {
      const checkedRules = rules.filter((r) => r.isChecked);
      if (checkedRules.length === 0) {
        toast.info(translate('pages.admin.noSelectedRules'));
        return;
      }

      setModal(true, () => {
        dispatch(bulkDeleteRules({ fieldId }));
        dispatch(uncheckTableGlobalCheckedById('alarmRulesDetail'));
      });
    },
    [dispatch, setModal] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleBulkDeleteNotifications = useCallback(() => {
    const checkedNotifications = notifications.filter((n) => n.isChecked);
    if (checkedNotifications.length === 0) {
      toast.info(translate('pages.admin.noSelectedNotifications'));
      return;
    }

    setModal(true, () => {
      dispatch(bulkDeleteNotifications({}));
      dispatch(uncheckTableGlobalCheckedById('alarmNotifications'));
    });
  }, [dispatch, setModal]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseRuleModal = () => {
    setIsRulePopupOpen(false);
    setEditedRule(undefined);
  };

  const handleCloseNotificationModal = () => {
    setIsNotificationPopup(false);
    setEditedNotification(undefined);
  };

  const handleCreateNotificationClick = () => {
    setIsNotificationPopup(true);
  };

  const handleCreateNotificationSaveClick = (notification: NotificationProps) => {
    dispatch(
      addNotification({
        text: translate('common.duplicateNotificationModalText'),
        notification: { ...notification, id: createTemporaryId() },
      })
    );
  };

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <>
            <MDBRow className='mb-3'>
              <MDBCol md='12' className='px-4'>
                <BackToPage
                  text={translate('backToLink.machine')}
                  navigateTo={`/admin/machine/${machineId}`}
                  extraAction={() => dispatch(setDisplayInfoMessage(true))}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md='12' className='px-4'>
                <AlarmDetail
                  alarm={alarm}
                  machineId={machineId}
                  attributes={attributesDropdownData}
                  onSubmitAlarm={handleSubmitAlarm}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md='12' className='px-4'>
                <MDBCard className='mt-5'>
                  <MDBCardBody>
                    <GenericTable
                      tableId={'alarmRulesDetail'}
                      items={rules || []}
                      hasSearch={true}
                      hasItemsPerPageControl={true}
                      hasCheckboxInput={true}
                      handleItemToggle={handleItemToggleChecked}
                      onGlobalItemsChecked={handleItemGlobalCheckedToggle}
                      onItemEditClick={handleEditRuleClick}
                      onItemDeleteClick={handleDeleteRuleClick}
                      onBulkDeleteClick={() => handleBulkDeleteItemsClick('rules')}
                      onActionButtonClick={onCreateRuleClick}
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              <MDBCol md='12' className='px-4'>
                <MDBCard className='mt-5'>
                  <MDBCardBody>
                    <GenericTable
                      tableId={'alarmNotifications'}
                      items={notifications || []}
                      hasSearch={true}
                      hasItemsPerPageControl={true}
                      hasCheckboxInput={true}
                      handleItemToggle={handleNotificationToggleChecked}
                      onGlobalItemsChecked={handleNotificationGlobalCheckedToggle}
                      onItemEditClick={handleEditNotificationClick}
                      onItemDeleteClick={handleNotificationDelete}
                      onBulkDeleteClick={handleBulkDeleteNotifications}
                      onActionButtonClick={handleCreateNotificationClick}
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>

            {isRulePopupOpen && (
              <RulePopup
                isOpen={isRulePopupOpen}
                onShowModal={setIsRulePopupOpen}
                defaultRule={editedRule}
                onCloseModal={handleCloseRuleModal}
                onSaveRule={handleCreateRuleSaveClick}
                onEditRule={handleEditRuleSaveClick}
              />
            )}

            {isNotificationPopup && (
              <NotificationPopup
                isOpen={isNotificationPopup}
                onShowModal={setIsNotificationPopup}
                defaultNotification={editedNotification}
                onCloseModal={handleCloseNotificationModal}
                onEditNotification={handleEditNotificationSaveClick}
                onSaveNotification={handleCreateNotificationSaveClick}
              />
            )}

            {isOpen && <AppGenericPopup isOpen={isOpen} text={text} onClose={() => dispatch(closeAlarmModal({}))} />}
          </>
        );
      }}
    />
  );
};

export default AlarmDetails;
