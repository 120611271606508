import React, { useCallback, useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import LoadingOverlay from 'react-loading-overlay-ts';

import '../../../app.scss';
import Routes from '../routes/Routes';
import Footer from '../footer/Footer';
import TopNavigation from '../topNavigation/TopNavigation';
import SideNavigation from '../sideNavigation/SideNavigation';
import { translate } from '../../services/translation.service';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { selectSelectedMachine } from '../../pages/admin/Machines/machines.slice';
import { selectMachine } from '../../pages/data/Details/dataDetails.slice';
import Spinner from './Spinner';
import { selectMachines } from '../../store/slices/machinesSlice';
import useSpinnerState from '../../hooks/useSpinnerState';
import useActiveTabState from '../../hooks/useActiveTabState';
import { CommonConstants } from '../../constants/common.constants';
import useUserState from '../../hooks/useUserState';
import { useLocation } from 'react-router';
import useMachinesState from '../../hooks/useMachinesState';
import { addUserStatistics } from '../../store/slices/userStatisticsSlice';

const RoutesWithNavigation: React.FC = () => {
  const dispatch = useAppDispatch();

  const { spinnerState, setSpinnerState } = useSpinnerState();
  const { getGeoData } = useMachinesState();
  const location = useLocation();
  const { promiseInProgress } = usePromiseTracker();
  const [breakWidth] = useState(1400);
  const [windowWidth, setWindowWidth] = useState(0);
  const [currentPage, setCurrentPage] = useState('');
  const [currentPageMachineName, setCurrentPageMachineName] = useState('');
  const [sideNavToggled, setSideNavToggled] = useState(false);

  const { userState } = useUserState();

  const { tabsState } = useActiveTabState();
  const { profileTabs } = tabsState;

  const currentAdminMachine = useAppSelector(selectSelectedMachine);
  const currentDataMachine = useAppSelector(selectMachine);
  const allMachines = useAppSelector(selectMachines);

  const formatLocationString = (inputString) => {
    return inputString.replace(CommonConstants.RouteMyProfileregex, (ls) => ls.toUpperCase());
  };

  const removeAppCacheAsync = async () => {
    const cacheKeys = await window.caches.keys();
    await Promise.all(cacheKeys.map((key) => window.caches.delete(key)));
  };

  useEffect(() => {
    if (location.pathname !== '/user-not-synchronized') {
      const appVersion = userState.appVersion;
      const cachedAppVersion = localStorage.getItem('userMachineBiVersion');

      if (!cachedAppVersion || cachedAppVersion !== appVersion) {
        removeAppCacheAsync().then(() => {
          localStorage.setItem('userMachineBiVersion', appVersion!);
          window.location.replace(window.location.href);
        });
      }
    }
  }, []); // eslint-disable-line

  const assessLocation = useCallback(
    (location: string) => {
      let locationString = '';
      let locationMachineName = '';

      if (location === '/' || location === '/#') {
        locationString = translate('pageHeaders.home');
      } else if (location.includes('/geo/machine/')) {
        locationString = translate('pageHeaders.geoMachineDetails');

        const selectedMachineId = Number(location.slice(location.lastIndexOf('/') + 1));

        if (selectedMachineId) {
          const machine = allMachines.find((m) => m.id === selectedMachineId);
          if (machine) {
            locationString = `${translate('pageHeaders.geoMachineDetails')} | ${machine.name}`;
          }
        }
      } else if (location.includes('/geo/machines')) {
        locationString = translate('pageHeaders.geoMachines');
      } else if (location.includes('/data/machines')) {
        locationString = translate('pageHeaders.dataMachines');
      } else if (location.includes('/data/analytics/')) {
        locationString = `${translate('pageHeaders.dataMachinesDetails')} | ${
          currentDataMachine?.name ? currentDataMachine?.name : ''
        }`;
      } else if (location.includes('/admin/user/')) {
        locationString = translate('pageHeaders.adminUserDetails');
      } else if (location.includes('/admin/admin-groups')) {
        locationString = translate('pageHeaders.adminGroups');
      } else if (location.includes('/admin/admin-group')) {
        locationString = translate('pageHeaders.adminGroupDetails');
      } else if (location.includes('/admin/attributes')) {
        locationString = translate('pageHeaders.attributes');
      } else if (location.includes('/admin/machine-owners')) {
        locationString = translate('pageHeaders.machineOwners');
      } else if (location.includes('/admin/machine-owner')) {
        locationString = translate('pageHeaders.adminMachineOwnerDetails');
      } else if (location.includes('/admin/machines')) {
        locationString = translate('pageHeaders.machines');
      } else if (location.includes('/admin/machine')) {
        locationString = `${translate('pageHeaders.machine')} | ${
          currentAdminMachine?.name ? currentAdminMachine?.name : ''
        }`;
      } else if (location.includes('/admin/users')) {
        locationString = translate('pageHeaders.adminUsers');
      } else if (location.includes('/admin/contracts')) {
        locationString = translate('pageHeaders.adminContracts');
      } else if (location.includes('/admin/contract')) {
        locationString = translate('pageHeaders.adminContractDetails');
      } else if (location.includes('/admin/sensor-groups')) {
        locationString = translate('pageHeaders.adminSensorGroups');
      } else if (location.includes('/admin/sensor-group')) {
        locationString = translate('pageHeaders.adminSensorGroup');
      } else if (location.includes('/profile')) {
        locationString = `${translate('common.myAccount')} | `;
        if (profileTabs === 1) {
          locationString += formatLocationString(translate('pages.profile.myProfile'));
        } else if (profileTabs === 2) {
          locationString += formatLocationString(translate('pages.profile.myPreferences'));
        } else if (profileTabs === 3) {
          locationString += formatLocationString(translate('pages.profile.myGroups'));
        } else if (profileTabs === 4) {
          locationString += formatLocationString(translate('pages.profile.mySensorGroups'));
        }
      } else if (location.includes('/user-not-synchronized')) {
        locationString = translate('pageHeaders.userNotSynched');
      }

      if (windowWidth < 768) {
        locationMachineName = locationString.split('|')[2];
        locationString = locationString.split('|')[1];
      }
      setCurrentPageMachineName(locationMachineName);
      setCurrentPage(locationString);
    },
    [windowWidth, currentAdminMachine?.name, currentDataMachine?.name, allMachines, profileTabs, userState.language] //eslint-disable-line
  );

  useEffect(() => {
    getGeoData();
  }, [getGeoData]);

  useEffect(() => {
    dispatch(addUserStatistics(location.pathname));
  }, [location, dispatch]);

  useEffect(() => {
    assessLocation(location.pathname);
  }, [location, assessLocation]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      assessLocation(location.pathname);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [location, assessLocation, setWindowWidth]);

  const toggleSideNav = useCallback(() => {
    if (window.matchMedia('screen and (min-width: 1400px)').matches) {
      setSideNavToggled(true);
    } else {
      setSideNavToggled(false);
    }
  }, []);

  useEffect(() => {
    toggleSideNav();
  }, [toggleSideNav]);

  useEffect(() => {
    if (!promiseInProgress && spinnerState.isVisible) {
      setSpinnerState(false);
    }
  }, [promiseInProgress]); // eslint-disable-line

  useEffect(() => {
    window.addEventListener('resize', toggleSideNav);

    return () => {
      window.removeEventListener('resize', toggleSideNav);
    };
  }, [toggleSideNav]);

  const dynamicLeftPadding = {
    paddingLeft: windowWidth > breakWidth ? '240px' : '0',
  };

  const updateHeight = () => {
    document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
  };

  window.addEventListener('resize', updateHeight);
  window.addEventListener('DOMContentLoaded', updateHeight);

  return (
    <div className='app'>
      <LoadingOverlay
        active={promiseInProgress || spinnerState.isVisible}
        spinner={<Spinner promiseInProgress={promiseInProgress} />}
      >
        <div className='white-skin'>
          <SideNavigation
            breakWidth={breakWidth}
            triggerOpening={sideNavToggled}
            onLinkClick={toggleSideNav}
            setTriggerOpening={setSideNavToggled}
          />
        </div>
        <div className='white-skin'>
          <TopNavigation
            toggle={windowWidth < breakWidth}
            routeName={currentPage}
            setTriggerOpening={setSideNavToggled}
            machineName={currentPageMachineName}
          />
          <main style={{ ...dynamicLeftPadding }}>
            <div className='main-section'>
              <Routes />
            </div>
          </main>
          <Footer style={{ ...dynamicLeftPadding }} className='d-none d-lg-block w-100 position-fixed' />
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default RoutesWithNavigation;
