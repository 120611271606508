import moment, { Moment } from 'moment-timezone';

import { TimestampType } from '../models/enum/timestampType.enum';

export const timeZoneTransform = (
  date: Date | null | undefined,
  timezone: TimestampType | undefined,
  customTimeZone: string | undefined
): string => {
  if (!date) {
    return '';
  }

  date = new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
  const dateFormat = 'DD.MM.YYYY HH:mm:ss.SSS';
  if (timezone === TimestampType.Default) {
    return moment(date.getTime()).format(dateFormat);
  }

  const localDate = new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()));
  if (timezone === TimestampType.Custom && !!customTimeZone) {
    return moment(localDate.getTime()).tz(customTimeZone).format(dateFormat);
  }

  return moment(localDate.getTime()).format(dateFormat);
};

export const getUtcNow = (): Date => {
  const now = new Date();
  return new Date(now.setMinutes(now.getMinutes() + now.getTimezoneOffset()));
};

export const timeZoneTransformToNumber = (
  date: Date | null | undefined,
  timezone: TimestampType | undefined,
  customTimeZone: string | undefined
): number => {
  const dateAsString = timeZoneTransform(date, timezone, customTimeZone);
  const dateParsed = moment(dateAsString, 'DD.MM.YYYY HH:mm:ss.SSS').toDate();
  return dateParsed.getTime() - dateParsed.getTimezoneOffset() * 60000;
};

export const timeZoneTransformToMomentDate = (
  date: Date | null | undefined,
  timezone: TimestampType | undefined,
  customTimeZone: string | undefined
): Moment => {
  const dateAsString = timeZoneTransform(date, timezone, customTimeZone);
  const dateParsed = moment(dateAsString, 'DD.MM.YYYY hh:mm A').toDate();
  return moment(dateParsed);
};

export const getUTC = (date: Date, timezone: TimestampType, customTimeZone: string): Date => {
  if (timezone === TimestampType.Default) {
    return moment(date.getTime()).toDate();
  }

  if (timezone === TimestampType.Custom && !!customTimeZone) {
    const utcOffset = moment(date.getTime()).tz(customTimeZone).utcOffset();
    const customZoneDate = moment(date.getTime()).tz(customTimeZone).utc().toDate();
    return new Date(customZoneDate.setMinutes(customZoneDate.getMinutes() - utcOffset));
  }

  return new Date(date.setMinutes(date.getMinutes() + date.getTimezoneOffset()));
};

export const getCustomTimeZoneUtcOffset = (
  timezone: TimestampType | undefined,
  customTimeZone: string | undefined
): number => {
  if (timezone === TimestampType.Default) {
    return 0;
  }

  if (timezone === TimestampType.Custom && !!customTimeZone) {
    return moment(new Date().getTime()).tz(customTimeZone).utcOffset();
  }

  return moment(new Date()).utcOffset();
};

export const getMomentTimeFromUtc = (stringUtcTime: string, utcOffset: number): moment.Moment => {
  return moment(stringUtcTime).add(utcOffset + new Date().getTimezoneOffset(), 'minutes');
};

export const formatDateToUtc = (date: Date): Date => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
};

export const dateUTC12AM = (date: Date | string): Date => {
  const formattedDate = new Date(date);

  const now = new Date(new Date().setHours(0, 0, 0, 0));
  const nowUTC = new Date(now.setMinutes(now.getMinutes() - now.getTimezoneOffset()));
  const dateUTC12AM = date
    ? new Date(
        new Date(formattedDate.setHours(0, 0, 0, 0)).setMinutes(
          formattedDate.getMinutes() - formattedDate.getTimezoneOffset()
        )
      )
    : nowUTC;

  return dateUTC12AM;
};

export const getUtcFromLocalDate = (localDate: Date): Date => {
  return new Date(localDate.setMinutes(localDate.getMinutes() + localDate.getTimezoneOffset()));
};
