import React from 'react';
import { CSVLink } from 'react-csv';

import { MDBCol, MDBIcon } from 'mdbreact';

import { translatedCsvHeaders, generateExportTimestamp } from '../../utils/csvExport.utility';
import { formatMachineState, formatMachineStateAttributes, formatGpsTime } from '../../utils/formatting.utility';
import { CsvExportProps } from '../../models/props/components/csvExportProps';
import { translate } from '../../services/translation.service';

const CsvExport: React.FC<CsvExportProps> = ({ id, items, fileName, buttonTitle = translate('common.export'), classes }) => {
  const formattedItems = items.map((item) => ({
    ...item,
    gpsTime: formatGpsTime(item.gpsTime, item.fromRangeTime),
    machineState: formatMachineState(item.machineState),
    engine: formatMachineStateAttributes(item.engine),
    power: formatMachineStateAttributes(item.power),
    brake: formatMachineStateAttributes(item.brake),
    gear: formatMachineStateAttributes(item.gear),
  }));

  return (
    <CSVLink
      headers={translatedCsvHeaders[id]}
      data={formattedItems}
      filename={`${fileName}-${generateExportTimestamp()}.csv`}
      className={` ${classes} link-decoration`}
    >
      <MDBCol className='d-flex flow-row justify-content-center align-items-center '>
        {buttonTitle}
        <MDBIcon icon='file-download' className='ml-2 fa-lg' />
      </MDBCol>
    </CSVLink>
  );
};

export default CsvExport;
