import { translate } from '../../../../services/translation.service';
import { TimeRangeEnum } from '../../../../models/enum/timeRange.enum';

export const durations = [
  { text: 'Select time period', value: 0, disabled: true, defaultSelected: false },
  {
    text: 'Last 24 hours',
    value: TimeRangeEnum.LastDay,
    defaultSelected: false,
  },
  {
    text: 'Last 48 hours',
    value: TimeRangeEnum.LastTwoDays,
    defaultSelected: false,
  },
  {
    text: 'Last 7 days',
    value: TimeRangeEnum.LastWeek,
    defaultSelected: false,
  },
  {
    text: 'Last 30 days',
    value: TimeRangeEnum.LastMonth,
    defaultSelected: false,
  },
  {
    text: 'Last 2 month',
    value: TimeRangeEnum.Last2Months,
    defaultSelected: false,
  },
  {
    text: 'Last 3 month',
    value: TimeRangeEnum.Last3Months,
    defaultSelected: false,
  },
  {
    text: 'Last 6 month',
    value: TimeRangeEnum.Last6Months,
    defaultSelected: false,
  },
  {
    text: 'Last 1 year',
    value: TimeRangeEnum.Last1Year,
    defaultSelected: false,
  },
  {
    text: 'Since the beginning of the year',
    value: TimeRangeEnum.ThisYear,
    defaultSelected: false,
  },
];

export const getDurationTranslatedText = (value: string | number): string => {
  switch (+value) {
    case 0:
      return translate('pages.geo.timePeriod');
    case TimeRangeEnum.LastDay:
      return translate('pages.geo.lastDay');
    case TimeRangeEnum.LastTwoDays:
      return translate('pages.geo.lastTwoDays');
    case TimeRangeEnum.LastWeek:
      return translate('pages.geo.lastWeek');
    case TimeRangeEnum.LastMonth:
      return translate('pages.geo.lastMonth');
    case TimeRangeEnum.Last2Months:
      return translate('pages.geo.last2Months');
    case TimeRangeEnum.Last3Months:
      return translate('pages.geo.last3Months');
    case TimeRangeEnum.Last6Months:
      return translate('pages.geo.last6Months');
    case TimeRangeEnum.Last1Year:
      return translate('pages.geo.Last1Year');
    case TimeRangeEnum.ThisYear:
      return translate('pages.geo.thisYear');
    default:
      return translate('pages.geo.timePeriod');
  }
};
