import { updateActiveTabById, selectMapTab, selectGroupTab } from '../store/slices/tabsSlice';
import { useAppDispatch, useAppSelector } from './reduxHooks';

export default function useActiveTabState() {
  const tabsState = useAppSelector((state) => state.tabs);
  const dispatch = useAppDispatch();
  const mapTab = useAppSelector(selectMapTab);
  const groupTab = useAppSelector(selectGroupTab);

  const setActiveTab = (tabId: string, tabIndex: number) => {
    dispatch!(
      updateActiveTabById({
        id: tabId,
        tabIndex: tabIndex,
      })
    );
  };

  return {
    tabsState,
    mapTab,
    groupTab,
    setActiveTab,
  };
}
