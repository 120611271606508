import React, { useCallback, useEffect, useRef, useState } from 'react';

import MarkerClusterGroup from '@changey/react-leaflet-markercluster';

import { MapContainer, TileLayer, Popup, LayersControl, Marker, Polyline, Tooltip } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import '@changey/react-leaflet-markercluster/dist/styles.min.css'; // sass
import 'leaflet-arrowheads';

import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';

import './mapOpenRailway.scss';
import PopupWindow from '../../../../components/popupWindow/PopupWindow';
import MapSettings from '../../../../components/mapSettings/MapSettings';
import { MapMachinesProps } from '../../../../models/props/geo/mapMachines.props';
import { OpenRailwayMapConstants } from '../../../../constants/openRailwayMap.constants';
import {
  getIconAngle,
  getOpenRailwayMarkerIcon,
  getOpenRailwayUserLocationMarkerIcon,
  openRailwayMapFitBounds,
} from '../../../../utils/map.utility';
import { MachineModel } from '../../../../models/data/machine/machine.model';
import { MachineTrackHistoryModel } from '../../../../models/data/machine/machineTrackHistory.model';
import PopupWindowDetail from '../../../../components/popupWindowDetail/PopupWindowDetail';
import { EventTrackHistoryModel } from '../../../../models/data/machine/eventTrackHistory.model';
import { getDateColor } from '../../../../utils/formatting.utility';
import { translate } from '../../../../services/translation.service';
import { resetMapState, selectUserLocation, updateMapZoomAndCenterByMapId } from '../../../../store/slices/mapsSlice';
import MapOpenRailwayEvents from './MapOpenRailwayEvents';
import { CommonConstants } from '../../../../constants/common.constants';

const MapOpenRailway: React.FC<MapMachinesProps> = ({
  mapId,
  selectedMachineId,
  containerStyles,
  zoomToPositionLabel,
  hasUserLocationButton,
  hasLastPositionButton,
  cleanTooltip,
  syncMaps,
  syncTooltips,
  triggerZoomToPosition,
  triggerSelectUserLocation,
}) => {
  const dispatch = useAppDispatch();
  const userLocation = useAppSelector(selectUserLocation);

  const mapStateById = useAppSelector((state) => state.maps[mapId]);
  const { center, zoom, path, positions, activePosition, preventFitBounds, isTooltipOpen } = mapStateById;

  const [forceRerender, setForceRerender] = useState(1);
  const mapRef = useRef<any>();
  const [markersRef, setMarkersRef] = useState<any>();
  const polylineRef = useRef<any>();
  const popupRef = useRef<any>({});

  const fitBounds = useCallback(() => {
    if (
      mapRef &&
      markersRef &&
      !preventFitBounds &&
      (activePosition === undefined ||
        activePosition === null ||
        activePosition?.id < 0 ||
        activePosition?.id === undefined)
    ) {
      openRailwayMapFitBounds(positions, mapRef.current, markersRef, dispatch, mapId);
    }
  }, [dispatch, mapId, positions, mapRef, markersRef, preventFitBounds, activePosition]);

  useEffect(() => {
    if (mapRef?.current && markersRef && !preventFitBounds && (center === undefined || zoom === undefined)) {
      openRailwayMapFitBounds(positions, mapRef.current, markersRef, dispatch, mapId, center);
    } else if (mapRef?.current && markersRef && activePosition && zoom === undefined) {
      openRailwayMapFitBounds(positions, mapRef.current, markersRef, dispatch, mapId, {
        lat: activePosition.latitude,
        lng: activePosition.longitude,
      });
    }

    if (isTooltipOpen && mapRef?.current && popupRef?.current) {
      setTimeout(() => {
        const popupToOpen = popupRef.current[activePosition?.id];
        if (popupToOpen) {
          mapRef.current.openPopup(popupToOpen);
        }
      }, 100);
    }
  }, [isTooltipOpen, activePosition, dispatch, mapId, positions, markersRef, center, zoom, preventFitBounds]);

  useEffect(() => {
    fitBounds();
  }, [fitBounds]);

  const onMarkerClick = (machine) => {
    if (syncTooltips) {
      syncTooltips(true, machine);
    }

    if (syncMaps) {
      syncMaps(machine);
    }
  };

  const onAutoZoomClick = () => {
    syncTooltips(false, -1);
    handlePopupClose();
    dispatch(resetMapState({}));
    fitBounds();
  };

  const handlePopupClose = () => {
    if (cleanTooltip) {
      cleanTooltip();
    }

    mapRef.current.closePopup();
    syncTooltips(false);
    setForceRerender((prevState) => prevState + 1);
  };

  const onSelectUserLocation = () => {
    triggerSelectUserLocation!();
  };

  const onOpenLastLocationInApp = () => {
    let lastPosition = [...positions].sort((x, y) => Date.parse(y.messageTime) - Date.parse(x.messageTime))[0];
    window.open(`${CommonConstants.GoogleMapsUrl}${lastPosition?.latitude}, ${lastPosition?.longitude}`);
  };

  useEffect(() => {
    const getMapPosition = () => {
      if (mapRef.current) {
        dispatch(
          updateMapZoomAndCenterByMapId({
            id: mapId,
            center: mapRef.current.getCenter(),
            zoom: mapRef.current.getZoom(),
          })
        );
      }
    };

    return () => {
      getMapPosition();
    };
  }, []); // eslint-disable-line

  useEffect(() => setForceRerender((prevState) => prevState + 1), [center, positions.length]);

  useEffect(() => {
    const polyline = polylineRef.current;

    if (polyline) {
      polyline.arrowheads(OpenRailwayMapConstants.polylineArrowOptions);
      polyline._update();
    }

    return () => {
      if (polyline) {
        polyline.deleteArrowheads();
      }
    };
  }, [polylineRef.current]); // eslint-disable-line

  const hasAnimation = (machineId: number) => {
    if (mapId === OpenRailwayMapConstants.geoMachinesOpenRailwayMapId) {
      return selectedMachineId === machineId;
    }

    return activePosition?.id === machineId;
  };

  const hasActivePosition = () => {
    if (mapId === OpenRailwayMapConstants.geoMachinesOpenRailwayMapId) {
      return selectedMachineId && selectedMachineId > -1;
    }

    return !!activePosition;
  };

  return (
    <>
      <MapContainer
        key={forceRerender}
        style={containerStyles || OpenRailwayMapConstants.containerStyle}
        center={center}
        zoom={zoom}
        maxZoom={OpenRailwayMapConstants.zoomedMapDetails}
        ref={(map) => (mapRef.current = map)}
        zoomAnimation={false}
        scrollWheelZoom={false}
      >
        <MarkerClusterGroup
          maxClusterRadius={OpenRailwayMapConstants.maxClusterRadius}
          disableClusteringAtZoom={
            activePosition ? OpenRailwayMapConstants.zoomedMap : OpenRailwayMapConstants.disableClusteringAtZoom
          }
          ref={(markers) => setMarkersRef(markers)}
        >
          {(positions as (MachineModel | MachineTrackHistoryModel | EventTrackHistoryModel)[]).map((machine, index) => (
            <Marker
              key={machine.id}
              position={[machine.latitude, machine.longitude]}
              icon={getOpenRailwayMarkerIcon(
                getIconAngle(machine, mapId),
                machine.compassDirection ? machine.compassDirection : null,
                hasAnimation(machine?.id),
                mapId === OpenRailwayMapConstants.geoMachineDetailsOpenRailwayMapId && index === 0
              )}
              eventHandlers={{
                click: () => {
                  onMarkerClick(machine);
                },
              }}
            >
              {mapId === OpenRailwayMapConstants.geoMachinesOpenRailwayMapId &&
                (machine as MachineModel)?.nameShort &&
                (machine as MachineModel)?.nameShort !== '' && (
                  <Tooltip
                    className={`marker-tooltip ${getDateColor((machine as MachineModel)?.gpsTime)}-marker-tooltip`}
                    direction='top'
                    offset={[machine?.rotateAngle !== null ? 0 : 6, -12]}
                    permanent
                  >
                    {(machine as MachineModel)?.nameShort}
                  </Tooltip>
                )}
              <Popup
                ref={(p) => {
                  popupRef.current[machine.id] = p;
                }}
                closeButton={false}
                eventHandlers={{ click: handlePopupClose }}
              >
                <button className='close-button-tooltip' onClick={handlePopupClose}>
                  x
                </button>
                <>
                  <br />
                  {path && path.length > 0 ? (
                    <PopupWindowDetail
                      selectedMachine={machine}
                      isEventTrackHistoryMap={mapId === OpenRailwayMapConstants.dataMachineDetailsOpenRailwayMapId}
                    ></PopupWindowDetail>
                  ) : (
                    <PopupWindow selectedMachine={machine} activeMap={2}></PopupWindow>
                  )}
                </>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>

        {hasUserLocationButton && userLocation && (
          <Marker
            position={[userLocation.lat, userLocation.lng]}
            icon={getOpenRailwayUserLocationMarkerIcon()}
          ></Marker>
        )}

        {path && <Polyline ref={polylineRef} positions={path} color='#0000ff' weight={1} />}

        <LayersControl position='topright'>
          <LayersControl.Overlay checked name='Open Street'>
            <TileLayer
              attribution={OpenRailwayMapConstants.openStreetAttribution}
              url={OpenRailwayMapConstants.openStreetUrl}
              maxZoom={OpenRailwayMapConstants.maxZoom}
              maxNativeZoom={OpenRailwayMapConstants.zoomedMapDetails}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name='Open Railway [standard]'>
            <TileLayer
              attribution={OpenRailwayMapConstants.openRailwayAttribution}
              url={OpenRailwayMapConstants.openRailwayStandardUrl}
              maxZoom={OpenRailwayMapConstants.maxZoom}
              maxNativeZoom={OpenRailwayMapConstants.zoomedMapDetails}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name='Open Railway [max speed]'>
            <TileLayer
              attribution={OpenRailwayMapConstants.openRailwayAttribution}
              url={OpenRailwayMapConstants.openRailwayMaxSpeedUrl}
              maxZoom={OpenRailwayMapConstants.maxZoom}
              maxNativeZoom={OpenRailwayMapConstants.zoomedMapDetails}
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name='Open Railway [signals]'>
            <TileLayer
              attribution={OpenRailwayMapConstants.openRailwayAttribution}
              url={OpenRailwayMapConstants.openRailwaySignalsUrl}
              maxZoom={OpenRailwayMapConstants.maxZoom}
              maxNativeZoom={OpenRailwayMapConstants.zoomedMapDetails}
            />
          </LayersControl.Overlay>
          <MapOpenRailwayEvents
            mapRef={mapRef}
            mapId={mapId}
            activePosition={activePosition}
            selectedMachineId={selectedMachineId}
          />
        </LayersControl>
      </MapContainer>

      <div className='d-flex flex-wrap'>
        <div className='d-flex map-settings-button-wrapper'>
          <MapSettings label={translate('common.autoZoom')} onButtonClick={onAutoZoomClick} />
          {hasActivePosition() && <MapSettings label={zoomToPositionLabel} onButtonClick={triggerZoomToPosition} />}
        </div>
        <div className='d-flex map-settings-button-wrapper'>
          {hasUserLocationButton && userLocation && (
            <MapSettings label={translate('common.userLocation')} onButtonClick={onSelectUserLocation} />
          )}
          {hasLastPositionButton && positions && positions.length > 0 && (
            <MapSettings label={translate('common.viewInApp')} onButtonClick={onOpenLastLocationInApp} />
          )}
        </div>
      </div>
    </>
  );
};

export default MapOpenRailway;
