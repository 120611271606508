import axios from 'axios';

import { ContactPersonModel } from '../models/data/contract/contactPerson.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getContactPersons = async () => {
  const result = await axios.get<ContactPersonModel[]>(`${baseApiUrl}api/contactPersons`);

  return result?.data;
};
