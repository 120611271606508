import { useCallback } from 'react';

import { useAppSelector } from './reduxHooks';
import { selectUserLanguage } from '../store/slices/userSlice';
import { selectAttributeMappings } from '../pages/admin/Attributes/attributes.slice';
import { AlarmModel, DataAlarmModel } from '../models/data/machine/alarms.model';
import { AttributeDropdownModel, AttributeModel } from '../models/data/machine/attribute.model';
import { SensorModel } from '../models/data/machine/sensor.model';
import {
  AdminSensorGroupsModel,
  ProfileSensorGroupModel,
  SensorGroupChartModel,
} from '../models/data/machine/sensorGroup.model';

export default function useAttributeMappings() {
  const userLanguage = useAppSelector(selectUserLanguage);
  const attributeMappings = useAppSelector(selectAttributeMappings);

  const mapSensorName = useCallback(
    (uuid: string, name: string) => {
      if (attributeMappings.has(uuid && uuid.toLowerCase())) {
        return attributeMappings.get(uuid.toLowerCase())![userLanguage] || name;
      }

      return name;
    },
    [userLanguage, attributeMappings]
  );

  const mapSensors = useCallback(
    (sensor: SensorModel) => ({ ...sensor, name: mapSensorName(sensor.uuid, sensor.name) }),
    [mapSensorName]
  );

  const mapAttributes = useCallback(
    (attributes: AttributeModel[]) => attributes.map((a) => ({ ...a, name: mapSensorName(a.uuid, a.name) })),
    [mapSensorName]
  );

  const mapAlarmAttributes = useCallback(
    (alarms: DataAlarmModel[]) => {
      return alarms.map((a) => ({ ...a, attributeName: mapSensorName(a.attributeUuid, a.attributeName) }));
    },
    [mapSensorName]
  );

  const mapSensorGroupAttributes = useCallback(
    (group: SensorGroupChartModel) => ({
      ...group,
      sensors: group.sensors.map((s) => ({ ...s, name: mapSensorName(s.uuid, s.name) })),
    }),
    [mapSensorName]
  );

  const mapAdminSensorGroupAttributes = useCallback(
    (groups: AdminSensorGroupsModel[]) =>
      groups.map((g) => ({
        ...g,
        id: g.id as number,
        sensors: Array.from(
          new Set(
            g.sensorGroupCharts
              .map((sgc) => sgc.sensors)
              .reduce((x, y) => x.concat(y), [])
              .map((s) => mapSensorName(s.uuid, s.name))
          )
        ),
      })),
    [mapSensorName]
  );

  const mapProfileSensorGroupAttributes = useCallback(
    (groups: ProfileSensorGroupModel[]) =>
      groups.map((g) => ({ ...g, sensors: g.sensors.map((s) => mapSensorName(s.uuid, s.name)) })),
    [mapSensorName]
  );

  const mapMachineConfigurationAlarmAttributes = useCallback(
    (attributes: AlarmModel[]): any[] =>
      attributes.map((a) => ({ ...a, attributeName: mapSensorName(a.attribute!, a.attributeName!) })),
    [mapSensorName]
  );

  const mapMachineConfigurationAlarmDropdownAttributes = useCallback(
    (attributes: AttributeDropdownModel[]) => attributes.map((a) => ({ ...a, text: mapSensorName(a.uuid!, a.text!) })),
    [mapSensorName]
  );

  return {
    mapSensors,
    mapAttributes,
    mapAlarmAttributes,
    mapSensorGroupAttributes,
    mapAdminSensorGroupAttributes,
    mapProfileSensorGroupAttributes,
    mapMachineConfigurationAlarmAttributes,
    mapMachineConfigurationAlarmDropdownAttributes,
  };
}
