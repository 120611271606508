import React, { useEffect, useState, useCallback } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';

import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact';

import GenericTable from '../../components/genericTable/genericTable';
import ProtectedRoute from '../../hocs/ProtectedRoute';
import { AuthConstants } from '../../constants/auth.constants';
import { UserModel } from '../../models/data/user/user.model';
import { getUsers } from '../../services/user.service';

const UsersList: React.FC = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<UserModel[]>([]);

  useEffect(() => {
    async function getUsersList() {
      const data = await trackPromise(getUsers());
      setUsers(data);
    }

    getUsersList();
  }, []);

  const onUserClick = useCallback((userId: string | number) => navigate(`/admin/user/${userId}`), [navigate]);

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <MDBRow>
            <MDBCol md='12' className='px-4'>
              <MDBCard>
                <MDBCardBody>
                  <GenericTable
                    tableId={'usersTable'}
                    items={users}
                    onItemDetailsClick={onUserClick}
                    hasSearch={true}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        );
      }}
    />
  );
};

export default UsersList;
