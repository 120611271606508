import React, { useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBModalFooter } from 'mdbreact';

import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import ProtectedRoute from '../../hocs/ProtectedRoute';
import GenericTable from '../../components/genericTable/genericTable';
import { translate } from '../../services/translation.service';
import { getAllMachineOwners } from '../../store/api/machineOwners.service';
import {
  toggleMachineOwnerChecked,
  selectAvailableMachineOwners,
  selectCheckedMachineOwner,
} from './MachineOwners/machineOwners.slice';
import {
  selectSelectedMachineName,
  addSelectedMachineMachineOwner,
  setDisplayInfoMessage,
} from './Machines/machines.slice';
import { AuthConstants } from '../../constants/auth.constants';
import { getMachineById } from '../../store/api/machines.service';

const AddMachineMachineOwner: React.FC = () => {
  const navigate = useNavigate();
  const { machineId } = useParams<{ machineId: string }>();

  const dispatch = useAppDispatch();

  const machineOwners = useAppSelector(selectAvailableMachineOwners);
  const selectedMachineOwner = useAppSelector(selectCheckedMachineOwner);
  const selectedMachineName = useAppSelector(selectSelectedMachineName);

  useEffect(() => {
    dispatch(getAllMachineOwners());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedMachineName) {
      dispatch(getMachineById({ machineId: +machineId! }));
    }
  }, [machineId, selectedMachineName, dispatch]);

  const handleMachineOwnerCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) {
        dispatch(toggleMachineOwnerChecked(id));
      }
    },
    [dispatch]
  );

  const backToDetailsPage = useCallback(() => {
    dispatch(setDisplayInfoMessage(true));
    navigate(`/admin/machine/${machineId}`);
  }, [machineId, navigate, dispatch]);

  const addSelectedMachineOwner = useCallback(() => {
    if (!selectedMachineOwner) {
      toast.info(translate('pages.admin.noSelectedMachineMachineOwnerForAdd'));
      return;
    }

    dispatch(addSelectedMachineMachineOwner({ selectedMachineOwner }));

    backToDetailsPage();
  }, [dispatch, backToDetailsPage, selectedMachineOwner]);

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <MDBRow>
            <MDBCol md='12' className='px-4'>
              <MDBCard>
                <MDBCardBody>
                  <MDBRow className='pb-4'>
                    <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
                        <MDBIcon icon='user-tie' className='p-4 shadow-3-strong bg-warning rounded' />
                        <h5 className='font-weight-bold ml-4'>
                          {translate('pages.admin.selectMachineOwner')}
                        </h5>
                    </MDBCol>
                  </MDBRow>

                  <GenericTable
                    tableId={'machineMachineOwnerTable'}
                    handleItemToggle={handleMachineOwnerCheckedToggle}
                    items={machineOwners}
                    hasSearch={true}
                    hasItemsPerPageControl={true}
                    hasCheckboxInput={true}
                  />
                </MDBCardBody>

                <MDBModalFooter>
                  <MDBBtn color='warning' className='text-white' onClick={addSelectedMachineOwner}>
                    {translate('common.addSelected')}
                  </MDBBtn>
                  <MDBBtn outline color='warning' onClick={backToDetailsPage}>
                    {translate('common.cancel')}
                  </MDBBtn>
                </MDBModalFooter>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        );
      }}
    />
  );
};

export default AddMachineMachineOwner;
