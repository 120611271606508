import React, { useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBModalFooter } from 'mdbreact';

import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import ProtectedRoute from '../../hocs/ProtectedRoute';
import GenericTable from '../../components/genericTable/genericTable';
import { translate } from '../../services/translation.service';
import { getMachineById } from '../../store/api/machines.service';
import { getAllAdminGroups } from '../../store/api/adminGroups.service';
import { AuthConstants } from '../../constants/auth.constants';
import { updateTableGlobalCheckedById } from '../../store/slices/tableSlice';
import { addItemsByFieldId, selectSelectedMachineName, setDisplayInfoMessage } from './Machines/machines.slice';
import {
  selectAvailableAdminGroups,
  updateAdminGroupChecked,
  selectCheckedAdminGroups,
  updateGlobalAdminGroupChecked,
} from './AdminGroups/adminGroups.slice';

const AddAdminGroupMachine: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { machineId } = useParams<{ machineId: string }>();

  const adminGroups = useAppSelector(selectAvailableAdminGroups);
  const selectedAdminGroups = useAppSelector(selectCheckedAdminGroups);
  const selectedMachineName = useAppSelector(selectSelectedMachineName);

  useEffect(() => {
    dispatch(getAllAdminGroups());

    return () => {
      dispatch(
        updateTableGlobalCheckedById({
          id: 'addMachineAdminGroup',
          globalChecked: false,
        })
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (!selectedMachineName) {
      dispatch(getMachineById({ machineId: +machineId! }));
    }
  }, [machineId, selectedMachineName, dispatch]);

  const handleAdminGroupCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) dispatch!(updateAdminGroupChecked(id));
    },
    [dispatch]
  );

  const backToDetailsPage = useCallback(() => {
    dispatch(setDisplayInfoMessage(true));
    navigate(`/admin/machine/${machineId}`);
  }, [machineId, navigate, dispatch]);

  const addSelectedMachines = useCallback(() => {
    if (selectedAdminGroups.length === 0) {
      toast.info(translate('pages.admin.noSelectedMachineAdminGroupsForAdd'));
      return;
    }

    dispatch(addItemsByFieldId({ fieldId: 'adminGroups', items: selectedAdminGroups }));

    backToDetailsPage();
  }, [backToDetailsPage, dispatch, selectedAdminGroups]);

  const handleAdminGroupGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      dispatch(updateGlobalAdminGroupChecked({ isChecked: !!event.currentTarget.checked }));
    },
    [dispatch]
  );

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <MDBRow>
            <MDBCol md='12' className='px-4'>
              <MDBCard>
                <MDBCardBody>
                  <MDBRow className='pb-4'>
                    <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
                      <MDBIcon color='dark' icon='folder-open' className='p-4 shadow-3-strong bg-warning rounded' />
                      <h5 className='font-weight-bold ml-4'>{translate('pages.admin.addAdminGroup')}</h5>
                    </MDBCol>
                  </MDBRow>

                  <GenericTable
                    tableId={'addMachineAdminGroup'}
                    items={adminGroups}
                    hasSearch={true}
                    hasItemsPerPageControl={true}
                    hasCheckboxInput={true}
                    handleItemToggle={handleAdminGroupCheckedToggle}
                    onGlobalItemsChecked={handleAdminGroupGlobalCheckedToggle}
                  />
                </MDBCardBody>

                <MDBModalFooter>
                  <MDBBtn color='warning' className='text-white' onClick={addSelectedMachines}>
                    {translate('common.addSelected')}
                  </MDBBtn>

                  <MDBBtn outline color='warning' className='cancel-onHover' onClick={backToDetailsPage}>
                    {translate('common.cancel')}
                  </MDBBtn>
                </MDBModalFooter>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        );
      }}
    />
  );
};

export default AddAdminGroupMachine;
