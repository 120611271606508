import React, { useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBCol, MDBRow, MDBCard, MDBCardBody } from 'mdbreact';

import { toast } from 'react-toastify';
import ProtectedRoute from '../../../hocs/ProtectedRoute';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import useInterval from '../../../hooks/useInterval';
import { translate } from '../../../services/translation.service';
import {
  getAdminGroupById,
  createAdminGroup,
  updateAdminGroup,
  getAdminGroupByIdPolling,
} from '../../../store/api/adminGroups.service';
import {
  selectSelectedAdminGroup,
  selectIsSelectedAdminGroupProcessed,
  clearSelectedAdminGroup,
  selectAdminGroups,
} from './adminGroups.slice';
import { AuthConstants } from '../../../constants/auth.constants';
import { AdminConstants } from '../../../constants/admin.constants';
import GenericTable from '../../../components/genericTable/genericTable';
import BackToPage from '../../../components/backToPage/BackToPage';
import AdminGroupDetail from '../components/adminGroupDetail/AdminGroupDetail';

const AdminGroupDetails: React.FC = () => {
  const navigate = useNavigate();
  const { adminGroupId: adminGroupIdParam } = useParams<{ adminGroupId: string }>();

  const adminGroupId = useMemo(() => +adminGroupIdParam!, [adminGroupIdParam]);

  const dispatch = useAppDispatch();

  const adminGroups = useAppSelector(selectAdminGroups);
  const adminGroupData = useAppSelector(selectSelectedAdminGroup);
  const isGroupProcessing = useAppSelector(selectIsSelectedAdminGroupProcessed);

  useEffect(() => {
    if (adminGroupId) {
      dispatch(getAdminGroupById({ adminGroupId }));
    }

    return () => {
      dispatch(clearSelectedAdminGroup(undefined));
    };
  }, [adminGroupId, dispatch]);

  // Polling for processing/synchronizing Admin Groups
  useInterval(
    () => dispatch(getAdminGroupByIdPolling({ adminGroupId })),
    isGroupProcessing ? AdminConstants.pollingInterval : null
  );

  const onSubmitAdminGroup = useCallback(
    async (adminGroupIsNew: boolean) => {
      const group = adminGroups.find((g) => g.name.toLowerCase().trim() === adminGroupData.name.toLowerCase().trim());

      if (group && (adminGroupIsNew || group.id !== adminGroupData.id)) {
        toast.warn(translate('common.nameAlreadyExists'));
        return;
      }

      if (adminGroupIsNew) {
        const { adminGroup: updatedAdminGroup } = await dispatch(createAdminGroup({ adminGroupData })).unwrap();

        navigate(`/admin/admin-group/${updatedAdminGroup?.id}`);
      } else {
        dispatch(updateAdminGroup({ adminGroupData }));
      }
    },
    [adminGroups, adminGroupData, navigate, dispatch]
  );

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => (
        <>
          <MDBRow className='mb-3'>
            <MDBCol md='12' className='px-4'>
              <BackToPage text={translate('backToLink.adminGroups')} navigateTo='/admin/admin-groups' />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md='12' className='px-4'>
              <AdminGroupDetail adminGroup={adminGroupData} onSubmitAdminGroup={onSubmitAdminGroup} />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md='12' className='px-4'>
              <MDBCard className='mt-5'>
                <MDBCardBody>
                  <GenericTable
                    tableId={'adminGroupMachines'}
                    items={adminGroupData?.machines || []}
                    hasSearch={true}
                    hasItemsPerPageControl={true}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </>
      )}
    />
  );
};

export default AdminGroupDetails;
