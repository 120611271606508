import { createSlice, Slice, createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../store/store';
import {
  getAdminGroupById,
  getAllAdminGroups,
  createAdminGroup,
  updateAdminGroup,
  deleteAdminGroupById,
  bulkDeleteAdminGroups,
  getAdminGroupByIdPolling,
  getAllAdminGroupsPolling,
} from '../../../store/api/adminGroups.service';

import { AdminGroupModel } from '../../../models/data/group/adminGroup.model';
export interface adminGroupsState {
  adminGroups: AdminGroupModel[];
  selectedAdminGroup: AdminGroupModel | undefined;
}

const initialState: adminGroupsState = {
  adminGroups: [],
  selectedAdminGroup: undefined,
};

export const adminGroupsSlice: Slice = createSlice({
  name: 'adminGroups',
  initialState,
  reducers: {
    updateAdminGroupChecked(state, action) {
      const adminGroup = state.adminGroups.find((adminGroup) => adminGroup.id.toString() === action.payload);

      if (adminGroup) {
        adminGroup.isChecked = !adminGroup.isChecked;
      }
    },
    updateGlobalAdminGroupChecked(state, action) {
      const { isChecked } = action.payload;

      state.adminGroups = state.adminGroups.map((group) => ({ ...group, isChecked: group.isProcessed && isChecked }));
    },
    setAdminGroupName(state, action) {
      if (state.selectedAdminGroup) {
        state.selectedAdminGroup.name = action.payload;
      } else {
        state.selectedAdminGroup = { name: action.payload };
      }
    },

    clearSelectedAdminGroup(state, action) {
      state.selectedAdminGroup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminGroupById.fulfilled, (state, action) => {
        const { adminGroup } = action.payload;

        state.selectedAdminGroup = adminGroup;
      })
      .addCase(getAllAdminGroups.fulfilled, (state, action) => {
        const { adminGroups } = action.payload;

        state.adminGroups = adminGroups;
      })
      .addCase(createAdminGroup.fulfilled, (state, action) => {
        const { adminGroup } = action.payload;

        state.adminGroups.push(adminGroup);
        state.selectedAdminGroup = adminGroup;
      })
      .addCase(updateAdminGroup.fulfilled, (state, action) => {
        const { adminGroup } = action.payload;

        state.selectedAdminGroup = { ...state.selectedAdminGroup, ...adminGroup };
      })
      .addCase(deleteAdminGroupById.fulfilled, (state, action) => {
        const { deletedAdminGroupIds } = action.payload;

        state.adminGroups = state.adminGroups.filter((group) => !deletedAdminGroupIds.includes(group.id));
      })
      .addCase(bulkDeleteAdminGroups.fulfilled, (state, action) => {
        const { deletedAdminGroupIds } = action.payload;

        state.adminGroups = state.adminGroups.filter((group) => !deletedAdminGroupIds.includes(group.id));
      })
      .addCase(getAdminGroupByIdPolling.fulfilled, (state, action) => {
        const { adminGroup } = action.payload;

        state.selectedAdminGroup = adminGroup;
      })
      .addCase(getAllAdminGroupsPolling.fulfilled, (state, action) => {
        const { adminGroups } = action.payload;

        state.adminGroups = adminGroups;
      });
  },
});

export const { updateAdminGroupChecked, updateGlobalAdminGroupChecked, setAdminGroupName, clearSelectedAdminGroup } =
  adminGroupsSlice.actions;

export default adminGroupsSlice.reducer;

export const selectAdminGroups = (state: RootState) => state.adminGroups.adminGroups;

export const selectSelectedAdminGroup = (state: RootState) => state.adminGroups.selectedAdminGroup;

const selectSelectedMachineAdminGroups = (state: RootState) => state.adminMachines.adminGroups;

export const selectAvailableAdminGroups = createSelector(
  [selectAdminGroups, selectSelectedMachineAdminGroups],
  (adminGroups, machineAdminGroup) =>
    adminGroups.filter((a) => !machineAdminGroup.filter((y) => y.id === a.id).length && a.isProcessed)
);

export const selectCheckedAdminGroups = createSelector([selectAdminGroups], (adminGroups) =>
  adminGroups.filter((adminGroup) => adminGroup?.isChecked).map((group) => ({ ...group, isChecked: false }))
);

export const selectCheckedAdminGroupIds = createSelector([selectAdminGroups], (adminGroups) =>
  adminGroups.filter((adminGroup) => adminGroup?.isChecked).map((c) => c.id)
);

export const selectIsSelectedAdminGroupProcessed = createSelector(
  [selectSelectedAdminGroup],
  (adminGroup) => adminGroup?.id && !adminGroup?.isProcessed
);

export const selectHasProcessingAdminGroups = createSelector([selectAdminGroups], (adminGroups) =>
  adminGroups.some((adminGroup) => !adminGroup.isProcessed)
);
