import React, { useEffect } from 'react';

import { MDBPaginationItem, MDBPaginationLink, MDBPagination } from 'mdbreact';

import './pagination.scss';
import { PaginationProps } from '../../models/props/components/pagination.props';

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  pageToCount,
  itemsLength,
  itemsPerPage,
  pagesCount = 10,
  setCurrentPage,
  setPageToCount,
}) => {
  useEffect(() => {
    setPageToCount(10);
    setCurrentPage(1);
  }, [itemsLength, setCurrentPage, setPageToCount]);

  const changePage = (nextPage: number) => {
    if (nextPage > pageToCount) {
      setPageToCount(pageToCount + pagesCount);
    }
    if (nextPage < pageToCount - pagesCount + 1) {
      setPageToCount(pageToCount - pagesCount);
    }
    setCurrentPage(nextPage);
  };

  const handlePreviousPageChange = () => {
    changePage(currentPage - 1);
  };

  const handleNextPageChange = () => {
    changePage(currentPage + 1);
  };

  const renderPageNavItems = () => {
    const result: any[] = [];
    const pages = Math.ceil(itemsLength / itemsPerPage);

    result.push(
      <MDBPaginationItem
        key={0}
        disabled={currentPage === 1}
        className={`${currentPage === 1 ? 'isDisabled' : ''}`}
        onClick={handlePreviousPageChange}
      >
        <MDBPaginationLink
          tabIndex={-1}
          aria-disabled='true'
          className={`border-0 page-link-arrows ${currentPage === 1 ? 'isDisabled' : ''}`}
          aria-label='Previous'
        >
          <span aria-hidden='true'>&laquo;</span>
          <span className='sr-only'>Previous</span>
        </MDBPaginationLink>
      </MDBPaginationItem>
    );

    const pageFrom = pageToCount - pagesCount + 1;
    const pageTo = pageToCount >= pages ? pages : pageToCount;

    for (let i = pageFrom; i <= pageTo; i++) {
      result.push(
        <MDBPaginationItem key={i} active={currentPage === i} onClick={() => changePage(i)}>
          <MDBPaginationLink className='border-0 clickable'>{i}</MDBPaginationLink>
        </MDBPaginationItem>
      );
    }

    result.push(
      <MDBPaginationItem
        key={pages + 1}
        disabled={currentPage === pages}
        className={`${currentPage === pages ? 'isDisabled' : ''}`}
        onClick={handleNextPageChange}
      >
        <MDBPaginationLink
          tabIndex={-1}
          aria-disabled='true'
          className={`page-link border-0  page-link-arrows ${currentPage === pages + 1 ? 'isDisabled' : ''}`}
          aria-label='Next'
        >
          <span aria-hidden='true'>&raquo;</span>
          <span className='sr-only'>Next</span>
        </MDBPaginationLink>
      </MDBPaginationItem>
    );

    return result;
  };

  return (
    <MDBPagination data-testid='Pagination-wrapper-pagination' circle className='pt-1 float-right'>
      {renderPageNavItems()}
    </MDBPagination>
  );
};

export default Pagination;
