
import axios from 'axios';

import { AdminGroupModel } from '../models/data/group/adminGroup.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getAdminGroups = async () => {
  const result = await axios.get<AdminGroupModel[]>(`${baseApiUrl}api/adminGroups`);
  for (const group of result?.data) {
    group.isChecked = false;
  }

  return result?.data;
};

export const getAdminGroup = async (adminGroupId: number) => {
  const result = await axios.get<AdminGroupModel>(`${baseApiUrl}api/adminGroups/${adminGroupId}`);
  return result?.data;
};

export const addAdminGroup = async (adminGroup: Partial<AdminGroupModel>) => {
  const result = await axios.post<AdminGroupModel>(`${baseApiUrl}api/adminGroups`, adminGroup);
  return result?.data;
};

export const editAdminGroup = async (adminGroup: Partial<AdminGroupModel>) => {
  const result = await axios.put<AdminGroupModel>(`${baseApiUrl}api/adminGroups/${adminGroup.id}`, adminGroup);
  return result?.data;
};

export const deleteAdminGroup = async (adminGroupIds: number[]) => {
  const result = await axios.delete<number[]>(`${baseApiUrl}api/adminGroups`, { data: adminGroupIds });
  return result.data;
};
