import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBCol, MDBRow, MDBCard, MDBCardBody } from 'mdbreact';

import { toast } from 'react-toastify';
import ProtectedRoute from '../../../hocs/ProtectedRoute';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import useModalState from '../../../hooks/useModalState';
import useInterval from '../../../hooks/useInterval';
import { translate } from '../../../services/translation.service';
import { getAllContactPersons } from '../../../store/api/contactPersons.service';
import {
  getContractById,
  createContract,
  updateContract,
  getContractByIdPolling,
} from '../../../store/api/contract.service';
import {
  selectSelectedContract,
  selectSelectedUsers,
  bulkDeleteItems,
  updateItemChecked,
  updateGlobalItemsChecked,
  deleteItem,
  selectFormattedContractMachines,
  selectContractState,
  selectFormattedSelectedContract,
  selectAvailableContactPersons,
  selectIsSelectedContractProcessed,
  setSelectedContractContactOwnerField,
  selectAlarmModal,
  closeAlarmModal,
  addContactPerson,
} from './contracts.slice';
import { ContactPersonModel } from '../../../models/data/contract/contactPerson.model';
import { AuthConstants } from '../../../constants/auth.constants';
import { AdminConstants } from '../../../constants/admin.constants';
import BackToPage from '../../../components/backToPage/BackToPage';
import ContractDetail from '../components/contractDetail/ContractDetail';
import CreateContactPersonPopup from './components/ContactPersonPopup';
import GenericTable from '../../../components/genericTable/genericTable';
import { uncheckTableGlobalCheckedById } from '../../../store/slices/tableSlice';
import AppGenericPopup from '../../../common/appGenericPopup/AppGenericPopup';

const ContractDetails: React.FC = () => {
  const navigate = useNavigate();
  const { contractId: contractIdParam } = useParams<{ contractId: string }>();
  const dispatch = useAppDispatch();
  const { setModal } = useModalState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const contractId = useMemo(() => +contractIdParam!, [contractIdParam]);

  const { isOpen, text } = useAppSelector(selectAlarmModal);
  const contractState = useAppSelector(selectContractState);
  const contract = useAppSelector(selectSelectedContract);
  const formattedContract = useAppSelector(selectFormattedSelectedContract);
  const isContractProcessing = useAppSelector(selectIsSelectedContractProcessed);
  const machines = useAppSelector(selectFormattedContractMachines);
  const users = useAppSelector(selectSelectedUsers);
  const contactPersons = useAppSelector(selectAvailableContactPersons);

  useEffect(() => {
    return () => {
      dispatch(uncheckTableGlobalCheckedById('contractMachines'));
      dispatch(uncheckTableGlobalCheckedById('contractUsersTable'));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!contractId && contactPersons.length === 0) {
      dispatch(getAllContactPersons());
    }
  }, [contactPersons.length, contractId, dispatch]);

  useEffect(() => {
    if (contractId && contract?.id !== contractId) {
      dispatch(getContractById({ contractId }));
    }
  }, [contract?.id, contractId, dispatch]);

  // Polling request for processing/synchronizing Contracts
  useInterval(
    () => dispatch(getContractByIdPolling({ contractId })),
    isContractProcessing ? AdminConstants.pollingInterval : null
  );

  const handleSubmitContract = async (contractIsNew: boolean) => {
    if (contractIsNew) {
      const { contract: newContract } = await dispatch(createContract({ contractData: formattedContract })).unwrap();

      navigate(`/admin/contract/${newContract?.id}`);
    } else {
      await dispatch(updateContract({ contractData: formattedContract }));
    }
  };

  const handleItemCheckedToggle = useCallback(
    (fieldId: string, itemId: string | number | undefined) => {
      if (itemId) {
        dispatch!(updateItemChecked({ fieldId, itemId }));
      }
    },
    [dispatch]
  );

  const handleItemGlobalCheckedToggle = useCallback(
    (fieldId: string, event: React.FormEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;

      dispatch(updateGlobalItemsChecked({ fieldId, isChecked: !!checked }));
    },
    [dispatch]
  );

  const handleItemDelete = useCallback(
    (fieldId: string, itemId: number | string) => setModal(true, () => dispatch(deleteItem({ fieldId, itemId }))),
    [dispatch, setModal]
  );

  const handleContractItemsBulkDelete = useCallback(
    (fieldId: string) => {
      const checkedItems = contractState[fieldId].filter((item) => item.isChecked);

      if (checkedItems.length === 0) {
        toast.info(translate(`pages.admin.noSelected${fieldId[0].toUpperCase() + fieldId.slice(1)}`));
        return;
      }

      setModal(true, () => {
        dispatch(bulkDeleteItems({ fieldId }));
        dispatch(uncheckTableGlobalCheckedById('contractMachines'));
        dispatch(uncheckTableGlobalCheckedById('contractUsersTable'));
      });
    },
    [contractState, dispatch, setModal]
  );

  const handleContactPersonModalSubmit = useCallback(
    (contractOwner: ContactPersonModel) => {
      dispatch(
        addContactPerson({ text: translate('pages.admin.duplicateContractContactOwner'), contactPerson: contractOwner })
      );

      dispatch(
        setSelectedContractContactOwnerField({
          itemId: 'contactPerson',
          item: contractOwner,
        })
      );
    },
    [dispatch]
  );

  const handleItemAddClick = useCallback(
    (fieldId: string) => navigate(`/admin/contract/${contractId}/add-${fieldId}`),
    [contractId, navigate]
  );

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <>
            <MDBRow className='mb-3'>
              <MDBCol md='12' className='px-4'>
                <BackToPage text={translate('backToLink.contracts')} navigateTo='/admin/contracts'></BackToPage>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='12' className='px-4'>
                <ContractDetail
                  contract={contract}
                  contractId={contractId}
                  onSubmitContract={handleSubmitContract}
                  onCreateContactPerson={() => setIsModalOpen(true)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='12' className='px-4'>
                <MDBCard className='mt-5' >
                  <MDBCardBody>
                    <GenericTable
                      tableId={'contractMachines'}
                      items={machines}
                      hasSearch={true}
                      hasCheckboxInput={true}
                      hasItemsPerPageControl={true}
                      isDisabled={contract?.isProcessed === false}
                      handleItemToggle={(id) => handleItemCheckedToggle('machines', id)}
                      onGlobalItemsChecked={(event) => handleItemGlobalCheckedToggle('machines', event)}
                      onItemDeleteClick={(id) => handleItemDelete('machines', id)}
                      onBulkDeleteClick={() => handleContractItemsBulkDelete('machines')}
                      onActionButtonClick={() => handleItemAddClick('machine')}
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol md='12' className='px-4'>
                <MDBCard className='mt-5' >
                  <MDBCardBody>
                    <GenericTable
                      tableId={'contractUsersTable'}
                      items={users}
                      hasSearch={true}
                      hasCheckboxInput={true}
                      hasItemsPerPageControl={true}
                      isDisabled={contract?.isProcessed === false}
                      handleItemToggle={(id) => handleItemCheckedToggle('users', id)}
                      onGlobalItemsChecked={(event) => handleItemGlobalCheckedToggle('users', event)}
                      onItemDeleteClick={(id) => handleItemDelete('users', id)}
                      onBulkDeleteClick={() => handleContractItemsBulkDelete('users')}
                      onActionButtonClick={() => handleItemAddClick('user')}
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>

            {isModalOpen && (
              <CreateContactPersonPopup
                isOpen={isModalOpen}
                onShowModal={setIsModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleContactPersonModalSubmit}
              />
            )}

            {isOpen && <AppGenericPopup isOpen={isOpen} text={text} onClose={() => dispatch(closeAlarmModal({}))} />}
          </>
        );
      }}
    />
  );
};

export default ContractDetails;
