import React from 'react';

import DateFormatter from '../../../../components/dateFormatter/DataFormatter';
import { MachineDetailsProps } from '../../../../models/props/geo/machineDetails.props';
import { formatMachineStateAttributes } from '../../../../utils/formatting.utility';
import { translate } from '../../../../services/translation.service';
import { converNegativeDegreesToPositive } from '../../../../utils/map.utility';
import './machineDetails.scss';

const MachineDetails: React.FC<MachineDetailsProps> = ({ machine }) => {
  const contactPersonName = machine?.machineOwner?.contactPerson
    ? machine?.machineOwner?.contactPerson?.firstName + ' ' + machine?.machineOwner?.contactPerson?.lastName
    : null;

  return (
    <>
      <div className='mb-2'>
        {translate('common.name')}: {machine?.name || translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div className='mb-2'>
        {translate('common.owner')}: {machine?.machineOwner?.company || translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div className='mb-2'>
        {translate('common.contactName')}: {contactPersonName || translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div className='mb-2'>
        {translate('common.contactEmail')}: {machine?.machineOwner?.contactPerson?.email || translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div className='mb-2'>
        {translate('common.adminGroups')}: {machine?.adminGroups || translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div className='d-flex'>
        <span className='mr-2'>{translate('common.lastMessage')}:</span>
        {machine?.messageTime ? <DateFormatter date={machine?.messageTime} /> : translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div className='d-flex'>
        <span className='mr-2'>{translate('common.lastGpsPosition')}:</span>
        {machine?.gpsTime ? <DateFormatter date={machine?.gpsTime} /> : translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div>
        {translate('pages.geo.longitude')}:{' '}
        {machine?.longitude ? machine?.longitude?.toFixed(6) + '°' : translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div>
        {translate('pages.geo.latitude')}:{' '}
        {machine?.latitude ? machine?.latitude?.toFixed(6) + '°' : translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div>
        {translate('pages.geo.altitude')}:{' '}
        {machine?.altitude ? machine?.altitude?.toFixed(2) + 'm' : translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div className='d-flex'>
        {translate('common.machinePower')}: {formatMachineStateAttributes(machine?.power)}
        {machine?.powerTime && (
          <span className='ml-2'>
            <DateFormatter date={machine?.powerTime} />
          </span>
        )}
      </div>
      <hr className='hr-thin-line' />
      <div className='d-flex'>
        {translate('common.machineEngine')}: {formatMachineStateAttributes(machine?.engine)}{' '}
        {machine?.engineTime && (
          <span className='ml-2'>
            <DateFormatter date={machine?.engineTime} />
          </span>
        )}
      </div>{' '}
      <hr className='hr-thin-line' />
      <div className='d-flex'>
        {translate('common.machineBrake')}: {formatMachineStateAttributes(machine?.brake)}{' '}
        {machine?.brakeTime && (
          <span className='ml-2'>
            <DateFormatter date={machine?.brakeTime} />
          </span>
        )}
      </div>{' '}
      <hr className='hr-thin-line' />
      <div className='d-flex'>
        {translate('common.machineGear')}: {formatMachineStateAttributes(machine?.gear)}{' '}
        {machine?.gearTime && (
          <span className='ml-2'>
            <DateFormatter date={machine?.gearTime} />
          </span>
        )}
      </div>
      <hr className='hr-thin-line' />
      <div className='d-flex'>
        {translate('common.speed')}:{' '}
        {machine?.speed || machine?.speed === 0 ? machine?.speed?.toFixed(2) + ' km/h' : translate('common.none')}{' '}
        {machine?.speedTime && (
          <span className='ml-2'>
            <DateFormatter date={machine?.speedTime} />
          </span>
        )}
      </div>
      <hr className='hr-thin-line' />
      <div>
        {translate('pages.geo.systemName')}: {machine?.systemName || translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div className='d-flex'>
        {translate('common.rotateAngle')}:{' '}
        <span className='d-block ml-1'>
          {machine?.rotateAngle || machine?.rotateAngle === 0
            ? machine?.rotateAngle?.toFixed(2) + '°'
            : translate('common.none')}{' '}
        </span>
        {machine?.rotateAngleTime && (
          <span className='ml-1'>
            <DateFormatter date={machine?.rotateAngleTime} />
          </span>
        )}
      </div>
      <hr className='hr-thin-line' />
      <div className='d-flex'>
        {translate('pages.geo.compassDirection')}:{' '}
        <span className='d-block ml-1'>
          {machine?.compassDirection || machine?.compassDirection === 0
            ? converNegativeDegreesToPositive(machine?.compassDirection).toFixed(2) + '°'
            : translate('common.none')}{' '}
        </span>
        {machine?.compassDirectionTime && (
          <span className='ml-1'>
            <DateFormatter date={machine?.compassDirectionTime} />
          </span>
        )}
      </div>
      <hr className='hr-thin-line' />
      <div>
        {translate('pages.geo.gpsAgeSeconds')}: {machine?.gpsAgeSeconds || translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div>
        {translate('pages.geo.infoAgeSeconds')}: {machine?.infoAgeSeconds || translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div>
        {translate('pages.geo.expectedIntervalSeconds')}: {machine?.expectedIntervalSeconds || translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div className='mb-2'>UUID: {machine?.uuid || translate('common.none')}</div>
      <hr className='hr-thin-line' />
      <div className='mb-2'>
        {translate('common.identifier')}: {machine?.plugIdentifier || translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div className='mb-2'>
        {translate('common.plugUuid')}: {machine?.plugUuid || translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div className='mb-2'>
        {translate('common.setupUuid')}: {machine?.setupUuid || translate('common.none')}
      </div>
      <hr className='hr-thin-line' />
      <div className='mb-2'>
        {translate('pages.admin.modifier')}: {machine?.modifier || translate('common.none')}
      </div>
    </>
  );
};

export default MachineDetails;
