import { useMapEvents } from 'react-leaflet';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { updateMapZoomAndCenterByMapId } from '../../../../store/slices/mapsSlice';

const MapOpenRailwayEvents = ({ mapRef, mapId, activePosition, selectedMachineId }) => {
  const dispatch = useAppDispatch();

  useMapEvents({
    zoomend: () => {
      const newZoom = mapRef.current.getZoom();
      let mapCenter = mapRef.current.getCenter();

      if (activePosition && activePosition.id === selectedMachineId) {
        mapCenter = {
          lat: activePosition.latitude,
          lng: activePosition.longitude,
        };
      }

      dispatch(updateMapZoomAndCenterByMapId({ id: mapId, zoom: newZoom, center: mapCenter }));
    },
  });

  return null;
};

export default MapOpenRailwayEvents;
