import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from './reduxHooks';
import {
  selectUser,
  selectUserLocaleLanguage,
  setCurrentUser,
  setCurrentUserPreferences,
} from '../store/slices/userSlice';
import { CurrentUserModel, CurrentUserPreferencesModel } from '../models/data/user/currentUser.model';
import { Language } from '../models/enum/language.enum';
import { changeLanguage } from '../services/translation.service';
import { mapNumberToLangCode } from '../utils/language.utility';
import { AuthConstants } from '../constants/auth.constants';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/de';

export default function useUserState() {
  const userState = useAppSelector(selectUser);
  const userLocaleLanguage = useAppSelector(selectUserLocaleLanguage);

  const dispatch = useAppDispatch();

  const isAdmin: boolean =
    userState.email && userState.roles.map((r) => r.toLowerCase()).includes(AuthConstants.adminRoleName);

  const setUser = useCallback(
    (currentUser: CurrentUserModel) => {
      changeUserLanguage(currentUser.language);
      dispatch(setCurrentUser(currentUser));
    },
    [dispatch]
  );

  const setUserPreferences = (userPreferences: CurrentUserPreferencesModel) => {
    changeUserLanguage(userPreferences.language);
    dispatch(setCurrentUserPreferences(userPreferences));
  };

  const changeUserLanguage = (language: Language) => {
    const code = mapNumberToLangCode(language || 1);
    changeLanguage(code);
  };

  return {
    isAdmin,
    userState,
    userLocaleLanguage,
    setUser,
    setUserPreferences,
  };
}
