export enum ExceptionMessageCode {
  TelemetryHandlerParamsNotReadable = 1,
  TelemetryHandlerOutOfMemory = 2,
  TelemetryHandlerGeneral = 99,

  GetAllMachines = 101,
  GetMachineDetail = 102,
  GetMachineTrackHistory = 103,
  GetMachineEventHistory = 104,
  GetAvailableMachines = 106,
  AddMachinesToGroup = 107,
  RemoveMachinesFromGroup = 108,
  AddMachineToGroups = 109,
  TrackHistoryNotFound = 110,
  EventHistoryNotFound = 111,
  EditMachineConfiguration = 112,
  GetMachineGeoDetail = 113,
  GetAllMachinesGeo = 114,
  DeleteMachineConfiguration = 115,

  GetAllContracts = 201,
  GetContractsForUser = 202,
  DeleteContractsForUser = 203,
  AddContractForUser = 204,
  EditContractForUser = 205,
  DeleteContracts = 206,
  GetContract = 207,
  AddContract = 208,
  EditContract = 209,
  GetContractMachines = 210,
  DeleteContractMachines = 211,
  GetAvailableContractMachines = 212,
  AddContractMachines = 213,
  GetContractUsers = 214,
  GetAvailableContractUsers = 215,

  EditUser = 301,
  GetAllUsers = 302,
  GetUserDetail = 303,
  EditUserDetail = 304,
  GetAllUsersByContractId = 305,
  GetUserSettings = 306,
  UserNotSynchronized = 307,

  ItemAlreadyExists = 400,
  ItemNotFound = 401,
  ItemNotValid = 402,

  GetUserGroups = 501,
  AddUserGroups = 502,
  DeleteUserGroups = 503,
  EditUserGroup = 504,
  EditFlagUserGroup = 505,

  GetAdminGroups = 601,
  GetAdminGroup = 602,
  CreateAdminGroup = 603,
  EditAdminGroup = 604,
  DeleteAdminGroup = 605,

  GetMachineOwner = 701,
  GetMachineOwners = 702,
  CreateMachineOwner = 703,
  EditMachineOwner = 704,
  DeleteMachineOwner = 705,

  GetAttributes = 801,
  GetAttributesByMachineId = 802,
  UpdateAvailableSensorGroupAttributes = 803,

  GetThema = 901,

  GetAlarm = 1001,
  GetAlarms = 1002,
  GetSensorAlarms = 1003,
  GetSensorAlarmsByMachineId = 1004,

  GetTelemetryRawEvents = 1102,
  GetTrackHistory = 1105,
  GetTelemetryBuckets = 1106,
  GetTelemetryCurrent = 1107,

  GetSystems = 1202,

  GetContactPersons = 1301,

  GetSensorGroup = 1401,
  GetSensorGroups = 1402,
  GetSensorGroupsDetails = 1403,
  CreateSensorGroup = 1404,
  EditSensorGroup = 1405,
  DeleteSensorGroup = 1406,

  GetSetups = 1601,
  GetSetupsByMachine = 1602,

  AddUserStatistics = 1701,
}
