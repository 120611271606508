import React from 'react';

import { MDBAccordion, MDBBtn, MDBCheckbox, MDBCol, MDBCollapse, MDBIcon } from 'mdbreact';

import GroupMachines from '../groupMachines/GroupMachines';
import { UserGroupModel } from '../../../../models/data/group/userGroup.model';
import { AdminGroupModel } from '../../../../models/data/group/adminGroup.model';
import { MachineGroupsProps } from '../../../../models/props/geo/machineGroups.props';
import { favoriteGroupNameTransform } from '../../../../transformers/translate.transform';
import useActiveGroupState from '../../../../hooks/useActiveGroupState';

import './machineGroups.scss';

const MachineGroups: React.FC<MachineGroupsProps> = ({
  groups,
  displayed,
  selectedMachineId,
  onToggleGroupClick,
  onSelectedMachineClick,
  onMachineRemoveClick,
  onMachineDataDetailsClick,
  onMachineGeoDetailsClick,
}) => {
  const { selectedGroup, setSelectedGroup } = useActiveGroupState();

  if (!displayed) {
    return null;
  }

  return (
    <MDBAccordion>
      {groups?.length > 0
        ? (groups as Array<UserGroupModel | AdminGroupModel>).map((group) => (
            <div key={group.id}>
              <MDBCol className='d-flex flex-row align-items-center group-name-header py-2'>
                <MDBCheckbox
                  type='checkbox'
                  id={`checkbox${group.id}`}
                  checked={group.isChecked!}
                  value={group.id}
                  inline
                  onChange={(e) => onToggleGroupClick(e.target.value)}
                />

                <MDBBtn
                  id={group.id.toString()}
                  className='w-100 p-0 text-left group-name-btn '
                  onClick={(e) => {
                    setSelectedGroup(e);
                  }}
                >
                  {favoriteGroupNameTransform(group.name)}
                </MDBBtn>

                <MDBIcon
                  id={group.id}
                  className='px-3 pt-1 clickable'
                  icon={group.id === selectedGroup ? 'angle-up' : 'angle-down'}
                  onClick={setSelectedGroup}
                />
              </MDBCol>

              <hr className='m-0'></hr>

              <MDBCollapse
                id={group.id.toString()}
                show={group.id.toString() === selectedGroup}
                className='w-100 group-machines'
              >
                <GroupMachines
                  groupId={group.id.toString()}
                  machines={[...group.machines].sort((a, b) => a.name.localeCompare(b.name))}
                  selectedMachineId={selectedMachineId}
                  onSelectedMachineClick={onSelectedMachineClick}
                  onMachineRemoveClick={onMachineRemoveClick}
                  onMachineDataDetailsClick={onMachineDataDetailsClick}
                  onMachineGeoDetailsClick={onMachineGeoDetailsClick}
                />
              </MDBCollapse>
            </div>
          ))
        : null}
    </MDBAccordion>
  );
};

export default MachineGroups;
