import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';

import {
  createAdminSensorGroup,
  deleteAdminSensorGroups,
  getAdminSensorGroup,
  getAdminSensorGroups,
  updateAdminSensorGroup,
} from '../../services/adminSensorGroups.service';

import { AdminSensorGroupChartModel, AdminSensorGroupsModel } from '../../models/data/machine/sensorGroup.model';
import { AdminSensorGroupRequestPops } from '../../models/props/profile/createSensorGroupRequest.props';
import { toast } from 'react-toastify';
import { translate } from '../../services/translation.service';
import { AvailableMachineModel } from '../../models/data/machine/availableMachine.model';
import { getAvailableMachines } from '../../services/groupMachine.service';
import { updateAvailableAttributes } from '../../services/attributes.service';
import { AvailableSensorGroupAttributesBindingModel } from '../../models/data/machine/availableSensorGroupAttributesBindingModel.model';

export const getAllAdminSensorGroups = createAsyncThunk<{ sensorGroups: AdminSensorGroupsModel[] }, undefined>(
  'adminSensorGroups/getAllAdminSensorGroups',
  async () => {
    const sensorGroups = await trackPromise(getAdminSensorGroups());

    for (const sensorGroup of sensorGroups) {
      sensorGroup.isChecked = false;
    }

    return { sensorGroups };
  }
);

export const createSensorGroup = createAsyncThunk<
  { newSensorGroup: AdminSensorGroupsModel },
  { sensorGroup: AdminSensorGroupRequestPops }
>('adminSensorGroups/createSensorGroup', async ({ sensorGroup }) => {
  const newSensorGroup = await trackPromise(createAdminSensorGroup(sensorGroup));

  toast.success(translate('pages.profile.createUserSensorGroupSuccessfully'));

  return { newSensorGroup };
});

export const updateSensorGroup = createAsyncThunk<
  { response: boolean; sensorGroup: AdminSensorGroupsModel },
  { sensorGroupId: number; sensorGroup: AdminSensorGroupRequestPops }
>('adminSensorGroups/updateSensorGroup', async ({ sensorGroupId, sensorGroup }) => {
  const response = await trackPromise(updateAdminSensorGroup(sensorGroupId, sensorGroup));

  toast.success(translate('pages.profile.editUserSensorGroupSuccessfully'));

  const updatedSensorGroup = await getAdminSensorGroup(sensorGroupId);

  return { response, sensorGroup: updatedSensorGroup };
});

export const getAdminSensorGroupById = createAsyncThunk<
  { sensorGroup: AdminSensorGroupsModel },
  { sensorGroupId: number }
>('adminSensorGroups/getAdminSensorGroupById', async ({ sensorGroupId }) => {
  const sensorGroup = await trackPromise(getAdminSensorGroup(sensorGroupId));

  for (const machine of sensorGroup.machines) {
    machine.isChecked = false;
  }

  for (const chart of sensorGroup.sensorGroupCharts) {
    for (const sensor of chart.sensors) {
      sensor.isChecked = false;
    }
  }

  return { sensorGroup };
});

export const deleteAdminSensorGroupById = createAsyncThunk<
  { response: boolean; sensorGroupId: number },
  { sensorGroupId: number }
>('adminSensorGroups/deleteAdminSensorGroupById', async ({ sensorGroupId }) => {
  const response = await trackPromise(deleteAdminSensorGroups([sensorGroupId]));

  toast.success(translate('pages.profile.deleteSensorGroup'));

  return { response, sensorGroupId };
});

export const bulkDeleteAdminSensorGroups = createAsyncThunk<
  { response: boolean; sensorGroupIds: number[] },
  { sensorGroupIds: number[] }
>('adminSensorGroups/bulkDeleteAdminSensorGroups', async ({ sensorGroupIds }) => {
  const response = await trackPromise(deleteAdminSensorGroups(sensorGroupIds));

  toast.success(translate('pages.profile.deleteSensorGroups'));

  return { response, sensorGroupIds };
});

export const getSensorAvailableMachines = createAsyncThunk<{ machineUuids: AvailableMachineModel[] }, undefined>(
  'adminSensorGroups/getSensorAvailableMachines',
  async () => {
    const machineUuids = await trackPromise(getAvailableMachines());

    return { machineUuids };
  }
);

export const updateSensorGroupAvailableAttributes = createAsyncThunk<
  { updatedSensorGroupCharts: AdminSensorGroupChartModel[] },
  AvailableSensorGroupAttributesBindingModel
>('adminSensorGroups/updateAdminSensorGroupAvailableAttributes', async (availableSensorGroupAttributes) => {
  const updatedSensorGroupCharts = await trackPromise(updateAvailableAttributes(availableSensorGroupAttributes));

  return { updatedSensorGroupCharts };
});
