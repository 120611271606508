export class GoogleMapConstants {
  public static readonly apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;

  public static readonly geoMachinesGoogleMapId = 'geoMachinesGoogleMap';
  public static readonly geoMachineDetailsGoogleMapId = 'geoMachineDetailsGoogleMap';
  public static readonly dataMachineDetailsGoogleMapId = 'dataMachineDetailsGoogleMap';

  public static readonly containerStyle = {
    width: '100%',
    height: '460px',
  };

  public static readonly defaultCenter = {
    lat: 41.3851,
    lng: 2.1734,
  };

  public static readonly defaultZoom: number = 2;
  public static readonly zoomedMap: number = 16;
  public static readonly zoomedMapDetails: number = 18;

  public static readonly markerClusterOptions = {
    maxZoom: 20, //The maximum zoom level at which clustering is enabled or null if clustering is to be enabled at all zoom levels. The default value is null.
    minimumClusterSize: 10, //The minimum number of markers needed in a cluster before the markers are hidden and a cluster marker appears. The default value is 2.
  };

  public static readonly polylineStyle = {
    strokeColor: '#0000ff',
    strokeOpacity: 1,
    strokeWeight: 1,
  };

  public static readonly polylineArrowStyle = {
    scale: 1.5,
    strokeWeight: 1,
  };

  public static readonly polylineArrowOptions = {
    offset: '100%',
    repeat: '40px',
  };
}
