const sensorTwoDigitsUuids = ['4595BC28-A1F1-4D73-8DAA-E86A2A7EA9B5', '967F95AE-E4B7-42A4-9BAC-286DC59C4763'];

export const roundSensorValue = (sensorUuid: string, sensorValue: string) => {
  if (!sensorUuid || !sensorValue) {
    return sensorValue;
  }

  if (sensorTwoDigitsUuids.includes(sensorUuid.toUpperCase())) {
    return (+sensorValue).toFixed(2);
  }

  if (isNaN(+sensorValue)) {
    return sensorValue;
  }

  return parseFloat((+sensorValue).toFixed(6)).toString();
};
