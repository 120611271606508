import { createSlice, Slice } from '@reduxjs/toolkit';
import { getMachinesData } from '../api/machines.service';

import { RootState } from '../store';

export interface GroupsState {
  activeGroupId: string;
}

const initialState: GroupsState = {
  activeGroupId: '',
};

export const groupsSlice: Slice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setActiveGroupById: (state, action) => {
      state.activeGroupId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMachinesData.fulfilled, (state, action) => {
      const { userGroups } = action.payload;
      if (!state.activeGroupId) {
        const favGroup = userGroups.find((g) => g.isLocked);
        if (favGroup) {
          state.activeGroupId = favGroup.id.toString();
        }
      }
    });
  },
});

export const { setActiveGroupById } = groupsSlice.actions;
export const selectGroup = (state: RootState) => state.groups.activeGroupId;

export default groupsSlice.reducer;
