import { createSlice, Slice } from '@reduxjs/toolkit';

export interface SpinnerState {
  isVisible: boolean;
}

const initialState: SpinnerState = {
  isVisible: false,
};

export const spinnerSlice: Slice = createSlice({
  name: 'spinner',
  initialState,
  reducers: {
    updateVisibility: (state, action) => {
      state.isVisible = action.payload;
    },
  },
});

export const { updateVisibility } = spinnerSlice.actions;

export default spinnerSlice.reducer;
