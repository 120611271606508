import { useState } from 'react';

import { MDBSideNavLink, MDBSideNavItem, MDBSideNavCollapse } from 'mdbreact';

import './sideNavigationGroups.scss';
import { favoriteGroupNameTransform } from '../../transformers/translate.transform';
import { translate } from '../../services/translation.service';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { updateIsFirstRun } from '../../pages/data/Details/dataDetails.slice';

const SideNavigationGroups = ({ userGroups, linkUrl, onLinkClick }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<any>({});

  if (!userGroups || userGroups.length === 0) {
    return null;
  }

  const toggleOpen = (id: string) => {
    setIsOpen({
      ...isOpen,
      [id]: !isOpen[id],
    });
  };

  const navigateTo = (toLocation: string) => {
    dispatch(updateIsFirstRun(true));
    navigate(toLocation);
  };

  return (
    <>
      {userGroups.length > 0 &&
        userGroups.map((userGroup) => (
          <MDBSideNavItem id={`${userGroup.id}`} key={userGroup.id}>
            <MDBSideNavLink
              shouldBeExpanded={isOpen[userGroup.id]}
              onClick={() => toggleOpen(userGroup.id)}
              icon='angle-down'
            >
              {favoriteGroupNameTransform(userGroup.name)}
            </MDBSideNavLink>

            <MDBSideNavCollapse
              style={{ overflow: 'auto', height: 'fit-content' }}
              navbar={true}
              show={isOpen[userGroup.id]}
            >
              {userGroup.machines.length > 0 ? (
                [...userGroup.machines]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((machine) => (
                    <MDBSideNavItem key={machine.id}>
                      <MDBSideNavLink
                        key={machine.id}
                        className='side-navigation-machines'
                        onClick={() => {
                          onLinkClick();
                          navigateTo(`${linkUrl}/${machine.id}`);
                        }}
                        tag='div'
                      >
                        {machine.name}
                      </MDBSideNavLink>
                    </MDBSideNavItem>
                  ))
              ) : (
                <span className='side-navigation-no-machines'>{translate('pages.geo.noMachinesInGroup')}</span>
              )}
            </MDBSideNavCollapse>
          </MDBSideNavItem>
        ))}
    </>
  );
};

export default SideNavigationGroups;
