import { createSelector, createSlice, Slice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { UserGroupModel } from '../../models/data/group/userGroup.model';
import { addUserGroupMachine, removeUserGroupMachine } from './machinesSlice';
import { addMachinesToUserGroup, removeMachinesFromUserGroup } from '../api/userGroups.service';

export interface FlaggedGroupsState {
  groups: UserGroupModel[];
}

const initialState: FlaggedGroupsState = {
  groups: [],
};

export const flaggedGroupsSlice: Slice = createSlice({
  name: 'flaggedGroups',
  initialState,
  reducers: {
    setUserFlaggedGroups: (state, action) => {
      state.groups = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeUserGroupMachine.fulfilled, (state, action) => {
        const { machineId, groupId } = action.payload;
        const group = state.groups.find((group) => group.id.toString() === groupId);

        if (group) {
          group.machines = group.machines.filter((machine) => machine.id !== machineId);
        }
      })
      .addCase(addUserGroupMachine.fulfilled, (state, action) => {
        const { machineToEdit, groups } = action.payload;

        if (machineToEdit) {
          for (const group of state.groups) {
            const currentGroup = groups.find((g) => g.id === group.id);
            const containsMachine = group.machines.some((m) => m.id === machineToEdit.id);

            if (currentGroup?.isChecked && !containsMachine) {
              group.machines.push(machineToEdit);
            } else if (!currentGroup?.isChecked && containsMachine) {
              group.machines = group.machines.filter((m) => m.id !== machineToEdit.id);
            }
          }
        }
      })
      .addCase(addMachinesToUserGroup.fulfilled, (state, action) => {
        const { groupId, machinesToAdd } = action.payload;

        const group = state.groups.find((group) => group.id.toString() === groupId);

        if (group) {
          group.machines.push(...machinesToAdd);
        }
      })
      .addCase(removeMachinesFromUserGroup.fulfilled, (state, action) => {
        const { groupId, machineIds } = action.payload;

        const group = state.groups.find((group) => group.id.toString() === groupId);

        if (group) {
          group.machines = group.machines.filter((machine) => !machineIds.includes(machine.id));
        }
      });
  },
});

export const { setUserFlaggedGroups } = flaggedGroupsSlice.actions;

export const selectFlaggedGroups = (state: RootState) => state.flaggedGroups.groups;

export const selectFlaggedDataGroups = createSelector([selectFlaggedGroups], (groups) =>
  groups.map((group) => ({
    ...group,
    machines: group.machines.filter((m) => m.isDataModuleActive),
  }))
);

export default flaggedGroupsSlice.reducer;
