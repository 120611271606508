import React from 'react';

import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow, MDBTooltip } from 'mdbreact';

import './groupMachines.scss';
import { GroupMachinesProps } from '../../../../models/props/geo/groupMachines.props';
import { translate } from '../../../../services/translation.service';
import useUserState from '../../../../hooks/useUserState';
import useMachinesState from '../../../../hooks/useMachinesState';

const GroupMachines: React.FC<GroupMachinesProps> = ({
  groupId,
  machines,
  selectedMachineId,
  onSelectedMachineClick,
  onMachineRemoveClick,
  onMachineDataDetailsClick,
  onMachineGeoDetailsClick,
}) => {
  const onRemoveClick = (e: any, machineId: number) => {
    onMachineRemoveClick!(machineId, groupId);
    e.stopPropagation();
  };

  const onDetailClick = (e: any, machineId: number) => {
    onMachineGeoDetailsClick!(machineId);
    e.stopPropagation();
  };

  const { isAdmin } = useUserState();
  const { contractPermissions } = useMachinesState();

  return (
    <MDBContainer className='p-0'>
      {machines &&
        machines.map((machine) => (
          <div
            key={machine.id}
            className={
              'd-flex flex-row justify-content-between align-items-center group-machine-row m-0 pr-2 pl-30 clickable ' +
              (selectedMachineId === machine.id ? 'row-selected' : '')
            }
            onClick={() => onSelectedMachineClick(machine.id, groupId)}
          >
            <div className='text-left min-Width-40 pr-20'>{machine.name}</div>
            <div className='d-flex text-right justify-content-end col-machine-groups-buttons'>
              <div className='d-flex justify-content-end detail-buttons'>
                {onMachineDataDetailsClick && (isAdmin || machine.isDataModuleActive) && (
                  <MDBTooltip
                    tag='span'
                    placement='top'
                    className='btn-tooltip'
                    title={translate('tooltip.machineDataDetails')}
                  >
                    <MDBBtn
                      outline
                      size='sm'
                      className={
                        contractPermissions().length === 0 && !isAdmin ? 'd-none' : 'mx-1 my-1 btn-rounded btn-yellow'
                      }
                      onClick={() => onMachineDataDetailsClick?.(machine.id)}
                    >
                      <MDBIcon icon='chart-line' className='mt-0' />
                    </MDBBtn>
                  </MDBTooltip>
                )}
                {machine.isAvailable && (
                  <MDBTooltip
                    tag='span'
                    placement='top'
                    className=' btn-tooltip'
                    title={translate('tooltip.machineGeoDetails')}
                  >
                    <MDBBtn
                      outline
                      size='sm'
                      className='mx-1 my-1 btn-rounded btn-yellow'
                      onClick={(e) => onDetailClick(e, machine.id)}
                    >
                      <MDBIcon icon={'map-marker-alt'} className='mt-0' />
                    </MDBBtn>
                  </MDBTooltip>
                )}
              </div>

              {onMachineRemoveClick && (
                <MDBTooltip
                  tag='span'
                  placement='top'
                  className='btn-tooltip'
                  title={translate('tooltip.removeMachine')}
                >
                  <MDBBtn
                    outline
                    size='sm'
                    className='mx-1 my-1 btn-rounded btn-yellow'
                    onClick={(e) => onRemoveClick(e, machine.id)}
                  >
                    <MDBIcon icon='times-circle' className='mt-0' />
                  </MDBBtn>
                </MDBTooltip>
              )}
            </div>
          </div>
        ))}

      {(!machines || machines.length === 0) && (
        <MDBRow className='group-machine-row'>
          <MDBCol className='text-left pl-5 pt-1 pb-1'>{translate('pages.geo.noMachinesInGroup')}</MDBCol>
        </MDBRow>
      )}
    </MDBContainer>
  );
};

export default GroupMachines;
