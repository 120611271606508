import React from 'react';

import { MDBIcon } from 'mdbreact';

import './profileTogglePanel.scss';
import { ProfileTogglePanelProps } from '../../../../models/props/profile/profileTogglePanel.props';
import { translate } from '../../../../services/translation.service';
import { PROFILE_TABS } from '../../../../utils/profileTabs.utility';

const ProfileTogglePanel: React.FC<ProfileTogglePanelProps> = ({ activeTab, triggerActiveTab }) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    triggerActiveTab(+event.currentTarget.id);
  };

  return (
    <div className='d-flex flex-row profile-header'>
      {PROFILE_TABS.map((tab) => (
        <div
          id={`${tab.id}`}
          key={`${tab.id} ${tab.i18nName}`}
          onClick={handleClick}
          className={`p-3 clickable profile-tab ${activeTab === tab.id ? 'active-tab' : ''}`}
        >
          <MDBIcon icon={tab.icon} />
          <div>{translate(tab.i18nName)}</div>
        </div>
      ))}
    </div>
  );
};

export default ProfileTogglePanel;
