import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBModalFooter } from 'mdbreact';

import GenericTable from '../../../components/genericTable/genericTable';
import { translate } from '../../../services/translation.service';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { getSensorAvailableMachines } from '../../../store/api/userSensorGroups.service';
import { updateTableGlobalCheckedById } from '../../../store/slices/tableSlice';
import {
  addMachines,
  selectAvailableMachines,
  toggleAvailableMachineChecked,
  toggleAvailableGlobalMachinesChecked,
  setDisplayInfoMessage,
} from './sensorGroups.slice';

const AddProfileGroupMachine: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const machines = useAppSelector(selectAvailableMachines);

  const { groupId: groupIdParam } = useParams<{ groupId: string }>();
  const groupId = useMemo(() => +groupIdParam!, [groupIdParam]);

  useEffect(() => {
    dispatch(getSensorAvailableMachines());

    return () => {
      dispatch(
        updateTableGlobalCheckedById({
          id: 'profileGroupAddMachines',
          globalChecked: false,
        })
      );
    };
  }, [dispatch]);

  const handleMachineCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) {
        dispatch!(toggleAvailableMachineChecked(id));
      }
    },
    [dispatch]
  );

  const handleMachineGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;

      dispatch(toggleAvailableGlobalMachinesChecked({ isChecked: !!checked }));
    },
    [dispatch]
  );

  const addSelectedMachines = () => {
    const selectedMachines = machines.filter((m) => m.isChecked);

    if (selectedMachines.length === 0) {
      toast.info(translate('pages.admin.noSelectedMachine'));
      return;
    }

    dispatch(setDisplayInfoMessage(true));
    dispatch(addMachines({ selectedMachines: selectedMachines.map((m) => ({ ...m, isChecked: false })) }));
    navigate(`/profile/group/${groupId}`);
  };

  const onCancel = () => {
    dispatch(setDisplayInfoMessage(true));
    navigate(`/profile/group/${groupId}`);
  };

  return (
    <>
      <MDBRow>
        <MDBCol md='12'>
          <MDBCard>
            <MDBCardBody>
              <MDBRow className='pb-4'>
                <MDBCol md='4' className='text-left d-flex align-items-center'>
                  <MDBIcon icon='file-signature' className='warning-color p-4 shadow-3-strong bg-warning rounded' />
                  <h5 className='font-weight-bold dark-grey-text ml-4'>{translate('pages.admin.addMachines')}</h5>
                </MDBCol>
              </MDBRow>

              <GenericTable
                tableId={'profileGroupAddMachines'}
                items={machines || []}
                hasSearch={true}
                hasCheckboxInput={true}
                hasItemsPerPageControl={true}
                handleItemToggle={handleMachineCheckedToggle}
                onGlobalItemsChecked={handleMachineGlobalCheckedToggle}
              />
            </MDBCardBody>

            <MDBModalFooter>
              <MDBBtn
                color='warning'
                className='text-white'
                onClick={addSelectedMachines}
                data-testid='AddProfileGroupMachine-addSelected-btn'
              >
                {translate('common.addSelected')}
              </MDBBtn>
              <MDBBtn
                outline
                color='warning'
                className='cancel-onHover'
                onClick={onCancel}
                data-testid='AddProfileGroupMachine-cancel-btn'
              >
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default AddProfileGroupMachine;
