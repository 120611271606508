import { createSlice, Slice } from '@reduxjs/toolkit';

import { SensorGroupModel } from '../../models/data/group/sensorGroup.model';
import { RootState } from '../store';

export interface ChartModel {
  name: string;
}
export interface SensorGroupState {
  groups: SensorGroupModel[];
  nextGroupId: number;
  charts: ChartModel[];
}

const initialState: SensorGroupState = {
  groups: [
    {
      name: 'Group 1',
      id: 1,
      description: 'Some random desc',
      isChecked: false,
      sensors: [
        {
          name: 'Temperature',
          id: 1,
          description: 'Some random description',
          chart: 'Default',
          isChecked: false,
        },
        {
          name: 'Pressure',
          id: 2,
          description: 'Some random description',
          chart: 'Default',
          isChecked: false,
        },
      ],
    },
    {
      name: 'Group 2',
      id: 2,
      description: 'Some random desc',
      isChecked: false,
      sensors: [
        {
          name: 'Battery Voltage',
          id: 3,
          description: 'Some random description',
          chart: 'Default',
          isChecked: false,
        },
      ],
    },
    {
      name: 'Group 3',
      id: 3,
      description: 'Some random desc',
      isChecked: false,
      sensors: [
        {
          name: 'Speed',
          id: 4,
          description: 'Some random description',
          chart: 'Default',
          isChecked: false,
        },
        {
          name: 'Elevation',
          id: 5,
          description: 'Some random description',
          chart: 'Default',
          isChecked: false,
        },
      ],
    },
  ],
  nextGroupId: 4,
  charts: [
    {
      name: 'Default',
    },
    {
      name: 'Custom Name 1',
    },
  ],
};

export const sensorGroupsSlice: Slice = createSlice({
  name: 'sensorGroupsSlice',
  initialState,
  reducers: {
    setUserFlaggedGroups: (state, action) => {
      state.groups = action.payload;
    },
    addSensorGroup: (state, action) => {
      state.groups.push(action.payload);
      state.nextGroupId += 1;
    },
    addSensorToGroup: (state, action) => {
      const { groupId, sensor } = action.payload;
      const group = state.groups.find((group) => group.id === groupId);
      if (group) {
        group.sensors.push(sensor);
      }
    },
    addSensorsToGroup: (state, action) => {
      const { groupId, sensors } = action.payload;
      const group = state.groups.find((group) => group.id === groupId);
      if (group) {
        group.sensors = [...group.sensors, ...sensors];
      }
    },
    updateSensorGroupChecked(state, action) {
      const group = state.groups.find((group) => group.id === +action.payload);

      if (group) {
        group.isChecked = !group.isChecked;
      }
    },
    updateSensorGroupGlobalChecked(state, action) {
      state.groups = state.groups.map((group) => ({ ...group, isChecked: !!action.payload }));
    },
    removeSensorsFromGroup: (state, action) => {
      const { groupId, sensors } = action.payload;
      const group = state.groups.find((group) => group.id === groupId);
      const sensorIds = sensors.map((sensor) => sensor.id);
      if (group) {
        group.sensors = group.sensors.filter((sensor) => !sensorIds.includes(sensor.id));
      }
    },
    removeSensorFromGroup: (state, action) => {
      const { groupId, sensorId } = action.payload;
      const group = state.groups.find((group) => group.id === groupId);

      if (group) {
        group.sensors = group.sensors.filter((sensor) => sensor.id !== sensorId);
      }
    },
    updateSensorGroup: (state, action) => {
      const { groupId, groupData } = action.payload;
      const groupIndex = state.groups.findIndex((group) => group.id === +groupId);
      if (groupIndex) {
        state.groups[groupIndex] = groupData;
      }
    },
    deleteSensorGroup: (state, action) => {
      state.groups = state.groups.filter((group) => group.id !== action.payload);
    },
    deleteSensorGroups: (state) => {
      state.groups = state.groups.filter((group) => !group.isChecked);
    },
    addChartName: (state, action) => {
      state.charts.push({ name: action.payload });
    },
    addSensorToChart: (state, action) => {
      const { groupId, sensorId, chartName } = action.payload;
      const group = state.groups.find((group) => group.id === +groupId);

      if (group) {
        const sensor = group.sensors.find((sensor) => sensor.id === +sensorId);
        if (sensor) sensor.chart = chartName;
      }
    },
  },
});

export const {
  addSensorGroup,
  deleteSensorGroup,
  addSensorToGroup,
  addSensorsToGroup,
  updateSensorGroup,
  deleteSensorGroups,
  removeSensorsFromGroup,
  updateSensorGroupChecked,
  updateSensorGroupGlobalChecked,
  removeSensorFromGroup,
  addChartName,
  addSensorToChart,
} = sensorGroupsSlice.actions;

export const selectSensorGroups = (state: RootState) => state.sensorGroups.groups;

export const selectCharts = (state: RootState) => state.sensorGroups.charts;

export const selectSensorGroupById = (state: RootState, groupId: string) =>
  state.sensorGroups.groups.find((group) => group.id === groupId);

export default sensorGroupsSlice.reducer;
