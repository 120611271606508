import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBCard, MDBCardBody, MDBCol, MDBRow, MDBBtn } from 'mdbreact';

import ProtectedRoute from '../../../hocs/ProtectedRoute';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import useInterval from '../../../hooks/useInterval';
import { getAllMachines, getAllMachinesPolling } from '../../../store/api/machines.service';
import {
  selectMachines,
  selectHasProcessingMachines,
  setDisplayInfoMessage,
  clearSelectedMachine,
} from './machines.slice';
import { AuthConstants } from '../../../constants/auth.constants';
import { AdminConstants } from '../../../constants/admin.constants';
import GenericTable from '../../../components/genericTable/genericTable';
import { translate } from '../../../services/translation.service';

const MachinesList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const machines = useAppSelector(selectMachines);
  const hasProcessingMachines = useAppSelector(selectHasProcessingMachines);

  const getMachines = useCallback(() => dispatch(getAllMachines()), [dispatch]);

  useEffect(() => {
    getMachines();
  }, [getMachines]);

  // Polling request for processing/synchronizing Machine/Machines
  useInterval(() => dispatch(getAllMachinesPolling()), hasProcessingMachines ? AdminConstants.pollingInterval : null);

  const onEditMachineClick = useCallback(
    (machineId: number | string) => {
      dispatch(clearSelectedMachine({}));
      dispatch(setDisplayInfoMessage(false));
      navigate(`/admin/machine/${machineId}`);
    },
    [navigate, dispatch]
  );

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <MDBRow>
            <MDBCol md='12' className='px-4'>
              <MDBCard>
                <MDBCardBody>
                  {hasProcessingMachines && (
                    <MDBBtn color='warning' className='text-white' onClick={getMachines}>
                      {translate('common.refresh')}
                    </MDBBtn>
                  )}
                  <GenericTable
                    tableId={'machinesAdminTableSettings'}
                    items={machines}
                    hasSearch={true}
                    hasItemsPerPageControl={true}
                    onItemEditClick={onEditMachineClick}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        );
      }}
    />
  );
};

export default MachinesList;
