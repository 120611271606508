import React from 'react';

import { MDBCol, MDBInput, MDBRow } from 'mdbreact';

import { translate } from '../../../../services/translation.service';
import { ProfileInfoProps } from '../../../../models/props/profile/profileInfo.props';

const ProfileInfo: React.FC<ProfileInfoProps> = ({ user }) => {
  return (
    <>
      <MDBRow>
        <MDBCol lg='4' md='4' className='p-3'>
          <MDBInput
            data-testid='ProfileInfo-FirstName-Input'
            type='text'
            disabled
            label={translate('common.firstName')}
            value={user?.firstName || ''}
            className='bg-transparent'
          />
        </MDBCol>
        <MDBCol lg='4' md='4' className='p-3'>
          <MDBInput
            data-testid='ProfileInfo-LastName-Input'
            type='text'
            disabled
            label={translate('common.lastName')}
            value={user?.lastName || ''}
            className='bg-transparent'
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol lg='4' md='4' className='p-3'>
          <MDBInput
            data-testid='ProfileInfo-Email-Input'
            type='text'
            disabled
            label={translate('common.emailAddress')}
            value={user?.email || ''}
            className='bg-transparent'
          />
        </MDBCol>
        <MDBCol lg='4' md='4' className='p-3'>
          <MDBInput
            data-testid='ProfileInfo-Company-Input'
            type='text'
            disabled
            label={translate('common.company')}
            value={user?.company || ''}
            className='bg-transparent'
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol lg='4' md='4' className='p-3'>
          <MDBInput
            data-testid='ProfileInfo-Roles-Input'
            type='text'
            disabled
            label={translate('pages.profile.roles')}
            value={user?.roles ? user.roles.join(', ') : ''}
            className='bg-transparent'
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default ProfileInfo;
