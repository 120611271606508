import { useEffect, useMemo, useState } from 'react';

import { MDBBtn, MDBCol, MDBIcon, MDBRadio, MDBRow, MDBSelect, MDBSwitch, MDBTypography } from 'mdbreact';

import './periodFilter.scss';
import { translate } from '../../../../services/translation.service';
import { PeriodFilterProps } from '../../../../models/props/geo/periodFilter.props';
import CsvExport from '../../../../components/csvExport/CsvExport';
import useUserState from '../../../../hooks/useUserState';
import { useQueryValue } from '../../../../utils/route.utility';
import { DURATION_FILTER_OPTIONS } from '../../../../utils/filter.utility';
import {
  getCustomTimeZoneUtcOffset,
  getMomentTimeFromUtc,
  getUTC,
  getUtcNow,
} from '../../../../transformers/timeZone.transform';

import moment from 'moment';
import { TimeRangeEnum } from '../../../../models/enum/timeRange.enum';
import DateTimePicker from '../../../../components/dateTimePicker/DateTimePicker';

export const PeriodFilter: React.FC<PeriodFilterProps> = ({
  items,
  duration,
  machineId,
  timeRange,
  startDateUtcString,
  endDateUtcString,
  onViewClick,
}) => {
  const { userState } = useUserState();
  const { timeZone, timeZoneName } = userState;
  const preserveFilterState = useQueryValue('preserveState');

  const [isOptimized, setIsOptimized] = useState(true);
  const [durationState, setDurationState] = useState((preserveFilterState && duration) || 'duration');
  const [filterRange, setFilterRange] = useState((preserveFilterState && timeRange) || 2);
  const getDateTimePickerValue = (preserveFilterState: any, dateUtcString?: string): moment.Moment => {
    return (
      (preserveFilterState &&
        dateUtcString &&
        getMomentTimeFromUtc(dateUtcString!, getCustomTimeZoneUtcOffset(timeZone, timeZoneName))) ||
      moment(new Date())
    );
  };
  const [dateFromMoment, setDateFromMoment] = useState(getDateTimePickerValue(preserveFilterState, startDateUtcString));
  const [dateToMoment, setDateToMoment] = useState(getDateTimePickerValue(preserveFilterState, endDateUtcString));
  const [errorText, setErrorText] = useState<string>('');
  const [filterOptions, setFilterOptions] = useState(() => [
    ...DURATION_FILTER_OPTIONS.map((d) => ({ ...d, text: translate(d.text) })),
  ]);

  useEffect(() => {
    setFilterRange((preserveFilterState && timeRange) || 2);
  }, [preserveFilterState, machineId]); // eslint-disable-line

  useEffect(() => {
    setDurationState((preserveFilterState && duration) || 'duration');
  }, [preserveFilterState, machineId]); // eslint-disable-line

  useEffect(() => {
    setIsOptimized(true);
  }, [machineId]);

  useEffect(() => {
    if (machineId) {
      setFilterOptions((prevState) =>
        prevState.map((option) => ({
          ...option,
          checked: option.value === filterRange?.toString(),
          defaultSelected: option.value === filterRange?.toString(),
        }))
      );
    }
  }, [machineId, filterRange]);

  useEffect(() => {
    setDateFromMoment(getDateTimePickerValue(preserveFilterState, startDateUtcString));
    setDateToMoment(getDateTimePickerValue(preserveFilterState, endDateUtcString));
  }, [machineId]); //eslint-disable-line

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setDurationState(e.currentTarget.id);
  };

  const handleOptimizationChange = () => {
    setIsOptimized((prevState) => !prevState);
  };

  const getDateTimeFrom = (value) => {
    setErrorText('');
    setDateFromMoment(value);
  };

  const getDateTimeTo = (value) => {
    setErrorText('');
    setDateToMoment(value);
  };

  const handlePeriodChange = (chosenPeriod: any) => {
    const value = chosenPeriod.value;

    if (isNaN(+value)) {
      setFilterRange(filterRange);
      setDurationState('duration');
    } else if (+value !== filterRange) {
      setFilterRange(+value);
      setDurationState('duration');
    }
  };

  const handleViewClick = () => {
    if (durationState === 'duration') {
      onViewClick(filterRange, durationState, null, null, null, null, isOptimized);
    } else {
      const dateFromUtc = getUTC(dateFromMoment.toDate(), timeZone, timeZoneName);
      const dateFromString = new Date(dateFromUtc.getTime() - dateFromUtc.getTimezoneOffset() * 60000).toISOString();
      const dateToUtc = getUTC(dateToMoment.toDate(), timeZone, timeZoneName);
      const dateToString = new Date(dateToUtc.getTime() - dateToUtc.getTimezoneOffset() * 60000).toISOString();

      if (dateFromMoment.toDate() >= dateToMoment.toDate()) {
        setErrorText(translate('common.toDateAfterFromDate'));
        return;
      }
      if (dateFromUtc > getUtcNow()) {
        setErrorText(translate('common.fromDateInTheFuture'));
        return;
      }
      if (dateToUtc > getUtcNow()) {
        setErrorText(translate('common.toDateInTheFuture'));
        return;
      }

      onViewClick(null, durationState, dateFromUtc, dateFromString, dateToUtc, dateToString, isOptimized);
    }
  };

  const showWarningForAverageData = useMemo(() => {
    const customFilterRangeInMs = dateToMoment.toDate().getTime() - dateFromMoment.toDate().getTime();
    const customFilterRangeInDays = customFilterRangeInMs / (1000 * 3600 * 24);

    return (
      (filterRange > TimeRangeEnum.LastWeek && durationState === 'duration') ||
      (durationState === 'custom-period' && customFilterRangeInDays > 7)
    );
  }, [filterRange, durationState, dateToMoment, dateFromMoment]);

  return (
    <MDBRow className='period-filter px-4'>
      <MDBCol md='12' lg='3' xl='3'>
        <MDBRow className='filter-label sensor-duration-label text-left font-weight-bold py-1 pl-2'>
          {translate('pages.geo.duration')}
        </MDBRow>
        <MDBCol className='pt-4 pl-1 mb-3' md='6' lg='12' xl='12'>
          <MDBRow>
            <MDBCol className='radio-buttons'>
              <MDBRadio
                id='duration'
                name='exampleRadios'
                onChange={handleChange}
                checked={durationState === 'duration'}
              />
            </MDBCol>
            <MDBCol>
              <MDBSelect
                onValueChange={(e: any) => handlePeriodChange(e)}
                data={filterOptions}
                value={filterRange?.toString()}
                label={translate('pages.geo.selectFilter')}
                disabled={durationState === 'custom-period'}
                size='lg'
              />
              {showWarningForAverageData && (
                <div className='period-filter-message-panel period-filter-panel-position m-0 mt-2 p-1 border-2'>
                  <MDBIcon icon='info-circle' className='amber-text autoWidth' size='1x' color='warning' />
                  <MDBTypography tag='span' className='period-filter-message fs-12'>
                    {translate('pages.geo.reductionDataToAverageValues')}
                  </MDBTypography>
                </div>
              )}
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBCol>

      <MDBCol sm='12' md='12' lg='5' xl='4'>
        <MDBRow className='filter-label text-left font-weight-bold py-1 pl-2 pr-4 w-100'>
          {translate('common.customPeriod')}
        </MDBRow>

        <MDBCol className='mt-4 pl-1' md='12' lg='12' xl='12'>
          <MDBRow md='12' lg='12' xl='12'>
            <MDBCol className='filter-wrapper'>
              <MDBRadio
                id='custom-period'
                name='exampleRadios'
                onChange={handleChange}
                checked={durationState === 'custom-period'}
              />
            </MDBCol>
            <MDBRow className='d-flex flex-column align-items-start mt-0 flex-md-row' sm='11' md='11' lg='11' xl='11'>
              <MDBCol md='6' lg='9' xl='9' className='p-0 pl-3 mb-4 '>
                <DateTimePicker
                  label={translate('common.fromDate')}
                  disabled={durationState === 'duration'}
                  value={dateFromMoment}
                  onChange={getDateTimeFrom}
                ></DateTimePicker>
              </MDBCol>
              <MDBCol md='6' lg='9' xl='9' className='p-0 pl-3'>
                <DateTimePicker
                  label={translate('common.toDate')}
                  disabled={durationState === 'duration'}
                  value={dateToMoment}
                  onChange={getDateTimeTo}
                ></DateTimePicker>
              </MDBCol>
            </MDBRow>
          </MDBRow>
        </MDBCol>

        <MDBRow>
          <MDBCol md='12'>
            <span className='error-message d-flex align-items-start mt-2 pt-0 mb-3'>{errorText}</span>
          </MDBCol>
        </MDBRow>
      </MDBCol>

      <MDBCol md='12' lg='2' xl='2'>
        <MDBRow className='filter-label sensor-duration-label text-left font-weight-bold py-1 pl-2'>
          {translate('common.dataOptimization')}
        </MDBRow>
        <MDBRow>
          <div className='d-flex'>
            <span className='pr-3 mt-2'>{translate('common.off')}</span>
            <MDBSwitch
              inline
              className='mt-2 switch-no-border'
              checked={isOptimized}
              onChange={handleOptimizationChange}
            />
            <span className='pl-1 mt-2'>{translate('common.on')}</span>
          </div>
        </MDBRow>
      </MDBCol>

      <MDBCol md='12' lg='2' xl='3'>
        <MDBRow lg='12' xl='12'>
          <MDBBtn color='warning' className='text-white mb-2' onClick={handleViewClick}>
            {translate('pages.data.viewData')}
          </MDBBtn>
        </MDBRow>
        <MDBRow lg='12' xl='12'>
          <MDBBtn color='warning'>
            <CsvExport
              id='trackHistory'
              items={items}
              buttonTitle={translate('common.exportVisible')}
              fileName='track-history'
              classes='text-white'
            />
          </MDBBtn>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};
