import { createSlice, Slice, createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../store/store';
import { AlarmModel } from '../../../models/data/machine/alarms.model';
import { getAlarm } from '../../../store/api/alarms.service';

export interface alarmState {
  alarm: AlarmModel;
  alarmModal: { text: string; isOpen: boolean };
}

const initialState: alarmState = {
  alarmModal: {
    text: '',
    isOpen: false,
  },
  alarm: {
    attribute: '',
    message: '',
    rules: [],
    notification: { emails: [], phones: [] },
  } as AlarmModel,
};

export const alarmSlice: Slice = createSlice({
  name: 'alarm',
  initialState,
  reducers: {
    updateItemChecked(state, action) {
      const { itemId } = action.payload;

      const item = state.alarm.rules.find((item) => item?.id?.toString() === itemId);

      if (item) {
        item.isChecked = !item?.isChecked;
      }
    },
    updateNotificationChecked(state, action) {
      const { fieldId, itemId } = action.payload;

      const item = state.alarm.notification[fieldId].find((item) => item?.id?.toString() === itemId);

      if (item) {
        item.isChecked = !item?.isChecked;
      }
    },
    updateGlobalItemChecked(state, action) {
      const { isChecked } = action.payload;

      state.alarm.rules = state.alarm.rules.map((item) => ({ ...item, isChecked: isChecked }));
    },
    updateGlobalNotificationsChecked(state, action) {
      const { isChecked } = action.payload;

      const keys = Object.keys(state.alarm.notification);

      for (const key of keys) {
        state.alarm.notification[key] = state.alarm.notification[key].map((item) => ({
          ...item,
          isChecked: isChecked,
        }));
      }
    },
    addRule(state, action) {
      const { text, rule } = action.payload;
      const ruleExists = state.alarm.rules.some((r) => r.symbol === rule.symbol && r.value === rule.value);

      if (ruleExists) {
        state.alarmModal = { text: text, isOpen: true };
      } else {
        state.alarm.rules.push(rule);
      }
    },
    addAlarmData(state, action) {
      const { id, value } = action.payload;

      state.alarm[id] = value;
    },
    addNotification(state, action) {
      const { text, notification } = action.payload;
      const { type, ...rest } = notification;
      const notificationExists = state.alarm.notification.emails.some((n) => n.email === notification.email);

      if (notificationExists) {
        state.alarmModal = { text: text, isOpen: true };
      } else {
        state.alarm.notification[type] = [...state.alarm.notification[type], rest];
      }
    },
    setAlarm(state, action) {
      const { alarm } = action.payload;

      state.alarm = alarm;
    },
    editRule(state, action) {
      const { text, rule } = action.payload;
      const ruleExists = state.alarm.rules
        .filter((r) => r.id !== rule.id)
        .some((r) => r.symbol === rule.symbol && r.value === rule.value);

      if (ruleExists) {
        state.alarmModal = { text: text, isOpen: true };
      } else {
        state.alarm.rules = state.alarm.rules.map((r) => (r.id !== rule.id ? r : rule));
      }
    },
    editNotification(state, action) {
      const { text, updatedNotification, originalNotification } = action.payload;
      const notificationExists = state.alarm.notification.emails
        .filter((n) => n.id !== originalNotification.id)
        .some((n) => n.email === updatedNotification.email);

      if (notificationExists) {
        state.alarmModal = { text: text, isOpen: true };
      } else {
        if (updatedNotification.type === originalNotification.type) {
          state.alarm.notification[updatedNotification.type] = state.alarm.notification[updatedNotification.type].map(
            (n) => (n.id !== originalNotification.id ? n : updatedNotification)
          );
        } else {
          const { type, ...rest } = updatedNotification;

          state.alarm.notification = state.alarm.notification[originalNotification.type].filter(
            (n) => n.id !== originalNotification.id
          );
          if (state.alarm.notification[type]) {
            state.alarm.notification[type] = [...state.alarm.notification[type], rest];
            return;
          }
          state.alarm.notification[type] = [rest];
        }
      }
    },
    deleteRule(state, action) {
      const { ruleId } = action.payload;

      state.alarm.rules = state.alarm.rules.filter((rule) => rule.id !== ruleId);
    },
    bulkDeleteRules(state, action) {
      const { fieldId } = action.payload;

      state.alarm[fieldId] = state.alarm[fieldId].filter((item) => !item.isChecked);
    },
    deleteNotification(state, action) {
      const { fieldId, itemId } = action.payload;

      state.alarm.notification[fieldId] = state.alarm.notification[fieldId].filter((item) => item.id !== itemId);
    },
    bulkDeleteNotifications(state) {
      const keys = Object.keys(state.alarm.notification);

      for (const key of keys) {
        state.alarm.notification[key] = state.alarm.notification[key].filter((item) => !item.isChecked);
      }
    },
    closeAlarmModal(state) {
      state.alarmModal = initialState.alarmModal;
    },
    clearAlarmState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAlarm.fulfilled, (state, action) => {
      const { alarm } = action.payload;

      state.alarm = alarm;
    });
  },
});

export const {
  updateItemChecked,
  updateGlobalItemChecked,
  updateNotificationChecked,
  updateGlobalNotificationsChecked,
  addRule,
  addNotification,
  addAlarmData,
  setAlarm,
  editRule,
  editNotification,
  deleteRule,
  deleteNotification,
  bulkDeleteRules,
  bulkDeleteNotifications,
  closeAlarmModal,
  clearAlarmState,
} = alarmSlice.actions;

export default alarmSlice.reducer;

export const selectAlarm = (state: RootState) => state.alarm.alarm;

export const selectAlarmModal = (state: RootState) => state.alarm.alarmModal;

export const selectAlarmRules = createSelector([selectAlarm], (alarm) => alarm?.rules);

export const selectAlarmNotifications = createSelector([selectAlarm], (alarm) => alarm?.notification);

const selectFlattenedNotifications = createSelector(
  [selectAlarmNotifications],
  (notification) => notification && Object.values(notification).flat()
);

export const selectTransformedNotifications = createSelector(
  [selectFlattenedNotifications],
  (notifications) =>
    notifications &&
    notifications.length &&
    notifications.length > 0 &&
    notifications.map((n) => ({ ...n, type: n?.email ? 'Email' : 'Phone' }))
);
