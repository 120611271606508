import { createSlice, Slice } from '@reduxjs/toolkit';
import { GoogleMapConstants } from '../../constants/googleMap.constants';
import { OpenRailwayMapConstants } from '../../constants/openRailwayMap.constants';
import { MapPosition } from '../../models/data/common/mapPosition.model';
import { MachineModel } from '../../models/data/machine/machine.model';
import { MachineTrackHistoryModel } from '../../models/data/machine/machineTrackHistory.model';
import { EventTrackHistoryModel } from '../../models/data/machine/eventTrackHistory.model';
import { getBucketGpsEvents } from '../api/telemetry.service';
import { RootState } from '../store';

interface MapState {
  positions: MachineModel[] | MachineTrackHistoryModel[] | EventTrackHistoryModel[];
  activePosition: MachineModel | MachineTrackHistoryModel | EventTrackHistoryModel | null;
  isTooltipOpen: boolean;
  center: MapPosition;
  zoom: number | undefined;
  preventFitBounds: boolean;
  path: MapPosition[];
}

export interface MapsState {
  userLocation: MapPosition | null;
  geoMachinesGoogleMap: MapState;
  geoMachineDetailsGoogleMap: MapState;
  dataMachineDetailsGoogleMap: MapState;
  geoMachinesOpenRailwayMap: MapState;
  geoMachineDetailsOpenRailwayMap: MapState;
  dataMachineDetailsOpenRailwayMap: MapState;
}

const initialMapState: MapState = {
  positions: [],
  activePosition: null,
  isTooltipOpen: false,
  center: { lat: 0, lng: 0 },
  zoom: undefined,
  preventFitBounds: false,
  path: [],
};

const initialState: MapsState = {
  userLocation: null,
  geoMachinesGoogleMap: {
    ...initialMapState,
    center: GoogleMapConstants.defaultCenter,
    zoom: GoogleMapConstants.defaultZoom,
  },
  geoMachineDetailsGoogleMap: {
    ...initialMapState,
    center: GoogleMapConstants.defaultCenter,
    zoom: GoogleMapConstants.defaultZoom,
  },
  dataMachineDetailsGoogleMap: {
    ...initialMapState,
    center: GoogleMapConstants.defaultCenter,
    zoom: GoogleMapConstants.defaultZoom,
  },
  geoMachinesOpenRailwayMap: {
    ...initialMapState,
    center: OpenRailwayMapConstants.defaultCenter,
    zoom: undefined,
  },
  geoMachineDetailsOpenRailwayMap: {
    ...initialMapState,
    center: OpenRailwayMapConstants.defaultCenter,
    zoom: OpenRailwayMapConstants.defaultZoom,
  },
  dataMachineDetailsOpenRailwayMap: {
    ...initialMapState,
    center: OpenRailwayMapConstants.defaultCenter,
    zoom: OpenRailwayMapConstants.defaultZoom,
  },
};

export const mapsSlice: Slice = createSlice({
  name: 'maps',
  initialState,
  reducers: {
    setUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },
    setUserLocationByMapId: (state, action) => {
      state[action.payload.id].center = state.userLocation;
      state[action.payload.id].zoom = action.payload.zoom;
      state[action.payload.id].preventFitBounds = true;
      state[action.payload.id].isTooltipOpen = false;
      state[action.payload.id].activePosition = null;
    },
    setActivePositionByMapId: (state, action) => {
      state[action.payload.id].activePosition = action.payload.activePosition;
    },
    removeActivePositionByMapId: (state, action) => {
      state[action.payload.id].activePosition = null;
    },
    updateTooltipByMapId: (state, action) => {
      state[action.payload.id].isTooltipOpen = action.payload.isTooltipOpen;
    },
    setPathByMapId: (state, action) => {
      state[action.payload.id].path = action.payload.path;
    },
    setPositionsByMapId: (state, action) => {
      state[action.payload.id].positions = action.payload.positions || [];
    },
    updateMapAndActivePositionByMapId: (state, action) => {
      state[action.payload.id] = {
        path: state[action.payload.id].path,
        positions: state[action.payload.id].positions || [],
        isTooltipOpen: state[action.payload.id].isTooltipOpen || false,
        ...action.payload,
      };
    },
    updateMapCenterByMapId: (state, action) => {
      state[action.payload.id].center = action.payload.center;
    },
    updateMapZoomAndCenterByMapId: (state, action) => {
      state[action.payload.id].zoom = action.payload.zoom;
      state[action.payload.id].center = action.payload.center;
    },
    updateFitBounds: (state, action) => {
      state[action.payload.id].preventFitBounds = action.payload.preventFitBounds;
    },
    resetMapState: (state, action) => {
      const mapsToReset = [
        GoogleMapConstants.geoMachinesGoogleMapId,
        GoogleMapConstants.geoMachineDetailsGoogleMapId,
        GoogleMapConstants.dataMachineDetailsGoogleMapId,
        OpenRailwayMapConstants.geoMachinesOpenRailwayMapId,
        OpenRailwayMapConstants.geoMachineDetailsOpenRailwayMapId,
        OpenRailwayMapConstants.dataMachineDetailsOpenRailwayMapId,
      ];

      mapsToReset.forEach(
        (mapId) =>
          (state[mapId] = { path: state[mapId].path, positions: state[mapId].positions || [], ...action.payload })
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBucketGpsEvents.fulfilled, (state, action) => {
      const { eventTrackHistory } = action.payload;
      const path = eventTrackHistory.map((e) => ({ lat: e.latitude, lng: e.longitude }));
      state.dataMachineDetailsGoogleMap.path = path;
      state.dataMachineDetailsOpenRailwayMap.path = path;
    });
  },
});

export const {
  setUserLocation,
  setUserLocationByMapId,
  setActivePositionByMapId,
  removeActivePositionByMapId,
  updateTooltipByMapId,
  setPathByMapId,
  setPositionsByMapId,
  updateMapCenterByMapId,
  updateMapZoomAndCenterByMapId,
  updateMapAndActivePositionByMapId,
  resetMapState,
  updateFitBounds,
} = mapsSlice.actions;

export default mapsSlice.reducer;

export const selectUserLocation = (state: RootState) => state.maps.userLocation;
