import { createSelector, createSlice, Slice } from '@reduxjs/toolkit';

import { RootState } from '../../../store/store';

import { DataAlarmModel } from '../../../models/data/machine/alarms.model';
import { getDataSensorAlarms } from '../../../store/api/alarms.service';
import { selectSelectedMachineUuid, selectVisibleMachineUuids } from '../../../store/slices/machinesSlice';
import { TimeRangeEnum } from '../../../models/enum/timeRange.enum';

export interface dataMachinesState {
  alarms: DataAlarmModel[];
  alarmsTimeRange: TimeRangeEnum;
}

const initialState: dataMachinesState = {
  alarms: [],
  alarmsTimeRange: TimeRangeEnum.LastWeek,
};

export const dataMachinesSlice: Slice = createSlice({
  name: 'dataMachines',
  initialState,
  reducers: {
    setTimeRange(state, action) {
      state.alarmsTimeRange = action.payload;
    },
    clearMachineSensorGroups() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataSensorAlarms.fulfilled, (state, action) => {
      const { alarms } = action.payload;

      state.alarms = alarms;
    });
  },
});

export const { setTimeRange, clearMachineSensorGroups } = dataMachinesSlice.actions;

export default dataMachinesSlice.reducer;

export const selectDataAlarms = (state: RootState) => state.dataMachines.alarms;

export const selectAlarmsTimeRange = (state: RootState) => state.dataMachines.alarmsTimeRange;

export const selectVisibleAlarms = createSelector(
  [selectDataAlarms, selectVisibleMachineUuids, selectSelectedMachineUuid],
  (alarms, uuids, selectedMachineUuid) => {
    if (alarms && alarms.length > 0) {
      if (selectedMachineUuid) {
        return alarms.filter((alarm) => alarm?.machineUuid?.toLowerCase() === selectedMachineUuid);
      }
      if (uuids && uuids.length > 0) {
        return alarms.filter((alarm) => uuids.includes(alarm?.machineUuid?.toLowerCase()));
      }
    }
    return alarms;
  }
);
