import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { GoogleMapConstants } from '../constants/googleMap.constants';
import { OpenRailwayMapConstants } from '../constants/openRailwayMap.constants';
import {
  setSelectedMachineId,
  updateMachineVisibility,
  updateBulkMachinesVisibility,
  updateMachineChecked,
  updateGlobalChecked,
  updateGroupChecked,
  removeUserGroupMachine,
  addUserGroupMachine,
  selectUserGroups,
  selectMachines,
  selectSelectedMachineId,
  selectAdminGroups,
  selectHasMachineData,
  selectSelectedMachine,
} from '../store/slices/machinesSlice';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import useTablesState from './useTablesState';
import useActiveTabState from './useActiveTabState';
import { translate } from '../services/translation.service';

import { getMachinesData } from '../store/api/machines.service';
import { UserGroupTableModel } from '../models/data/group/userGroupTable.model';
import {
  resetMapState,
  setActivePositionByMapId,
  updateFitBounds,
  updateMapCenterByMapId,
  updateTooltipByMapId,
} from '../store/slices/mapsSlice';

const pageMapIds = [GoogleMapConstants.geoMachinesGoogleMapId, OpenRailwayMapConstants.geoMachinesOpenRailwayMapId];

export default function useMachinesState() {
  const dispatch = useAppDispatch();

  const userGroups = useAppSelector(selectUserGroups);
  const adminGroups = useAppSelector(selectAdminGroups);
  const allMachines = useAppSelector(selectMachines);
  const hasMachineData = useAppSelector(selectHasMachineData);
  const selectedMachine = useAppSelector(selectSelectedMachine);
  const selectedMachineId = useAppSelector(selectSelectedMachineId);
  const { setGlobalItemsChecked } = useTablesState({ tableId: 'allGeoMachines' });
  const { tabsState } = useActiveTabState();

  const { groupTab } = tabsState;

  const contractPermissions = useCallback(
    () => allMachines.filter((machine) => machine.isDataModuleActive),
    [allMachines]
  );

  // Api handling functions
  const getGeoData = useCallback(() => {
    dispatch(getMachinesData());
  }, [dispatch]);

  const removeUserGroupMachineClick = useCallback(
    async (machineId: number, groupId: string) => {
      await dispatch(removeUserGroupMachine({ machineId, groupId }));
      dispatch(updateMachineVisibility(machineId));
    },
    [dispatch]
  );

  const addUserGroupMachineClick = useCallback(
    (machineId: number, groups: UserGroupTableModel[]) => dispatch(addUserGroupMachine({ machineId, groups })),
    [dispatch]
  );

  // State handling helper functions
  const resetActiveMachine = useCallback(() => {
    dispatch!(setSelectedMachineId(-1));
    dispatch(resetMapState({}));
  }, [dispatch]);

  const handleMachineOnMapToggle = useCallback(
    (machineId: number) => {
      if (Number(machineId) === selectedMachineId) {
        resetActiveMachine();
      }

      dispatch!(updateMachineVisibility(machineId));
    },
    [dispatch, selectedMachineId] // eslint-disable-line
  );

  const handleMachineCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) {
        dispatch!(updateMachineChecked(id));
      }
    },
    [dispatch]
  );

  const handleGroupToggle = useCallback(
    (groupId: string | number, requiredChecked?: boolean) => {
      const groupsId = groupTab === 2 ? 'adminGroups' : 'userGroups';

      if (!requiredChecked) {
        resetActiveMachine();
      }

      dispatch!(updateGroupChecked({ groupsId, groupId, isChecked: requiredChecked }));
    },
    [dispatch, groupTab, resetActiveMachine]
  );

  const handleMachineSelect = (machineId: number, groupId?: string) => {
    if (groupId) {
      handleGroupToggle(groupId, true);
    }

    const machine = allMachines.find((machine) => machine.id === machineId);
    if (!machine?.longitude || !machine?.latitude) {
      toast.dismiss();
      toast.warn(`${translate('common.machine')} ${machine?.name} ${translate('pages.geo.noLocationInfo')}`, {
        autoClose: false,
      });

      resetActiveMachine();
      return;
    }

    if (machineId === selectedMachineId) {
      resetActiveMachine();
      return;
    } else {
      toast.dismiss();

      pageMapIds.forEach((id) => {
        dispatch(updateFitBounds({ id, preventFitBounds: true }));
        dispatch(updateTooltipByMapId({ id, isTooltipOpen: false }));
        dispatch(setActivePositionByMapId({ id, activePosition: machine }));
        dispatch(updateMapCenterByMapId({ id, center: { lat: machine.latitude, lng: machine.longitude } }));
      });

      dispatch!(setSelectedMachineId(machine.id));
      dispatch!(updateMachineVisibility(machineId));
    }
  };

  const handleDataMachineSelect = (machineId: number) => {
    if (machineId === selectedMachineId) {
      resetActiveMachine();
      return;
    }

    dispatch(setSelectedMachineId(machineId));
  };

  const handleBulkMachinesAdd = useCallback(() => {
    dispatch!(updateBulkMachinesVisibility(true));
    setGlobalItemsChecked(false);
  }, [dispatch, setGlobalItemsChecked]);

  const handleBulkMachinesRemove = useCallback(() => {
    dispatch!(updateBulkMachinesVisibility(false));
    setGlobalItemsChecked(false);
  }, [dispatch, setGlobalItemsChecked]);

  const handleGlobalItemsChecked = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      setGlobalItemsChecked(!!event.currentTarget.checked);
      dispatch!(updateGlobalChecked(!!event.currentTarget.checked));
    },
    [dispatch, setGlobalItemsChecked]
  );

  return {
    userGroups,
    adminGroups,
    allMachines,
    hasMachineData,
    selectedMachine,
    selectedMachineId,
    resetActiveMachine,
    getGeoData,
    handleMachineSelect,
    handleDataMachineSelect,
    handleMachineOnMapToggle,
    handleBulkMachinesAdd,
    handleBulkMachinesRemove,
    handleMachineCheckedToggle,
    handleGlobalItemsChecked,
    handleGroupToggle,
    removeUserGroupMachineClick,
    addUserGroupMachineClick,
    contractPermissions,
  };
}
