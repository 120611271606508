import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';

import tabsSlice from './slices/tabsSlice';
import tablesSlice from './slices/tableSlice';
import groupsSlice from './slices/groupsSlice';
import mapsSlice from './slices/mapsSlice';
import userSlice from './slices/userSlice';
import flaggedGroupsSlice from './slices/flaggedGroupsSlice';
import machinesSlice from './slices/machinesSlice';
import modalSlice from './slices/modalSlice';
import sensorGroupSlice from './slices/sensorGroupSlice';
import userGroupsSlice from './slices/userGroupsSlice';
import adminGroupsSlice from '../pages/admin/AdminGroups/adminGroups.slice';
import attributesSlice from '../pages/admin/Attributes/attributes.slice';
import machineOwnersSlice from '../pages/admin/MachineOwners/machineOwners.slice';
import adminMachinesSlice from '../pages/admin/Machines/machines.slice';
import alarmSlice from '../pages/admin/Alarm/alarm.slice';
import contracts from '../pages/admin/Contracts/contracts.slice';
import userSensorGroupsSlice from '../pages/profile/SensorGroups/sensorGroups.slice';
import dataDetailsSlice from '../pages/data/Details/dataDetails.slice';
import dataMachinesSlice from '../pages/data/DataMachines/dataMachines.slice';
import adminSensorGroupsSlice from '../pages/admin/AdminSensorGroups/adminSensorGroups.slice';
import machineFilterSlice from './slices/machineFilterSlice';
import spinnerSlice from './slices/spinnerSlice';
import userStatisticsSlice from './slices/userStatisticsSlice';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  reducer: {
    tabs: tabsSlice,
    tables: tablesSlice,
    groups: groupsSlice,
    maps: mapsSlice,
    user: userSlice,
    flaggedGroups: flaggedGroupsSlice,
    machines: machinesSlice,
    modal: modalSlice,
    sensorGroups: sensorGroupSlice,
    userGroups: userGroupsSlice,
    adminGroups: adminGroupsSlice,
    attributes: attributesSlice,
    machineOwners: machineOwnersSlice,
    adminMachines: adminMachinesSlice,
    alarm: alarmSlice,
    contracts: contracts,
    userSensorGroups: userSensorGroupsSlice,
    dataDetails: dataDetailsSlice,
    dataMachines: dataMachinesSlice,
    adminSensorGroups: adminSensorGroupsSlice,
    machineFilter: machineFilterSlice,
    spinner: spinnerSlice,
    userStatistics: userStatisticsSlice,
  },
  middleware: customizedMiddleware,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
