import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
// @ts-ignore
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'; //COMMENT: TypeScript disable?
import '@microsoft/applicationinsights-shims';
import 'mdbreact/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'react-toastify/scss/main.scss';

import './index.scss';
import App from './App';
import ScrollToTop from './app/common/scrollToTop/ScrollToTop';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { reactPlugin } from './app/utils/appInsights';
//import { customHistory } from './app/utils/browserHistory.utility';
import { store } from './app/store/store';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StyledEngineProvider } from '@mui/material/styles';

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <Router>
        <ScrollToTop />
        <ToastContainer hideProgressBar={true} newestOnTop={true} autoClose={5000} theme={'colored'} />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <App />
          </AppInsightsContext.Provider>
        </LocalizationProvider>
      </Router>
    </StyledEngineProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
