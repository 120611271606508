import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import {
  getAllMachines as getMachines,
  getMachineDetails,
  updateAdminMachineConfiguration,
  getAllGeoMachines,
  deleteAdminMachineConfiguration,
} from '../../services/machine.service';
import { getAlarmsByMachine } from '../../services/alarams.service';
import { getMachineOwnerByMachine } from '../../services/machineOwners.service';
import { GeoMachineModel, MachineModel } from '../../models/data/machine/machine.model';
import { MachineOwnerModel } from '../../models/data/machine/machineOwner.model';
import { MachineAlarmModel } from '../../models/data/machine/alarms.model';
import { getSystems } from '../../services/system.service';
import { SystemModel } from '../../models/data/machine/system.model';
import { UserGroupModel } from '../../models/data/group/userGroup.model';
import { AdminGroupModel } from '../../models/data/group/adminGroup.model';
import { RootState } from '../store';
import { getUserGroups } from '../../services/group.service';
import { getAdminGroups } from '../../services/adminGroup.service';
import { toast } from 'react-toastify';
import { translate } from '../../services/translation.service';
import { enrichGroupMachines, getVisibleMachines } from '../../utils/machines.mapping.utility';

export const getAllMachines = createAsyncThunk<{ machines: MachineModel[] }, undefined>(
  'adminMachines/getAllMachines',
  async () => {
    const machines = await trackPromise(getMachines());

    return { machines };
  }
);

export const getGeoMachines = createAsyncThunk<{ machines: GeoMachineModel[]; systems: SystemModel[] }, undefined>(
  'dataMachines/getGeoMachines',
  async () => {
    const [geoMachines, systems] = await trackPromise(Promise.all([getAllGeoMachines(), getSystems()]));

    const machines = geoMachines.map(
      (machine) =>
        ({
          ...machine.data,
          ...machine.telemetry,
          systemName:
            systems && systems.find((s) => s.uuid.toLowerCase() === machine.telemetry?.systemUuid?.toLowerCase())?.name,
        } as GeoMachineModel)
    );

    return { machines, systems };
  }
);

export const getUserGroupsData = createAsyncThunk<
  { userGroups: UserGroupModel[] },
  undefined,
  {
    state: RootState;
  }
>('dataMachines/getGeoUserGroups', async (_, thunkApi) => {
  const uGroups = await getUserGroups();

  const { userGroups: stateUserGroups, allMachines: stateMachines } = thunkApi.getState().machines;
  const machineIds = stateMachines.map((m) => m.id);

  const userGroups = enrichGroupMachines(uGroups, stateUserGroups, machineIds) as UserGroupModel[];

  return { userGroups };
});

export const getMachinesData = createAsyncThunk<
  {
    userGroups: UserGroupModel[];
    adminGroups: AdminGroupModel[];
    allMachines: MachineModel[];
    systems: SystemModel[];
  },
  undefined,
  {
    state: RootState;
  }
>('machines/getMachinesFlow', async (_, thunkApi) => {
  const [uGroups, aGroups, geoMachines, systems] = await trackPromise(
    Promise.all([getUserGroups(), getAdminGroups(), getAllGeoMachines(), getSystems()])
  );

  if (geoMachines.length === 0) {
    toast.info(translate('common.emptyMachinesResult'));
  }

  const mappedMachines = geoMachines.map(
    (machine) =>
      ({
        ...machine.data,
        ...machine.telemetry,
        systemName:
          systems && systems.find((s) => s.uuid.toLowerCase() === machine.telemetry?.systemUuid?.toLowerCase())?.name,
      } as GeoMachineModel)
  );

  const {
    allMachines: stateMachines,
    userGroups: stateUserGroups,
    adminGroups: stateAdminGroups,
  } = thunkApi.getState().machines;

  const machineIds = mappedMachines.map((m) => m.id);

  const userGroups = enrichGroupMachines(uGroups, stateUserGroups, machineIds) as UserGroupModel[];
  const adminGroups = enrichGroupMachines(aGroups, stateAdminGroups, machineIds) as AdminGroupModel[];
  const { allMachines } = getVisibleMachines(stateMachines, mappedMachines, userGroups);

  return { userGroups, adminGroups, allMachines, systems };
});

export const getAllMachinesPolling = createAsyncThunk<{ machines: MachineModel[] }, undefined>(
  'adminMachines/getAllMachinesPolling',
  async () => {
    const machines = await getMachines();

    return { machines };
  }
);

export const getMachineById = createAsyncThunk<
  { machine: MachineModel; machineOwner: MachineOwnerModel; alarms: MachineAlarmModel[] },
  { machineId: number }
>('adminMachines/getMachineById', async ({ machineId }) => {
  const [machine, machineOwner, alarms] = await trackPromise(
    Promise.all([getMachineDetails(machineId), getMachineOwnerByMachine(machineId), getAlarmsByMachine(machineId)])
  );

  for (const group of machine.adminGroups || []) {
    group.isChecked = false;
  }

  for (const alarm of alarms) {
    alarm.isChecked = false;
  }

  return { machine, machineOwner, alarms };
});

export const getMachineByIdPolling = createAsyncThunk<
  { machine: MachineModel; machineOwner: MachineOwnerModel; alarms: MachineAlarmModel[] },
  { machineId: number }
>('adminMachines/getMachineByIdPolling', async ({ machineId }) => {
  const [machine, machineOwner, alarms] = await Promise.all([
    getMachineDetails(machineId),
    getMachineOwnerByMachine(machineId),
    getAlarmsByMachine(machineId),
  ]);

  return { machine, machineOwner, alarms };
});

export const updateMachineConfiguration = createAsyncThunk<
  { machine: MachineModel },
  { machineId: number; machineData: MachineModel }
>('adminMachines/updateAdminMachineConfiguration', async ({ machineId, machineData }) => {
  const machine = await trackPromise(updateAdminMachineConfiguration(machineId, machineData));

  toast.success(translate('pages.admin.machineConfigurationUpdated'));

  return { machine };
});

export const deleteMachineConfiguration = createAsyncThunk<
  { machine: MachineModel },
  { machineId: number; machineData: MachineModel }
>('adminMachines/deleteAdminMachineConfiguration', async ({ machineId, machineData }) => {
  const machine = await trackPromise(deleteAdminMachineConfiguration(machineId, machineData));

  toast.success(translate('pages.admin.machineConfigurationDeleted'));

  return { machine };
});
