import axios from 'axios';

import { MachineModel } from '../models/data/machine/machine.model';
import { MachineDbGeoModel } from '../models/data/machine/machineDbGeo.model';
import { MachineDetailsModel } from '../models/data/machine/machineDetails.model';
import { MachineEventHistoryModel } from '../models/data/machine/machineEventHistory.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getAllMachines = async () => {
  const result = await axios.get<MachineDbGeoModel[]>(`${baseApiUrl}api/machines/geo`);
  const machines: MachineModel[] = [];

  for (const machine of result.data) {
    const machineModel: MachineModel = {
      id: machine.data.id,
      uuid: machine.data.uuid,
      modifier: machine.data.modifier,
      name: machine.data.name,
      nameShort: machine.data.nameShort,
      systemName: machine.telemetry?.systemUuid,
      longitude: machine.telemetry?.longitude,
      latitude: machine.telemetry?.latitude,
      altitude: machine.telemetry?.altitude,
      messageTime: machine.telemetry?.messageTime,
      gpsTime: machine.telemetry?.gpsTime,
      isProcessed: machine.data.isProcessed,
      adminGroup: '',
      rotateAngle: machine.telemetry?.rotateAngle,
      compassDirection: machine.telemetry?.compassDirection,
      machineState: machine.telemetry?.machineState,
      power: machine.telemetry?.power,
      engine: machine.telemetry?.engine,
      brake: machine.telemetry?.brake,
      gear: machine.telemetry?.gear,
      isChecked: false,
      isOnMap: false,
    };

    machines.push(machineModel);
  }

  return machines;
};

export const getAllGeoMachines = async () => {
  const result = await axios.get<MachineDbGeoModel[]>(`${baseApiUrl}api/machines/geo`);

  return result?.data;
};

export const getMachineDetails = async (id: number) => {
  const result = await axios.get<MachineModel>(`${baseApiUrl}api/machines/${id}`);
  return result?.data;
};

export const getMachineGeoDetails = async (id: number) => {
  const result = await axios.get<MachineDetailsModel>(`${baseApiUrl}api/machines/geo/${id}`);
  return result?.data;
};

export const updateFavoriteMachines = async (machineIds: number[]) => {
  const result = await axios.put<number[]>(`${baseApiUrl}api/machines`, machineIds);
  return result?.data;
};

export const getMachineEventHistory = async (id: number, timeRange: number) => {
  const result = await axios.get<MachineEventHistoryModel[]>(
    `${baseApiUrl}api/machines/event-history/${id}?timeRange=${timeRange}`
  );
  return result?.data;
};

export const updateAdminMachineConfiguration = async (id: number, machineConfig: any) => {
  const result = await axios.put<MachineModel>(`${baseApiUrl}api/machines/${id}`, machineConfig);
  return result?.data;
};

export const deleteAdminMachineConfiguration = async (id: number, machineConfig: any) => {
  const result = await axios.delete<MachineModel>(`${baseApiUrl}api/machines/${id}`, { data: machineConfig });
  return result?.data;
};
