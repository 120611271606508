import { ExceptionMessageCode } from '../models/enum/exceptionMessageCode.enum';
import { translate } from '../services/translation.service';

export const getExceptionMessage = (exceptionMessageCode: ExceptionMessageCode) => {
  const message = ExceptionMessageCode[exceptionMessageCode];
  if (!message) {
    return translate('exception.somethingWentWrong');
  }

  const key = message.charAt(0).toLowerCase() + message.slice(1);
  return translate(`exception.${key}`);
};
