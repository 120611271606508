import axios from 'axios';

import { UserGroupModel } from '../models/data/group/userGroup.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getUserGroups = async () => {
  const result = await axios.get<UserGroupModel[]>(`${baseApiUrl}api/groups`);

  for (const group of result?.data) {
    group.isChecked = false;
    for (const machine of group.machines) {
      machine.isChecked = false;
    }
  }

  return result?.data;
};

export const addUserGroup = async (groupName: string, machineIds: number[]) => {
  const result = await axios.post<UserGroupModel[]>(`${baseApiUrl}api/groups`, { name: groupName, machineIds });
  for (const group of result?.data) {
    group.isChecked = false;
  }

  return result?.data;
};

export const editUserGroup = async (groupId: string, groupName: string, machineIds: number[]) => {
  const result = await axios.put<UserGroupModel[]>(`${baseApiUrl}api/groups/${groupId}`, {
    name: groupName,
    machineIds,
  });

  for (const group of result?.data) {
    group.isChecked = false;
  }

  return result?.data;
};

export const editFlagUserGroup = async (groupId: string) => {
  const result = await axios.put<UserGroupModel[]>(`${baseApiUrl}api/groups/flag/${groupId}`);
  for (const group of result?.data) {
    group.isChecked = false;
  }

  return result?.data;
};

export const deleteUserGroups = async (groupIds: string[]) => {
  const result = await axios.delete<UserGroupModel[]>(`${baseApiUrl}api/groups`, { data: groupIds });
  for (const group of result?.data) {
    group.isChecked = false;
  }

  return result?.data;
};
