import { setActiveGroupById } from '../store/slices/groupsSlice';
import { useAppDispatch, useAppSelector } from './reduxHooks';

export default function useActiveGroupState() {
  const selectedGroup = useAppSelector((state) => state.groups.activeGroupId);
  const dispatch = useAppDispatch();

  const setSelectedGroup = (event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
    const groupId = event.currentTarget.id;
    if (selectedGroup === groupId) {
      dispatch!(setActiveGroupById(''));
    } else {
      dispatch!(setActiveGroupById(groupId.toString()));
    }
  };

  return {
    selectedGroup,
    setSelectedGroup,
  };
}
