import { createSlice, Slice } from '@reduxjs/toolkit';

export interface ModalState {
  modal: { isOpen: boolean; callbackFunc: (() => void) | null };
}

const initialState: ModalState = {
  modal: { isOpen: false, callbackFunc: null },
};

export const modalSlice: Slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    updateModal: (state, action) => {
      state.modal = action.payload;
    },
  },
});

export const { updateModal } = modalSlice.actions;

export default modalSlice.reducer;
