import { createSelector, createSlice, Slice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { Language } from '../../models/enum/language.enum';
import { TimestampType } from '../../models/enum/timestampType.enum';
import { mapNumberToLangCode } from '../../utils/language.utility';

export interface UserState {
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  language: Language;
  timeZone: TimestampType;
  timeZoneName: string;
  roles: string[];
}

const initialState: UserState = {
  email: '',
  firstName: '',
  lastName: '',
  company: '',
  language: Language.German,
  timeZone: TimestampType.Localized,
  timeZoneName: '',
  roles: [],
};

export const userSlice: Slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (_, action) => {
      return action.payload;
    },
    setCurrentUserPreferences: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setCurrentUser, setCurrentUserPreferences } = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export const selectUserLanguage = createSelector([selectUser], (user) => mapNumberToLangCode(user.language));
export const selectUserLocaleLanguage = createSelector([selectUser], (user) => {
  switch (user.language) {
    case 1:
      return 'en-gb';
    case 2:
      return 'de';
    case 3:
      return 'it';
    case 4:
      return 'fr';
  }
});

export default userSlice.reducer;
