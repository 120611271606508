import axios from 'axios';

import { UserModel } from '../models/data/user/user.model';
import { UserDetailsModel } from '../models/data/user/userDetails.model';
import { UserProfileBindingModel } from '../models/data/user/userProfileBinding.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getUserDetails = async (id: string) => {
  const result = await axios.get<UserDetailsModel>(`${baseApiUrl}api/users/${id}`);
  return result?.data;
};

export const getUsers = async () => {
  const result = await axios.get<UserModel[]>(`${baseApiUrl}api/users/all`);
  return result?.data;
};

export const editUserProfile = async (userProfileBindingModel: UserProfileBindingModel) => {
  const result = await axios.put<UserDetailsModel>(`${baseApiUrl}api/users`, userProfileBindingModel);
  return result?.data;
};
