import { useCallback, useEffect, useState } from 'react';

import { getUserPreferences } from '../services/userSettings.service';
import { getAccessToken, getCachedUser, isAuthenticated, logoutUser, setUserToken } from '../services/auth.service';
import useUserState from './useUserState';

const reactRedirectUri = process.env.REACT_APP_B2C_REDIRECT_URL!;

export default function useAuthorization(): [
  boolean,
  () => Promise<void>,
  React.Dispatch<React.SetStateAction<boolean>>
] {
  const { setUser } = useUserState();
  const [isLoading, setIsLoading] = useState(true);

  const signInSetup = useCallback(async () => {
    const isLoggedIn = await isAuthenticated();
    const hasCodeInUrl = window.location.href.includes('code=');

    if (!isLoggedIn && !hasCodeInUrl) {
      logoutUser();
      return;
    }

    if (!isLoggedIn && hasCodeInUrl) {
      const urlTokens = window.location.href.split('&code=');

      const redirectUrl = decodeURIComponent(urlTokens[0].split('state=')[1]);
      const code = urlTokens[1];

      const userToken = await getAccessToken(code);
      setUserToken(userToken);

      if (redirectUrl.startsWith(reactRedirectUri) && redirectUrl.length > reactRedirectUri.length) {
        window.location.href = redirectUrl;
      } else {
        window.location.href = reactRedirectUri;
      }

      return;
    }

    if (isLoggedIn) {
      const claims = getCachedUser();
      try {
        const userPreferences = await getUserPreferences();

        setUser({
          email: claims.email,
          firstName: claims.firstName,
          lastName: claims.lastName,
          userId: userPreferences.userId,
          language: userPreferences.language || 1,
          timeZone: userPreferences.timeZone || 1,
          timeZoneName: userPreferences.timeZoneName,
          company: userPreferences.companyName || '',
          roles: userPreferences.roles,
          appVersion: userPreferences.appVersion,
        });
      } catch (error: any) {
        console.error(error);
      }

      setIsLoading(false);
    }
  }, [setUser]);

  useEffect(() => {
    signInSetup();
  }, [signInSetup]);

  return [isLoading, signInSetup, setIsLoading];
}
