import React from 'react';

import { MDBBtn } from 'mdbreact';

import { MapSettingsProps } from '../../models/props/components/mapSettings.props';

const MapSettings: React.FC<MapSettingsProps> = ({ label, onButtonClick }) => {
  return (
    <MDBBtn
      className='text-white d-flex mt-2 mr-2 auto-zoom-font map-settings-button'
      color='warning'
      size='sm'
      onClick={onButtonClick}
    >
      {label}
    </MDBBtn>
  );
};

export default MapSettings;
