import { useState, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from './reduxHooks';
import { getUserGroupsData } from '../store/api/machines.service';
import { UserGroupModel } from '../models/data/group/userGroup.model';
import { GroupMachineModel } from '../models/data/machine/groupMachine.model';
import useFlaggedGroupsState from './useFlaggedGroupsState';
import {
  selectUserGroups,
  updateUserGroupsGlobalChecked,
  updateUserGroupChecked,
  setUserGroups,
  selectHasUserGroupsData,
  addMachinesToUserGroup,
  removeMachinesFromUserGroup,
  selectSelectedGroup,
} from '../store/slices/userGroupsSlice';
import {
  addUserMachineGroup,
  getUserMachineGroups,
  editUserMachineGroupFlag,
  removeUserMachineGroup,
  bulkRemoveUserMachineGroup,
  editUserMachineGroup,
} from '../store/api/userGroups.service';

export default function useUserGroupState() {
  const dispatch = useAppDispatch();
  const userGroups = useAppSelector(selectUserGroups);
  const selectedGroup = useAppSelector(selectSelectedGroup);
  const hasUserMachineGroupsData = useAppSelector(selectHasUserGroupsData);
  const { setFlaggedGroups } = useFlaggedGroupsState();
  const [globalChecked, setGlobalChecked] = useState<boolean>(false);

  const getUserMachineGroupsData = useCallback(() => dispatch(getUserMachineGroups()), [dispatch]);

  const handleEditUserMachineGroupFlag = useCallback(
    async (groupId: string) => {
      await dispatch(editUserMachineGroupFlag({ groupId }));
      const result = await dispatch(getUserGroupsData()).unwrap();
      setFlaggedGroups(result.userGroups);
    },
    [dispatch, setFlaggedGroups]
  );

  const handleAddUserMachineGroup = useCallback(
    async (groupName: string, machineIds: number[]) => {
      const groups = await dispatch!(addUserMachineGroup({ groupName, machineIds })).unwrap();
      const result = await dispatch(getUserGroupsData()).unwrap();
      setFlaggedGroups(result.userGroups);

      return groups;
    },
    [dispatch, setFlaggedGroups]
  );

  const handleEditUserMachineGroup = useCallback(
    async (groupId: string, groupName: string, machineIds: number[]) => {
      await dispatch(editUserMachineGroup({ groupId, groupName, machineIds }));
      const result = await dispatch(getUserGroupsData()).unwrap();
      setFlaggedGroups(result.userGroups);
    },
    [dispatch, setFlaggedGroups]
  );

  const handleDeleteUserMachineGroup = useCallback(
    async (groupId: string) => {
      await dispatch(removeUserMachineGroup({ groupId }));
      const result = await dispatch(getUserGroupsData()).unwrap();
      setFlaggedGroups(result.userGroups);
    },
    [dispatch, setFlaggedGroups]
  );

  const handleBulkDeleteUserMachineGroup = useCallback(async () => {
    const groupIds = userGroups.filter((g) => g.isChecked && !g.isLocked).map((g) => g.id);
    await dispatch!(bulkRemoveUserMachineGroup({ groupIds }));
    const result = await dispatch(getUserGroupsData()).unwrap();
    setFlaggedGroups(result.userGroups);
  }, [userGroups, dispatch, setFlaggedGroups]);

  const handleMachineCheckedToggle = useCallback(
    (group: UserGroupModel) => {
      if (group.id) {
        dispatch(updateUserGroupChecked(group.id));
      }
    },
    [dispatch]
  );

  const handleGlobalUserGroupsChecked = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      setGlobalChecked(!!event.currentTarget.checked);
      dispatch(updateUserGroupsGlobalChecked(!!event.currentTarget.checked));
    },
    [dispatch]
  );

  const handleRemoveGroupMachines = useCallback(
    async (machineIds: number[]) => dispatch(removeMachinesFromUserGroup(machineIds)),
    [dispatch]
  );

  const handleBulkAddGroupMachine = useCallback(
    (machines: GroupMachineModel[]) => dispatch(addMachinesToUserGroup(machines)),
    [dispatch]
  );

  const setUserGroupsMachines = useCallback((groups: UserGroupModel[]) => dispatch(setUserGroups(groups)), [dispatch]);

  return {
    userGroups,
    selectedGroup,
    hasUserMachineGroupsData,
    globalChecked,
    setUserGroupsMachines,
    getUserMachineGroupsData,
    handleMachineCheckedToggle,
    handleGlobalUserGroupsChecked,
    handleAddUserMachineGroup,
    handleEditUserMachineGroup,
    handleEditUserMachineGroupFlag,
    handleDeleteUserMachineGroup,
    handleBulkDeleteUserMachineGroup,
    handleRemoveGroupMachines,
    handleBulkAddGroupMachine,
  };
}
