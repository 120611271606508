import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBModalFooter } from 'mdbreact';

import { toast } from 'react-toastify';
import ProtectedRoute from '../../hocs/ProtectedRoute';
import { AuthConstants } from '../../constants/auth.constants';
import { translate } from '../../services/translation.service';
import GenericTable from '../../components/genericTable/genericTable';
import { getAvailableUsers } from '../../store/api/contract.service';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { updateTableGlobalCheckedById } from '../../store/slices/tableSlice';
import {
  addItemsByFieldId,
  updateItemChecked,
  updateGlobalItemsChecked,
  selectFilteredAvailableContractUsers,
  selectCheckedAvailableContractUsers,
} from '../admin/Contracts/contracts.slice';

const AddContractUsers: React.FC = () => {
  const navigate = useNavigate();
  const { contractId } = useParams<{ contractId: string }>();

  const dispatch = useAppDispatch();

  const users = useAppSelector(selectFilteredAvailableContractUsers);
  const selectedUsers = useAppSelector(selectCheckedAvailableContractUsers);

  useEffect(() => {
    dispatch(getAvailableUsers({ contractId: +contractId! }));

    return () => {
      dispatch(updateGlobalItemsChecked({ fieldId: 'availableUsers', isChecked: false }));
      dispatch(
        updateTableGlobalCheckedById({
          id: 'addContractUser',
          globalChecked: false,
        })
      );
    };
  }, [contractId, dispatch]);

  const backToDetailsPage = useCallback(() => navigate(`/admin/contract/${contractId}`), [contractId, navigate]);

  const addSelectedUsers = () => {
    if (selectedUsers.length === 0) {
      toast.info(translate('pages.admin.noSelectedUser'));
      return;
    }

    dispatch(addItemsByFieldId({ fieldId: 'users', items: selectedUsers }));

    backToDetailsPage();
  };

  const handleUserCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) {
        dispatch!(updateItemChecked({ fieldId: 'availableUsers', itemId: id }));
      }
    },
    [dispatch]
  );

  const handleUserGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;
      dispatch(updateGlobalItemsChecked({ fieldId: 'availableUsers', isChecked: !!checked }));
    },
    [dispatch]
  );

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <MDBRow>
            <MDBCol md='12' className='px-4'>
              <MDBCard>
                <MDBCardBody>
                  <MDBRow className='pb-4'>
                    <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
                      <MDBIcon icon='file-signature' color='dark' className='p-4 shadow-3-strong bg-warning rounded' />
                      <h5 className='font-weight-bold ml-4'>{translate('pages.admin.addContractUser')}</h5>
                    </MDBCol>
                  </MDBRow>

                  <GenericTable
                    tableId={'addContractUser'}
                    items={users || []}
                    hasSearch={true}
                    hasItemsPerPageControl={true}
                    hasCheckboxInput={true}
                    handleItemToggle={handleUserCheckedToggle}
                    onGlobalItemsChecked={handleUserGlobalCheckedToggle}
                  />
                </MDBCardBody>

                <MDBModalFooter>
                  <MDBBtn color='warning' className='text-white' onClick={addSelectedUsers}>
                    {translate('common.addSelected')}
                  </MDBBtn>
                  <MDBBtn outline color='warning' className='cancel-onHover' onClick={backToDetailsPage}>
                    {translate('common.cancel')}
                  </MDBBtn>
                </MDBModalFooter>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        );
      }}
    />
  );
};

export default AddContractUsers;
