import axios from 'axios';
import { UserStatisticModel } from '../models/data/user/userStatistic.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const addUserStatistics = async (userStatistics: UserStatisticModel[]) => {
  const result = await axios.post<boolean>(`${baseApiUrl}api/userStatistics`, {
    userStatistics: Array.from(new Set(userStatistics)),
  });

  return result?.data;
};
