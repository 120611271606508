import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCheckbox,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModalFooter,
  MDBRow,
  MDBSelect,
} from 'mdbreact';

import { translate } from '../../../../services/translation.service';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import SynchronizingStatus from '../SynchronizingStatus/SynchronizingStatus';
import { ContractDetailProps } from '../../../../models/props/admin/contractDetail.props';
import {
  setSelectedItemField,
  setSelectedContractContactOwnerField,
  selectFormattedContractContactPersons,
  selectAvailableContactPersons,
  selectSelectedContractContractPerson,
} from '../../Contracts/contracts.slice';
import moment, { Moment } from 'moment';
import { SelectData } from '../../../../models/types/MDBtypes';

import './contractDetail.scss';
import { MobileDatePicker } from '@mui/x-date-pickers';

const ContractDetail: React.FC<ContractDetailProps> = ({
  contract,
  contractId,
  onSubmitContract,
  onCreateContactPerson,
}) => {
  const dispatch = useAppDispatch();

  const contactPerson = useAppSelector(selectSelectedContractContractPerson);
  const contractContactPeople = useAppSelector(selectFormattedContractContactPersons);
  const availableContactPeople = useAppSelector(selectAvailableContactPersons);

  const navigate = useNavigate();
  const [errorText, setErrorText] = useState<string>('');
  const [ownerErrorText, setOwnerErrorText] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(!!contractId);
  const [contactPersonList, setContactPersonList] = useState<any>(contractContactPeople);
  const [selectedContactPerson, setSelectedContactPerson] = useState<string>();
  const [isInvalidContractName, setIsInvalidContractName] = useState<boolean>(false);
  const [isInvalidCompanyName, setIsInvalidCompanyName] = useState<boolean>(false);
  const [isInvalidContactPerson, setIsInvalidContactPerson] = useState<boolean>(false);

  const tomorrow = useMemo(() => {
    const tomorrowDate = new Date();
    const tomorrowDay = new Date().getDate() + 1;
    tomorrowDate.setDate(tomorrowDay);
    tomorrowDate.setHours(0, 0, 0, 0);

    return tomorrowDate;
  }, []);

  const startDate = useMemo(() => {
    if (contract?.startDate) {
      return contract?.startDate;
    }

    return new Date();
  }, [contract]);

  const stopDate = useMemo(() => {
    if (contract?.stopDate) {
      return contract?.stopDate;
    }

    return tomorrow;
  }, [contract, tomorrow]);

  const handleDateChange = useCallback(
    (value: Moment | string | null, dateId: string) => {
      setErrorText('');
      dispatch(setSelectedItemField({ fieldId: dateId, value }));
    },
    [dispatch]
  );

  useEffect(() => {
    setEditMode(!!contractId);
    handleDateChange(moment(startDate), 'startDate');
    handleDateChange(moment(stopDate), 'stopDate');
  }, [contractId]); // eslint-disable-line

  useEffect(() => {
    setContactPersonList(contractContactPeople);
  }, [contractContactPeople]);

  useEffect(() => {
    if (contactPerson) {
      setOwnerErrorText('');
      setSelectedContactPerson(contactPerson.id.toString());
    }
  }, [contactPerson]);

  useEffect(() => {
    const selectedPerson = contactPersonList.find((x) => x.value === selectedContactPerson);

    if (selectedPerson) {
      selectedPerson.defaultSelected = 'true';
      selectedPerson.checked = 'true';

      setContactPersonList((old) => {
        return [...old, selectedPerson];
      });
    }
  }, [contactPerson, selectedContactPerson]); //eslint-disable-line

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { id, value } = event.currentTarget;

      dispatch(setSelectedItemField({ fieldId: id, value }));
    },
    [dispatch]
  );

  const handleIsDataModuleActiveChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { id, value } = event.currentTarget;
      const valueToBool = value === 'false' ? false : true;

      dispatch(setSelectedItemField({ fieldId: id, value: !valueToBool }));
    },
    [dispatch]
  );

  const handleCompanyChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;

      dispatch(setSelectedContractContactOwnerField({ itemId: 'company', item: value }));
    },
    [dispatch]
  );

  const submitForm = useCallback(
    (event) => {
      event.preventDefault();
      const isValid = validateForm();
      if (!isValid) {
        return;
      }
      onSubmitContract(!editMode);
    },
    [contract?.name, contract?.startDate, contract?.stopDate, contract?.contractOwner, editMode, onSubmitContract] //eslint-disable-line
  );

  const validateForm = (): boolean => {
    let isValid = true;
    if (!contract || !contract.name || contract!.name === '') {
      setIsInvalidContractName(true);
      isValid = false;
    } else {
      setIsInvalidContractName(false);
    }

    if (!contract || !contract.contractOwner?.company || contract!.contractOwner?.company === '') {
      setIsInvalidCompanyName(true);
      isValid = false;
    } else {
      setIsInvalidCompanyName(false);
    }

    if (!contract?.contractOwner?.contactPerson) {
      setOwnerErrorText(translate('pages.admin.conractPersonIsRequired'));
      setIsInvalidContactPerson(true);
      isValid = false;
    } else {
      setOwnerErrorText('');
    }

    if (new Date(startDate) >= new Date(stopDate)) {
      setErrorText(translate('pages.admin.endDateShouldBeGreaterThanStartDate'));
      isValid = false;
    }

    const now = new Date();
    const nowUTC12AM = new Date(now.setMinutes(now.getMinutes() - now.getTimezoneOffset()));

    if (nowUTC12AM >= new Date(stopDate)) {
      setErrorText(translate('pages.admin.endDateShouldBeAfterNow'));
      isValid = false;
    }

    if (!contract?.name || !contract?.contractOwner?.contactPerson) {
      isValid = false;
    }

    return isValid;
  };

  const handleContractOwnerChange = useCallback(
    (event: any) => {
      const value = event.value;

      if (value && contract?.contractOwner?.contactPerson?.id !== +value) {
        const contactPerson = availableContactPeople.find((cp) => cp.id === +value);

        dispatch(setSelectedContractContactOwnerField({ itemId: 'contactPerson', item: contactPerson }));
      }
    },
    [availableContactPeople, contract?.contractOwner?.contactPerson?.id, dispatch]
  );

  return (
    <>
      <MDBCard>
        <MDBCardBody>
          <form className='needs-validation contract-detail-form' onSubmit={submitForm} noValidate>
            <MDBRow className='pb-4' between>
              <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
                <MDBIcon icon='file-signature' color='dark' className='p-4 shadow-3-strong bg-warning rounded' />
                <h5 className='font-weight-bold ml-4'>
                  {editMode ? translate('common.edit') : translate('common.create')} {translate('pages.admin.contract')}
                </h5>
              </MDBCol>
              {editMode && !contract?.isProcessed && <SynchronizingStatus />}
            </MDBRow>
            <MDBRow className='my-4'>
              <MDBCol md='6' xl='3' className='mb-4'>
                <MDBInput
                  id='name'
                  className={isInvalidContractName ? 'form-control is-invalid' : 'form-control'}
                  required
                  type='text'
                  label={`${translate('common.name')}*`}
                  value={contract?.name}
                  onChange={handleChange}
                >
                  <span className='invalid-feedback error-message'>
                    {translate('pages.admin.contractNameIsRequired')}
                  </span>
                </MDBInput>
              </MDBCol>
              <MDBCol md='6' xl='3'>
                <MDBInput
                  id='company'
                  className={isInvalidCompanyName ? 'form-control is-invalid' : 'form-control'}
                  required
                  type='text'
                  label={`${translate('common.company')}*`}
                  value={contract?.contractOwner?.company}
                  onChange={handleCompanyChange}
                >
                  <span className='invalid-feedback error-message'>{translate('pages.admin.companyIsRequired')}</span>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow className='my-4'>
              <MDBCol md='6' xl='3' className='mb-4'>
                <MDBSelect
                  className={isInvalidContactPerson ? 'is-invalid' : ''}
                  search
                  searchLabel={translate('common.search')}
                  label={translate('pages.admin.contactPerson')}
                  data={contactPersonList}
                  preventFirstSelection
                  onValueChange={(e: SelectData | SelectData[] | any) => {
                    handleContractOwnerChange(e);
                    setSelectedContactPerson(e.value);
                  }}
                />
                <span className='invalid-feedback error-message'>{ownerErrorText}</span>
              </MDBCol>
              <MDBCol md='6' lg='6'>
                <MDBBtn
                  outline
                  type='button'
                  className='cancel-onHover'
                  color='warning'
                  onClick={onCreateContactPerson}
                >
                  {translate('pages.admin.createContactPerson')}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <MDBRow className='my-4'>
              <MDBCol md='6' xl='3'>
                <MobileDatePicker
                  className='w-100'
                  label={`${translate('pages.admin.startDateUtc')}*`}
                  value={moment(startDate)}
                  format='DD MMMM, YYYY'
                  onChange={(value: Moment | null) => handleDateChange(value, 'startDate')}
                  localeText={{
                    cancelButtonLabel: translate('common.cancel'),
                    okButtonLabel: translate('common.ok'),
                    toolbarTitle: translate('common.selectDate'),
                  }}
                />
              </MDBCol>
              <MDBCol md='6' xl='3'>
                <MobileDatePicker
                  className='w-100'
                  label={`${translate('pages.admin.endDateUtc')}*`}
                  value={moment(stopDate)}
                  minDate={moment(tomorrow)}
                  format='DD MMMM, YYYY'
                  disablePast
                  onChange={(value: Moment | null) => handleDateChange(value, 'stopDate')}
                  localeText={{
                    cancelButtonLabel: translate('common.cancel'),
                    okButtonLabel: translate('common.ok'),
                    toolbarTitle: translate('common.selectDate'),
                  }}
                />
              </MDBCol>
              <span className='pt-1 error-message'>{errorText}</span>
            </MDBRow>
            <MDBRow className='my-4 p-0'>
              <MDBCol md='12'>
                <MDBCheckbox
                  id={`isDataModuleActive`}
                  checked={contract?.isDataModuleActive || false}
                  value={contract?.isDataModuleActive?.toString() || 'false'}
                  onChange={handleIsDataModuleActiveChange}
                  label={`${translate('pages.admin.allowAccessToData')}`}
                  className='align-top'
                />
              </MDBCol>
            </MDBRow>
            <MDBModalFooter className='m-0 p-0'>
              <MDBBtn color='warning' className='text-white' type='submit'>
                {translate('common.save')}
              </MDBBtn>
              <MDBBtn outline color='warning' className='cancel-onHover' onClick={() => navigate('/admin/contracts')}>
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

export default ContractDetail;
