import axios from 'axios';

import { MachineOwnerModel, MachineOwnerResponseModel } from '../models/data/machine/machineOwner.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getMachineOwners = async () => {
  const result = await axios.get<MachineOwnerModel[]>(`${baseApiUrl}api/machineOwners`);
  return result?.data;
};

export const getMachineOwnerById = async (machineOwnerId: number) => {
  const result = await axios.get<MachineOwnerModel>(`${baseApiUrl}api/machineOwners/${machineOwnerId}`);
  return result?.data;
};

export const getMachineOwnerByMachine = async (machineId: number) => {
  const result = await axios.get<MachineOwnerModel>(`${baseApiUrl}api/machineOwners/machine/${machineId}`);
  return result?.data;
};

export const addMachineOwner = async (machineOwner: MachineOwnerResponseModel) => {
  const result = await axios.post<MachineOwnerResponseModel>(`${baseApiUrl}api/machineOwners`, machineOwner);
  return result?.data;
};

export const editMachineOwner = async (machineOwner: MachineOwnerResponseModel) => {
  const result = await axios.put<MachineOwnerResponseModel>(`${baseApiUrl}api/machineOwners/${machineOwner.id}`, machineOwner);
  return result?.data;
};

export const deleteMachineOwner = async (machineOwnerIds: number[]) => {
  const result = await axios.delete<number[]>(`${baseApiUrl}api/machineOwners`, { data: machineOwnerIds });
  return result.data;
};
