import { createSelector, createSlice, Slice } from '@reduxjs/toolkit';

import { RootState } from '../../../store/store';
import { AdminSensorGroupsModel } from '../../../models/data/machine/sensorGroup.model';
import { AvailableMachineModel } from '../../../models/data/machine/availableMachine.model';
import {
  bulkDeleteAdminSensorGroups,
  deleteAdminSensorGroupById,
  getAdminSensorGroupById,
  getAllAdminSensorGroups,
  getSensorAvailableMachines,
  updateSensorGroupAvailableAttributes,
  updateSensorGroup,
} from '../../../store/api/adminSensorGroups.service';
import { validateId } from '../../../utils/adminPages.utility';

export interface AdminSensorGroupsState {
  displayInfoMessage?: boolean;
  adminSensorGroups: AdminSensorGroupsModel[];
  availableMachines: AvailableMachineModel[];
  selectedSensorGroup?: AdminSensorGroupsModel;
}

const initialState: AdminSensorGroupsState = {
  displayInfoMessage: false,
  adminSensorGroups: [],
  availableMachines: [],
  selectedSensorGroup: {
    name: '',
    description: '',
    isChecked: false,
    sensors: [],
    machines: [],
    sensorGroupCharts: [],
  },
};

export const adminSensorGroupsSlice: Slice = createSlice({
  name: 'adminSensorGroups',
  initialState,
  reducers: {
    addSensorGroupInformation(state, action) {
      const { fieldId, value } = action.payload;
      state.selectedSensorGroup[fieldId] = value;
    },
    addSensors(state, action) {
      const { selectedSensors } = action.payload;
      const defaultSensorChart = state.selectedSensorGroup.sensorGroupCharts?.find(
        (chart) => chart.name === 'Default Chart'
      );

      if (defaultSensorChart) {
        defaultSensorChart.sensors.push(...selectedSensors);
        return;
      }

      state.selectedSensorGroup.sensorGroupCharts.push({ name: 'Default Chart', id: -1, sensors: selectedSensors });
    },
    addMachines(state, action) {
      const { selectedMachines } = action.payload;
      state.selectedSensorGroup.machines = [...state.selectedSensorGroup.machines, ...selectedMachines];
    },
    addChart(state, action) {
      const { name, id } = action.payload;

      state.selectedSensorGroup.sensorGroupCharts.push({ name, id, sensors: [] });
    },
    moveSensorToChart(state, action) {
      const { currentChartId, nextChartId, sensorId } = action.payload;
      const currentChart = state.selectedSensorGroup.sensorGroupCharts.find((ch) => ch.id === currentChartId);
      if (currentChart) {
        const sensor = currentChart.sensors.find((a) => a.id === sensorId);
        currentChart.sensors = currentChart.sensors.filter((a) => a.id !== sensorId);
        const nextChart = state.selectedSensorGroup.sensorGroupCharts.find((ch) => ch.id === nextChartId);
        if (nextChart && sensor) {
          nextChart.sensors.push(sensor);
        }
      }
    },
    toggleSensorGroupChecked(state, action) {
      const { sensorGroupId } = action.payload;
      const selectedAdminSensorGroup = state.adminSensorGroups.find((group) => group.id === sensorGroupId);
      if (selectedAdminSensorGroup) {
        selectedAdminSensorGroup.isChecked = !selectedAdminSensorGroup.isChecked;
      }
    },
    toggleGlobalSensorGroupsChecked(state, action) {
      const { isChecked } = action.payload;
      state.adminSensorGroups = state.adminSensorGroups.map((group) => ({
        ...group,
        isChecked,
      }));
    },
    toggleSensorChecked(state, action) {
      const { chartId, sensorId } = action.payload;
      const selectedChart = state.selectedSensorGroup.sensorGroupCharts.find((chart) => chart.id === chartId);
      if (selectedChart) {
        const selectedSensor = selectedChart.sensors.find((att) => att.id === sensorId);
        if (selectedSensor) {
          selectedSensor.isChecked = !selectedSensor.isChecked;
        }
      }
    },
    toggleGlobalSensorsChecked(state, action) {
      const { isChecked } = action.payload;
      state.selectedSensorGroup.sensorGroupCharts = state.selectedSensorGroup.sensorGroupCharts.map((chart) => ({
        ...chart,
        sensors: chart.sensors.map((sensor) => ({ ...sensor, isChecked })),
      }));
    },
    deleteSensor(state, action) {
      const { chartId, sensorId } = action.payload;
      const selectedChart = state.selectedSensorGroup.sensorGroupCharts.find((chart) => chart.id === chartId);
      if (selectedChart) {
        selectedChart.sensors = selectedChart.sensors.filter((att) => att.id !== sensorId);
      }
    },
    bulkDeleteSensors(state, action) {
      const { checkedSensorIds } = action.payload;
      state.selectedSensorGroup.sensorGroupCharts = state.selectedSensorGroup.sensorGroupCharts.map((chart) => ({
        ...chart,
        sensors: chart.sensors.filter((a) => !checkedSensorIds.includes(a.id)),
      }));
    },
    clearSelectedSensorGroup(state) {
      state.selectedSensorGroup = initialState.selectedSensorGroup;
    },
    deleteMachine(state, action) {
      const { machineId } = action.payload;

      state.selectedSensorGroup.machines = state.selectedSensorGroup.machines.filter((m) => m.id !== machineId);
    },
    bulkDeleteMachines(state, action) {
      const { checkedMachineIds } = action.payload;
      state.selectedSensorGroup.machines = state.selectedSensorGroup.machines.filter(
        (m) => !checkedMachineIds.includes(m.id)
      );
    },
    toggleMachineChecked(state, action) {
      const machine = state.selectedSensorGroup.machines.find((m) => m.id.toString() === action.payload);
      if (machine) {
        machine.isChecked = !machine.isChecked;
      }
    },
    toggleGlobalMachinesChecked(state, action) {
      const { isChecked } = action.payload;
      state.selectedSensorGroup.machines = state.selectedSensorGroup.machines.map((machine) => ({
        ...machine,
        isChecked: isChecked,
      }));
    },
    toggleAvailableMachineChecked(state, action) {
      const machine = state.availableMachines.find((m) => m.id.toString() === action.payload);
      if (machine) {
        machine.isChecked = !machine.isChecked;
      }
    },
    toggleAvailableGlobalMachinesChecked(state, action) {
      const { isChecked } = action.payload;
      state.availableMachines = state.availableMachines.map((machine) => ({
        ...machine,
        isChecked: isChecked,
      }));
    },
    setDisplayInfoMessage(state, action) {
      state.displayInfoMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAdminSensorGroups.fulfilled, (state, action) => {
        const { sensorGroups } = action.payload;
        if (sensorGroups && sensorGroups?.length > 0) {
          state.adminSensorGroups = sensorGroups;
        }
      })
      .addCase(getAdminSensorGroupById.fulfilled, (state, action) => {
        const { sensorGroup } = action.payload;

        state.selectedSensorGroup = sensorGroup;
      })
      .addCase(updateSensorGroup.fulfilled, (state, action) => {
        const { sensorGroup } = action.payload;
        state.selectedSensorGroup = sensorGroup;
      })
      .addCase(deleteAdminSensorGroupById.fulfilled, (state, action) => {
        const { sensorGroupId } = action.payload;

        state.adminSensorGroups = state.adminSensorGroups.filter((sg) => sg.id !== sensorGroupId);
      })
      .addCase(bulkDeleteAdminSensorGroups.fulfilled, (state, action) => {
        const { sensorGroupIds } = action.payload;

        state.adminSensorGroups = state.adminSensorGroups
          .filter((sg) => !!sg.id)
          .map((sg) => ({ ...sg, id: sg.id as number }))
          .filter((sg) => !sensorGroupIds.includes(sg.id));
      })
      .addCase(getSensorAvailableMachines.fulfilled, (state, action) => {
        const { machineUuids } = action.payload;

        const selectedMachineIds = state.selectedSensorGroup?.machines?.map((m) => m.id) || [];
        state.availableMachines = machineUuids.filter((m) => !selectedMachineIds.includes(m.id));
      })
      .addCase(updateSensorGroupAvailableAttributes.fulfilled, (state, action) => {
        const { updatedSensorGroupCharts } = action.payload;

        state.selectedSensorGroup!.sensorGroupCharts = updatedSensorGroupCharts;
      });
  },
});

export const {
  addSensorGroupInformation,
  addSensors,
  addMachines,
  addChart,
  toggleSensorGroupChecked,
  toggleGlobalSensorGroupsChecked,
  toggleSensorChecked,
  toggleGlobalSensorsChecked,
  deleteSensor,
  bulkDeleteSensors,
  moveSensorToChart,
  clearSelectedSensorGroup,
  deleteMachine,
  bulkDeleteMachines,
  toggleMachineChecked,
  toggleGlobalMachinesChecked,
  toggleAvailableMachineChecked,
  toggleAvailableGlobalMachinesChecked,
  setDisplayInfoMessage,
} = adminSensorGroupsSlice.actions;

export default adminSensorGroupsSlice.reducer;

export const selectAttributes = (state: RootState) => state.attributes.attributes;

export const selectAdminSensorGroups = (state: RootState) => state.adminSensorGroups;

export const selectDisplayInfoMessage = (state: RootState) => state.adminSensorGroups.displayInfoMessage;

export const selectAvailableMachines = createSelector([selectAdminSensorGroups], (slice) => slice.availableMachines);

export const selectSelectedSensorGroup = createSelector(
  [selectAdminSensorGroups],
  (slice) => slice.selectedSensorGroup
);

export const selectSelectedsensorCharts = createSelector(
  [selectSelectedSensorGroup],
  (selectedGroup) => selectedGroup.sensorCharts ?? selectedGroup.sensorGroupCharts
);

export const selectFormattedSensors = createSelector(
  [selectSelectedsensorCharts],
  (charts) =>
    charts &&
    charts.length > 0 &&
    charts.reduce((previousChart, currentChart) => {
      if (currentChart.sensors.length > 0) {
        const currentCharSensors = currentChart.sensors.map((sensor) => ({
          ...sensor,
          chart: currentChart?.name,
          chartId: currentChart?.id,
        }));
        previousChart.push(...currentCharSensors);
      }
      return previousChart;
    }, [])
);

export const selectCheckedSensorsIds = createSelector(
  [selectFormattedSensors],
  (sensors) => sensors && sensors.filter((sensor) => sensor.isChecked).map((s) => s.id)
);

export const selectCheckedMachineIds = createSelector(
  [selectSelectedSensorGroup],
  (group) => group?.machines && group?.machines.filter((machine) => machine.isChecked).map((machine) => machine.id)
);

export const selectSensorChartsOptions = createSelector([selectSelectedsensorCharts], (charts) =>
  charts?.map((chart) => ({ id: chart.id, name: chart.name }))
);

export const selectMachines = createSelector([selectSelectedSensorGroup], (group) =>
  group.machines ? group.machines : group.machineUuids
);

export const selectSensorsIds = createSelector(
  [selectFormattedSensors],
  (sensors) => sensors && sensors.map((s) => s.id)
);

export const selectAvailableSensors = createSelector(
  [selectAttributes, selectSensorsIds],
  (attributes, formattedSensors) =>
    attributes &&
    attributes.length > 0 &&
    attributes.filter((attribute) => (formattedSensors ? !formattedSensors.includes(attribute.id) : attribute))
);

export const selectSelectedGroupSubmitFormat = createSelector(
  [selectSelectedSensorGroup],
  (group) =>
    group && {
      name: group.name,
      description: group.description,
      machineUuids: (group.machineUuids ?? group.machines).map((m) => m.uuid),
      sensorCharts: (group.sensorCharts ?? group.sensorGroupCharts).map((sensorChart) => ({
        id: validateId(sensorChart?.id),
        name: sensorChart.name,
        attributeUuids: sensorChart.sensors.map((sensor) => sensor.uuid),
      })),
    }
);

export const selectCheckedAdminSensorGroups = createSelector(
  [selectAdminSensorGroups],
  (sensorGroups) =>
    sensorGroups.adminSensorGroups &&
    sensorGroups.adminSensorGroups.length > 0 &&
    sensorGroups.adminSensorGroups.filter((group) => group.isChecked)
);

export const selectCheckedAdminSensorGroupIds = createSelector(
  [selectCheckedAdminSensorGroups],
  (sensorGroups) => sensorGroups && sensorGroups.map((group) => group.id)
);
