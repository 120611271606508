export class OpenRailwayMapConstants {
  public static readonly geoMachinesOpenRailwayMapId = 'geoMachinesOpenRailwayMap';
  public static readonly geoMachineDetailsOpenRailwayMapId = 'geoMachineDetailsOpenRailwayMap';
  public static readonly dataMachineDetailsOpenRailwayMapId = 'dataMachineDetailsOpenRailwayMap';

  public static readonly containerStyle = {
    width: '100%',
    height: '460px',
  };

  public static readonly defaultCenter = {
    lat: 41.3851,
    lng: 2.1734,
  };

  public static readonly openRailwayAttribution: string =
    '<a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>, Style: <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA 2.0</a> <a href="http://www.openrailwaymap.org/">OpenRailwayMap</a> and OpenStreetMap';
  public static readonly openStreetAttribution: string = `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`;

  public static readonly openStreetUrl: string = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  public static readonly openRailwayStandardUrl: string =
    'http://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png';
  public static readonly openRailwaySignalsUrl: string = 'http://{s}.tiles.openrailwaymap.org/signals/{z}/{x}/{y}.png';
  public static readonly openRailwayMaxSpeedUrl: string =
    'http://{s}.tiles.openrailwaymap.org/maxspeed/{z}/{x}/{y}.png';

  public static readonly defaultZoom: number = 2;
  public static readonly zoomedMap: number = 16;
  public static readonly zoomedMapDetails: number = 18;
  public static readonly maxZoom: number = 20;

  public static readonly maxClusterRadius: number = 40; //The maximum radius that a cluster will cover from the central marker (in pixels). Default 80. Decreasing will make more, smaller clusters. You can also use a function that accepts the current map zoom and returns the maximum cluster radius in pixels.
  public static readonly disableClusteringAtZoom: number = 18; //If set, at this zoom level and below, markers will not be clustered.

  public static readonly polylineArrowOptions = {
    frequency: 'allvertices',
    size: '8px',
    yawn: 50,
  };
}
