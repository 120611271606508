import React, { useCallback } from 'react';

import { MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBRow, MDBBtn } from 'mdbreact';

import { useAppSelector } from '../../../../hooks/reduxHooks';
import { translate } from '../../../../services/translation.service';
import { selectSelectedMachineMachineOwner } from '../../Machines/machines.slice';
import { MachineMachineOwnerDetailProps } from '../../../../models/props/admin/machineMachineOwnerDetail.props';

const MachineMachineOwnerDetail: React.FC<MachineMachineOwnerDetailProps> = ({ isProcessing, onEditClick }) => {
  const machineOwner = useAppSelector(selectSelectedMachineMachineOwner);

  const handleClick = useCallback(
    (event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
      const { id } = event.currentTarget;

      onEditClick(id);
    },
    [onEditClick]
  );

  return (
    <MDBCard>
      <MDBCardBody>
        <MDBRow className='pb-4'>
          <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
              <MDBIcon icon='cogs' className='p-4 shadow-3-strong bg-warning rounded' />
              <h5 className='font-weight-bold ml-4'>
                {translate('common.edit')} {translate('pages.admin.machineConfiguration')}
              </h5>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md='6' xl='3' className='mb-4'>
            <MDBInput
              id='firstName'
              className='form-control'
              required
              type='text'
              label={`${translate('common.firstName')}*`}
              value={machineOwner?.contactPerson?.firstName || ''}
              disabled
            >
              <div className='invalid-feedback error-message'>{translate('pages.admin.firstNameIsRequired')}</div>
            </MDBInput>
          </MDBCol>
          <MDBCol md='6' xl='3' className='mb-4'>
            <MDBInput
              id='lastName'
              className='form-control'
              required
              type='text'
              label={`${translate('common.lastName')}*`}
              value={machineOwner?.contactPerson?.lastName || ''}
              disabled
            >
              <div className='invalid-feedback error-message'>{translate('pages.admin.lastNameIsRequired')}</div>
            </MDBInput>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md='6' xl='3' className='mb-4'>
            <MDBInput
              id='email'
              className='form-control'
              required
              type='text'
              label={`${translate('common.emailAddress')}*`}
              value={machineOwner?.contactPerson?.email || ''}
              disabled
            >
              <div className='invalid-feedback error-message'>{translate('pages.admin.emailIsRequired')}</div>
            </MDBInput>
          </MDBCol>
          <MDBCol md='6' xl='3'>
            <MDBInput
              id='company'
              className='form-control'
              required
              type='text'
              label={`${translate('common.company')}*`}
              value={machineOwner?.company || ''}
              disabled
            >
              <div className='invalid-feedback error-message'>{translate('pages.admin.companyIsRequired')}</div>
            </MDBInput>
          </MDBCol>
        </MDBRow>
        <MDBRow className='my-4'>
          <MDBCol md='12' xl='12' className='d-flex justify-content-end'>
            <MDBBtn
              color='warning'
              id='add-machine-owner'
              disabled={isProcessing}
              className='text-white'
              type='submit'
              onClick={(e: any) => handleClick(e)}
            >
              {translate('pages.admin.selectMachineOwner')}
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
};

export default MachineMachineOwnerDetail;
