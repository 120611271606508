import { createAsyncThunk, createSlice, Slice, createSelector } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';

import { RootState } from '../store';
import { UserGroupModel } from '../../models/data/group/userGroup.model';
import { MachineModel } from '../../models/data/machine/machine.model';
import { AdminGroupModel } from '../../models/data/group/adminGroup.model';
import { UserGroupTableModel } from '../../models/data/group/userGroupTable.model';

import { removeMachineFromGroup } from '../../services/groupMachine.service';
import { addMachineToUserGroups } from '../../services/groupMachine.service';
import { translate } from '../../services/translation.service';
import { getMachinesData, getUserGroupsData } from '../api/machines.service';
import { SystemModel } from '../../models/data/machine/system.model';

export interface MachinesState {
  userGroups: UserGroupModel[];
  adminGroups: AdminGroupModel[];
  allMachines: MachineModel[];
  systems: SystemModel[];
  selectedMachineId: number;
}

const initialState: MachinesState = {
  userGroups: [],
  adminGroups: [],
  allMachines: [],
  systems: [],
  selectedMachineId: -1,
};

export const removeUserGroupMachine = createAsyncThunk<
  { machineId: number; groupId: string },
  { machineId: number; groupId: string }
>('machines/removeMachineFromGroup', async ({ machineId, groupId }) => {
  await trackPromise(removeMachineFromGroup(groupId, [machineId]));
  toast.success(translate('pages.profile.removeMachine'));
  return { machineId, groupId };
});

export const addUserGroupMachine = createAsyncThunk<
  { machineToEdit: MachineModel; groups: UserGroupTableModel[] },
  { machineId: number; groups: UserGroupTableModel[] },
  {
    state: RootState;
  }
>('machines/addUserGroupMachine', async ({ machineId, groups }, thunkApi) => {
  await trackPromise(addMachineToUserGroups(machineId, groups));

  toast.success(translate('pages.geo.addMachineToGroupsSuccessfully'));

  const { allMachines: stateMachines } = thunkApi.getState().machines;
  const machineToEdit = stateMachines.find((m) => m.id === machineId);

  return { machineToEdit, groups };
});

export const machinesSlice: Slice = createSlice({
  name: 'machines',
  initialState,
  reducers: {
    setMachines(state, action) {
      state.allMachines = action.payload;
    },
    setSelectedMachineId(state, action) {
      state.selectedMachineId = action.payload;
    },
    updateMachineVisibility(state, action) {
      const machine = state.allMachines.find((machine) => machine.id === action.payload);

      if (machine) {
        machine.isOnMap = state.selectedMachineId === machine.id || !machine.isOnMap;
      }
    },
    updateMachineChecked(state, action) {
      const machine = state.allMachines.find((machine) => machine.id.toString() === action.payload);

      if (machine) {
        machine.isChecked = !machine.isChecked;
      }
    },
    updateGroupChecked(state, action) {
      const group = state[action.payload.groupsId].find((group) => group.id.toString() === action.payload.groupId);
      const groupMachineIds = group?.machines.map((m) => m.id) || [];

      const userGroupMachines = state.userGroups
        .filter((group) => group.id.toString() !== action.payload.groupId && group.isChecked)
        .map((g) => g.machines)
        .reduce((x, y) => x.concat(y), [])
        .map((m) => m.id);

      if (group) {
        group.isChecked = action.payload.isChecked || !group.isChecked;
        state.allMachines = state.allMachines.map((machine) =>
          groupMachineIds.includes(machine.id) && !userGroupMachines.includes(machine.id)
            ? { ...machine, isOnMap: action.payload.isChecked || group.isChecked }
            : machine
        );
      }
    },
    updateGlobalChecked(state, action) {
      state.allMachines = state.allMachines.map((machine) => ({ ...machine, isChecked: !!action.payload }));
    },
    updateBulkMachinesVisibility(state, action) {
      state.allMachines = state.allMachines.map((machine) =>
        machine.isChecked ? { ...machine, isOnMap: !!action.payload, isChecked: false } : machine
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserGroupsData.fulfilled, (state, action) => {
        const { userGroups } = action.payload;

        state.userGroups = userGroups;
      })
      .addCase(getMachinesData.fulfilled, (state, action) => {
        const { userGroups, adminGroups, allMachines, systems } = action.payload;

        return {
          ...state,
          userGroups,
          adminGroups,
          allMachines,
          systems,
        };
      })
      .addCase(removeUserGroupMachine.fulfilled, (state, action) => {
        const { machineId, groupId } = action.payload;
        const group = state.userGroups.find((group) => group.id.toString() === groupId);

        if (group) {
          group.machines = group.machines.filter((machine) => machine.id !== machineId);
        }
      })
      .addCase(addUserGroupMachine.fulfilled, (state, action) => {
        const { machineToEdit, groups } = action.payload;

        if (machineToEdit) {
          for (const group of state.userGroups) {
            const currentGroup = groups.find((g) => g.id === group.id);
            const containsMachine = group.machines.some((m) => m.id === machineToEdit.id);

            if (currentGroup?.isChecked && !containsMachine) {
              group.machines.push({ ...machineToEdit, isAvailable: true });
            } else if (!currentGroup?.isChecked && containsMachine) {
              group.machines = group.machines.filter((m) => m.id !== machineToEdit.id);
            }
          }

          const machine = state.allMachines.find((machine) => machine.id === machineToEdit.id);

          if (machine) {
            machine.isOnMap = true;
          }
        }
      });
  },
});

export const {
  setSelectedMachineId,
  updateMachineVisibility,
  updateBulkMachinesVisibility,
  updateMachineChecked,
  updateGlobalChecked,
  setMachines,
  updateGroupChecked,
} = machinesSlice.actions;

export default machinesSlice.reducer;

export const selectMachinesSlice = (state: RootState) => state.machines;

export const selectMachines = createSelector([selectMachinesSlice], (machines) => machines.allMachines);

export const selectSelectedMachineId = createSelector([selectMachinesSlice], (machines) => machines.selectedMachineId);

export const selectUserGroups = createSelector([selectMachinesSlice], (machines) => machines.userGroups);

export const selectAdminGroups = createSelector([selectMachinesSlice], (machines) => machines.adminGroups);

export const selectSystems = createSelector([selectMachinesSlice], (machines) => machines.systems);

export const selectSelectedMachine = createSelector([selectMachines, selectSelectedMachineId], (machines, machineId) =>
  machines.find((m) => m.id === machineId)
);

export const selectVisibleMachines = createSelector(selectMachines, (machines) =>
  machines.filter((machine) => machine.longitude && machine.latitude && machine.isOnMap)
);

export const selectSelectedMachineUuid = createSelector(
  [selectMachines, selectSelectedMachineId],
  (machines, selectedMachineId) =>
    selectedMachineId &&
    selectedMachineId > 0 &&
    machines.find((machine) => machine.id === selectedMachineId)?.uuid?.toLowerCase()
);

export const selectAlarmVisibleMachines = createSelector(selectMachines, (machines) =>
  machines.filter((machine) => machine.isOnMap)
);

export const selectVisibleMachineUuids = createSelector(selectAlarmVisibleMachines, (machines) =>
  machines.map((machine) => machine.uuid.toLowerCase())
);

export const selectHasMachineData = createSelector(
  [selectMachines, selectSystems],
  (machines, systems) => machines && systems && machines?.length > 0 && systems?.length > 0
);
