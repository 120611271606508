import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBCard, MDBCardBody, MDBCol, MDBRow, MDBBtn } from 'mdbreact';

import { toast } from 'react-toastify';
import { translate } from '../../../services/translation.service';
import ProtectedRoute from '../../../hocs/ProtectedRoute';
import { AuthConstants } from '../../../constants/auth.constants';
import GenericTable from '../../../components/genericTable/genericTable';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { AdminConstants } from '../../../constants/admin.constants';
import useInterval from '../../../hooks/useInterval';
import useModalState from '../../../hooks/useModalState';
import { uncheckTableGlobalCheckedById } from '../../../store/slices/tableSlice';
import {
  bulkDeleteMachineOwners,
  deleteMachineOwnerById,
  getAllMachineOwners,
  getAllMachineOwnersPolling,
} from '../../../store/api/machineOwners.service';
import {
  selectMachineOwners,
  selectHasProcessingMachineOwner,
  selectCheckedMachineOwnerIds,
  updateMachineOwnerChecked,
  updateGlobalMachineOwnerChecked,
} from './machineOwners.slice';

const MachineOwnersList: React.FC = () => {
  const { setModal } = useModalState();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const machineOwners = useAppSelector(selectMachineOwners);
  const hasProcessingMachineOwners = useAppSelector(selectHasProcessingMachineOwner);
  const selectedMachineOwnerIds = useAppSelector(selectCheckedMachineOwnerIds);

  const getMachineOwners = useCallback(() => dispatch(getAllMachineOwners()), [dispatch]);

  useEffect(() => {
    getMachineOwners();

    return () => {
      dispatch(uncheckTableGlobalCheckedById('machineOwnersTableSettings'));
    };
  }, [dispatch, getMachineOwners]);

  useInterval(
    () => dispatch(getAllMachineOwnersPolling()),
    hasProcessingMachineOwners ? AdminConstants.pollingInterval : null
  );

  const onEditMachineOwnerClick = useCallback(
    (machineOwnerId: number | string) => navigate(`/admin/machine-owner/${machineOwnerId}`),
    [navigate]
  );

  const onMachineOwnerDeleteClick = useCallback(
    (machineOwnerId: number) => setModal(true, () => dispatch(deleteMachineOwnerById({ machineOwnerId }))),
    [dispatch, setModal]
  );

  const onBulkDeleteClick = useCallback(() => {
    if (selectedMachineOwnerIds.length === 0) {
      toast.info(translate('pages.admin.noSelectedMachineOwners'));
      return;
    }

    setModal(true, () => {
      dispatch(bulkDeleteMachineOwners({ machineOwnerIds: selectedMachineOwnerIds }));
      dispatch(uncheckTableGlobalCheckedById('machineOwnersTableSettings'));
    });
  }, [selectedMachineOwnerIds, dispatch, setModal]);

  const onCreateMachineOwnerClick = useCallback(() => navigate(`/admin/machine-owner`), [navigate]);

  const handleMachineOwnerCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) dispatch!(updateMachineOwnerChecked(id));
    },
    [dispatch]
  );

  const onMachineOwnerGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      dispatch(updateGlobalMachineOwnerChecked({ isChecked: !!event.currentTarget.checked }));
    },
    [dispatch]
  );

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <MDBRow>
            <MDBCol md='12'>
              <MDBCard>
                <MDBCardBody>
                  {hasProcessingMachineOwners && (
                    <MDBBtn color='warning' className='text-white' onClick={getMachineOwners}>
                      {translate('common.refresh')}
                    </MDBBtn>
                  )}
                  <GenericTable
                    tableId={'machineOwnersTableSettings'}
                    items={machineOwners}
                    hasSearch={true}
                    hasCheckboxInput={true}
                    hasItemsPerPageControl={true}
                    handleItemToggle={handleMachineOwnerCheckedToggle}
                    onGlobalItemsChecked={onMachineOwnerGlobalCheckedToggle}
                    onItemEditClick={onEditMachineOwnerClick}
                    onActionButtonClick={onCreateMachineOwnerClick}
                    onItemDeleteClick={onMachineOwnerDeleteClick}
                    onBulkDeleteClick={onBulkDeleteClick}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        );
      }}
    />
  );
};

export default MachineOwnersList;
