import React, { memo } from 'react';

import { MDBCardBody, MDBIcon } from 'mdbreact';

import './groupChart.scss';
import ChartContainer from '../../../../components/chartContainer/ChartContainer';
import { GroupChartProps } from '../../../../models/props/data/groupChart.props';
import { translate } from '../../../../services/translation.service';

export const GroupChart: React.FC<GroupChartProps> = memo(
  ({ sensorGroups, isAdminSensorGroup, filterItems, handleChartCreated, toggleCollapse, handleSensorOptions }) => {
    return (
      <>
        {sensorGroups?.some((sg) => sg.sensorGroupCharts?.some((c) => c.sensors?.some((c) => c))) ? (
          sensorGroups
            .filter((item) => item?.sensorGroupCharts?.length > 0)
            .filter((item) => filterItems(item))
            .map(
              (group) =>
                group?.sensorGroupCharts?.some((chart) => chart.sensors?.length !== 0) && (
                  <React.Fragment key={group.id}>
                    <div id={`header-${group.id}`} className='chartsContainer'>
                      <div
                        id={group.id}
                        onClick={(e) => toggleCollapse(e, isAdminSensorGroup)}
                        className='collapse-header p-3'
                      >
                        <h5 className='d-inline'>{group.name}</h5>
                        <span className='collapse-subtitle'>{group.description}</span>
                        <MDBIcon icon={group?.isOpen ? 'angle-up' : 'angle-down'} className='float-right' />
                      </div>
                      <div
                        id={`${group.id} ${group.name}`}
                        className={`m-2 zIndex-8 ${group?.isOpen ? '' : 'hidden-chart'}`}
                      >
                        <MDBCardBody className='rgba-grey-light p-2'>
                          <ChartContainer
                            handleChartCreated={handleChartCreated}
                            charts={handleSensorOptions(group.sensorGroupCharts)}
                          />
                        </MDBCardBody>
                      </div>
                    </div>
                    {!group?.isOpen && <hr className='hr-line' />}
                  </React.Fragment>
                )
            )
        ) : (
          <div className='p-4 text-center'>
            <h5>
              {isAdminSensorGroup
                ? translate('pages.data.noAdminSensorGroups')
                : translate('pages.data.noUserSensorGroups')}
            </h5>
          </div>
        )}
      </>
    );
  }
);
