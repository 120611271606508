import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBSelect,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBModalDialog,
  MDBModalContent,
} from 'mdbreact';

import { UserContractModel } from '../../../../models/data/contract/userContract.model';
import { ContractModel } from '../../../../models/data/contract/contract.model';
import { AddUserContractProps } from '../../../../models/props/admin/addUserContract.props';
import { translate } from '../../../../services/translation.service';
import { getAllContracts } from '../../../../services/contract.service';

const AddUserContractModal: React.FC<AddUserContractProps> = ({
  isOpen,
  userContracts,
  onShowModal,
  onCloseModal,
  onSubmitUserContract,
}) => {
  const [contracts, setContracts] = useState<ContractModel[]>([]);
  const [selectedContract, setSelectedContract] = useState<ContractModel>();
  const [selectedInput, setSelectedInput] = useState<any>();
  const [errorText, setErrorText] = useState<string>('');

  let objectContracts: { text: string; value: string | undefined; disabled: boolean; defaultSelected: boolean }[] = [];
  contracts.forEach((contract) =>
    objectContracts.push({
      text: contract.name?.toString() || '',
      value: contract.id.toString(),
      disabled: false,
      defaultSelected: false,
    })
  );

  objectContracts.push({ text: translate('common.userContract'), value: '0', disabled: true, defaultSelected: true });
  const [contactPersonList, setContactPersonList] = useState<any>(objectContracts);

  useEffect(() => {
    setContactPersonList(objectContracts);
  }, [contracts]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      const allContracts = await trackPromise(getAllContracts());

      const userContractIds = userContracts?.map((uc) => uc.id.toString());
      const availableContracts = allContracts.filter((c) => c.uuid && !userContractIds?.includes(c.uuid));

      setContracts(availableContracts);
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    const selectedPerson = objectContracts.find((x) => x.value === selectedInput);
    if (selectedPerson) {
      selectedPerson.defaultSelected = true;

      setContactPersonList((old) => {
        return [...old, selectedPerson];
      });
    }
  }, []); // eslint-disable-line

  const handleContractSelect = (items: any) => {
    const selectedContract = contracts.find((c) => c.id === +items.value);
    setSelectedContract(selectedContract);
    setErrorText('');
  };

  const onSubmitForm = () => {
    if (!selectedContract?.name) {
      setErrorText(translate('pages.admin.contractNameIsRequired'));
      return;
    }

    onSubmitUserContract({
      ...selectedContract,
      id: selectedContract.uuid,
    } as UserContractModel);
  };

  return (
    <MDBContainer className='center-modal-page'>
      <MDBModal
        animationDirection='top'
        backdrop={true}
        show={isOpen}
        setShow={onShowModal}
        className='modal-notify modal-warning'
      >
        <MDBModalDialog centered={true}>
          <MDBModalContent>
            <MDBModalHeader>{translate('pages.admin.addUserContract')}</MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol md='12'>
                  <span className='text-danger'>{errorText}</span>
                </MDBCol>
              </MDBRow>

              <span>{translate('pages.admin.contract')}</span>

              <MDBRow>
                <MDBCol md='12'>
                  <MDBSelect
                    className='dropdown-warning select-dropdown-container'
                    data={contactPersonList}
                    onValueChange={(e: any) => {
                      handleContractSelect(e);
                      setSelectedInput(e.value);
                    }}
                  />
                </MDBCol>
              </MDBRow>

              <span>{translate('pages.admin.responsible')}</span>

              <MDBRow>
                <MDBCol md='12'>
                  <MDBInput
                    disabled
                    value={
                      selectedContract
                        ? `${selectedContract?.contractOwner?.contactPerson.firstName} ${selectedContract?.contractOwner?.contactPerson.lastName}`
                        : ''
                    }
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='warning' className='text-white' onClick={onSubmitForm}>
                {translate('common.save')}
              </MDBBtn>
              <MDBBtn outline color='warning' className='cancel-onHover' onClick={onCloseModal}>
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
  );
};

export default AddUserContractModal;
