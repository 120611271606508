export class AdminConstants {
  // Polling Interval on the processing/synchronizing items in MS
  public static readonly pollingInterval = 5000;
  public static readonly machineConfigurationValidationSchema = {
    properties: {
      alarms: {
        description: 'alarm configuration',
        type: 'array',
        items: {
          description: 'list of alarms',
          type: 'object',
          properties: {
            id: {
              description: 'unique id of the alarm',
              type: 'number',
            },
            attribute: {
              description: 'unique id of the attribute referenced',
              type: 'string',
            },
            notification: {
              description: 'notification object',
              type: 'object',
              properties: {
                email: {
                  description: 'list of recipients email addresses',
                  type: 'array',
                  items: {
                    description: 'email address of recipient',
                    type: 'string',
                  },
                },
               },
            },
            message: {
              description: 'alarm message',
              type: 'string',
            },
            rules: {
              description: 'rule object',
              type: 'array',
              items: {
                description: 'list of rules to be applied for alarming',
                type: 'object',
                properties: {
                  symbol: {
                    description: 'one of <|>|=>|<=|==|!=',
                    type: 'string',
                    enum: ['<', '>', '=>', '<=', '==', '!='],
                  },
                  value: {
                    description: 'value to be compared by symbol',
                    type: 'string',
                  },
                },
                required: ['symbol', 'value'],
              },
            },
          },
          required: ['attribute', 'notification', 'message', 'rules'],
        },
      },
      machineOwner: {
        description: 'Machine Owner data',
        type: 'object',
        properties: {
          id: {
            description: 'unique id of the machine owner',
            type: 'number',
          },
          uuid: {
            description: 'unique guid of the machine owner',
            type: 'string',
          },
          company: {
            description: 'Machine owner company',
            type: 'string',
          },
          contactPerson: {
            description: 'Contact person',
            type: 'object',
            properties: {
              id: {
                description: 'unique id of the contact Person',
                type: 'number',
              },
              firstName: {
                description: 'first Name of the Contact person',
                type: 'string',
              },
              lastName: {
                description: 'last Name of the Contact person',
                type: 'string',
              },
              email: {
                description: 'email of the Contact person',
                type: 'string',
              },
            },
            required: ['id', 'firstName', 'lastName', 'email'],
          },
        },
        required: ['id', 'company', 'contactPerson'],
      },
      adminGroups: {
        description: 'admin group list where the machine is member of',
        type: 'array',
        items: {
          type: 'object',
          properties: {
            id: {
              description: 'unique id of the admin group',
              type: 'number',
            },
            name: {
              description: ' Name of the admin group',
              type: 'string',
            },
          },
          required: ['id', 'name'],
        },
      },
    },
    required: ['alarms', 'machineOwner', 'adminGroups'],
  };
}
