import moment from 'moment-timezone';

import { translate } from '../services/translation.service';

export const translatedCsvHeaders = {
  geoMachinesCsvHeaders: [
    {
      label: 'id',
      key: 'id',
    },
    {
      label: translate(`common.name`),
      key: 'name',
    },
    {
      label: translate(`pages.geo.systemName`),
      key: 'systemName',
    },
    {
      label: translate(`common.lastGpsPosition`),
      key: 'gpsTime',
    },
    {
      label: translate(`common.lastMessage`),
      key: 'messageTime',
    },
    {
      label: translate(`pages.geo.latitude`) + '(°)',
      key: 'latitude',
    },
    {
      label: translate(`pages.geo.longitude`) + '(°)',
      key: 'longitude',
    },
    {
      label: translate(`pages.geo.altitude`) + '(m)',
      key: 'altitude',
    },
    {
      label: translate(`common.rotateAngle`) + '(°)',
      key: 'rotateAngle',
    },
    {
      label: translate(`common.machineState`),
      key: 'machineState',
    },
  ],
  trackHistory: [
    {
      label: translate(`common.lastGpsPosition`),
      key: 'gpsTime',
    },
    {
      label: translate(`pages.geo.latitude`) + ' (°)',
      key: 'latitude',
    },
    {
      label: translate(`pages.geo.longitude`) + ' (°)',
      key: 'longitude',
    },
    {
      label: translate(`pages.geo.altitude`) + ' (m)',
      key: 'altitude',
    },
    {
      label: translate(`common.rotateAngle`) + ' (°)',
      key: 'rotateAngle',
    },
    {
      label: translate(`pages.geo.compassDirection`) + ' (°)',
      key: 'compassDirection',
    },
    {
      label: translate(`common.speed`) + ' (km/h)',
      key: 'speed',
    },
    {
      label: translate(`common.machinePower`),
      key: 'power',
    },
    {
      label: translate(`common.machineEngine`),
      key: 'engine',
    },
    {
      label: translate(`common.machineBrake`),
      key: 'brake',
    },
    {
      label: translate(`common.machineGear`),
      key: 'gear',
    },
  ],
};

export const generateExportTimestamp = () => moment().format('DD.MM.YYYY HH-mm-ss');
