import React, { useEffect } from 'react';

import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBModalFooter, MDBRow } from 'mdbreact';

import { translate } from '../../services/translation.service';
import useAuthorization from '../../hooks/useAuthorization';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectUser } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import useFlaggedGroupsState from '../../hooks/useFlaggedGroupsState';
import { getUserGroups } from '../../services/group.service';
import { logoutUser } from '../../services/auth.service';

const UserNotSynched: React.FC = () => {
  const [isLoading, signInSetup] = useAuthorization();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const { setFlaggedGroups } = useFlaggedGroupsState();

  useEffect(() => {
    async function getFlaggedGroups() {
      const groups = await getUserGroups();
      setFlaggedGroups(groups);
    }

    if (user.userId) {
      getFlaggedGroups();

      navigate('/');
    }
  }, [user.userId, navigate, setFlaggedGroups]);

  return (
    <MDBCard>
      <MDBCardBody>
        <MDBRow>
          <MDBCol size='auto' className='text-left'>
            <div className='porfile-title'>
              <MDBIcon icon='info-circle' color='warning' className='p-2 z-depth-2 userNotSyncronized-text' />
            </div>
          </MDBCol>
          <MDBCol md='6' className='text-left mt-3'>
            <h5 className='font-weight-bold dark-grey-text ml-2 mb-4'>{translate('common.signedUpThanks')}</h5>
            <h5 className='ml-2'>{translate('common.retrySystemRegistration')}</h5>
          </MDBCol>
        </MDBRow>

        <MDBModalFooter>
          <MDBBtn color='warning' outline onClick={() => logoutUser(true)} disabled={isLoading}>
            {translate('common.logout')}
          </MDBBtn>
          <MDBBtn color='warning' className='text-white' type='submit' onClick={signInSetup} disabled={isLoading}>
            {translate('common.retry')}
          </MDBBtn>
        </MDBModalFooter>
      </MDBCardBody>
    </MDBCard>
  );
};

export default UserNotSynched;
