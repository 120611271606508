export const PROFILE_TABS = [
  {
    id: 1,
    icon: 'user',
    i18nName: 'pages.profile.myProfile',
  },
  {
    id: 2,
    icon: 'user-cog',
    i18nName: 'pages.profile.myPreferences',
  },
  {
    id: 3,
    icon: 'folder-open',
    i18nName: 'pages.profile.myGroups',
  },
  {
    id: 4,
    icon: 'tachometer-alt',
    i18nName: 'pages.profile.mySensorGroups',
  },
];
