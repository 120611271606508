import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { getAttributeMappings, getAttributes, getAttributesByMachineIds } from '../../services/attributes.service';
import { AttributeInternationalization, AttributeModel } from '../../models/data/machine/attribute.model';

export const getAllAttributes = createAsyncThunk<{ attributes: AttributeModel[] }, undefined>(
  'attributes/getAllAttributes',
  async () => {
    const attributes = await trackPromise(getAttributes());
    return { attributes };
  }
);

export const getAttributesByMachines = createAsyncThunk<
  { attributes: AttributeModel[] },
  { getOnlyNumericAttributes: boolean; machineIds: number[] }
>('attributes/getAttributesByMachines', async ({ getOnlyNumericAttributes, machineIds }) => {
  const attributes = await trackPromise(getAttributesByMachineIds(getOnlyNumericAttributes, machineIds));
  return { attributes };
});

export const getAllAttributeMappings = createAsyncThunk<{ mappings: AttributeInternationalization[] }, undefined>(
  'attributes/getAttributeMappings',
  async () => {
    const mappings = await trackPromise(getAttributeMappings());
    return { mappings };
  }
);
