import { createSlice, Slice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { TimeRangeEnum } from '../../models/enum/timeRange.enum';

export interface MachineFilterState {
  timeRange: TimeRangeEnum | null;
  duration: string | null;
  dateFrom: Date | null;
  dateFromString: string | null;
  dateTo: Date | null;
  dateToString: string | null;
  isFastSlicesOptions: boolean;
  isAverageDataOptions: boolean;
}

const initialState: MachineFilterState = {
  timeRange: TimeRangeEnum.LastDay,
  duration: 'duration',
  dateFrom: null,
  dateTo: null,
  dateFromString: null,
  dateToString: null,
  isFastSlicesOptions: true,
  isAverageDataOptions: true,
};

export const machineFilterSlice: Slice = createSlice({
  name: 'machineFilter',
  initialState,
  reducers: {
    setMachineFilter(_, action) {
      return action.payload;
    },
    clearMachineFilter() {
      return initialState;
    },
  },
});

export const { setMachineFilter, clearMachineFilter } = machineFilterSlice.actions;

export const selectMachineFilter = (state: RootState) => {
  let from: Date | null = null;
  let to: Date | null = null;

  if (state.machineFilter.dateFromString) {
    from = new Date(state.machineFilter.dateFromString);
    from.setMinutes(from.getMinutes() + from.getTimezoneOffset());
  }

  if (state.machineFilter.dateToString) {
    to = new Date(state.machineFilter.dateToString);
    to.setMinutes(to.getMinutes() + to.getTimezoneOffset());
  }

  return {
    ...state.machineFilter,
    dateFrom: from,
    dateTo: to,
  };
};

export default machineFilterSlice.reducer;
