import React from 'react';

import './tabsHeader.scss';
import { TabsHeaderProps } from '../../models/props/components/tabsHeader.props';
import useActiveTabState from '../../hooks/useActiveTabState';

const TabsHeader: React.FC<TabsHeaderProps> = ({ tabId, tabNames }) => {
  const { tabsState, setActiveTab } = useActiveTabState();

  return (
    <div className='d-flex flex-row clickable tab-header'>
      {tabNames.map((tabName, index) => (
        <div
          key={tabName}
          className={`w-50 py-2 tab-header-name text-center ${tabsState[tabId] === index + 1 ? 'tab-active' : ''}`}
          onClick={() => setActiveTab(tabId, index + 1)}
        >
          <span>{tabName}</span>
        </div>
      ))}
    </div>
  );
};

export default TabsHeader;
