import React, { memo } from 'react';
import { timeZoneTransform } from '../../transformers/timeZone.transform';
import { EventStateType } from '../../models/enum/eventStateType.enum';

import { MDBBtn, MDBCol, MDBRow, MDBTooltip, MDBIcon, MDBPopover, MDBPopoverBody } from 'mdbreact';

import './tableFragments.scss';
import { translate } from '../../services/translation.service';
import { ContractOwnerModel } from '../../models/data/contract/contractOwner.model';
import useUserState from '../../hooks/useUserState';
import DateFormatter from '../dateFormatter/DataFormatter';

export interface TooltipTableCellProps {
  keyId: string;
  item: ContractOwnerModel;
}

export interface TableCellProps {
  keyId: string;
  item: string;
}

export interface SensorTableCellProps {
  rowId: string;
  item: string[];
}

export interface MachineGroupsTableCellProps {
  keyId: string;
  item: string[];
}

export interface SensorCellProps {
  keyId: string;
}

export interface StateHistoryTableCellProps {
  keyId: string;
  state: number;
  item: string;
}

export interface DateProps {
  keyId: string;
  item: Date | null | undefined;
}

interface ButtonProps {
  title: string;
  handleClick: () => void;
  disabled?: boolean;
}

interface ActionButtonProps {
  handleClick: () => void;
  title: string;
  icon: string;
  color?: string;
  disabled?: boolean;
}

export interface ActionItemProps {
  handleClick: (id?: number | string, validFrom?: Date | undefined) => void;
  title: string;
  icon: string;
}

export interface TableRowProps {
  handleClick?: (id: number) => void;
  isRowSelected?: boolean;
  id: number | string | undefined;
  children: React.ReactNode;
}

export interface MobileActionPopoverProps {
  itemActions: ActionItemProps[];
  id?: number | string;
  validFrom?: Date | undefined;
}

const getMachineStateStyleClass = (state: EventStateType) => {
  switch (state) {
    case EventStateType.Info:
      return 'grey darken-1';
    case EventStateType.Success:
      return 'green darken-1';
    case EventStateType.Warning:
      return 'yellow darken-2';
    case EventStateType.Error:
      return 'red darken-1';
    default:
      return '';
  }
};

const TextTableCell: React.FC<TableCellProps> = memo(({ keyId, item }) => {
  return (
    <td key={`${keyId}-th-header`} className='align-middle text-left rsrg-table-cell'>
      {item}
    </td>
  );
});

const TooltipTableCell: React.FC<TooltipTableCellProps> = memo(({ keyId, item }) => {
  return (
    <td key={`${keyId}-th-header`} className='align-middle text-left'>
      <MDBTooltip
        id={`${item.id}`}
        placement='bottom'
        tag='span'
        title={
          <div>
            <span>
              <span className='fontWeight600'>Name:</span> {item.contactPerson.firstName} {item.contactPerson.lastName}
            </span>
            <br />
            <span>
              <span className='fontWeight600'>Company:</span> {item.company}
            </span>
            <br />
            <span>
              <span className='fontWeight600'>Email:</span> {item.contactPerson.email}
            </span>
          </div>
        }
      >
        <span>{`${item.contactPerson.firstName} ${item.contactPerson.lastName}`}</span>
      </MDBTooltip>
    </td>
  );
});

const SensorTextTableCell: React.FC<SensorTableCellProps> = memo(({ rowId, item }) => {
  return (
    <td key={`${rowId}-${item}-th-header`} className='align-middle text-left'>
      {item?.map((item, index) => (
        <span key={`${item}-${rowId}-${index}`}>
          {index !== 0 && ', '}
          {item}
        </span>
      ))}
    </td>
  );
});

const MachineGroupsTableCell: React.FC<MachineGroupsTableCellProps> = memo(({ keyId, item }) => {
  return (
    <td key={`${keyId}-th-header`} className='align-middle text-left'>
      {item.map((item, index) => (
        <span key={`${keyId} ${item}`}>
          {index !== 0 && ', '}
          {item}
        </span>
      ))}
    </td>
  );
});

const DateTableCell: React.FC<DateProps> = memo(({ keyId, item }) => {
  const { userState } = useUserState();
  const { timeZone, timeZoneName } = userState;

  return (
    <td key={`${keyId}-th-header-date`} className='align-middle text-left'>
      {timeZoneTransform(item, timeZone, timeZoneName)}
    </td>
  );
});

const StateHistoryTableCell: React.FC<StateHistoryTableCellProps> = memo(({ keyId, item, state }) => {
  return (
    <td key={keyId} className='align-middle text-left'>
      <span
        className={
          'px-1 text-white font-weight-bold rounded shadow-box-example z-depth-1 ' + getMachineStateStyleClass(state)
        }
      >
        {item}
      </span>
    </td>
  );
});

export const TableActionButton: React.FC<ButtonProps> = memo(({ title, handleClick, disabled }) => {
  return (
    <MDBRow>
      <MDBCol md='12' sm='12' className='text-right p-3'>
        <MDBBtn
          color='warning'
          className='text-white'
          onClick={handleClick}
          disabled={disabled}
          data-testid={`tableFragements-${title && title.slice(title.lastIndexOf('.') + 1)}-btn`}
        >
          {translate(title)}
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  );
});

export const ActionButton: React.FC<ActionButtonProps> = memo(({ handleClick, title, icon, color, disabled }) => {
  return (
    <MDBTooltip placement='top' tag='span' title={translate(title)}>
      <MDBBtn
        outline
        size='sm'
        className={`btn-rounded ${color === 'grey' ? 'btn-black' : 'btn-yellow'}`}
        onClick={handleClick}
        disabled={disabled}
      >
        <MDBIcon icon={icon} />
      </MDBBtn>
    </MDBTooltip>
  );
});

export const ClickableTableRow: React.FC<TableRowProps> = memo(({ id, handleClick, isRowSelected, children }) => {
  const onRowClick = (e: any) => {
    const nodeName = e.target.nodeName.toLowerCase();
    if (handleClick && (nodeName === 'td' || nodeName === 'span')) {
      handleClick(Number(id));
    }
  };

  return (
    <tr
      id={`${id}`}
      className={handleClick ? 'clickable ' + (isRowSelected ? 'row-selected' : '') : ''}
      onClick={(e) => onRowClick(e)}
    >
      {children}
    </tr>
  );
});

export const MobileActionPopover: React.FC<MobileActionPopoverProps> = memo(({ itemActions, id, validFrom }) => {
  return (
    <MDBPopover
      placement='bottom'
      id='popperheader'
      data-testid='tablelFragements-popperheader-modal'
      btnChildren={
        <MDBBtn outline color='dark' rounded size='lg' className='actions-toggle' data-testid='tablelFragements-popper-btn'>
          <MDBIcon icon='ellipsis-v' className='mt-0' />
        </MDBBtn>
      }
      tag='span'
      dismiss
    >
      <div>
        {itemActions &&
          itemActions.map((itemAction) => (
            <MDBPopoverBody key={itemAction.title} data-testid='tableFragement-popoverBody-button'>
              <div onClick={() => itemAction.handleClick(id, validFrom)}>
                <MDBBtn outline rounded size='sm' className='btn-yellow' data-testid='tablelFragements-actionPopover-btn'>
                  <MDBIcon icon={itemAction.icon} />
                </MDBBtn>
                <span className='ml-3 action-text clickable'>{translate(itemAction.title)}</span>
              </div>
            </MDBPopoverBody>
          ))}
      </div>
    </MDBPopover>
  );
});

const getSensor = (id: number) => {
  switch (id) {
    case 1:
      return 'Temperature';
    case 2:
      return 'Pressure';
    case 3:
      return 'Battery Voltage';
    case 4:
      return 'Proximity';
  }
};

const SensorCell: React.FC<SensorCellProps> = memo(({ keyId }) => {
  const sensor = Math.floor(Math.random() * 4) + 1;
  return (
    <td key={`${keyId}-th-header`} className='align-middle text-left'>
      {getSensor(sensor)}
    </td>
  );
});

const ValueCell: React.FC<SensorCellProps> = memo(({ keyId }) => {
  return (
    <td key={`${keyId}-th-header`} className='align-middle text-left'>
      {Math.random().toPrecision(4)}
    </td>
  );
});

const getRenderComponent = (tableCellType: string) => {
  switch (tableCellType) {
    case 'tooltip-data-cell':
      return TooltipTableCell;
    case 'date-cell':
      return DateTableCell;
    case 'text-cell':
      return TextTableCell;
    case 'state-history-cell':
      return StateHistoryTableCell;
    case 'formatted-date':
      return DateFormatter;
    case 'machine-groups-cell':
      return MachineGroupsTableCell;
    case 'sensor':
      return SensorCell;
    case 'sensor-value':
      return ValueCell;
    case 'sensors-names':
      return SensorTextTableCell;
  }
};

export default getRenderComponent;
