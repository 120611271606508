import React from 'react';

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBTable,
  MDBTableBody,
  MDBModalDialog,
  MDBModalContent,
  MDBCheckbox,
} from 'mdbreact';

import './addMachineToGroupModal.scss';
import { AddMachineToGroupModalProps } from '../../../../models/props/geo/addMachineToGroupModal.props';
import { translate } from '../../../../services/translation.service';
import { favoriteGroupNameTransform } from '../../../../transformers/translate.transform';

const AddMachineToGroupModal: React.FC<AddMachineToGroupModalProps> = ({
  isOpen,
  groups,
  onShowModal,
  onCloseModal,
  onToggleGroupModalClick,
  onSaveUserGroupMachines,
}) => {
  return (
    <MDBContainer className='center-modal-page'>
      <MDBModal
        animationDirection='top'
        backdrop={true}
        show={isOpen}
        setShow={onShowModal}
        className='modal-notify modal-warning'
      >
        <MDBModalDialog centered={true}>
          <MDBModalContent>
            <MDBModalHeader>{translate('pages.geo.addMachineToGroups')}</MDBModalHeader>
            <MDBModalBody className='modal-scrollable'>
              <MDBTable className='rsrg-table' hover responsive>
                <MDBTableBody>
                  {groups.map((group) => (
                    <tr key={group.id}>
                      <td className='align-middle text-left'>
                        <MDBCheckbox
                          id={`modal-group-checkbox${group.id}`}
                          className='pr-0 mr-0'
                          inline
                          checked={group.isChecked}
                          value={group.id}
                          onChange={(e: any) => onToggleGroupModalClick(e.currentTarget.value)}
                        />
                      </td>

                      <td className='align-middle text-left w-100'>{favoriteGroupNameTransform(group.name)}</td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='warning' className='text-white' onClick={onSaveUserGroupMachines}>
                {translate('common.save')}
              </MDBBtn>
              <MDBBtn outline color='warning' className='cancel-onHover' onClick={onCloseModal}>
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
  );
};

export default AddMachineToGroupModal;
