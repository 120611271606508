import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  MDBBtn,
  MDBIcon,
  MDBPopover,
  MDBPopoverBody,
  MDBTable,
  MDBTableBody,
  MDBTooltip,
  MDBRow,
  MDBCol,
  MDBCheckbox,
  MDBTableHead,
} from 'mdbreact';

import './groupsTable.scss';
import { toast } from 'react-toastify';
import useModalState from '../../../../hooks/useModalState';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { genericSort } from '../../../../utils/sort.utility';
import { translate } from '../../../../services/translation.service';
import { favoriteGroupNameTransform } from '../../../../transformers/translate.transform';
import Pagination from '../../../../components/pagination/Pagination';
import TableHeader from '../../../../components/tableHeader/tableHeader';
import { SortCriteria } from '../../../../models/enum/sortCriteria.enum';
import TableSearch from '../../../../components/tableSearch/TableSearch';
import HeaderSection from '../../../../components/headerSection/HeaderSection';
import EmptyResultset from '../../../../components/emptyResultset/EmptyResultset';
import { GroupsTableProps } from '../../../../models/props/profile/groupsTable.props';
import { TableActionButton } from '../../../../components/tableFragments/tableFragments';
import {
  TableIds,
  updateTableFilterTextById,
  updateTableCurrentPageById,
  updateTableSortCriteriaById,
  updateTablePageToCountById,
} from '../../../../store/slices/tableSlice';

const itemsPerPage = 10;
const tableId: TableIds = 'userGroupsTableSettings';

const GroupsTable: React.FC<GroupsTableProps> = ({
  groups,
  globalChecked,
  onGlobalCheckClick,
  onToggleGroupClick,
  onGroupEditClick,
  onGroupDeleteClick,
  onBulkDeleteClick,
  onGroupFlagEditClick,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setModal } = useModalState();
  const { sortCriteria, filterText, currentPage, pageToCount } = useAppSelector((state) => state.tables[tableId]);

  const [isMobileHeaderOpen, setMobileHeaderOpen] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleGroupRedirect = () => {
    navigate('/profile/machine-group');
  };

  const handleSortToggle = useCallback(() => {
    dispatch(
      updateTableSortCriteriaById({
        id: tableId,
        sortCriteria: {
          ...sortCriteria,
          order: sortCriteria.order === SortCriteria.Descending ? SortCriteria.Ascending : SortCriteria.Descending,
        },
      })
    );
  }, [dispatch]);

  const updateFilterText = useCallback(
    (value?: string) => {
      dispatch(
        updateTableFilterTextById({
          id: tableId,
          filterText: value || '',
        })
      );
    },
    [dispatch]
  );

  const updateCurrentPage = useCallback(
    (page: number) => {
      dispatch(
        updateTableCurrentPageById({
          id: tableId,
          currentPage: page,
        })
      );
    },
    [dispatch]
  );

  const updatePageCount = useCallback(
    (count: number) => {
      dispatch(
        updateTablePageToCountById({
          id: tableId,
          pageToCount: count,
        })
      );
    },
    [dispatch]
  );

  const onSingleGroupDeleteClick = (groupId: string) => {
    setModal(true, () => onGroupDeleteClick(groupId));
  };

  const onBulkGroupDeleteClick = () => {
    const groupIds = groups.filter((g) => g.isChecked && !g.isLocked).map((g) => g.id);
    if (groupIds.length === 0) {
      toast.info(translate('pages.profile.noSelectedGroups'));
      return;
    }

    setModal(true, () => {
      onBulkDeleteClick();
      const clickEvent = { currentTarget: { checked: false } };
      onGlobalCheckClick!(clickEvent as React.FormEvent<HTMLInputElement>);
    });
  };

  return (
    <>
      <TableActionButton title={'pages.profile.createGroup'} handleClick={handleGroupRedirect} />
      <MDBRow className='justify-content-between'>
        <MDBCol md='4' sm='12'>
          <TableSearch
            filterText={filterText}
            clearSearch={() => updateFilterText('')}
            setFilterText={(event) => updateFilterText(event.currentTarget.value)}
          />
        </MDBCol>
        <MDBCol md='8' sm='12'>
          {groups.length > 0 && (
            <Pagination
              currentPage={currentPage}
              pageToCount={pageToCount}
              itemsLength={groups.length}
              itemsPerPage={itemsPerPage}
              setPageToCount={updatePageCount}
              setCurrentPage={updateCurrentPage}
            ></Pagination>
          )}
        </MDBCol>
      </MDBRow>
      <HeaderSection text={translate('pages.profile.groups')} />
      <EmptyResultset entitiesCount={groups.length}>
        <MDBTable className='rsrg-table' hover responsive>
          <MDBTableHead>
            <tr>
              <th className='th-xs align-items-bottom text-left'>
                <MDBCheckbox
                  data-testid='GroupTable-Header-Checkbox'
                  id='checkboxHeader'
                  inline
                  value={globalChecked.toString()}
                  checked={globalChecked}
                  onChange={onGlobalCheckClick}
                />
              </th>
              <th className='th-xs text-left d-table-cell d-md-none'>
                <MDBPopover
                  placement='bottom'
                  id='popperheader'
                  btnClassName='d-inline-block'
                  isOpen={isMobileHeaderOpen}
                  onClick={() => {
                    setMobileHeaderOpen(!isMobileHeaderOpen);
                  }}
                  tag='span'
                  btnChildren={
                    <MDBBtn outline color='dark' size='lg' className='actions-toggle'>
                      <MDBIcon icon='ellipsis-v' />
                    </MDBBtn>
                  }
                  dismiss
                >
                  <MDBPopoverBody className='p-0 m-2' data-testid='GroupsTable-PopoverBody-Header'>
                    <MDBBtn outline size='sm' className='btn-rounded btn-yellow' onClick={onBulkGroupDeleteClick}>
                      <MDBIcon icon='times-circle' />
                    </MDBBtn>
                    <span className='ml-3 action-text clickable'>{translate('tooltip.deleteSelected')}</span>
                  </MDBPopoverBody>
                </MDBPopover>
              </th>
              <TableHeader
                propertyName='name'
                sortState={sortCriteria}
                onSortClick={handleSortToggle}
                headerText={translate('common.name')}
              ></TableHeader>
              <th className='th-xs text-right d-none d-md-block'>
                <MDBTooltip tag='span' placement='top' title={translate('tooltip.deleteSelected')}>
                  <MDBBtn size='sm' outline className='btn-rounded btn-yellow' onClick={onBulkGroupDeleteClick}>
                    <MDBIcon icon='times-circle' />
                  </MDBBtn>
                </MDBTooltip>
              </th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {groups &&
              groups.length > 0 &&
              [...groups]
                .sort((x, y) => genericSort(sortCriteria.order, sortCriteria.column, x, y))
                .sort((x, y) => +y.isLocked - +x.isLocked)
                .filter((g) => g.name.toLowerCase().includes(filterText?.toLowerCase()))
                .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                .map((group) => (
                  <tr key={group.id}>
                    <td className='align-middle text-left'>
                      {!group.isLocked && (
                        <MDBCheckbox
                          id={`checkboxGroup${group.id}`}
                          data-testid={`GroupsTable-Group-Input-${group.id}`}
                          type='checkbox'
                          inline
                          value={group.isChecked ? group.isChecked.toString() : ''}
                          checked={group.isChecked}
                          onChange={() => onToggleGroupClick(group)}
                        />
                      )}
                    </td>
                    <td className='text-left d-table-cell d-md-none align-middle'>
                      <MDBPopover
                        placement='bottom'
                        id={'popper' + group.id}
                        btnChildren={
                          <MDBBtn
                            outline
                            color='dark'
                            size='lg'
                            data-testid={`GroupsTable-ModalBtn-${group.id}`}
                            className='actions-toggle'
                          >
                            <MDBIcon icon='ellipsis-v' />
                          </MDBBtn>
                        }
                        tag='span'
                        dismiss
                      >
                        <MDBPopoverBody data-testid={`GroupsTable-PopoverBody-${group.id}`}>
                          {group.isLocked && (
                            <div>
                              <div onClick={() => onGroupEditClick(group.id.toString())}>
                                <MDBBtn outline rounded size='sm' className='mb-2 btn-yellow'>
                                  <MDBIcon icon='pen' />
                                </MDBBtn>
                                <span className='ml-3 clickable action-text'>{translate('tooltip.editGroup')}</span>
                              </div>
                              <MDBBtn outline rounded color={'white'} size='sm' className='mb-2  btn-black'>
                                <MDBIcon icon='lock' />
                              </MDBBtn>
                              <span className='ml-3 clickable action-text'>{translate('tooltip.lockedGroup')}</span>
                            </div>
                          )}
                          {!group.isLocked && (
                            <>
                              <div
                                data-testid='GroupsTable-Options-Flag'
                                onClick={() => onGroupFlagEditClick(group.id.toString())}
                              >
                                <MDBBtn
                                  outline
                                  rounded
                                  size='sm'
                                  className={`mb-2 ${group.isFlagged ? 'btn-black' : 'btn-yellow'}`}
                                >
                                  <MDBIcon icon='flag' />
                                </MDBBtn>
                                <span data-testid='GroupsTable-Options-Span' className='ml-3 clickable action-text'>
                                  {group.isFlagged ? translate('tooltip.unflagGroup') : translate('tooltip.flagGroup')}
                                </span>
                              </div>
                              <div
                                data-testid='GroupsTable-Options-Edit'
                                onClick={() => onGroupEditClick(group.id.toString())}
                              >
                                <MDBBtn
                                  data-testid='GroupsTable-Options-Edit-Btn'
                                  outline
                                  rounded
                                  size='sm'
                                  className=' mb-2 btn-yellow'
                                >
                                  <MDBIcon icon='pen' />
                                </MDBBtn>
                                <span data-testid='GroupsTable-Options-Span' className='ml-3 clickable action-text'>
                                  {translate('tooltip.editGroup')}
                                </span>
                              </div>
                              <div
                                data-testid='GroupsTable-Options-Delete'
                                onClick={() => onSingleGroupDeleteClick(group.id.toString())}
                              >
                                <MDBBtn outline rounded size='sm' className='mb-2 btn-yellow'>
                                  <MDBIcon icon='times-circle' />
                                </MDBBtn>
                                <span data-testid='GroupsTable-Options-Span' className='ml-3 clickable action-text'>
                                  {translate('tooltip.deleteGroup')}
                                </span>
                              </div>
                            </>
                          )}
                        </MDBPopoverBody>
                      </MDBPopover>
                    </td>
                    <td className='align-middle text-left'>{favoriteGroupNameTransform(group.name)}</td>
                    <td className='align-middle text-right d-none d-md-block'>
                      {group.isLocked && (
                        <>
                          <MDBTooltip placement='top' tag='span' title={translate('tooltip.editGroup')}>
                            <MDBBtn
                              outline
                              size='sm'
                              className='btn-rounded btn-yellow'
                              onClick={() => onGroupEditClick(group.id.toString())}
                            >
                              <MDBIcon icon='pen' />
                            </MDBBtn>
                          </MDBTooltip>
                          <MDBTooltip placement='top' tag='span' title={translate('tooltip.lockedGroup')}>
                            <MDBBtn color='white' size='sm' rounded className='btn-black'>
                              <MDBIcon icon='lock' />
                            </MDBBtn>
                          </MDBTooltip>
                        </>
                      )}
                      {!group.isLocked && (
                        <>
                          <MDBTooltip
                            tag='span'
                            title={group.isFlagged ? translate('tooltip.unflagGroup') : translate('tooltip.flagGroup')}
                            placement='top'
                          >
                            <MDBBtn
                              size='sm'
                              outline
                              className={`btn-rounded ${group.isFlagged ? 'btn-black' : 'btn-yellow'}`}
                              onClick={() => {
                                setToggled(!toggled);
                                onGroupFlagEditClick(group.id.toString());
                              }}
                              noRipple
                            >
                              <MDBIcon icon='flag' />
                            </MDBBtn>
                          </MDBTooltip>
                          <MDBTooltip tag='span' placement='top' title={translate('tooltip.editGroup')}>
                            <MDBBtn
                              outline
                              size='sm'
                              className='btn-rounded btn-yellow'
                              onClick={() => onGroupEditClick(group.id.toString())}
                            >
                              <MDBIcon icon='pen' />
                            </MDBBtn>
                          </MDBTooltip>
                          <MDBTooltip tag='span' title={translate('tooltip.deleteGroup')} placement='top'>
                            <MDBBtn
                              size='sm'
                              outline
                              className='btn-rounded btn-yellow'
                              onClick={() => onSingleGroupDeleteClick(group.id.toString())}
                            >
                              <MDBIcon icon='times-circle' />
                            </MDBBtn>
                          </MDBTooltip>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
          </MDBTableBody>
        </MDBTable>
      </EmptyResultset>
      <hr className='my-0' />

      {groups.length > 0 && (
        <Pagination
          pageToCount={pageToCount}
          currentPage={currentPage}
          itemsLength={groups.length}
          itemsPerPage={itemsPerPage}
          setPageToCount={updatePageCount}
          setCurrentPage={updateCurrentPage}
        ></Pagination>
      )}
    </>
  );
};

export default GroupsTable;
