import React from 'react';

import { DateFormatterProps } from '../models/props/components/dateFormatter.props';
import getRenderComponent, {
  DateProps,
  TableCellProps,
  StateHistoryTableCellProps,
  SensorTableCellProps,
  TooltipTableCellProps,
  MachineGroupsTableCellProps,
} from '../components/tableFragments/tableFragments';

export interface GeneratedTableFunctionProps {
  onBulkDeleteClick?: () => void;
  onItemDeleteClick?(id: number | string, validFrom?: Date | undefined): void;
  onItemEditClick?: (id: number | string) => void;
  onItemDetailsClick?: (id: number | string) => void;
  onItemAddClick?: (id: number) => void;
  onBulkAddClick?: () => void;
  onItemAddToGroupsClick?: (id: number) => void;
  onItemVisibilityClick?: (id: number) => void;
  onItemGeoLocationClick?: (id: number | string) => void;
}

export const generateTableData = ({
  onBulkDeleteClick,
  onItemDeleteClick,
  onItemEditClick,
  onItemDetailsClick,
  onItemAddClick,
  onItemAddToGroupsClick,
  onItemVisibilityClick,
  onBulkAddClick,
  onItemGeoLocationClick,
}: GeneratedTableFunctionProps) => ({
  machinesAdminTableSettings: {
    tableHeader: 'common.machines',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.modifier',
        propertyName: 'modifier',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'modifier'],
    itemActions: [{ icon: 'cogs', handleClick: onItemEditClick, title: 'tooltip.editMachineConfiguration' }],
  },
  machineOwnerMachinesTable: {
    tableHeader: 'common.machines',
    headers: [
      {
        i18name: 'pages.admin.machineName',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.adminGroups',
        propertyName: 'groups',
        Component: getRenderComponent('machine-groups-cell') as React.FC<MachineGroupsTableCellProps> | undefined,
      },
    ],
    searchFields: ['name'],
  },
  attributesTableSettings: {
    tableHeader: 'common.attributes',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.unit',
        propertyName: 'unit',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.dataType',
        propertyName: 'dataType',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.modifier',
        propertyName: 'modifier',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'unit', 'dataType', 'description', 'modifier'],
  },
  adminGroupMachines: {
    tableHeader: 'common.machines',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.owner',
        propertyName: 'owner',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'owner'],
  },
  machineDetailAdminGroups: {
    tableHeader: 'common.adminGroups',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name'],
    buttonTitle: 'pages.admin.addAdminGroups',
    itemActions: [{ icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.removeAdminGroup' }],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.removeSelected' }],
  },
  machineDetailAlarms: {
    tableHeader: 'common.alarms',
    headers: [
      {
        i18name: 'common.attribute',
        propertyName: 'attributeName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.message',
        propertyName: 'message',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.rules',
        propertyName: 'formattedRules',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.notifications',
        propertyName: 'hasNotifications',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['attributeName', 'message'],
    buttonTitle: 'pages.admin.createAlarm',
    itemActions: [
      { icon: 'pen', handleClick: onItemEditClick, title: 'tooltip.editAlarm' },
      { icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.deleteAlarm' },
    ],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.deleteSelected' }],
  },
  machineAttributesTableSettings: {
    tableHeader: 'common.attributes',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.unit',
        propertyName: 'unit',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.dataType',
        propertyName: 'dataType',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.description',
        propertyName: 'description',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.modifier',
        propertyName: 'modifier',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.chart',
        propertyName: 'chart',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'unit', 'dataType', 'description', 'modifier'],
    buttonTitle: 'pages.admin.addThemeAttribute',
    itemActions: [
      { icon: 'chart-area', handleClick: onItemAddToGroupsClick, title: 'tooltip.addToChart' },
      { icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.removeAttribute' },
    ],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.removeSelected' }],
  },
  adminGroupsTableSettings: {
    tableHeader: 'common.adminGroups',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name'],
    buttonTitle: 'pages.admin.createAdminGroup',
    itemActions: [
      { icon: 'pen', handleClick: onItemEditClick, title: 'tooltip.editAdminGroup' },
      { icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.deleteAdminGroup' },
    ],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.deleteSelected' }],
  },
  adminSensorGroupMachines: {
    tableHeader: 'common.machines',
    headers: [
      {
        i18name: 'ID',
        propertyName: 'id',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.owner',
        propertyName: 'owner',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'owner'],
    buttonTitle: 'pages.admin.addMachines',
    itemActions: [{ icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.removeMachine' }],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.removeSelected' }],
  },
  adminSensorGroups: {
    tableHeader: 'pages.profile.sensorGroups',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.description',
        propertyName: 'description',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.machines',
        propertyName: 'machineNames',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.sensor',
        propertyName: 'sensors',
        Component: getRenderComponent('sensors-names') as React.FC<SensorTableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'description'],
    buttonTitle: 'pages.profile.createGroup',
    itemActions: [
      { icon: 'pen', handleClick: onItemEditClick, title: 'tooltip.editGroup' },
      { icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.deleteGroup' },
    ],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.deleteSelected' }],
  },
  adminSensorGroupSensors: {
    tableHeader: 'common.sensors',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.chart',
        propertyName: 'chart',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'chart', 'description'],
    buttonTitle: 'pages.profile.addSensor',
    itemActions: [
      { icon: 'chart-area', handleClick: onItemAddToGroupsClick, title: 'tooltip.addToChart' },
      { icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.removeSensor' },
    ],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.removeSelected' }],
  },
  alarmRulesDetail: {
    tableHeader: 'pages.admin.rules',
    headers: [
      {
        i18name: 'common.symbol',
        propertyName: 'symbol',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.value',
        propertyName: 'value',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    itemActions: [
      { icon: 'pen', handleClick: onItemEditClick, title: 'tooltip.editRule' },
      { icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.deleteRule' },
    ],
    searchFields: ['symbol', 'value'],
    buttonTitle: 'pages.admin.createRule',
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.deleteSelected' }],
  },
  alarmNotifications: {
    tableHeader: 'pages.admin.notifications',
    headers: [
      {
        i18name: 'common.type',
        propertyName: 'type',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.emailAddress',
        propertyName: 'email',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    itemActions: [
      { icon: 'pen', handleClick: onItemEditClick, title: 'tooltip.editNotification' },
      { icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.deleteNotification' },
    ],
    searchFields: ['type', 'email'],
    buttonTitle: 'pages.admin.createNotification',
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.deleteSelected' }],
  },
  addThemeAttribute: {
    tableHeader: 'common.attributes',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.unit',
        propertyName: 'unit',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.dataType',
        propertyName: 'dataType',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.description',
        propertyName: 'description',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.modifier',
        propertyName: 'modifier',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'unit', 'dataType', 'description', 'modifier'],
  },
  contractsTableSettings: {
    tableHeader: 'common.contracts',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.contactPerson',
        propertyName: 'contractOwner',
        Component: getRenderComponent('tooltip-data-cell') as React.FC<TooltipTableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.startDate',
        propertyName: 'startDate',
        Component: getRenderComponent('date-cell') as React.FC<DateProps> | undefined,
      },
      {
        i18name: 'pages.admin.endDate',
        propertyName: 'stopDate',
        Component: getRenderComponent('date-cell') as React.FC<DateProps> | undefined,
      },
    ],
    searchFields: ['name'],
    buttonTitle: 'pages.admin.createContract',
    itemActions: [
      { icon: 'pen', handleClick: onItemEditClick, title: 'tooltip.editContract' },
      { icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.deleteContract' },
    ],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.deleteSelected' }],
  },
  userDetailsContractsTable: {
    tableHeader: 'pages.admin.userContracts',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.responsible',
        propertyName: 'responsible',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.startDate',
        propertyName: 'startDate',
        Component: getRenderComponent('date-cell') as React.FC<DateProps> | undefined,
      },
      {
        i18name: 'pages.admin.endDate',
        propertyName: 'stopDate',
        Component: getRenderComponent('date-cell') as React.FC<DateProps> | undefined,
      },
    ],
    searchFields: ['name', 'responsible'],
    buttonTitle: 'pages.admin.addContract',
    itemActions: [{ icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.removeContract' }],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.removeSelected' }],
  },
  contractUsersTable: {
    tableHeader: 'common.users',
    headers: [
      {
        i18name: 'common.firstName',
        propertyName: 'firstName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.lastName',
        propertyName: 'lastName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.company',
        propertyName: 'company',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.emailAddress',
        propertyName: 'email',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.accountType',
        propertyName: 'issuer',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['firstName', 'lastName', 'email', 'company'],
    buttonTitle: 'pages.admin.addUsers',
    itemActions: [{ icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.removeUser' }],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.removeSelected' }],
  },
  usersTable: {
    tableHeader: 'common.users',
    headers: [
      {
        i18name: 'common.firstName',
        propertyName: 'firstName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.lastName',
        propertyName: 'lastName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.company',
        propertyName: 'company',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.emailAddress',
        propertyName: 'email',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.accountType',
        propertyName: 'issuer',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['firstName', 'lastName', 'email', 'company'],
    itemActions: [{ icon: 'pen', handleClick: onItemDetailsClick, title: 'tooltip.editUserDetails' }],
  },
  userContracts: {
    tableHeader: 'pages.admin.addContract',
    headers: [
      {
        i18name: 'common.firstName',
        propertyName: 'firstName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.lastName',
        propertyName: 'lastName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.company',
        propertyName: 'company',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.emailAddress',
        propertyName: 'email',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['firstName', 'lastName', 'email', 'company'],
  },
  addContractUser: {
    tableHeader: 'pages.admin.addContractUser',
    headers: [
      {
        i18name: 'common.firstName',
        propertyName: 'firstName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.lastName',
        propertyName: 'lastName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.company',
        propertyName: 'company',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.emailAddress',
        propertyName: 'email',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.accountType',
        propertyName: 'issuer',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['firstName', 'lastName', 'email', 'company'],
  },
  availableMachines: {
    headers: [
      {
        i18name: 'ID',
        propertyName: 'id',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.owner',
        propertyName: 'owner',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    itemActions: [{ icon: 'plus', handleClick: onItemAddClick, title: 'tooltip.addMachine' }],
    bulkActions: [{ icon: 'plus', handleClick: onBulkAddClick, title: 'tooltip.bulkAdd' }],
  },
  contractMachines: {
    tableHeader: 'common.machines',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.owner',
        propertyName: 'machineOwnerCompany',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.adminGroup',
        propertyName: 'adminGroups',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.validFrom',
        propertyName: 'validFrom',
        Component: getRenderComponent('date-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'machineOwnerCompany', 'adminGroups'],
    buttonTitle: `pages.admin.addMachines`,
    itemActions: [{ icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.removeMachine' }],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.removeSelected' }],
  },
  addContractMachinesTable: {
    tableHeader: 'common.availableMachines',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.owner',
        propertyName: 'machineOwnerCompany',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.adminGroup',
        propertyName: 'adminGroups',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.validFrom',
        propertyName: 'validFrom',
        Component: getRenderComponent('date-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'machineOwnerCompany', 'adminGroups'],
  },
  addMachineAdminGroup: {
    tableHeader: 'common.adminGroups',
    headers: [
      {
        i18name: 'common.adminGroupName',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name'],
  },
  stateHistoryTable: {
    headers: [
      {
        i18name: 'common.time',
        propertyName: 'time',
        Component: getRenderComponent('date-cell') as React.FC<DateProps> | undefined,
      },
      {
        i18name: 'pages.geo.state',
        propertyName: 'title',
        Component: getRenderComponent('state-history-cell') as React.FC<StateHistoryTableCellProps> | undefined,
      },
    ],
  },
  allGeoMachines: {
    tableHeader: 'common.machines',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.lastMessage',
        propertyName: 'messageTime',
        Component: getRenderComponent('formatted-date') as React.FC<DateFormatterProps> | undefined,
      },
      {
        i18name: 'common.lastGpsPosition',
        propertyName: 'gpsTime',
        Component: getRenderComponent('formatted-date') as React.FC<DateFormatterProps> | undefined,
      },
      {
        i18name: 'pages.geo.systemName',
        propertyName: 'systemName',
        Component: getRenderComponent('text-cell') as React.FC<DateProps> | undefined,
      },
    ],
    searchFields: ['name', 'owner'],
    itemActions: [
      { icon: 'chart-line', handleClick: onItemDetailsClick, title: 'tooltip.machineDataDetails' },
      { icon: 'map-marker-alt', handleClick: onItemGeoLocationClick, title: 'tooltip.machineGeoDetails' },
      { icon: 'tag', handleClick: onItemAddToGroupsClick, title: 'tooltip.updateMachineGroups' },
      { icon: 'eye', handleClick: onItemVisibilityClick, title: 'tooltip.toggleMapMachine' },
    ],
    bulkActions: [
      { icon: 'eye', handleClick: onBulkAddClick, title: 'tooltip.showSelected' },
      { icon: 'eye-slash', handleClick: onBulkDeleteClick, title: 'tooltip.hideSelected' },
    ],
  },
  machineOwnersTableSettings: {
    tableHeader: 'common.machineOwners',
    headers: [
      {
        i18name: 'common.firstName',
        propertyName: 'firstName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.lastName',
        propertyName: 'lastName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.company',
        propertyName: 'company',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.emailAddress',
        propertyName: 'email',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['firstName', 'lastName', 'email', 'company'],
    buttonTitle: 'pages.admin.createMachineOwner',
    itemActions: [
      { icon: 'pen', handleClick: onItemEditClick, title: 'tooltip.editMachineOwner' },
      { icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.deleteMachineOwner' },
    ],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.deleteSelected' }],
  },
  machineMachineOwnerTable: {
    tableHeader: 'common.machineOwners',
    headers: [
      {
        i18name: 'common.firstName',
        propertyName: 'firstName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.lastName',
        propertyName: 'lastName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.company',
        propertyName: 'company',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.emailAddress',
        propertyName: 'email',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['firstName', 'lastName', 'email', 'company'],
  },
  sensorTable: {
    headers: [
      {
        i18name: 'common.machine',
        propertyName: 'machineName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.attribute',
        propertyName: 'attributeName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.message',
        propertyName: 'message',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.value',
        propertyName: 'value',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.rule',
        propertyName: 'rule',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.time',
        propertyName: 'time',
        Component: getRenderComponent('formatted-date') as React.FC<DateFormatterProps> | undefined,
      },
    ],
    searchFields: ['machineName', 'attributeName', 'message', 'value', 'rule', 'time'],
    itemActions: [{ icon: 'chart-line', handleClick: onItemDetailsClick, title: 'tooltip.machineDataDetails' }],
  },
  sensorTableDetails: {
    headers: [
      {
        i18name: 'common.attribute',
        propertyName: 'attributeName',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.message',
        propertyName: 'message',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.value',
        propertyName: 'value',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.rule',
        propertyName: 'rule',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.time',
        propertyName: 'time',
        Component: getRenderComponent('formatted-date') as React.FC<DateFormatterProps> | undefined,
      },
    ],
    searchFields: ['attributeName', 'message', 'value', 'rule', 'time'],
  },
  dataMachinesTable: {
    tableHeader: 'common.machines',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.lastMessage',
        propertyName: 'messageTime',
        Component: getRenderComponent('formatted-date') as React.FC<DateFormatterProps> | undefined,
      },
      {
        i18name: 'pages.geo.systemName',
        propertyName: 'systemName',
        Component: getRenderComponent('text-cell') as React.FC<DateProps> | undefined,
      },
    ],
    searchFields: ['name', 'messageTime'],
    itemActions: [
      { icon: 'tag', handleClick: onItemAddToGroupsClick, title: 'tooltip.updateMachineGroups' },
      { icon: 'chart-line', handleClick: onItemDetailsClick, title: 'tooltip.machineDataDetails' },
      { icon: 'map-marker-alt', handleClick: onItemGeoLocationClick, title: 'tooltip.machineGeoDetails' },
    ],
  },
  profileSensorGroups: {
    tableHeader: 'pages.profile.sensorGroups',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.description',
        propertyName: 'description',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.machines',
        propertyName: 'machineNames',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.sensor',
        propertyName: 'sensors',
        Component: getRenderComponent('sensors-names') as React.FC<SensorTableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'description'],
    buttonTitle: 'pages.profile.createGroup',
    itemActions: [
      { icon: 'pen', handleClick: onItemEditClick, title: 'tooltip.editGroup' },
      { icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.deleteGroup' },
    ],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.deleteSelected' }],
  },
  profileGroupSensors: {
    tableHeader: 'common.sensors',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.chart',
        propertyName: 'chart',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'chart', 'description'],
    buttonTitle: 'pages.profile.addSensor',
    itemActions: [
      { icon: 'chart-area', handleClick: onItemAddToGroupsClick, title: 'tooltip.addToChart' },
      { icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.removeSensor' },
    ],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.removeSelected' }],
  },
  profileSensorGroupMachines: {
    tableHeader: 'common.machines',
    headers: [
      {
        i18name: 'ID',
        propertyName: 'id',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.owner',
        propertyName: 'owner',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'owner'],
    buttonTitle: 'pages.admin.addMachines',
    itemActions: [{ icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.removeMachine' }],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.removeSelected' }],
  },
  profileGroupAddMachines: {
    tableHeader: 'common.machines',
    headers: [
      {
        i18name: 'ID',
        propertyName: 'id',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.owner',
        propertyName: 'owner',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'owner'],
    buttonTitle: 'pages.admin.addMachines',
  },
  profileGroupAddSensors: {
    tableHeader: 'common.availableSensors',
    headers: [
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.unit',
        propertyName: 'unit',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'pages.admin.dataType',
        propertyName: 'dataType',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'description'],
  },
  profileUserGroupMachines: {
    tableHeader: 'common.machines',
    headers: [
      {
        i18name: 'ID',
        propertyName: 'id',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.owner',
        propertyName: 'owner',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'owner'],
    buttonTitle: 'pages.admin.addMachines',
    itemActions: [{ icon: 'times-circle', handleClick: onItemDeleteClick, title: 'tooltip.removeMachine' }],
    bulkActions: [{ icon: 'times-circle', handleClick: onBulkDeleteClick, title: 'tooltip.removeSelected' }],
  },
  profileUserGroupAddMachines: {
    tableHeader: 'common.availableMachines',
    headers: [
      {
        i18name: 'ID',
        propertyName: 'id',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.name',
        propertyName: 'name',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
      {
        i18name: 'common.owner',
        propertyName: 'owner',
        Component: getRenderComponent('text-cell') as React.FC<TableCellProps> | undefined,
      },
    ],
    searchFields: ['name', 'owner'],
    buttonTitle: 'pages.admin.addMachines',
  },
});
