import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { MDBSpinner } from 'mdbreact';

import { useAppDispatch, useAppSelector } from './app/hooks/reduxHooks';

import './app.scss';
import './mdbStyles.scss';
import './muiStyles.css';

import './app/interceptors/httpInterceptor';
import AppModal from './app/common/appModal/AppModal';
import LoadingOverlay from 'react-loading-overlay-ts';
import RoutesWithNavigation from './app/common/routesWithNavigation/RoutesWithNavigation';
import useAuthorization from './app/hooks/useAuthorization';
import useUserState from './app/hooks/useUserState';
import useInterval from './app/hooks/useInterval';
import { setUserLocation } from './app/store/slices/mapsSlice';
import { getAllAttributeMappings } from './app/store/api/attributes.service';
import moment from 'moment';
import { addUserStatisticHistory } from './app/store/api/userStatistics.service';
import { clearUserStatistics, selectUserStatistics } from './app/store/slices/userStatisticsSlice';
import { CommonConstants } from './app/constants/common.constants';

const App: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, , setIsLoading] = useAuthorization();
  const { userState, userLocaleLanguage } = useUserState();
  const userStatisticHistory = useAppSelector(selectUserStatistics);

  useInterval(() => {
    dispatch(addUserStatisticHistory(userStatisticHistory));
    dispatch(clearUserStatistics({}));
  }, CommonConstants.refreshUserStatisticsTime);

  useEffect(() => {
    if (!isLoading && !userState.userId) {
      setIsLoading(false);
      navigate('/user-not-synchronized');
    }
  }, [isLoading, navigate, setIsLoading, userState.userId]);

  useEffect(() => {
    const handleAppClose = async () => {
      await dispatch(addUserStatisticHistory(userStatisticHistory)).unwrap();
    };

    dispatch(getAllAttributeMappings());
    navigator?.geolocation.getCurrentPosition((location) =>
      dispatch(setUserLocation({ lat: location.coords.latitude, lng: location.coords.longitude }))
    );

    window.addEventListener('beforeunload', handleAppClose);
    return () => {
      window.removeEventListener('beforeunload', handleAppClose);
    };
  }, [dispatch, userStatisticHistory]);

  useEffect(() => {
    moment.locale(userLocaleLanguage);
  }, [userLocaleLanguage]);

  return (
    <>
      <LoadingOverlay active={isLoading} spinner={<MDBSpinner color='warning' />} />
      {!isLoading && <AppModal render={() => <RoutesWithNavigation />} />}
    </>
  );
};

export default App;
