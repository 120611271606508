import { createSlice, Slice, createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../store/store';
import { AttributeInternationalization, AttributeModel } from '../../../models/data/machine/attribute.model';
import { AttributeMapping } from '../../../models/data/common/attributeMappings.model';
import {
  getAllAttributeMappings,
  getAllAttributes,
  getAttributesByMachines,
} from '../../../store/api/attributes.service';

export interface attributesState {
  attributes: AttributeModel[];
  attributeMappings: AttributeInternationalization[];
}

const initialState: attributesState = {
  attributes: [],
  attributeMappings: [],
};

export const attributesSlice: Slice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    updateAttributeChecked(state, action) {
      const attribute = state.attributes.find((attribute) => attribute.id.toString() === action.payload);

      if (attribute) {
        attribute.isChecked = !attribute.isChecked;
      }
    },
    updateGlobalAttributeChecked(state, action) {
      const { isChecked } = action.payload;
      state.attributes = state.attributes.map((item) => ({ ...item, isChecked }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAttributes.fulfilled, (state, action) => {
        const { attributes } = action.payload;
        state.attributes = attributes;
      })
      .addCase(getAttributesByMachines.fulfilled, (state, action) => {
        const { attributes } = action.payload;
        state.attributes = attributes;
      })
      .addCase(getAllAttributeMappings.fulfilled, (state, action) => {
        const { mappings } = action.payload;
        state.attributeMappings = mappings;
      });
  },
});

export const { updateAttributeChecked, updateGlobalAttributeChecked } = attributesSlice.actions;

export default attributesSlice.reducer;

export const selectAlarm = (state: RootState) => state.alarm.alarm;
export const selectAttributes = (state: RootState) => state.attributes.attributes;
const selectMappings = (state: RootState) => state.attributes.attributeMappings;

export const selectAttributeMappings = createSelector([selectMappings], (mappings) => {
  const attributeMappings = new Map<string, AttributeMapping>();

  for (const mapping of mappings) {
    if (!attributeMappings.has(mapping.uuid.toLowerCase())) {
      let translation = { uuid: mapping.uuid } as AttributeMapping;

      for (const mappingTranslation of mapping.translations) {
        translation[mappingTranslation.code] = mappingTranslation.label;
      }

      attributeMappings.set(mapping.uuid.toLowerCase(), translation);
    }
  }

  return attributeMappings;
});

export const selectFormattedAttributes = createSelector([selectAttributes, selectAlarm], (attributes, alarm) =>
  attributes.map((attribute) => ({
    uuid: attribute?.uuid,
    text: attribute?.name,
    value: attribute?.uuid,
    checked: attribute?.uuid === alarm?.attribute,
  }))
);
