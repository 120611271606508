import React from 'react';

import { MDBIcon, MDBNavbar, MDBNavbarLink } from 'mdbreact';

import './backToPage.scss';
import { useNavigate } from 'react-router';

const BackToPage: React.FC<{ text: string; navigateTo: string; icon?: string; extraAction?: () => void }> = ({
  text,
  navigateTo,
  icon = 'chevron-left',
  extraAction,
}) => {
  const navigate = useNavigate();

  const onActionClick = () => {
    if (extraAction) {
      extraAction();
    }
  };

  const navigateToLocation = (locationTo: string) => {
    navigate(locationTo);
  };
  return (
    <MDBNavbar className='backTo'>
      <MDBNavbarLink className='backTo' onClick={() => navigateToLocation(navigateTo)}>
        <MDBIcon className='mr-2' icon={icon} />
        <span onClick={onActionClick}>{text}</span>
      </MDBNavbarLink>
    </MDBNavbar>
  );
};

export default BackToPage;
