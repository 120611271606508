import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from './reduxHooks';
import { selectFlaggedGroups, selectFlaggedDataGroups, setUserFlaggedGroups } from '../store/slices/flaggedGroupsSlice';
import { UserGroupModel } from '../models/data/group/userGroup.model';

export default function useFlaggedGroupsState() {
  const flaggedGroupsState = useAppSelector(selectFlaggedGroups);
  const flaggedDataGroupsState = useAppSelector(selectFlaggedDataGroups);
  const dispatch = useAppDispatch();

  const setFlaggedGroups = useCallback(
    (groups: UserGroupModel[]) => dispatch!(setUserFlaggedGroups(groups.filter((userGroup) => userGroup.isFlagged))),
    [dispatch]
  );

  return {
    flaggedGroupsState,
    flaggedDataGroupsState,
    setFlaggedGroups,
  };
}
