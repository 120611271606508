import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBModalFooter, MDBRow } from 'mdbreact';

import { translate } from '../../../../services/translation.service';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import SynchronizingStatus from '../SynchronizingStatus/SynchronizingStatus';
import { MachineOwnerDetailProps } from '../../../../models/props/admin/machineOwnerDetail.props';
import {
  setSelectedItemField,
  selectSelectedMachineOwnerHasValidFormData,
} from '../../MachineOwners/machineOwners.slice';
import { isValidEmail } from '../../../../utils/validation.utility';

const MachineOwnerDetail: React.FC<MachineOwnerDetailProps> = ({ machineOwner, onSubmitMachineOwner }) => {
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const hasValidFormData = useAppSelector(selectSelectedMachineOwnerHasValidFormData);

  useEffect(() => {
    setEditMode(!!machineOwner && !!machineOwner?.id);
  }, [machineOwner]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    if (id === 'email') {
      if (!isValidEmail(value)) {
        const divEmailError = document.getElementById('email-error') as HTMLElement;
        divEmailError!.textContent = translate('pages.admin.emailNotInTheCorrectFormat');
      } else {
        const emailInputEl = document.getElementById('email') as HTMLInputElement;
        emailInputEl.setCustomValidity('');
      }
    }

    dispatch(setSelectedItemField({ fieldId: id, value }));
  };
  const submitForm = (event) => {
    event.preventDefault();
    event.target.className += ' was-validated';

    if (!isValidEmail(machineOwner?.email)) {
      const emailInputEl = document.getElementById('email') as HTMLInputElement;
      emailInputEl!.setCustomValidity(translate('pages.admin.emailNotInTheCorrectFormat'));
      return;
    }

    if (!hasValidFormData) {
      return;
    }

    onSubmitMachineOwner(!editMode);
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <form className='needs-validation' onSubmit={submitForm} noValidate>
          <MDBRow className='pb-4' between>
            <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
              <MDBIcon icon='user-tie' color='dark' className='p-4 shadow-3-strong bg-warning rounded' />
              <h5 className='font-weight-bold ml-4'>
                {editMode ? translate('common.edit') : translate('common.create')}{' '}
                {translate('pages.admin.machineOwner')}
              </h5>
            </MDBCol>
            {editMode && !machineOwner?.isProcessed && <SynchronizingStatus />}
          </MDBRow>

          <MDBRow>
            <MDBCol md='6' xl='3' className='mb-3'>
              <MDBInput
                id='firstName'
                className='form-control'
                required
                type='text'
                label={`${translate('common.firstName')}*`}
                value={machineOwner?.firstName}
                onChange={handleChange}
                disabled={editMode && !machineOwner?.isProcessed}
              >
                <div className='invalid-feedback error-message'>{translate('pages.admin.firstNameIsRequired')}</div>
              </MDBInput>
            </MDBCol>
            <MDBCol md='6' xl='3'>
              <MDBInput
                id='lastName'
                className='form-control'
                required
                type='text'
                label={`${translate('common.lastName')}*`}
                value={machineOwner?.lastName}
                onChange={handleChange}
                disabled={editMode && !machineOwner?.isProcessed}
              >
                <div className='invalid-feedback error-message'>{translate('pages.admin.lastNameIsRequired')}</div>
              </MDBInput>
            </MDBCol>
          </MDBRow>

          <MDBRow className='mt-4'>
            <MDBCol md='6' xl='3' className='mb-3'>
              <MDBInput
                id='email'
                className='form-control'
                required
                type='text'
                label={`${translate('common.emailAddress')}*`}
                value={machineOwner?.email}
                onChange={handleChange}
                disabled={editMode && !machineOwner?.isProcessed}
              >
                <div id='email-error' className='invalid-feedback error-message'>
                  {translate('pages.admin.emailIsRequired')}
                </div>
              </MDBInput>
            </MDBCol>
            <MDBCol md='6' xl='3'>
              <MDBInput
                id='company'
                className='form-control'
                required
                type='text'
                label={`${translate('common.company')}*`}
                value={machineOwner?.company}
                onChange={handleChange}
                disabled={editMode && !machineOwner?.isProcessed}
              >
                <div className='invalid-feedback error-message'>{translate('pages.admin.companyNameIsRequired')}</div>
              </MDBInput>
            </MDBCol>
          </MDBRow>

          <MDBModalFooter className='pr-0'>
            <MDBBtn
              color='warning'
              className='text-white '
              type='submit'
              disabled={editMode && !machineOwner?.isProcessed}
            >
              {translate('common.save')}
            </MDBBtn>
            <MDBBtn
              outline
              color='warning'
              className='cancel-onHover'
              onClick={() => navigate('/admin/machine-owners')}
            >
              {translate('common.cancel')}
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBCardBody>
    </MDBCard>
  );
};

export default MachineOwnerDetail;
