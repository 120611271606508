import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Ajv from 'ajv';
import { toast } from 'react-toastify';

import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBModalFooter, MDBRow } from 'mdbreact';

import './machineDetail.scss';
import { translate } from '../../../../services/translation.service';
import { MachineDetailProps } from '../../../../models/props/admin/machineDetail.props';
import { AdminConstants } from '../../../../constants/admin.constants';
import SynchronizingStatus from '../SynchronizingStatus/SynchronizingStatus';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import {
  selectDisplayInfoMessage,
  selectSelectedMachineHasValidData,
  selectSelectedMachineUpdateData,
  setDisplayInfoMessage,
  setMachineData,
} from '../../Machines/machines.slice';

const MachineDetail: React.FC<MachineDetailProps> = ({ machine, onSubmitMachine, onDeleteMachineConfiguration }) => {
  const ajv = new Ajv();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const isFormValid = useAppSelector(selectSelectedMachineHasValidData);
  const machineData = useAppSelector(selectSelectedMachineUpdateData);
  const displayInfoMessage = useAppSelector(selectDisplayInfoMessage);

  useEffect(() => {
    setEditMode(!!machine);
  }, [machine]);

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.currentTarget.className += ' was-validated';

    if (!isFormValid) {
      toast.warn(translate('pages.admin.machineMachineOwnerIsRequired'));
      return;
    }

    onSubmitMachine(!editMode);
    dispatch(setDisplayInfoMessage(false));
  };

  const handleClick = () => {
    fileInputRef?.current?.click();
  };

  const handleInputChange = (event) => {
    const fileReader = new FileReader();

    fileReader.readAsText(event.target.files[0], 'UTF-8');

    fileReader.onload = (e) => {
      const parsedResult = JSON.parse(e?.target?.result as string);
      const validate = ajv.compile(AdminConstants.machineConfigurationValidationSchema);
      const valid = validate(parsedResult);

      if (!valid) {
        const errorMessage = validate.errors?.map((err) => err.message).join(', ');
        setErrorMsg(errorMessage);
        return;
      }

      const { adminGroups, alarms, machineOwner } = parsedResult;

      dispatch(setMachineData({ adminGroups, alarms, machineOwner }));
      dispatch(setDisplayInfoMessage(true));
    };
  };

  const handleExportClick = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(machineData))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `${machineData.name}-configuration.json`;

    link.click();
  };

  const handleDeleteConfiguration = () => {
    onDeleteMachineConfiguration(machine?.id!);
  };

  const handleCancelClick = () => {
    dispatch(setDisplayInfoMessage(false));
    navigate('/admin/machines');
  };

  return (
    <MDBCard className='mb-4'>
      <MDBCardBody>
        <form className='needs-validation' onSubmit={submitForm} noValidate>
          <MDBRow className='mb-4' between>
            <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
              <MDBIcon icon='train' color='dark' className='p-4 shadow-3-strong bg-warning rounded' />
              <h5 className='font-weight-bold ml-4'>{translate('tooltip.machineDetails')}</h5>
            </MDBCol>
            {editMode && !machine?.isProcessed && <SynchronizingStatus />}
          </MDBRow>

          <MDBRow>
            <MDBCol md='6' xl='3'>
              <MDBInput
                id='name'
                className='form-control'
                required
                type='text'
                label={`${translate('common.name')}*`}
                value={machine?.name}
                disabled
              />
            </MDBCol>
            <MDBCol md='6' xl='3' className='mt-3 mt-md-0 '>
              <MDBInput
                id='modifier'
                className='form-control'
                required
                type='text'
                label={`${translate('pages.admin.modifier')}*`}
                value={machine?.modifier}
                disabled
              />
            </MDBCol>
            {displayInfoMessage && (
              <MDBCol className='d-flex justify-content-end' xl='6'>
                <div className='d-flex align-items-center period-filter-message-panel'>
                  <MDBIcon icon='info-circle' color='warning' className='pl-2 pr-2 amber-text' size='2x' />
                  <div className='period-filter-message'>{translate('pages.admin.machineConfigurationMessage')}</div>
                </div>
              </MDBCol>
            )}
          </MDBRow>

          <MDBModalFooter className='px-0 mx-0 justify-content-between'>
            <MDBCol sm='12' md='7' xl='8' className='mx-0 p-0 justify-content-start'>
              <MDBBtn
                outline
                type='button'
                color='warning'
                className='cancel-onHover ml-0 mr-3 mt-2 mt-xlg-0'
                onClick={handleClick}
              >
                {translate('common.importConfiguration')}
              </MDBBtn>
              <MDBBtn
                outline
                type='button'
                color='warning'
                className='cancel-onHover mr-3 mt-2 mt-xlg-0'
                onClick={handleExportClick}
                disabled={!machineData.machineOwner}
              >
                {translate('common.exportConfiguration')}
              </MDBBtn>
              <MDBBtn
                outline
                type='button'
                color='warning'
                className='cancel-onHover mr-3 mt-2 mt-xlg-0'
                onClick={handleDeleteConfiguration}
                disabled={!machineData.machineOwner}
              >
                {translate('pages.admin.deleteMachineConfiguration')}
              </MDBBtn>
              <input className='d-none' ref={fileInputRef} type='file' accept='.json' onChange={handleInputChange} />
            </MDBCol>
            <MDBCol sm='12' md='4' xl='3' className='d-flex justify-content-end px-0'>
              <MDBBtn
                color='warning'
                className='text-white mr-3'
                type='submit'
                disabled={editMode && !machine?.isProcessed}
              >
                {translate('common.save')}
              </MDBBtn>
              <MDBBtn outline type='button' color='warning' className='m-0' onClick={handleCancelClick}>
                {translate('common.cancel')}
              </MDBBtn>
            </MDBCol>
          </MDBModalFooter>
          {errorMsg && <div className='error-message text-right'>{translate('pages.admin.configSchemaError')}:</div>}
          {errorMsg && <div className='error-message text-right'>{errorMsg}</div>}
        </form>
      </MDBCardBody>
    </MDBCard>
  );
};

export default MachineDetail;
