import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBModalFooter, MDBRow } from 'mdbreact';

import './machineGroupDetail.scss';
import { translate } from '../../../../services/translation.service';
import { UserGroupsProps } from '../../../../models/props/profile/userGroups.props';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import {
  clearSelectedGroup,
  selectDisplayInfoMessage,
  setDisplayInfoMessage,
  setSelectedGroupName,
} from '../../../../store/slices/userGroupsSlice';

const MachineGroupDetail: React.FC<UserGroupsProps> = ({ group, disabled, onSubmitGroup }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const displayInfoMessage = useAppSelector(selectDisplayInfoMessage);
  const isEditMode = group?.id ? true : false;

  const submitForm = (event) => {
    event.preventDefault();
    event.target.className += ' was-validated';

    if (group?.name) {
      onSubmitGroup(group.name, !isEditMode);
    }

    dispatch(setDisplayInfoMessage(false));
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    dispatch(setSelectedGroupName(event.currentTarget.value));
  };

  const onCancel = () => {
    dispatch(clearSelectedGroup({}));
    dispatch(setDisplayInfoMessage(false));
    navigate('/profile');
  };

  return (
    <>
      <MDBCard>
        <MDBCardBody>
          <form className='needs-validation' onSubmit={submitForm} noValidate>
            <MDBRow className='pb-4'>
              <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
                <MDBIcon icon='folder-open' color='dark' className='p-4 shadow-3-strong bg-warning rounded' />
                <h5 className='font-weight-bold ml-4'>
                  {isEditMode ? translate('common.edit') : translate('common.create')}
                  {` ${translate('pages.profile.machineGroup')}`}
                </h5>
              </MDBCol>
              {displayInfoMessage && (
                <MDBCol className='d-flex justify-content-end' md='8' xl='8'>
                  <div className='d-flex align-items-center period-filter-message-panel'>
                    <MDBIcon color='warning' className=' pl-2 pr-2' icon='info-circle' size='2x' />
                    <div className='period-filter-message'>{translate('pages.admin.machineConfigurationMessage')}</div>
                  </div>
                </MDBCol>
              )}
            </MDBRow>

            <MDBRow>
              <MDBCol md='4' className='pb-3'>
                <MDBInput
                  className='form-control'
                  required
                  type='text'
                  label={`${translate('common.name')}*`}
                  value={group?.name}
                  onChange={handleChange}
                  disabled={disabled}
                >
                  <div className='invalid-feedback error-message' data-testid='MachineGroupDetail-name-errorMsg'>
                    {translate('pages.profile.sensorGroupNameIsRequired')}
                  </div>
                </MDBInput>
              </MDBCol>

              <MDBCol className='d-flex justify-content-end m-0 p-0' md='8'>
                <MDBModalFooter>
                  <MDBBtn color='warning' className='text-white' type='submit'>
                    {translate('common.save')}
                  </MDBBtn>
                  <MDBBtn outline color='warning' className='cancel-onHover' onClick={onCancel}>
                    {translate('common.cancel')}
                  </MDBBtn>
                </MDBModalFooter>
              </MDBCol>
            </MDBRow>
          </form>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

export default MachineGroupDetail;
