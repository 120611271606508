import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBCol, MDBRow, MDBCard, MDBCardBody } from 'mdbreact';

import ProtectedRoute from '../../../hocs/ProtectedRoute';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import useInterval from '../../../hooks/useInterval';
import {
  getMachineOwner,
  createMachineOwner,
  updateMachineOwner,
  getMachineOwnerPolling,
} from '../../../store/api/machineOwners.service';
import { translate } from '../../../services/translation.service';
import { selectSelectedMachineOwner, clearSelectedMachineOwner } from './machineOwners.slice';
import { AdminConstants } from '../../../constants/admin.constants';
import { AuthConstants } from '../../../constants/auth.constants';
import BackToPage from '../../../components/backToPage/BackToPage';
import MachineOwnerDetail from '../components/machineOwnerDetail/MachineOwnerDetail';
import GenericTable from '../../../components/genericTable/genericTable';

const MachineOwnerDetails: React.FC = () => {
  const navigate = useNavigate();

  const { machineOwnerId: machineOwnerIdParam } = useParams<{ machineOwnerId: string }>();
  const machineOwnerId = useMemo(() => +machineOwnerIdParam!, [machineOwnerIdParam]);

  const dispatch = useAppDispatch();
  const machineOwner = useAppSelector(selectSelectedMachineOwner);

  useEffect(() => {
    if (machineOwnerId) {
      dispatch(getMachineOwner({ machineOwnerId }));
    }
    return () => {
      dispatch(clearSelectedMachineOwner(undefined));
    };
  }, [machineOwnerId, dispatch]);

  // Polling for processing/synchronizing Machine Owner
  useInterval(
    () => dispatch(getMachineOwnerPolling({ machineOwnerId })),
    machineOwnerId && machineOwner && !machineOwner?.isProcessed ? AdminConstants.pollingInterval : null
  );

  const onSubmitMachineOwner = async (machineOwnerIsNew: boolean) => {
    if (machineOwnerIsNew) {
      const { machineOwner: updatedMachineOwner } = await dispatch(
        createMachineOwner({ machineOwnerData: machineOwner })
      ).unwrap();

      navigate(`/admin/machine-owner/${updatedMachineOwner?.id}`);
    } else {
      dispatch(updateMachineOwner({ machineOwnerData: machineOwner }));
    }
  };

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <>
            <MDBRow className='mb-3'>
              <MDBCol md='12' className='px-4'>
                <BackToPage
                  text={translate('backToLink.machineOwners')}
                  navigateTo='/admin/machine-owners'
                ></BackToPage>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='12' className='px-4'>
                <MachineOwnerDetail machineOwner={machineOwner} onSubmitMachineOwner={onSubmitMachineOwner} />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='12' className='px-4'>
                <MDBCard className='mt-5'>
                  <MDBCardBody>
                    <GenericTable
                      tableId={'machineOwnerMachinesTable'}
                      items={machineOwner?.machines || []}
                      hasSearch={true}
                      hasItemsPerPageControl={true}
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </>
        );
      }}
    />
  );
};

export default MachineOwnerDetails;
