import { translate } from '../../services/translation.service';

export const pageSizeOptions = [
  {
    text: 'Rows per page',
    value: 0,
    disabled: true,
    defaultSelected: false,
  },
  {
    text: '5 rows',
    value: 5,
    disabled: false,
    defaultSelected: false,
  },
  {
    text: '10 rows',
    value: 10,
    disabled: false,
    defaultSelected: true,
  },
  {
    text: '25 rows',
    value: 25,
    disabled: false,
    defaultSelected: false,
  },
  {
    text: '50 rows',
    value: 50,
    disabled: false,
    defaultSelected: false,
  },
  {
    text: '100 rows',
    value: 100,
    disabled: false,
    defaultSelected: false,
  },
];

export const getRowsTranslatedText = (value: string | number): string => {
  switch (+value) {
    case 0:
      return translate('pages.geo.rowsPerPage');
    case 5:
      return translate(`${5} ${translate('pages.geo.rows')}`);
    case 10:
      return translate(`${10} ${translate('pages.geo.rows')}`);
    case 25:
      return translate(`${25} ${translate('pages.geo.rows')}`);
    case 50:
      return translate(`${50} ${translate('pages.geo.rows')}`);
    case 100:
      return translate(`${100} ${translate('pages.geo.rows')}`);
    default:
      return translate('pages.geo.rowsPerPage');
  }
};
