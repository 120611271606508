import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { MDBSideNavLink, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBIcon, MDBSideNavCollapse } from 'mdbreact';

import SideNavigationGroups from '../../components/sideNavigationGroups/SideNavigationGroups';
import { translate } from '../../services/translation.service';
import { getUserGroups } from '../../services/group.service';
import useUserState from '../../hooks/useUserState';
import useFlaggedGroupsState from '../../hooks/useFlaggedGroupsState';
import { Link } from 'react-router-dom';
import useMachinesState from '../../hooks/useMachinesState';

const SideNavigation = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAdmin } = useUserState();
  const { flaggedGroupsState, flaggedDataGroupsState, setFlaggedGroups } = useFlaggedGroupsState();
  const { contractPermissions } = useMachinesState();
  const [collapseOpened, setCollapseOpened] = useState('');

  useEffect(() => {
    async function getFlaggedGroups() {
      const groups = await getUserGroups();
      setFlaggedGroups(groups);
    }

    getFlaggedGroups();
  }, [setFlaggedGroups]);

  const onAppLogoClick = (e) => {
    if (e.target.src && e.target.src.endsWith('logo-rsrg.png') && pathname !== '/user-not-synchronized') {
      props.onLinkClick();
      navigate('/');
    }
  };

  const toggleAccordion = (value: string) => {
    value !== collapseOpened ? setCollapseOpened(value) : setCollapseOpened('');
  };

  const navigateTo = (toLocation: string) => {
    navigate(toLocation);
  };

  return (
    <MDBSideNav
      className='sideNav'
      isOpen={props.triggerOpening}
      getOpenState={(e) => {
        window.matchMedia('screen and (min-width: 1400px)').matches || props.setTriggerOpening(e);
      }}
      onClick={onAppLogoClick}
      constant={window.matchMedia('screen and (min-width: 1400px)').matches}
      backdrop={window.matchMedia('screen and (min-width: 1400px)').matches ? false : true}
    >
      {pathname !== '/user-not-synchronized' && (
        <div className='white-skin'>
          <div className='text-center'>
            <Link to='/'>
              <img className='py-3 rsrg-logo' src='/logo-rsrg.png' alt='RSRG-Logo' />
            </Link>
            <hr className='m-0 height-0' />
          </div>

          <MDBSideNavMenu>
            <MDBSideNavItem>
              <MDBSideNavLink
                id='dashboard-cat'
                icon='angle-down'
                shouldBeExpanded={collapseOpened === 'geoExpanded'}
                onClick={() => toggleAccordion('geoExpanded')}
              >
                <MDBIcon fas icon='map-marker-alt' className='me-1' />
                {translate('common.geo')}
              </MDBSideNavLink>
              <MDBSideNavCollapse
                show={collapseOpened === 'geoExpanded'}
                style={{ overflow: 'auto', height: 'fit-content' }}
              >
                <MDBSideNavLink
                  onClick={() => {
                    props.onLinkClick();
                    navigateTo('/geo/machines');
                  }}
                >
                  {translate('common.machines')}
                </MDBSideNavLink>

                <SideNavigationGroups
                  userGroups={flaggedGroupsState}
                  onLinkClick={props.onLinkClick}
                  linkUrl='/geo/machine'
                />
              </MDBSideNavCollapse>
            </MDBSideNavItem>

            <MDBSideNavItem>
              <MDBSideNavLink
                id='data-cat'
                icon='angle-down'
                className={!isAdmin && contractPermissions().length === 0 ? 'd-none' : ''}
                shouldBeExpanded={collapseOpened === 'dataExpanded'}
                onClick={() => toggleAccordion('dataExpanded')}
              >
                <MDBIcon fas icon='chart-line' className='me-1' />
                {translate('common.data')}
              </MDBSideNavLink>
              <MDBSideNavCollapse
                style={{ overflow: 'auto', height: 'fit-content' }}
                show={collapseOpened === 'dataExpanded'}
              >
                <MDBSideNavLink
                  onClick={() => {
                    props.onLinkClick();
                    navigateTo('/data/machines');
                  }}
                >
                  {translate('common.machines')}
                </MDBSideNavLink>
                <SideNavigationGroups
                  userGroups={isAdmin ? flaggedGroupsState : flaggedDataGroupsState}
                  onLinkClick={props.onLinkClick}
                  linkUrl='/data/analytics'
                />
              </MDBSideNavCollapse>
            </MDBSideNavItem>

            <MDBSideNavItem>
              <MDBSideNavLink
                id='admin-cat'
                icon='angle-down'
                className={!isAdmin ? 'd-none' : ''}
                data-testid='SideNav-Admin-Link'
                shouldBeExpanded={collapseOpened === 'adminExpanded'}
                onClick={() => toggleAccordion('adminExpanded')}
              >
                <MDBIcon fas icon='user-gear' className='me-1' />
                {translate('common.admin')}
              </MDBSideNavLink>
              <MDBSideNavCollapse show={collapseOpened === 'adminExpanded'}>
                <MDBSideNavLink
                  className={pathname.startsWith('/admin/admin-group') ? 'side-navigation-active-color' : ''}
                  onClick={() => {
                    props.onLinkClick();
                    navigateTo('/admin/admin-groups');
                  }}
                  data-testid='SideNav-Groups-Link'
                >
                  {translate('common.adminGroups')}
                </MDBSideNavLink>
                <MDBSideNavLink
                  className={pathname.startsWith('/admin/attribute') ? 'side-navigation-active-color' : ''}
                  onClick={() => {
                    props.onLinkClick();
                    navigateTo('/admin/attributes');
                  }}
                  data-testid='SideNav-Attributes-Link'
                >
                  {translate('common.attributes')}
                </MDBSideNavLink>
                <MDBSideNavLink
                  className={pathname.startsWith('/admin/machine-owner') ? 'side-navigation-active-color' : ''}
                  onClick={() => {
                    props.onLinkClick();
                    navigateTo('/admin/machine-owners');
                  }}
                  data-testid='SideNav-MachineOwners-Link'
                >
                  {translate('common.machineOwners')}
                </MDBSideNavLink>
                <MDBSideNavLink
                  className={pathname.startsWith('/admin/sensor-groups') ? 'side-navigation-active-color' : ''}
                  onClick={() => {
                    props.onLinkClick();
                    navigateTo('/admin/sensor-groups');
                  }}
                  data-testid='SideNav-SensorGroups-Link'
                >
                  {translate('pages.profile.sensorGroups')}
                </MDBSideNavLink>
                <MDBSideNavLink
                  className={
                    pathname.startsWith('/admin/machine') && !pathname.startsWith('/admin/machine-owner')
                      ? 'side-navigation-active-color'
                      : ''
                  }
                  onClick={() => {
                    props.onLinkClick();
                    navigateTo('/admin/machines');
                  }}
                  data-testid='SideNav-Machines-Link'
                >
                  {translate('common.machines')}
                </MDBSideNavLink>
                <MDBSideNavLink
                  className={pathname.startsWith('/admin/user') ? 'side-navigation-active-color' : ''}
                  onClick={() => {
                    props.onLinkClick();
                    navigateTo('/admin/users');
                  }}
                  data-testid='SideNav-Users-Link'
                >
                  {translate('common.users')}
                </MDBSideNavLink>
                <MDBSideNavLink
                  className={pathname.startsWith('/admin/contract') ? 'side-navigation-active-color' : ''}
                  onClick={() => {
                    props.onLinkClick();
                    navigateTo('/admin/contracts');
                  }}
                  data-testid='SideNav-Contracts-Link'
                >
                  {translate('common.contracts')}
                </MDBSideNavLink>
              </MDBSideNavCollapse>
            </MDBSideNavItem>
          </MDBSideNavMenu>
        </div>
      )}
    </MDBSideNav>
  );
};

export default SideNavigation;
