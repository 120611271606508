import React from 'react';

import './headerSection.scss';

const HeaderSection: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className='d-flex justify-content-center align-items-center w-100 header-section'>
      <span>{text}</span>
    </div>
  );
};

export default HeaderSection;
