import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from 'mdbreact';

import './geoMachines.scss';
import TabsHeader from '../../components/tabsHeader/TabsHeader';
import MapGoogle from './components/mapGoogle/MapGoogle';
import MapOpenRailway from './components/openRailwayMap/MapOpenRailway';
import MachineGroups from './components/machineGroups/MachineGroups';
import AddMachineToGroupModal from './components/addMachineToGroupModal/AddMachineToGroupModal';
import { GoogleMapConstants } from '../../constants/googleMap.constants';
import { OpenRailwayMapConstants } from '../../constants/openRailwayMap.constants';
import { translate } from '../../services/translation.service';
import { CommonConstants } from '../../constants/common.constants';
import useActiveTabState from '../../hooks/useActiveTabState';
import useMachinesState from '../../hooks/useMachinesState';
import useInterval from '../../hooks/useInterval';
import useModalState from '../../hooks/useModalState';
import GenericTable from '../../components/genericTable/genericTable';
import { UserGroupTableModel } from '../../models/data/group/userGroupTable.model';
import useUserGroupState from '../../hooks/useUserMachineGroups';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import {
  setActivePositionByMapId,
  setPositionsByMapId,
  setUserLocationByMapId,
  updateFitBounds,
  updateMapAndActivePositionByMapId,
  updateTooltipByMapId,
} from '../../store/slices/mapsSlice';
import { selectVisibleMachines, setSelectedMachineId } from '../../store/slices/machinesSlice';
import { uncheckTableGlobalCheckedById } from '../../store/slices/tableSlice';

const pageMapIds = [GoogleMapConstants.geoMachinesGoogleMapId, OpenRailwayMapConstants.geoMachinesOpenRailwayMapId];
const zoomSettings = {
  geoMachinesGoogleMap: GoogleMapConstants.zoomedMap,
  geoMachinesOpenRailwayMap: OpenRailwayMapConstants.zoomedMap,
};

const GeoMachines: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setModal } = useModalState();
  const { mapTab, groupTab } = useActiveTabState();
  const { getUserMachineGroupsData } = useUserGroupState();
  const {
    userGroups,
    adminGroups,
    allMachines,
    selectedMachine,
    selectedMachineId,
    getGeoData,
    handleMachineSelect,
    handleMachineOnMapToggle,
    handleBulkMachinesAdd,
    handleBulkMachinesRemove,
    handleMachineCheckedToggle,
    handleGlobalItemsChecked,
    handleGroupToggle,
    removeUserGroupMachineClick,
    addUserGroupMachineClick,
  } = useMachinesState();

  const { activePosition } = useAppSelector((state) => state.maps[GoogleMapConstants.geoMachinesGoogleMapId]);
  const visibleMachines = useAppSelector(selectVisibleMachines);
  const [userGroupMachines, setUserGroupMachines] = useState<UserGroupTableModel[]>([]);
  const [machineGroupModalState, setMachineGroupModalState] = useState<{ isOpen: boolean; machineId: number }>({
    isOpen: false,
    machineId: -1,
  });

  useEffect(() => {
    pageMapIds.forEach((id) => {
      dispatch(updateFitBounds({ id, preventFitBounds: false }));
      dispatch(setPositionsByMapId({ id, positions: visibleMachines }));
    });
  }, [visibleMachines, dispatch]);

  useEffect(() => {
    getGeoData();
  }, [getGeoData]);

  useInterval(() => {
    getGeoData();
  }, CommonConstants.refreshMapTime);

  useEffect(() => {
    return () => {
      dispatch(uncheckTableGlobalCheckedById('allGeoMachines'));
    };
  }, [dispatch, selectedMachineId, allMachines]);

  const onItemGeoLocationClick = useCallback(
    (machineId: string | number) => navigate(`/geo/machine/${machineId}`),
    [navigate]
  );

  const onDeleteClick = (callback: () => void) => {
    setModal(true, callback);
  };

  const onUserGroupsClick = useCallback(
    (machineId: number) => {
      const userGroupTableModel: UserGroupTableModel[] = userGroups.map((userGroup) => ({
        id: userGroup.id,
        name: userGroup.name,
        isChecked: userGroup.machines.some((m) => m.id === machineId),
      }));

      setMachineGroupModalState({ isOpen: true, machineId });
      setUserGroupMachines(userGroupTableModel);
    },
    [userGroups]
  );

  const modalSetState = (value: boolean) => {
    if (!value) {
      setMachineGroupModalState({ isOpen: false, machineId: -1 });
    }
  };

  const onCloseModal = useCallback(() => {
    setMachineGroupModalState({ isOpen: false, machineId: -1 });
  }, []);

  const onToggleGroupModalClick = (value: string | number) => {
    setUserGroupMachines((prevState) =>
      prevState.map((groupMachine) =>
        value === groupMachine.id.toString()
          ? {
              ...groupMachine,
              isChecked: !groupMachine.isChecked,
            }
          : groupMachine
      )
    );
  };

  const onSaveUserGroupMachines = async () => {
    await addUserGroupMachineClick(machineGroupModalState.machineId, userGroupMachines);
    setMachineGroupModalState({ isOpen: false, machineId: -1 });
  };

  const onMachineDataDetailsClick = useCallback(
    (machineId: string | number) => navigate(`/data/analytics/${machineId}`),
    [navigate]
  );

  const onMachineGeoDetailsClick = useCallback(
    (machineId: string | number) => navigate(`/geo/machine/${machineId}`),
    [navigate]
  );

  const syncTooltips = useCallback(
    (isTooltipOpen, machine?) => {
      pageMapIds.forEach((id) => {
        dispatch(
          updateTooltipByMapId({
            id,
            isTooltipOpen,
          })
        );
      });

      if (machine === -1) {
        dispatch!(setSelectedMachineId(-1));
      }
    },
    [dispatch]
  );

  const syncMaps = useCallback(
    (activePosition) => {
      pageMapIds.forEach((id) => {
        dispatch(
          setActivePositionByMapId({
            id,
            activePosition,
          })
        );
      });
    },
    [dispatch]
  );

  const onSelectUserLocation = useCallback(() => {
    dispatch(setSelectedMachineId(-1));
    for (const mapId of pageMapIds) {
      dispatch(updateMapAndActivePositionByMapId({ id: mapId }));
    }

    setTimeout(() => {
      for (const mapId of pageMapIds) {
        dispatch(setUserLocationByMapId({ id: mapId, zoom: zoomSettings[mapId] }));
      }
    }, 10);
  }, [dispatch]);

  const onZoomToPosition = useCallback(() => {
    if (selectedMachineId !== activePosition.id) {
      syncTooltips(false);
    }

    for (const mapId of pageMapIds) {
      dispatch(
        updateMapAndActivePositionByMapId({
          id: mapId,
          center: { lat: selectedMachine.latitude, lng: selectedMachine.longitude },
          zoom: zoomSettings[mapId],
          preventFitBounds: true,
          activePosition: activePosition,
        })
      );
    }
  }, [dispatch, selectedMachine, selectedMachineId, activePosition, syncTooltips]);

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol xl='4'>
          <MDBCard className='mb-4'>
            <TabsHeader tabId='groupTab' tabNames={[translate('common.myGroups'), translate('common.adminGroups')]} />
            <MDBCardBody className='card-body-groups text-center w-100 section-body p-0'>
              <MachineGroups
                displayed={groupTab === 1}
                groups={userGroups}
                selectedMachineId={selectedMachineId}
                onToggleGroupClick={handleGroupToggle}
                onSelectedMachineClick={handleMachineSelect}
                onMachineRemoveClick={(machineId: number, groupId: string) =>
                  onDeleteClick(async () => {
                    await removeUserGroupMachineClick(machineId, groupId);
                    getUserMachineGroupsData();
                  })
                }
                onMachineDataDetailsClick={onMachineDataDetailsClick}
                onMachineGeoDetailsClick={onMachineGeoDetailsClick}
              />
              <MachineGroups
                displayed={groupTab === 2}
                groups={adminGroups.filter((ag) => ag.machines.length > 0)}
                selectedMachineId={selectedMachineId}
                onToggleGroupClick={handleGroupToggle}
                onSelectedMachineClick={handleMachineSelect}
                onMachineDataDetailsClick={onMachineDataDetailsClick}
                onMachineGeoDetailsClick={onMachineGeoDetailsClick}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol xl='8' className='mb-4'>
          <MDBCard>
            <TabsHeader tabId='mapTab' tabNames={['Google Maps', 'Open Railway']}></TabsHeader>
            <MDBCardBody className='text-center w-100 section-body'>
              {mapTab === 1 && (
                <MapGoogle
                  mapId={GoogleMapConstants.geoMachinesGoogleMapId}
                  selectedMachineId={selectedMachineId}
                  hasUserLocationButton={true}
                  zoomToPositionLabel={translate('common.zoomToMachine')}
                  syncMaps={syncMaps}
                  syncTooltips={syncTooltips}
                  triggerZoomToPosition={onZoomToPosition}
                  triggerSelectUserLocation={onSelectUserLocation}
                />
              )}
              {mapTab === 2 && (
                <MapOpenRailway
                  mapId={OpenRailwayMapConstants.geoMachinesOpenRailwayMapId}
                  selectedMachineId={selectedMachineId}
                  hasUserLocationButton={true}
                  zoomToPositionLabel={translate('common.zoomToMachine')}
                  syncMaps={syncMaps}
                  syncTooltips={syncTooltips}
                  triggerZoomToPosition={onZoomToPosition}
                  triggerSelectUserLocation={onSelectUserLocation}
                />
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md='12'>
          <MDBCard>
            <MDBCardBody>
              <GenericTable
                tableId={'allGeoMachines'}
                items={allMachines}
                selectedItemId={selectedMachineId}
                hasSearch={true}
                hasItemsPerPageControl={true}
                hasCheckboxInput={true}
                onGlobalItemsChecked={handleGlobalItemsChecked}
                handleItemToggle={handleMachineCheckedToggle}
                onBulkDeleteClick={handleBulkMachinesRemove}
                onBulkAddClick={handleBulkMachinesAdd}
                onItemDetailsClick={onMachineDataDetailsClick}
                onItemAddToGroupsClick={onUserGroupsClick}
                onItemGeoLocationClick={onItemGeoLocationClick}
                onItemVisibilityClick={handleMachineOnMapToggle}
                onItemSelectClick={handleMachineSelect}
                hasExport={true}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      {machineGroupModalState.isOpen && (
        <AddMachineToGroupModal
          isOpen={machineGroupModalState.isOpen}
          onShowModal={modalSetState}
          groups={userGroupMachines}
          onCloseModal={onCloseModal}
          onToggleGroupModalClick={onToggleGroupModalClick}
          onSaveUserGroupMachines={onSaveUserGroupMachines}
        />
      )}
    </MDBContainer>
  );
};

export default GeoMachines;
