import React, { useState, useEffect } from 'react';

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBTable,
  MDBTableBody,
  MDBModalDialog,
  MDBModalContent,
} from 'mdbreact';

import { NotificationPopupProps, NotificationProps } from '../../../../../models/props/admin/notificationsPopup.props';
import { translate } from '../../../../../services/translation.service';
import { toast } from 'react-toastify';
import { isValidEmail } from '../../../../../utils/validation.utility';

const defaultRuleConfiguration = { phone: '' };

// const NOTIFICATION_OPTIONS = [
//   {
//     text: 'Email',
//     value: 'Email',
//     checked: true,
//   },
// ];

const NotificationPopup: React.FC<NotificationPopupProps> = ({
  isOpen,
  defaultNotification,
  onShowModal,
  onCloseModal,
  onEditNotification,
  onSaveNotification,
}) => {
  const [selectedRule, setSelectedRule] = useState<NotificationProps>(defaultNotification || defaultRuleConfiguration);
  const [selectedNotificationType] = useState<string>('Email');

  // const memoizedOptions = useMemo(() => {
  //   if (defaultNotification) {
  //     return NOTIFICATION_OPTIONS.map((m) => (m.value === defaultNotification.type ? { ...m, checked: true } : m));
  //   }

  //   return NOTIFICATION_OPTIONS;
  // }, [defaultNotification]);

  useEffect(() => {
    setSelectedRule(defaultNotification || defaultRuleConfiguration);
  }, [defaultNotification]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;

    setSelectedRule({ [id]: value, type: selectedNotificationType });
  };

  // const handleTypeChange = (value: string[]) => {
  //   if (selectedNotificationType) {
  //     setSelectedRule((prevState) => ({ ...prevState, [selectedNotificationType.toLowerCase()]: '' }));
  //   }

  //   setSelectedNotificationType(value[0]);
  // };

  const handleSubmit = () => {
    const isValid = !!selectedRule?.type;

    if (!isValid) {
      toast.warn(translate('pages.admin.emailIsRequired'));
      return;
    }

    const transformSelected = {
      [selectedRule?.type?.toLowerCase()!]: selectedRule[selectedNotificationType?.toLowerCase()!],
      type: `${selectedRule?.type?.toLowerCase()}s`,
    };

    if (!isValidEmail(transformSelected.email)) {
      toast.warn(translate('pages.admin.emailNotInTheCorrectFormat'));
      return;
    }

    if (defaultNotification) {
      onEditNotification(transformSelected);
      onCloseModal();
      return;
    }

    onSaveNotification(transformSelected);
    onCloseModal();
  };

  return (
    <MDBContainer className='center-modal-page'>
      <MDBModal animationDirection='top' show={isOpen} setShow={onShowModal} className='modal-notify modal-warning'>
        <MDBModalDialog centered={true}>
          <MDBModalContent>
            <MDBModalHeader>
              {defaultNotification
                ? translate('pages.admin.editNotification')
                : translate('pages.admin.createNotification')}
            </MDBModalHeader>

            <MDBModalBody>
              <MDBTable className='rsrg-table' hover responsive>
                <MDBTableBody>
                  <tr>
                    <td className='align-middle text-left no-border'>
                      <MDBInput
                        id={selectedNotificationType === 'Phone' ? 'phone' : 'email'}
                        type={selectedNotificationType === 'Phone' ? 'tel' : 'text'}
                        value={selectedNotificationType === 'Phone' ? selectedRule?.phone : selectedRule?.email}
                        disabled={!selectedNotificationType}
                        label={`${translate('common.email')}*`}
                        onChange={handleChange}
                        required
                      />
                    </td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='warning' className='text-white' onClick={handleSubmit}>
                {translate('common.ok')}
              </MDBBtn>
              <MDBBtn outline color='warning' className='cancel-onHover' onClick={onCloseModal}>
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
  );
};

export default NotificationPopup;
