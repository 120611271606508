import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBRow } from 'mdbreact';

import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { translate } from '../../../../services/translation.service';
import { SensorGroupDetails } from '../../../../models/props/profile/profileSensorGroups.props';
import {
  addSensorGroupInformation,
  clearSelectedSensorGroup,
  selectDisplayInfoMessage,
  setDisplayInfoMessage,
} from '../../SensorGroups/sensorGroups.slice';

const SensorGroupDetail: React.FC<SensorGroupDetails> = ({ group, onSubmitSensorGroup }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const displayInfoMessage = useAppSelector(selectDisplayInfoMessage);
  const [isEditMode, setIsEditMode] = useState<boolean>(!!group?.id);

  useEffect(() => {
    setIsEditMode(!!group?.id);
  }, [group?.id]);

  const submitForm = (event) => {
    event.preventDefault();
    event.target.className += ' was-validated';

    if (!group?.name || !group?.description) {
      return;
    }

    dispatch(setDisplayInfoMessage(false));
    onSubmitSensorGroup(!isEditMode);
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;

    dispatch(addSensorGroupInformation({ fieldId: id, value }));
  };

  const handleCancelClick = () => {
    dispatch(clearSelectedSensorGroup({}));
    dispatch(setDisplayInfoMessage(false));
    navigate('/profile');
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <form className='needs-validation' onSubmit={submitForm} noValidate data-testid='SensorGroupDetail-form-wrapper'>
          <MDBRow className='pb-3'>
            <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
              <MDBIcon icon='file-signature' color='dark' className='p-4 shadow-3-strong bg-warning rounded' />
              <h5 className='font-weight-bold ml-4'>
                {isEditMode ? translate('common.edit') : translate('common.create')}{' '}
                {translate('pages.profile.sensorGroup')}
              </h5>
            </MDBCol>
            {displayInfoMessage && (
              <MDBCol className='d-flex justify-content-end' md='8' xl='8'>
                <div className='d-flex align-items-center period-filter-message-panel'>
                  <MDBIcon icon='info-circle' color='warning' className='pl-2 pr-2' size='2x' />
                  <div className='period-filter-message'>{translate('pages.admin.machineConfigurationMessage')}</div>
                </div>
              </MDBCol>
            )}
          </MDBRow>

          <MDBRow>
            <MDBCol md='4' className='pb-3'>
              <MDBInput
                id='name'
                className='form-control'
                required
                type='text'
                label={`${translate('common.name')}*`}
                value={group?.name}
                onChange={handleChange}
                data-testid='name'
              >
                <div className='invalid-feedback error-message' data-testid='SensorGroypDetail-name-errorMsg'>
                  {translate('pages.profile.sensorGroupNameIsRequired')}
                </div>
              </MDBInput>
            </MDBCol>

            <MDBCol md='4'>
              <MDBInput
                id='description'
                className='form-control'
                type='text'
                required
                label={`${translate('common.description')}*`}
                value={group?.description}
                onChange={handleChange}
                data-testid='description'
              >
                <div className='invalid-feedback error-message' data-testid='SensorGroypDetail-description-errorMsg'>
                  {translate('pages.profile.sensorGroupDescriptionIsRequired')}
                </div>
              </MDBInput>
            </MDBCol>

            <MDBCol sm='12' md='4' className='mt-3 mt-md-0 p-0'>
              <MDBCol className='d-flex justify-content-end'>
                <MDBBtn color='warning' className='text-white mr-3' type='submit' data-testid='SensorGroypDetail-save-btn'>
                  {translate('common.save')}
                </MDBBtn>
                <MDBBtn
                  outline
                  color='warning'
                  className='cancel-onHover'
                  onClick={handleCancelClick}
                  data-testid='SensorGroypDetail-cancel-btn'
                >
                  {translate('common.cancel')}
                </MDBBtn>
              </MDBCol>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBCardBody>
    </MDBCard>
  );
};

export default SensorGroupDetail;
