import React from 'react';

import { MDBBtn } from 'mdbreact';

import './popupWindowDetail.scss';
import DateFormatter from '../dateFormatter/DataFormatter';
import { PopupWindowDetailProps } from '../../models/props/components/popupWindowDetail.props';
import { translate } from '../../services/translation.service';
import { formatMachineStateAttributes } from '../../utils/formatting.utility';
import { timeZoneTransform } from '../../transformers/timeZone.transform';
import { CommonConstants } from '../../constants/common.constants';
import useUserState from '../../hooks/useUserState';

const PopupWindowDetail: React.FC<PopupWindowDetailProps> = ({ selectedMachine, isEventTrackHistoryMap }) => {
  const { userState } = useUserState();

  return (
    <div className='text-left'>
      {!isEventTrackHistoryMap && (
        <>
          <div className='d-flex'>
            <span className='mr-2'>{translate('common.lastGpsPosition')}: </span>
            {selectedMachine.messageTime && <DateFormatter date={selectedMachine?.gpsTime} />}
          </div>
          <div className='d-flex float-right'>
            {selectedMachine.fromRangeTime && (
              <span className='second-timestamp'>
                {translate('common.since')}{' '}
                {timeZoneTransform(selectedMachine.fromRangeTime, userState.timeZone, userState.timeZoneName)}
              </span>
            )}
          </div>
        </>
      )}
      {isEventTrackHistoryMap && (
        <div className='d-flex'>
          <span className='mr-2'>{translate('common.time')}: </span>
          {selectedMachine.messageTime && <DateFormatter date={selectedMachine?.messageTime} />}
        </div>
      )}
      <div className='clearfix'>
        {translate('pages.geo.longitude')}:{' '}
        {selectedMachine.longitude ? selectedMachine.longitude?.toFixed(6) + '°' : translate('common.none')}
      </div>
      <div>
        {translate('pages.geo.latitude')}:{' '}
        {selectedMachine.latitude ? selectedMachine.latitude?.toFixed(6) + '°' : translate('common.none')}
      </div>
      <div>
        {translate('pages.geo.altitude')}:{' '}
        {selectedMachine.altitude ? selectedMachine.altitude?.toFixed(2) + 'm' : translate('common.none')}
      </div>
      {!isEventTrackHistoryMap && (
        <>
          <div className='d-flex'>
            {translate('common.machinePower')}: {formatMachineStateAttributes(selectedMachine?.power)}
            {selectedMachine?.powerTime && (
              <span className='ml-2'>
                <DateFormatter date={selectedMachine?.powerTime} />
              </span>
            )}
          </div>
          <div className='d-flex'>
            {translate('common.machineEngine')}: {formatMachineStateAttributes(selectedMachine?.engine)}{' '}
            {selectedMachine?.engineTime && (
              <span className='ml-2'>
                <DateFormatter date={selectedMachine?.engineTime} />
              </span>
            )}
          </div>{' '}
          <div className='d-flex'>
            {translate('common.machineBrake')}: {formatMachineStateAttributes(selectedMachine?.brake)}{' '}
            {selectedMachine?.brakeTime && (
              <span className='ml-2'>
                <DateFormatter date={selectedMachine?.brakeTime} />
              </span>
            )}
          </div>{' '}
          <div className='d-flex'>
            {translate('common.machineGear')}: {formatMachineStateAttributes(selectedMachine?.gear)}{' '}
            {selectedMachine?.gearTime && (
              <span className='ml-2'>
                <DateFormatter date={selectedMachine?.gearTime} />
              </span>
            )}
          </div>
          <div className='d-flex'>
            {translate('common.speed')}:{' '}
            {selectedMachine?.speed || selectedMachine?.speed === 0
              ? selectedMachine?.speed?.toFixed(2) + ' km/h'
              : translate('common.none')}{' '}
            {selectedMachine?.speedTime && (
              <span className='ml-2'>
                <DateFormatter date={selectedMachine?.speedTime} />
              </span>
            )}
          </div>
          <div>
            {translate('common.rotateAngle')}:{' '}
            {selectedMachine?.rotateAngle || selectedMachine?.rotateAngle === 0
              ? selectedMachine.rotateAngle?.toFixed(2) + '°'
              : translate('common.none')}
          </div>
          <div>
            {translate('pages.geo.compassDirection')}:{' '}
            {selectedMachine?.compassDirection || selectedMachine?.compassDirection === 0
              ? selectedMachine.compassDirection?.toFixed(2) + '°'
              : translate('common.none')}
          </div>
        </>
      )}
      <hr className='mt-2 mb-2' />
      <div className='w-100 p-1'>
        <MDBBtn
          className='btn-go-to-details w-100'
          color='warning'
          href={`${CommonConstants.GoogleMapsUrl}${selectedMachine?.latitude}, ${selectedMachine?.longitude}`}
          target='_blank'
        >
          {translate('common.viewInApp')}
        </MDBBtn>
      </div>
    </div>
  );
};

export default PopupWindowDetail;
