import React, { useEffect, useState, startTransition } from 'react';

import { MDBRow, MDBCol, MDBBtn, MDBSelect, MDBIcon, MDBRadio, MDBTypography, MDBSwitch } from 'mdbreact';

import './sensorFilter.scss';
import useUserState from '../../../../hooks/useUserState';
import { translate } from '../../../../services/translation.service';
import { useQueryValue } from '../../../../utils/route.utility';
import { SensorFilterProps } from '../../../../models/props/data/sensorFilter.props';
import {
  getCustomTimeZoneUtcOffset,
  getMomentTimeFromUtc,
  getUTC,
  getUtcNow,
} from '../../../../transformers/timeZone.transform';
import { DURATION_FILTER_OPTIONS } from '../../../../utils/filter.utility';
import useSpinnerState from '../../../../hooks/useSpinnerState';
import moment from 'moment';
import DateTimePicker from '../../../../components/dateTimePicker/DateTimePicker';

const SensorFilter: React.FC<SensorFilterProps> = ({
  machineId,
  timeRange,
  startDateUtcString,
  endDateUtcString,
  duration,
  isFastSlicesOptions,
  isAverageDataOptions,
  onViewSensorData,
}) => {
  const { setSpinnerState } = useSpinnerState();
  const { userState } = useUserState();
  const { timeZone, timeZoneName } = userState;
  const preserveFilterState = useQueryValue('preserveState');

  const [isFast, setIsFast] = useState(isFastSlicesOptions);
  const [isAverage, setIsAverage] = useState(isAverageDataOptions);
  const [durationState, setDurationState] = useState((preserveFilterState && duration) || 'duration');
  const [filterRange, setFilterRange] = useState((preserveFilterState && timeRange) || 2);

  const getDateTimePickerValue = (preserveFilterState: any, dateUtcString?: string): moment.Moment => {
    return (
      (preserveFilterState &&
        dateUtcString &&
        getMomentTimeFromUtc(dateUtcString!, getCustomTimeZoneUtcOffset(timeZone, timeZoneName))) ||
      moment(new Date())
    );
  };
  const [dateFromMoment, setDateFromMoment] = useState(getDateTimePickerValue(preserveFilterState, startDateUtcString));
  const [dateToMoment, setDateToMoment] = useState(getDateTimePickerValue(preserveFilterState, endDateUtcString));
  const [errorText, setErrorText] = useState<string>('');
  const [durationFilterOptions, setDurationFilterOptions] = useState(() => [
    ...DURATION_FILTER_OPTIONS.map((d) => ({ ...d, text: translate(d.text) })),
  ]);

  useEffect(() => {
    if (machineId) {
      setDurationFilterOptions((prevState) =>
        prevState.map((option) => ({
          ...option,
          checked: option.value === filterRange?.toString(),
          defaultSelected: option.value === filterRange?.toString(),
        }))
      );
    }
  }, [machineId, filterRange]);

  useEffect(() => {
    setFilterRange((preserveFilterState && timeRange) || 2);
  }, [preserveFilterState, machineId]); // eslint-disable-line

  useEffect(() => {
    setDurationState((preserveFilterState && duration) || 'duration');
  }, [preserveFilterState, machineId]); // eslint-disable-line

  useEffect(() => {
    setIsFast(isFastSlicesOptions);
  }, [machineId, isFastSlicesOptions]);

  useEffect(() => {
    setDateFromMoment(getDateTimePickerValue(preserveFilterState, startDateUtcString));
    setDateToMoment(getDateTimePickerValue(preserveFilterState, endDateUtcString));
  }, [machineId]); //eslint-disable-line

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setDurationState(e.currentTarget.id);
  };

  const getDateTimeFrom = (value) => {
    setErrorText('');
    setDateFromMoment(value);
  };

  const getDateTimeTo = (value) => {
    setErrorText('');
    setDateToMoment(value);
  };

  const handleError = (errorMessage: string) => {
    setErrorText(errorMessage);
    setSpinnerState(false);
  };

  const handleIsFastChange = () => {
    setIsFast((prevState) => !prevState);
  };

  const handleIsAverageChange = () => {
    setIsAverage((prevState) => !prevState);
  };

  const handleSubmit = () => {
    if (durationState === 'duration') {
      onViewSensorData({
        timeRange: filterRange,
        duration: durationState,
        isFastSlicesOptions: isFast,
        isAverageDataOptions: isAverage,
      });
    } else {
      const dateFromUtc = getUTC(dateFromMoment.toDate(), timeZone, timeZoneName);
      const dateFromString = new Date(dateFromUtc.getTime() - dateFromUtc.getTimezoneOffset() * 60000).toISOString();
      const dateToUtc = getUTC(dateToMoment.toDate(), timeZone, timeZoneName);
      const dateToString =
        new Date(dateToUtc.getTime() - dateToUtc.getTimezoneOffset() * 60000).toISOString().split('.')[0] + 'Z';

      if (dateFromMoment.toDate() >= dateToMoment.toDate()) {
        handleError(translate('common.toDateAfterFromDate'));
        return;
      }
      if (dateFromUtc > getUtcNow()) {
        handleError(translate('common.fromDateInTheFuture'));
        return;
      }
      if (dateToUtc > getUtcNow()) {
        handleError(translate('common.toDateInTheFuture'));
        return;
      }

      onViewSensorData({
        duration: durationState,
        dateFrom: dateFromUtc,
        dateFromString: dateFromString,
        dateTo: dateToUtc,
        dateToString: dateToString,
        isFastSlicesOptions: isFast,
        isAverageDataOptions: isAverage,
      });
    }
  };

  const handleDurationChange = (values: any) => {
    const value = values.value;

    if (durationState === 'duration') {
      if (isNaN(+value)) {
        setFilterRange(filterRange);
      } else if (+value !== filterRange) {
        setFilterRange(+value);
      }
    }
  };

  return (
    <MDBRow className='period-filter py-2 pl-4'>
      <MDBCol xs='12' sm='12' md='12' lg='12' xl='5'>
        <MDBRow className='filter-label sensor-duration-label text-left font-weight-bold py-1 pl-2'>
          {translate('pages.geo.timePeriodFilter')}
        </MDBRow>

        <MDBCol className='my-4' md='12' lg='12' xl='11'>
          <MDBRow>
            <MDBRadio
              id='duration'
              name='chosenTime'
              onChange={handleChange}
              checked={durationState === 'duration'}
              className='d-block mr-2, mt-2'
              type='radio'
              wrapperStyle={{ width: '1.25rem', display: 'inline' }}
            />

            <MDBCol className='d-xl-flex flex-xl-row d-md-flex d-sm-flex ml-2' xs='12' sm='12' md='12' lg='12' xl='12'>
              <MDBCol xs='10' md='5' lg='5' xl='6' className='p-0'>
                <MDBSelect
                  className='dropdown-warning filter-select-wrapper-xs mt-0'
                  onValueChange={(e: any) => handleDurationChange(e)}
                  data={durationFilterOptions}
                  value={filterRange?.toString()}
                  label={translate('pages.geo.selectFilter')}
                  disabled={durationState === 'custom-period'}
                  size='lg'
                />
              </MDBCol>
            </MDBCol>
          </MDBRow>
        </MDBCol>

        <MDBCol className='my-4' md='12' lg='12' xl='11'>
          <MDBRow>
            <MDBRadio
              id='custom-period'
              name='chosenTime'
              onChange={handleChange}
              checked={durationState === 'custom-period'}
              className='d-block mr-2 mt-2'
              type='radio'
              wrapperStyle={{ width: '1.25rem', display: 'inline' }}
            />
            <MDBCol className='d-xl-flex flex-xl-row d-md-flex d-sm-flex ml-2' xs='12' sm='12' md='12' lg='12' xl='12'>
              <MDBCol xs='11' md='5' lg='5' xl='6' className='p-0 mr-3'>
                <DateTimePicker
                  label={translate('common.fromDate')}
                  disabled={durationState === 'duration'}
                  value={dateFromMoment}
                  onChange={getDateTimeFrom}
                ></DateTimePicker>
              </MDBCol>
              <MDBCol xs='11' md='5' lg='5' xl='6' className='p-0 mt-3 mt-sm-0'>
                <DateTimePicker
                  label={translate('common.toDate')}
                  disabled={durationState === 'duration'}
                  value={dateToMoment}
                  onChange={getDateTimeTo}
                ></DateTimePicker>
              </MDBCol>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBCol>

      <MDBCol xs='12' sm='12' md='12' lg='12' xl='3'>
        <MDBRow className='filter-label sensor-duration-label text-left font-weight-bold py-1 pl-2'>
          {translate('pages.data.visualization')}
        </MDBRow>
        <MDBCol xs='12' sm='12' md='12' lg='12' xl='12' className='p-0'>
          <div className='d-flex'>
            <span className='mt-2 text-start toggle-options-width'>{translate('pages.data.accurate')}</span>
            <MDBSwitch inline className='mt-2 switch-no-border' checked={isFast} onChange={handleIsFastChange} />
            <span className='pl-3 mt-2'>{translate('pages.data.fast')}</span>
          </div>

          <div className='d-flex'>
            <span className='mt-2 text-start toggle-options-width'>{translate('pages.data.range')}</span>
            <MDBSwitch inline className='mt-2 switch-no-border' checked={isAverage} onChange={handleIsAverageChange} />
            <span className='pl-3 mt-2'>{translate('pages.data.average')}</span>
          </div>
        </MDBCol>
      </MDBCol>

      <MDBCol xs='12' sm='12' md='12' lg='12' xl='4'>
        <MDBRow end className='mr-0 mb-4'>
          <MDBCol xs='12' sm='12' xl='12'>
            <MDBRow>
              <MDBCol sm='12' xl='12' className='my-2'>
                <MDBBtn
                  color='warning'
                  className='text-white w-100 view-button-my'
                  onClick={() => {
                    setSpinnerState(true);
                    startTransition(() => {
                      handleSubmit();
                    });
                  }}
                >
                  {translate('pages.data.viewData')}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>

        <MDBRow className='period-filter-message-panel period-filter-panel-position'>
          <MDBIcon icon='info-circle' className='amber-text autoWidth' size='2x' color='warning' />
          <MDBTypography tag='span' className='period-filter-message px-2 m-2 width80Percent'>
            {translate('pages.data.mapIntervalMessage')}
          </MDBTypography>
        </MDBRow>
      </MDBCol>

      <MDBCol xs='12' sm='12' md='12' lg='12' xl='4'>
        <MDBRow>
          <MDBCol md='12'>
            <span className='error-message d-flex align-items-start'>{errorText}</span>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default SensorFilter;
