import React, { useMemo } from 'react';

import { MDBBtn, MDBCol, MDBRow, MDBSelect } from 'mdbreact';

import { translate } from '../../../../services/translation.service';

import { ProfilePreferencesProps } from '../../../../models/props/profile/profilePreferences.props';
import { getLanguageTranslatedText, languages } from './Languages';
import { getTimestampType, timeZones } from './TimeZones';
import useUserState from '../../../../hooks/useUserState';

const ProfilePreferences: React.FC<ProfilePreferencesProps> = ({
  zones,
  timeZone,
  setTimeZone,
  setTimeZoneName,
  selectLanguage,
  saveUserPreferences,
}) => {
  const { userState } = useUserState();

  const translatedLanguages = useMemo(() => {
    return languages.map((lang) => {
      return {
        ...lang,
        text: getLanguageTranslatedText(lang.value),
        defaultSelected: userState.language === lang.value,
      };
    });
  }, [userState.language]);

  const timezones = useMemo(() => {
    return timeZones.map((tz) => {
      return {
        ...tz,
        text: getTimestampType(tz.value),
        defaultSelected: userState.timeZone === tz.value,
      };
    });
  }, [userState.language]); // eslint-disable-line

  const timezoneNames = useMemo(() => {
    return zones.map((tzn) => {
      return {
        ...tzn,
        value: tzn.id,
        text: tzn.name,
        disabled: false,
        defaultSelected: userState.timeZoneName === tzn.id,
      };
    });
  }, [zones, userState.timeZoneName]);

  return (
    <>
      <MDBRow>
        <MDBCol lg='4' md='4' className='text-left p-3'>
          <MDBSelect
            data-testid='ProfilePreferences-Language-Select'
            label={translate('pages.profile.selectLanguage')}
            onValueChange={(item: any) => {
              selectLanguage(item.value);
            }}
            data={translatedLanguages}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg='4' md='4' className='text-left p-3' data-testid='ProfilePreferences-TimeZone-Dropdown'>
          <MDBSelect
            data-testid='ProfilePreferences-TimeZone-Select'
            label={translate('pages.profile.timeZone')}
            onValueChange={(item: any) => setTimeZone(item.value)}
            data={timezones}
          />
        </MDBCol>

        <MDBCol xl='5' lg='4' md='4' className='text-left'>
          {timeZone === 3 && (
            <MDBSelect
              className='mt-3'
              data-testid='ProfilePreferences-TimeZoneName-Select'
              label={translate('pages.profile.customTimeZone')}
              onValueChange={(item: any) => {
                setTimeZoneName(item.value);
              }}
              search={true}
              searchLabel={translate('common.search')}
              data={timezoneNames}
            />
          )}
        </MDBCol>
      </MDBRow>
      <MDBRow className='mt-3'>
        <MDBCol lg='4' md='4' className='text-left'>
          <MDBBtn
            data-testid='ProfilePreferences-Save-Btn'
            color='warning'
            className='d-inline px-4 text-white'
            onClick={saveUserPreferences}
          >
            {translate('common.save')}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default ProfilePreferences;
