import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBCol, MDBRow } from 'mdbreact';

import GroupsTable from '../groupsTable/GroupsTable';
import { ProfileGroupsProps } from '../../../../models/props/profile/profileGroups.props';
import useUserGroupState from '../../../../hooks/useUserMachineGroups';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { clearSelectedGroup } from '../../../../store/slices/userGroupsSlice';

const ProfileGroups: React.FC<ProfileGroupsProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    userGroups,
    globalChecked,
    handleGlobalUserGroupsChecked,
    handleBulkDeleteUserMachineGroup,
    handleDeleteUserMachineGroup,
    handleEditUserMachineGroupFlag,
    handleMachineCheckedToggle,
  } = useUserGroupState();

  useEffect(() => {
    dispatch(clearSelectedGroup({}));
  }, [dispatch]);

  const onEditGroupClick = (groupId: string) => {
    navigate(`/profile/machine-group/${groupId}`);
  };

  return (
    <MDBRow>
      <MDBCol xl='12'>
        <GroupsTable
          groups={userGroups}
          globalChecked={globalChecked}
          onGroupEditClick={onEditGroupClick}
          onGlobalCheckClick={handleGlobalUserGroupsChecked}
          onToggleGroupClick={handleMachineCheckedToggle}
          onGroupDeleteClick={handleDeleteUserMachineGroup}
          onBulkDeleteClick={handleBulkDeleteUserMachineGroup}
          onGroupFlagEditClick={handleEditUserMachineGroupFlag}
        />
      </MDBCol>
    </MDBRow>
  );
};

export default ProfileGroups;
