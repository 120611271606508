import React, { useEffect } from 'react';

import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact';

import ProtectedRoute from '../../../hocs/ProtectedRoute';
import { AuthConstants } from '../../../constants/auth.constants';
import GenericTable from '../../../components/genericTable/genericTable';
import { getAllAttributes } from '../../../store/api/attributes.service';
import { selectAttributes } from './attributes.slice';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import useAttributeMappings from '../../../hooks/useAttributeMappings';

const AttributesList: React.FC = () => {
  const dispatch = useAppDispatch();
  const attributes = useAppSelector(selectAttributes);
  const { mapAttributes } = useAttributeMappings();

  useEffect(() => {
    dispatch(getAllAttributes());
  }, [dispatch]);

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <MDBRow>
            <MDBCol md='12' className='px-4'>
              <MDBCard>
                <MDBCardBody>
                  <GenericTable
                    tableId={'attributesTableSettings'}
                    items={mapAttributes(attributes)}
                    hasSearch={true}
                    hasItemsPerPageControl={true}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        );
      }}
    />
  );
};

export default AttributesList;
