import React, { useState, useEffect } from 'react';

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBTable,
  MDBTableBody,
  MDBModalDialog,
  MDBModalContent,
  MDBRadio,
  MDBCol,
} from 'mdbreact';

import { toast } from 'react-toastify';
import { translate } from '../../../../services/translation.service';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { addChart, selectSelectedSensorGroup as selectedUserSensorGroup } from '../../SensorGroups/sensorGroups.slice';
import { createTemporaryId } from '../../../../utils/adminPages.utility';
import { AddSensorChartProps } from '../../../../models/props/profile/addSensorChart.props';
import {
  addChart as adminAddChart,
  selectSelectedSensorGroup as selectedAdminSensorGroup,
} from '../../../admin/AdminSensorGroups/adminSensorGroups.slice';

const AddSensorChartModal: React.FC<AddSensorChartProps> = ({
  admin,
  isOpen,
  chartOptions,
  defaultChartName,
  onShowModal,
  onCloseModal,
  onSaveSensorChart,
}) => {
  const [selectedChart, setSelectedChart] = useState<string>(defaultChartName || 'Default');
  const [customNameInput, setCustomNameInput] = useState<string>('');
  const adminSensorGroup = useAppSelector(selectedAdminSensorGroup);
  const userSensorGroup = useAppSelector(selectedUserSensorGroup);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelectedChart(defaultChartName || 'Default Chart');
  }, [defaultChartName]);

  const handleRadioClick = (event: React.FormEvent<HTMLInputElement>) => {
    setSelectedChart(event.currentTarget.id);
  };

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    setCustomNameInput(event.currentTarget.value);
  };

  const handleFocus = () => {
    setSelectedChart('New Chart');
  };

  const chartExists = (sensorGroup: any, newChart: any) => {
    const exists = sensorGroup.sensorGroupCharts.some(
      (chart) => chart.name.toLowerCase().trim() === newChart.name.toLowerCase().trim() || chart.id === newChart.id
    );

    if (exists) {
      return toast.warn(translate('common.chartExists'));
    }

    return exists;
  };

  const handleSubmit = () => {
    if (!customNameInput && selectedChart === 'New Chart') {
      toast.warn(translate('pages.profile.noSelectedChartName'));
      return;
    }
    if (customNameInput && selectedChart === 'New Chart') {
      const newChart = { name: customNameInput, id: createTemporaryId() };
      if (admin) {
        if (chartExists(adminSensorGroup, newChart)) {
          return;
        }
        dispatch(adminAddChart({ ...newChart }));
        setCustomNameInput('');
      } else {
        if (chartExists(userSensorGroup, newChart)) {
          return;
        }
        dispatch(addChart({ ...newChart }));
        setCustomNameInput('');
      }
      onSaveSensorChart(newChart.id);
      return;
    }

    onSaveSensorChart(+selectedChart);
    setCustomNameInput('');
  };

  const closeModalAndClearInput = () => {
    onCloseModal();
    setCustomNameInput('');
  };

  return (
    <MDBContainer className='center-modal-page'>
      <MDBModal
        animationDirection='top'
        backdrop={true}
        show={isOpen}
        setShow={onShowModal}
        className='modal-notify modal-warning'
        tag='div'
      >
        <MDBModalDialog centered={true} size='sm'>
          <MDBModalContent>
            <MDBModalHeader>{translate('pages.profile.addToChart')}</MDBModalHeader>

            <MDBModalBody className='m-3'>
              <MDBTable className='rsrg-table' hover responsive>
                <MDBTableBody className='m-3'>
                  {chartOptions &&
                    chartOptions.map((chart) => (
                      <tr key={chart.name}>
                        <td className='align-middle text-left'>
                          <MDBCol className='pl-4'>
                            <MDBRadio
                              id={`${chart?.id}`}
                              onChange={handleRadioClick}
                              checked={+selectedChart === chart?.id ? true : false}
                              label={chart.name}
                              name='chart-choice'
                            />
                          </MDBCol>
                        </td>
                      </tr>
                    ))}

                  <tr>
                    <td className='align-middle text-left my-1'>
                      <MDBCol className='pl-4 pb-3'>
                        <MDBRadio
                          id='New Chart'
                          onChange={handleRadioClick}
                          checked={selectedChart === 'New Chart' ? true : false}
                          label={'New Chart'}
                          name='chart-choice'
                        />
                      </MDBCol>
                      <MDBInput
                        label='Name'
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        value={customNameInput}
                      />
                    </td>
                  </tr>
                </MDBTableBody>
              </MDBTable>

              <hr className='my-0' />
            </MDBModalBody>

            <MDBModalFooter data-testid='AddSensorChartModal-footer-wrapper'>
              <MDBBtn color='warning' className='text-white' onClick={handleSubmit}>
                {translate('common.ok')}
              </MDBBtn>
              <MDBBtn outline color='warning' className='cancel-onHover' onClick={closeModalAndClearInput}>
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
  );
};

export default AddSensorChartModal;
