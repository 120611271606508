import { MDBSpinner } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { translate } from '../../services/translation.service';

interface SpinnerProps {
  promiseInProgress: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ promiseInProgress }) => {
  const [isLongWaiting, setIsLongWaiting] = useState(false);
  const [isEvenLongerWaiting, setIsEvenLongerWaiting] = useState(false);

  useEffect(() => {
    if (promiseInProgress) {
      const timer = setTimeout(() => {
        setIsLongWaiting(true);
        clearTimeout(timer);
      }, 4000);

      const secondTimer = setTimeout(() => {
        setIsEvenLongerWaiting(true);
        clearTimeout(secondTimer);
      }, 11000);

      setIsLongWaiting(false);
      setIsEvenLongerWaiting(false);
      return () => {
        clearTimeout(timer);
        clearTimeout(secondTimer);
      };
    }
  }, [promiseInProgress]);

  return (
    <div className='d-inline fixed-centered'>
      <MDBSpinner color='warning' />
      {isLongWaiting &&
        (!isEvenLongerWaiting ? (
          <p className='fw-bold'>{translate('common.loading')}</p>
        ) : (
          <>
            <p className='fw-bold'>{translate('common.dataIsComing')}</p>
            <p>{translate('common.itMayTakeAWhile')}</p>
          </>
        ))}
    </div>
  );
};

export default Spinner;
