import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact';

import { toast } from 'react-toastify';
import { translate } from '../../../services/translation.service';
import useModalState from '../../../hooks/useModalState';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import GenericTable from '../../../components/genericTable/genericTable';
import ProtectedRoute from '../../../hocs/ProtectedRoute';
import { AuthConstants } from '../../../constants/auth.constants';
import { updateTableGlobalCheckedById } from '../../../store/slices/tableSlice';
import useAttributeMappings from '../../../hooks/useAttributeMappings';
import {
  bulkDeleteAdminSensorGroups,
  deleteAdminSensorGroupById,
  getAllAdminSensorGroups,
} from '../../../store/api/adminSensorGroups.service';
import {
  clearSelectedSensorGroup,
  selectAdminSensorGroups,
  selectCheckedAdminSensorGroupIds,
  toggleGlobalSensorGroupsChecked,
  toggleSensorGroupChecked,
} from './adminSensorGroups.slice';

const AdminSensorGroupsList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setModal } = useModalState();
  const { mapAdminSensorGroupAttributes } = useAttributeMappings();
  const { adminSensorGroups } = useAppSelector(selectAdminSensorGroups);
  const checkedSensorGroupIds = useAppSelector(selectCheckedAdminSensorGroupIds);

  useEffect(() => {
    dispatch(getAllAdminSensorGroups());

    return () => {
      dispatch(
        updateTableGlobalCheckedById({
          id: 'adminSensorGroups',
          globalChecked: false,
        })
      );
    };
  }, [dispatch]);

  const handleSensorGroupCheckedToggle = useCallback(
    (adminSensorGroupId: string | number | undefined) => {
      if (adminSensorGroupId) {
        dispatch(toggleSensorGroupChecked({ sensorGroupId: Number(adminSensorGroupId) }));
      }
    },
    [dispatch]
  );

  const handleSensorGroupGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;
      dispatch(toggleGlobalSensorGroupsChecked({ isChecked: !!checked }));
    },
    [dispatch]
  );

  const onAdminSensorGroupDelete = useCallback(
    (sensorGroupId: number) => setModal(true, () => dispatch(deleteAdminSensorGroupById({ sensorGroupId }))),
    [dispatch, setModal]
  );

  const onBulkDelete = useCallback(() => {
    if (checkedSensorGroupIds.length === 0) {
      toast.info(translate('pages.admin.noSelectedSensorGroups'));
      return;
    }

    setModal(true, () => dispatch(bulkDeleteAdminSensorGroups({ sensorGroupIds: checkedSensorGroupIds })));
  }, [checkedSensorGroupIds, dispatch, setModal]);

  const onEditSensorGroupClick = (groupId: number | string) => navigate(`/admin/sensor-group/${groupId}`);

  const onCreateSensorGroupClick = () => {
    dispatch(clearSelectedSensorGroup({}));
    navigate(`/admin/sensor-group`);
  };

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <MDBRow>
            <MDBCol md='12' className='px-4'>
              <MDBCard>
                <MDBCardBody>
                  <GenericTable
                    tableId={'adminSensorGroups'}
                    items={mapAdminSensorGroupAttributes(adminSensorGroups || []).map((ags) => ({
                      ...ags,
                      machineNames: ags.machines.map((m) => m.name).join(', '),
                    }))}
                    hasSearch={true}
                    hasCheckboxInput={true}
                    handleItemToggle={handleSensorGroupCheckedToggle}
                    onGlobalItemsChecked={handleSensorGroupGlobalCheckedToggle}
                    onItemDeleteClick={onAdminSensorGroupDelete}
                    onBulkDeleteClick={onBulkDelete}
                    onItemEditClick={onEditSensorGroupClick}
                    onActionButtonClick={onCreateSensorGroupClick}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        );
      }}
    />
  );
};

export default AdminSensorGroupsList;
