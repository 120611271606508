import axios from 'axios';

import { ContractModel } from '../models/data/contract/contract.model';
import { UserContractModel } from '../models/data/contract/userContract.model';
import { UserContractBindingModel } from '../models/data/contract/userContractBinding.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getUserContracts = async (userId: string) => {
  const result = await axios.get<UserContractModel[]>(`${baseApiUrl}api/userContracts/${userId}`);
  for (const contract of result?.data) {
    contract.isChecked = false;
  }

  return result?.data;
};

export const deleteUserContracts = async (userContract: UserContractBindingModel[]) => {
  const result = await axios.delete<boolean>(`${baseApiUrl}api/userContracts/`, {
    data: userContract,
  });

  return result?.data;
};

export const addUserContract = async (userContract: UserContractBindingModel) => {
  const result = await axios.post<ContractModel>(`${baseApiUrl}api/userContracts/`, userContract);
  return result?.data;
};
