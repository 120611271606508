import { createSlice, Slice, createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../store/store';
import { MachineModel } from '../../../models/data/machine/machine.model';
import { AlarmModel } from '../../../models/data/machine/alarms.model';
import { MachineOwnerModel } from '../../../models/data/machine/machineOwner.model';
import { AdminGroupModel } from '../../../models/data/group/adminGroup.model';
import {
  getAllMachines,
  getAllMachinesPolling,
  getMachineById,
  getMachineByIdPolling,
  updateMachineConfiguration,
  deleteMachineConfiguration,
} from '../../../store/api/machines.service';

import {
  addItemsByField,
  bulkDeleteItemsById,
  deleteItemById,
  toggleGlobalItemsChecked,
  toggleItemChecked,
  addItemByField,
  updateItemById,
} from '../../../store/reducers/reducers';
import { validateId } from '../../../utils/adminPages.utility';
import { translate } from '../../../services/translation.service';

export interface machinesState {
  displayInfoMessage?: boolean;
  machines: MachineModel[];
  selectedMachine?: MachineModel;
  alarms?: AlarmModel[];
  machineOwner?: MachineOwnerModel;
  adminGroups?: AdminGroupModel[];
}

const initialState: machinesState = {
  displayInfoMessage: false,
  machines: [],
  selectedMachine: undefined,
  alarms: [],
  adminGroups: [],
  machineOwner: undefined,
};

export const adminMachinesSlice: Slice = createSlice({
  name: 'adminMachines',
  initialState,
  reducers: {
    addItemByFieldId: addItemByField,
    addItemsByFieldId: addItemsByField,
    updateItemChecked: toggleItemChecked,
    updateGlobalItemsChecked: toggleGlobalItemsChecked,
    updateItem: updateItemById,
    deleteItem: deleteItemById,
    bulkDeleteItems: bulkDeleteItemsById,
    setDisplayInfoMessage(state, action) {
      state.displayInfoMessage = action.payload;
    },
    addSelectedMachineMachineOwner(state, action) {
      const { selectedMachineOwner } = action.payload;

      state.machineOwner = selectedMachineOwner;
    },
    setMachineData(state, action) {
      const { adminGroups, alarms, machineOwner } = action.payload;

      state.adminGroups = adminGroups;
      state.alarms = alarms;
      state.machineOwner = machineOwner;
    },
    clearSelectedMachine(state) {
      state.selectedMachine = undefined;
      state.alarms = [];
      state.adminGroups = [];
      state.machineOwner = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMachines.fulfilled, (state, action) => {
        const { machines } = action.payload;

        state.machines = machines;
      })
      .addCase(getAllMachinesPolling.fulfilled, (state, action) => {
        const { machines } = action.payload;

        state.machines = machines;
      })
      .addCase(getMachineById.fulfilled, (state, action) => {
        const { machine, machineOwner, alarms } = action.payload;

        state.selectedMachine = machine;
        state.adminGroups = machine?.adminGroups;
        state.alarms = alarms;
        state.machineOwner = machineOwner || undefined;
      })
      .addCase(getMachineByIdPolling.fulfilled, (state, action) => {
        const { machine, machineOwner, alarms } = action.payload;

        state.selectedMachine = machine;
        state.adminGroups = machine?.adminGroups;
        state.alarms = alarms;
        state.machineOwner = machineOwner || undefined;
      })
      .addCase(updateMachineConfiguration.fulfilled, (state, action) => {
        const { machine } = action.payload;

        state.selectedMachine = machine;
        state.adminGroups = machine?.adminGroups;
        state.alarms = machine.alarms;
        state.machineOwner = machine.machineOwner;
      })
      .addCase(deleteMachineConfiguration.fulfilled, (state, action) => {
        const { machine } = action.payload;

        state.selectedMachine = machine;
        state.adminGroups = machine?.adminGroups;
        state.alarms = machine.alarms;
        state.machineOwner = machine.machineOwner;
      });
  },
});

export const {
  addItemByFieldId,
  addItemsByFieldId,
  addSelectedMachineMachineOwner,
  updateItem,
  updateItemChecked,
  updateGlobalItemsChecked,
  deleteItem,
  bulkDeleteItems,
  clearSelectedMachine,
  setMachineData,
  setDisplayInfoMessage,
} = adminMachinesSlice.actions;

export default adminMachinesSlice.reducer;

export const selectDisplayInfoMessage = (state: RootState) => state.adminMachines.displayInfoMessage;

export const selectMachines = (state: RootState) => state.adminMachines.machines;

export const selectSelectedMachine = (state: RootState) => state.adminMachines.selectedMachine;

export const selectSelectedMachineAdminGroups = (state: RootState) => state.adminMachines.adminGroups;

export const selectSelectedMachineMachineOwner = (state: RootState) => state.adminMachines.machineOwner;

export const selectSelectedMachineAlarms = (state: RootState) => state.adminMachines.alarms;

export const selectAttributes = (state: RootState) => state.attributes.attributes;

export const selectSelectedMachineName = createSelector([selectSelectedMachine], (machine) => machine?.name);

export const selectEnrichedAlarms = createSelector(
  [selectSelectedMachineAlarms, selectAttributes],
  (alarms, attributes) =>
    alarms.map((a) => ({
      ...a,
      attributeName: attributes.find((at) => at.uuid === a.attribute)?.name,
      hasNotifications: a.notification?.emails?.length > 0 ? translate('common.yes') : translate('common.no'),
      formattedRules: a.rules.map((r) => `${r.symbol} ${r.value}`).join(', '),
    }))
);

export const selectSelectedMachineNewAlarms = createSelector([selectSelectedMachineAlarms], (alarms) =>
  alarms.filter((alarm) => +alarm.id < 0)
);

export const selectSelectedMachineAlarmById = createSelector(
  [selectSelectedMachineNewAlarms, (_, alarmId) => alarmId],
  (alarms, alarmId) => alarms.find((alarm) => +alarm.id === alarmId)
);

export const selectSelectedMachineHasValidData = createSelector(
  [selectSelectedMachineMachineOwner],
  (machineOwner) => !!machineOwner
);

export const selectIsSelectedMachineProcessing = createSelector(
  [selectSelectedMachine],
  (machine) => machine && !machine?.isProcessed
);

export const selectHasProcessingMachines = createSelector([selectMachines], (machines) =>
  machines.some((machine) => !machine.isProcessed)
);

export const selectFormattedAlarms = createSelector([selectSelectedMachineAlarms], (alarms) =>
  alarms.map((alarm) => ({
    ...alarm,
    id: validateId(alarm.id),
    rules: alarm.rules.map((r) => ({ ...r, id: validateId(r.id) })),
    notification: {
      phones: alarm.notification.phones.map((n) => ({ ...n, id: validateId(n.id) })),
      emails: alarm.notification.emails.map((n) => ({ ...n, id: validateId(n.id) })),
    },
  }))
);

export const selectSelectedMachineUpdateData = createSelector(
  [selectSelectedMachine, selectSelectedMachineMachineOwner, selectSelectedMachineAdminGroups, selectFormattedAlarms],
  (machine, machineOwner, adminGroups, alarms) => ({ ...machine, machineOwner, adminGroups, alarms })
);
