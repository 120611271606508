const attributeUuids = [
  '14297435-F3FD-4C68-A01A-C677443C27F2',
  'DA7B2D7E-E6B3-436F-88FE-23749C0C900A',
  'E01E7F7D-0B12-4478-A4E1-55BB8343246E',
  'F548E875-1DDA-4C59-9AD3-9B445EF11321',
  '4595BC28-A1F1-4D73-8DAA-E86A2A7EA9B5',
  '967F95AE-E4B7-42A4-9BAC-286DC59C4763',
  '9B7A08A3-C53F-4343-92DA-C99083E5016B',
  '9FA4DD61-60B2-429E-B83C-9F07A831BEFC',
];

export const convertChartSensorRadiansToDegrees = (chart: any) => {
  return {
    ...chart,
    sensors: chart.sensors.map((s) => ({
      ...s,
      unit: s.unit === 'rad' ? '°' : s.unit,
      data: s.unit === 'rad' && s.data ? s.data.map((d) => (d ? (180 / Math.PI) * +d : d)) : s.data || [],
      range:
        s.unit === 'rad' && s.range
          ? s.range.map((r) => (r ? r.map((d) => (d ? (180 / Math.PI) * +d : d)) : r || []))
          : s.range || [],
    })),
  };
};

export const roundChartSensorDataValues = (chart: any) => {
  return {
    ...chart,
    sensors: chart.sensors.map((s) => ({
      ...s,
      data: attributeUuids.includes(s.uuid.toUpperCase())
        ? s.data.map((d) => (d ? +d.toFixed(6) : d))
        : s.data.map((d) => (d && typeof d === 'number' ? +d.toFixed(2) : d)),
      range: attributeUuids.includes(s.uuid.toUpperCase())
        ? s.range.map((r) => r.map((d) => (d ? +d.toFixed(6) : d)))
        : s.range.map((r) => r.map((d) => (d && typeof d === 'number' ? +d.toFixed(2) : d))),
    })),
  };
};

export const removeEmptySensorData = (chart: any) => {
  return {
    ...chart,
    sensors: chart.sensors.filter((s) => s.data?.length),
  };
};