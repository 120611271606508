import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { TelemetryConstants } from '../../constants/telemetry.constants';
import { EventTrackHistoryModel } from '../../models/data/machine/eventTrackHistory.model';
import { TelemetryEventsModel } from '../../models/data/telemetry/telemetryEvents.model';
import { MachineStateType } from '../../models/enum/machineStateType.enum';
import { fetchMachineEvents } from '../../services/telemetry.service';
import { TelemetryBucketsRequestModel } from '../../models/data/telemetry/telemetryBucketsRequest.model';

export const getBucketGpsEvents = createAsyncThunk<
  {
    eventTrackHistory: EventTrackHistoryModel[];
  },
  {
    machineUuid: string;
    attributeUuids: string[];
    timeRange?: number;
    dateFrom?: string | null;
    dateTo?: string | null;
    mapSensorSetupData: boolean;
    getEventsCount: boolean;
    slicesMax: number;
    includeRangeTelemetryData: boolean;
  }
>('telemetry/getBucketGpsEvents', async (telemetryBucketEventsModel: TelemetryBucketsRequestModel) => {
  const eventsData = await trackPromise(fetchMachineEvents(telemetryBucketEventsModel));
  const eventTrackHistory = convertToEventTrackHistoryModel(eventsData);

  return { eventTrackHistory };
});

const convertToEventTrackHistoryModel = (telemetryEventsModel: TelemetryEventsModel): EventTrackHistoryModel[] => {
  let eventsTrackHistory: EventTrackHistoryModel[] = [];
  let getGpsTwo = false;

  telemetryEventsModel.timeStamps.forEach((timeStamp, index) => {
    let longitude = telemetryEventsModel.events.find(
      (e) => e.attributeUuid === TelemetryConstants.AttributeUuidLongitude
    )?.values[index];

    let latitude = telemetryEventsModel.events.find((e) => e.attributeUuid === TelemetryConstants.AttributeUuidLatitude)
      ?.values[index];

    if (!longitude || longitude === '' || !latitude || latitude === '') {
      longitude = telemetryEventsModel.events.find(
        (e) => e.attributeUuid === TelemetryConstants.AttributeUuidLongitudeTwo
      )?.values[index];

      latitude = telemetryEventsModel.events.find(
        (e) => e.attributeUuid === TelemetryConstants.AttributeUuidLatitudeTwo
      )?.values[index];

      getGpsTwo = true;
    }

    if (!longitude || longitude === '' || !latitude || latitude === '') {
      return;
    }

    const altitude = telemetryEventsModel.events.find(
      (e) =>
        e.attributeUuid ===
        (getGpsTwo === true ? TelemetryConstants.AttributeUuidAltitudeTwo : TelemetryConstants.AttributeUuidAltitude)
    )?.values[index];

    const eventTrackHistory: EventTrackHistoryModel = {
      id: index,
      isOnMap: true,
      longitude: (180 / Math.PI) * +longitude,
      latitude: (180 / Math.PI) * +latitude,
      altitude: altitude && altitude !== '' ? +altitude : 0,
      messageTime: timeStamp,
      machineState: MachineStateType.Off,
    };

    eventsTrackHistory.push(eventTrackHistory);
  });

  return eventsTrackHistory;
};
