import axios from 'axios';

import { AttributeInternationalization, AttributeModel } from '../models/data/machine/attribute.model';
import { SensorGroupChartBaseModel } from '../models/data/machine/sensorGroup.model';
import { AvailableSensorGroupAttributesBindingModel } from '../models/data/machine/availableSensorGroupAttributesBindingModel.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getAttributes = async () => {
  const result = await axios.get<AttributeModel[]>(`${baseApiUrl}api/attributes`);
  for (const attribute of result?.data) {
    attribute.isChecked = false;
    attribute.isActive = true;
  }

  return result?.data;
};

export const getAttributesByMachineIds = async (getOnlyNumericAttributes: boolean, machineIds: number[]) => {
  const result = await axios.get<AttributeModel[]>(
    `${baseApiUrl}api/attributes/machines/${getOnlyNumericAttributes}?machineIds=${machineIds.join('&machineIds=')}`
  );
  for (const attribute of result?.data) {
    attribute.isChecked = false;
    attribute.isActive = true;
  }

  return result?.data;
};

export const getAttributeMappings = async () => {
  const result = await axios.get<AttributeInternationalization[]>(`${baseApiUrl}api/attributes/mappings`);
  return result.data;
};

export const updateAvailableAttributes = async (
  availableSensorGroupAttributes: AvailableSensorGroupAttributesBindingModel
) => {
  const result = await axios.put<SensorGroupChartBaseModel[]>(
    `${baseApiUrl}api/attributes/sensor-group`,
    availableSensorGroupAttributes
  );

  return result?.data;
};
