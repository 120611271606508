import { Slice, createSlice } from '@reduxjs/toolkit';
import { UserStatisticModel } from '../../models/data/user/userStatistic.model';
import { RootState } from '../store';
import { addUserStatisticHistory } from '../api/userStatistics.service';

export interface UserStatisticsState {
  statistics: UserStatisticModel[];
}

const initialState: UserStatisticsState = {
  statistics: [],
};

export const userStatisticsSlice: Slice = createSlice({
  name: 'userStatistics',
  initialState,
  reducers: {
    addUserStatistics: (state, action) => {
      state.statistics.push({ url: action.payload, timestamp: new Date() });
    },
    clearUserStatistics: (state) => {
      state.statistics = initialState.statistics;
    },
    extraReducers: (builder) => {
      builder.addCase(addUserStatisticHistory.fulfilled, (state, _) => {
        state.statistics = [];
      });
    },
  },
});

export const { addUserStatistics, clearUserStatistics } = userStatisticsSlice.actions;

export const selectUserStatistics = (state: RootState) => state.userStatistics;

export default userStatisticsSlice.reducer;
