import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBCard, MDBCardBody, MDBCol, MDBRow, MDBBtn } from 'mdbreact';

import { toast } from 'react-toastify';
import ProtectedRoute from '../../../hocs/ProtectedRoute';
import useModalState from '../../../hooks/useModalState';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { AuthConstants } from '../../../constants/auth.constants';
import { translate } from '../../../services/translation.service';
import GenericTable from '../../../components/genericTable/genericTable';
import useInterval from '../../../hooks/useInterval';
import { AdminConstants } from '../../../constants/admin.constants';
import { uncheckTableGlobalCheckedById } from '../../../store/slices/tableSlice';
import {
  getContracts,
  removeContracts,
  removeContract,
  getContractsPolling,
} from '../../../store/api/contract.service';
import {
  selectContracts,
  selectCheckedContractIds,
  updateItemChecked,
  updateGlobalItemsChecked,
  selectHasProcessingContracts,
  clearSelectedContract,
} from './contracts.slice';

const ContractsList: React.FC = () => {
  const { setModal } = useModalState();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const contracts = useAppSelector(selectContracts);
  const selectedContractIds = useAppSelector(selectCheckedContractIds);
  const hasProcessingContracts = useAppSelector(selectHasProcessingContracts);

  const getAllContracts = useCallback(() => dispatch(getContracts()), [dispatch]);

  useEffect(() => {
    getAllContracts();

    return () => {
      dispatch(uncheckTableGlobalCheckedById('contractsTableSettings'));
    };
  }, [dispatch, getAllContracts]);

  // Polling request for processing/synchronizing Contracts
  useInterval(() => dispatch(getContractsPolling()), hasProcessingContracts ? AdminConstants.pollingInterval : null);

  const onEditContractClick = useCallback(
    (contractId: number | string) => {
      navigate(`/admin/contract/${contractId}`);
    },
    [navigate]
  );

  const onContractDeleteClick = useCallback(
    (contractId: number) => setModal(true, () => dispatch(removeContract({ contractId }))),
    [dispatch, setModal]
  );

  const onBulkDeleteClick = useCallback(() => {
    if (selectedContractIds.length === 0) {
      toast.info(translate('pages.admin.noSelectedContracts'));
      return;
    }

    setModal(true, () => {
      dispatch(removeContracts({ contractIds: selectedContractIds }));
      dispatch(uncheckTableGlobalCheckedById('contractsTableSettings'));
    });
  }, [dispatch, selectedContractIds, setModal]);

  const handleAdminGroupCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) dispatch!(updateItemChecked({ fieldId: 'contracts', itemId: id }));
    },
    [dispatch]
  );

  const onAdminGroupGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      dispatch(updateGlobalItemsChecked({ fieldId: 'contracts', isChecked: !!event.currentTarget.checked }));
    },
    [dispatch]
  );

  const onCreateContractClick = useCallback(() => {
    dispatch(clearSelectedContract({}));
    navigate(`/admin/contract`);
  }, [dispatch, navigate]);

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <MDBRow>
            <MDBCol md='12' >
              <MDBCard >
                <MDBCardBody>
                  {hasProcessingContracts && (
                    <MDBBtn color='warning' className='text-white' onClick={getAllContracts}>
                      {translate('common.refresh')}
                    </MDBBtn>
                  )}
                  <GenericTable
                    tableId={'contractsTableSettings'}
                    items={contracts}
                    hasSearch={true}
                    hasItemsPerPageControl={true}
                    hasCheckboxInput={true}
                    handleItemToggle={handleAdminGroupCheckedToggle}
                    onGlobalItemsChecked={onAdminGroupGlobalCheckedToggle}
                    onItemDeleteClick={onContractDeleteClick}
                    onItemEditClick={onEditContractClick}
                    onBulkDeleteClick={onBulkDeleteClick}
                    onActionButtonClick={onCreateContractClick}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        );
      }}
    />
  );
};

export default ContractsList;
