import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBCol, MDBRow, MDBCard, MDBCardBody } from 'mdbreact';

import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import useModalState from '../../../hooks/useModalState';
import { translate } from '../../../services/translation.service';
import {
  getUserSensorGroupById,
  createSensorGroup,
  updateSensorGroup,
  getAllUserSensorGroups,
} from '../../../store/api/userSensorGroups.service';

import {
  toggleSensorChecked,
  toggleGlobalSensorsChecked,
  deleteSensor,
  bulkDeleteSensors,
  selectMachines,
  selectSelectedSensorGroup,
  selectFormattedSensors,
  selectCheckedSensorsIds,
  selectCheckedMachineIds,
  selectSelectedGroupSubmitFormat,
  selectSensorChartsOptions,
  moveSensorToChart,
  deleteMachine,
  bulkDeleteMachines,
  toggleMachineChecked,
  toggleGlobalMachinesChecked,
  selectUserSensorGroups,
  setDisplayInfoMessage,
} from './sensorGroups.slice';

import GenericTable from '../../../components/genericTable/genericTable';
import BackToPage from '../../../components/backToPage/BackToPage';
import SensorGroupDetail from '../components/sensorGroupDetail/SensorGroupDetail';
import AddSensorChartModal from '../components/addSensorChart/AddSensorChartModal';
import useAttributeMappings from '../../../hooks/useAttributeMappings';
import { SensorGroupsModel } from '../../../models/data/machine/sensorGroup.model';
import { AvailableSensorGroupAttributesBindingModel } from '../../../models/data/machine/availableSensorGroupAttributesBindingModel.model';
import { updateSensorGroupAvailableAttributes } from '../../../store/api/adminSensorGroups.service';
import { uncheckTableGlobalCheckedById } from '../../../store/slices/tableSlice';

const SensorGroupDetails: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setModal } = useModalState();
  const { mapAttributes } = useAttributeMappings();
  const { groupId: groupIdParam } = useParams<{ groupId: string }>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState<any>();
  const [defaultChartName, setDefaultChartName] = useState<any>();

  const groupId = useMemo(() => +groupIdParam!, [groupIdParam]);

  const userSensorGroups = useAppSelector(selectUserSensorGroups);
  const group = useAppSelector(selectSelectedSensorGroup);
  const sensors = useAppSelector(selectFormattedSensors);
  const machines = useAppSelector(selectMachines);
  const groupSubmitFormatted = useAppSelector(selectSelectedGroupSubmitFormat);
  const checkedSensorIds = useAppSelector(selectCheckedSensorsIds);
  const sensorChartOptions = useAppSelector(selectSensorChartsOptions);
  const checkedMachineIds = useAppSelector(selectCheckedMachineIds);

  useEffect(() => {
    if (groupId && group.id !== groupId) {
      dispatch(getUserSensorGroupById({ sensorGroupId: groupId }));
    }

    return () => {
      dispatch(uncheckTableGlobalCheckedById('profileSensorGroupMachines'));
      dispatch(uncheckTableGlobalCheckedById('profileGroupSensors'));
    };
  }, [group.id, groupId, dispatch]);

  useEffect(() => {
    dispatch(getAllUserSensorGroups());
  }, [dispatch]);

  const onSubmitGroup = async (groupIsNew: boolean) => {
    const userSensorGroup = userSensorGroups.find(
      (g) => g.name.toLowerCase().trim() === groupSubmitFormatted.name.toLowerCase().trim()
    );

    if (userSensorGroup && (groupIsNew || userSensorGroup.id !== groupId)) {
      toast.warn(translate('common.nameAlreadyExists'));
      return;
    }

    if (groupIsNew) {
      const { newSensorGroup } = await dispatch(createSensorGroup({ sensorGroup: groupSubmitFormatted })).unwrap();

      navigate(`/profile/group/${newSensorGroup?.id}`);
    } else {
      await dispatch(updateSensorGroup({ sensorGroupId: groupId, sensorGroup: groupSubmitFormatted }));
    }
  };

  const onSensorAddClick = () => {
    if (machines.length === 0) {
      toast.info(translate('pages.profile.noSelectedMachine'));
      return;
    }
    navigate(`/profile/group/${groupId}/add-sensor/`);
  };

  const onMachineAddClick = () => {
    navigate(`/profile/group/${groupId}/add-machine/`);
  };

  const handleSensorCheckedToggle = useCallback(
    (itemId: string | number | undefined) => {
      if (itemId) {
        const chartSensor = sensors.find((ta) => ta.id === +itemId);

        if (chartSensor) {
          const { chartId, id } = chartSensor;

          dispatch!(toggleSensorChecked({ chartId, sensorId: id }));
        }
      }
    },
    [dispatch, sensors]
  );

  const handleSensorsGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;

      dispatch(toggleGlobalSensorsChecked({ isChecked: !!checked }));
    },
    [dispatch]
  );

  const handleSensorDelete = useCallback(
    (sensorId: number | string) => {
      const selectedSensor = sensors.find((sensor) => sensor.id === sensorId);

      setModal(true, () => {
        dispatch(setDisplayInfoMessage(true));
        dispatch(deleteSensor({ chartId: selectedSensor.chartId, sensorId }));
      });
    },
    [dispatch, sensors, setModal]
  );

  const handleSensorsBulkDelete = useCallback(() => {
    if (checkedSensorIds.length === 0) {
      toast.info(translate('pages.profile.noSelectedSensors'));
      return;
    }

    setModal(true, () => {
      dispatch(setDisplayInfoMessage(true));
      dispatch(bulkDeleteSensors({ checkedSensorIds }));
      dispatch(uncheckTableGlobalCheckedById('profileGroupSensors'));
    });
  }, [checkedSensorIds, dispatch, setModal]);

  const handleModalToggle = (sensorId: number) => {
    const defaultChart = sensors.find((sensor) => sensor.id === sensorId);
    setDefaultChartName(defaultChart);

    setSelectedSensor(defaultChart);
    setIsModalOpen(true);
  };

  const handleCloseModel = () => {
    setSelectedSensor(undefined);
    setIsModalOpen(false);
    setDefaultChartName('');
  };

  const onSensorAddToChart = (nextChartId: number) => {
    const { chartId, id } = selectedSensor;
    dispatch(setDisplayInfoMessage(true));
    dispatch(moveSensorToChart({ currentChartId: chartId, nextChartId, sensorId: id }));
    handleCloseModel();
  };

  const handleMachineCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) {
        dispatch!(toggleMachineChecked(id));
      }
    },
    [dispatch]
  );

  const handleMachinesGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) =>
      dispatch(toggleGlobalMachinesChecked({ isChecked: !!event.currentTarget.checked })),
    [dispatch]
  );

  const handleMachineDelete = useCallback(
    (machineId: number) => {
      setModal(true, () => {
        dispatch(setDisplayInfoMessage(true));
        dispatch(deleteMachine({ machineId }));

        const { sensorGroupCharts, machines } = group as SensorGroupsModel;
        if (sensorGroupCharts && sensorGroupCharts.length > 0) {
          const availableSensorGroupAttributes: AvailableSensorGroupAttributesBindingModel = {
            availableMachineIds: machines?.map((m) => m.id).filter((id) => id !== machineId),
            sensorGroupCharts: sensorGroupCharts,
          };
          dispatch(updateSensorGroupAvailableAttributes(availableSensorGroupAttributes));
        }
      });
    },
    [group, dispatch, setModal]
  );

  const handleMachinesBulkDelete = useCallback(() => {
    if (checkedMachineIds.length === 0) {
      toast.info(translate('pages.profile.noSelectedMachinesRemove'));
      return;
    }

    setModal(true, () => {
      dispatch(setDisplayInfoMessage(true));
      dispatch(bulkDeleteMachines({ checkedMachineIds }));

      const { sensorGroupCharts, machines } = group as SensorGroupsModel;
      if (sensorGroupCharts && sensorGroupCharts.length > 0) {
        const availableSensorGroupAttributes: AvailableSensorGroupAttributesBindingModel = {
          availableMachineIds: machines?.map((m) => m.id).filter((id) => !checkedMachineIds.includes(id)),
          sensorGroupCharts: sensorGroupCharts,
        };

        dispatch(updateSensorGroupAvailableAttributes(availableSensorGroupAttributes));
      }

      dispatch(uncheckTableGlobalCheckedById('profileSensorGroupMachines'));
    });
  }, [group, checkedMachineIds, dispatch, setModal]);

  return (
    <>
      <MDBRow className='mb-3'>
        <MDBCol md='12' className='px-4'>
          <BackToPage
            text={translate('backToLink.sensorGroups')}
            navigateTo='/profile'
            extraAction={() => dispatch(setDisplayInfoMessage(false))}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md='12' className='px-4'>
          <SensorGroupDetail group={group} onSubmitSensorGroup={onSubmitGroup} />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md='12' className='px-4'>
          <MDBCard className='mt-5'>
            <MDBCardBody>
              <GenericTable
                tableId={'profileSensorGroupMachines'}
                items={machines || []}
                hasSearch={true}
                hasItemsPerPageControl={true}
                hasCheckboxInput={true}
                handleItemToggle={handleMachineCheckedToggle}
                onGlobalItemsChecked={handleMachinesGlobalCheckedToggle}
                onItemDeleteClick={handleMachineDelete}
                onBulkDeleteClick={handleMachinesBulkDelete}
                onActionButtonClick={onMachineAddClick}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md='12' className='px-4'>
          <MDBCard className='mt-5'>
            <MDBCardBody>
              <GenericTable
                tableId={'profileGroupSensors'}
                items={mapAttributes(sensors || [])}
                hasSearch={true}
                hasItemsPerPageControl={true}
                hasCheckboxInput={true}
                handleItemToggle={handleSensorCheckedToggle}
                onGlobalItemsChecked={handleSensorsGlobalCheckedToggle}
                onItemAddToGroupsClick={handleModalToggle}
                onItemDeleteClick={handleSensorDelete}
                onBulkDeleteClick={handleSensorsBulkDelete}
                onActionButtonClick={onSensorAddClick}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <AddSensorChartModal
        isOpen={isModalOpen}
        onShowModal={setIsModalOpen}
        chartOptions={sensorChartOptions}
        onCloseModal={handleCloseModel}
        onSaveSensorChart={onSensorAddToChart}
        defaultChartName={defaultChartName?.chartId}
      />
    </>
  );
};

export default SensorGroupDetails;
