export class CommonConstants {
  public static readonly supportEmail = 'mm@rsrg.com';

  public static readonly refreshMapTime: number = 5 * 60 * 1000;
  public static readonly refreshMachineGeoTime: number = 5 * 60 * 1000;

  public static readonly refreshUserStatisticsTime: number = 1 * 60 * 1000;

  public static readonly RouteMyProfileregex = /(^\w|\s\w)/g;

  public static readonly GoogleMapsUrl = 'https://www.google.com/maps/search/?api=1&query=';
}
