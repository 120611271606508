import { createSlice, Slice, createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../store/store';
import {
  getContracts,
  getContractById,
  getAvailableMachines,
  getAvailableUsers,
  createContract,
  updateContract,
  removeContract,
  removeContracts,
  getContractsPolling,
  getContractByIdPolling,
} from '../../../store/api/contract.service';
import { getAllContactPersons } from '../../../store/api/contactPersons.service';
import { dateUTC12AM } from '../../../transformers/timeZone.transform';

import {
  addItemsByField,
  bulkDeleteItemsById,
  deleteItemById,
  toggleGlobalItemsChecked,
  toggleItemChecked,
  setSelectedItemFieldById,
} from '../../../store/reducers/reducers';

import { ContactPersonModel } from '../../../models/data/contract/contactPerson.model';
import { ContractMachineModel } from '../../../models/data/contract/contractMachine.model';
import { ContractModel } from '../../../models/data/contract/contract.model';
import { ContractOwnerModel } from '../../../models/data/contract/contractOwner.model';

export interface contractsState {
  alarmModal: { text: string; isOpen: boolean };
  contracts: ContractModel[];
  selectedContract?: Partial<ContractModel>;
  machines: ContractMachineModel[];
  availableMachines: ContractMachineModel[];
  users: ContactPersonModel[];
  availableUsers: ContactPersonModel[];
  contactPersons: ContactPersonModel[];
}

const initialState: contractsState = {
  alarmModal: {
    text: '',
    isOpen: false,
  },
  contracts: [],
  selectedContract: undefined,
  machines: [],
  availableMachines: [],
  users: [],
  availableUsers: [],
  contactPersons: [],
};

export const contractsSlice: Slice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    addItemsByFieldId: addItemsByField,
    updateItemChecked: toggleItemChecked,
    updateGlobalItemsChecked: toggleGlobalItemsChecked,
    deleteItem: deleteItemById,
    bulkDeleteItems: bulkDeleteItemsById,
    setSelectedItemField: setSelectedItemFieldById,
    addContactPerson(state, action) {
      const { text, contactPerson } = action.payload;

      const contactPersonExists = state.contactPersons.some(
        (cp) =>
          cp.firstName.trim().toLowerCase() === contactPerson.firstName.trim().toLowerCase() &&
          cp.lastName.trim().toLowerCase() === contactPerson.lastName.trim().toLowerCase() &&
          cp.email.trim().toLowerCase() === contactPerson.email.trim().toLowerCase()
      );

      if (!contactPersonExists) {
        state.contactPersons.push(contactPerson);
      } else {
        state.alarmModal = { text: text, isOpen: true };
      }
    },
    setSelectedContractContactOwnerField(state, action) {
      const { itemId, item } = action.payload;

      if (itemId === 'contactPerson') {
        const contactPersonExists = state.contactPersons.some(
          (cp) =>
            cp.firstName.trim().toLowerCase() === item.firstName.trim().toLowerCase() &&
            cp.lastName.trim().toLowerCase() === item.lastName.trim().toLowerCase() &&
            cp.email.trim().toLowerCase() === item.email.trim().toLowerCase()
        );

        if (!contactPersonExists) {
          return;
        }
      }

      if (state.alarmModal.isOpen) {
        return;
      }

      if (!state?.selectedContract) {
        state.selectedContract = {};
      }

      if (!state.selectedContract?.contractOwner) {
        state.selectedContract.contractOwner = {
          [itemId as string]: item,
        } as ContractOwnerModel;

        return;
      }

      state.selectedContract.contractOwner[itemId] = item;
    },
    clearSelectedContract(state) {
      state.selectedContract = undefined;
      state.machines = [];
      state.users = [];
    },
    closeAlarmModal(state) {
      state.alarmModal = initialState.alarmModal;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContracts.fulfilled, (state, action) => {
        const { contracts } = action.payload;

        state.contracts = contracts;
        state.selectedContract = undefined;
      })
      .addCase(getContractsPolling.fulfilled, (state, action) => {
        const { contracts } = action.payload;

        state.contracts = contracts;
      })
      .addCase(getContractById.fulfilled, (state, action) => {
        const { contract, contractMachines, users, contactPersons } = action.payload;

        state.selectedContract = contract;
        state.machines = contractMachines;
        state.users = users;
        state.contactPersons = contactPersons;
      })
      .addCase(getContractByIdPolling.fulfilled, (state, action) => {
        const { contract } = action.payload;

        state.selectedContract = contract;
      })
      .addCase(getAllContactPersons.fulfilled, (state, action) => {
        const { contactPersons } = action.payload;

        state.contactPersons = contactPersons;
      })
      .addCase(getAvailableMachines.fulfilled, (state, action) => {
        const { availableMachines } = action.payload;

        state.availableMachines = availableMachines;
      })
      .addCase(getAvailableUsers.fulfilled, (state, action) => {
        const { availableUsers } = action.payload;

        state.availableUsers = availableUsers;
      })
      .addCase(createContract.fulfilled, (state, action) => {
        const { contract } = action.payload;

        state.selectedContract = contract as ContractModel;
      })
      .addCase(updateContract.fulfilled, (state, action) => {
        const { updatedContract, contactPersons } = action.payload;

        state.selectedContract = updatedContract;
        state.contactPersons = contactPersons;
      })
      .addCase(removeContracts.fulfilled, (state, action) => {
        const { contractIds } = action.payload;

        state.contracts = state.contracts.filter((contract) => !contractIds.includes(contract.id));
      })
      .addCase(removeContract.fulfilled, (state, action) => {
        const { contractId } = action.payload;

        state.contracts = state.contracts.filter((contract) => contract.id !== contractId);
      });
  },
});

export const {
  addContactPerson,
  addItemsByFieldId,
  setSelectedItemField,
  setSelectedContractContactOwnerField,
  updateItemChecked,
  updateGlobalItemsChecked,
  closeAlarmModal,
  deleteItem,
  bulkDeleteItems,
  clearSelectedContract,
} = contractsSlice.actions;

export default contractsSlice.reducer;

export const selectAlarmModal = (state: RootState) => state.contracts.alarmModal;

export const selectContractState = (state: RootState) => state.contracts;

export const selectContracts = (state: RootState) => state.contracts.contracts;

const selectSelectedContractMachines = (state: RootState) => state.contracts.machines;

export const selectSelectedContract = (state: RootState) => state.contracts.selectedContract;

export const selectSelectedUsers = (state: RootState) => state.contracts.users;

export const selectAvailableContactPersons = (state: RootState) => state.contracts.contactPersons;

export const selectAvailableContractMachines = (state: RootState) => state.contracts.availableMachines;

export const selectAvailableContractUsers = (state: RootState) => state.contracts.availableUsers;

export const selectCheckedContractIds = createSelector([selectContracts], (contracts) =>
  contracts.filter((contract: ContractModel) => contract?.isChecked).map((contract: ContractModel) => contract.id)
);

export const selectFormattedContractMachines = createSelector([selectSelectedContractMachines], (machines) =>
  machines.map((item) => ({ ...item, id: item.machineId }))
);

const selectContractMachineIds = createSelector([selectFormattedContractMachines], (machines) =>
  machines.map((machine) => machine.id)
);

const selectContractUserIds = createSelector([selectSelectedUsers], (users) => users.map((user) => user.id));

export const selectFilteredAvailableContractUsers = createSelector(
  [selectAvailableContractUsers, selectContractUserIds],
  (users, userIds) => users.filter((user) => !userIds.includes(user.id))
);

export const selectFormattedAvailableContractMachines = createSelector(
  [selectAvailableContractMachines, selectContractMachineIds],
  (machines, machineIds) =>
    machines.map((item) => ({ ...item, id: item.machineId })).filter((am) => !machineIds.includes(am.id))
);

export const selectCheckedAvailableContractMachine = createSelector(
  [selectFormattedAvailableContractMachines],
  (machines) => machines.filter((machine) => machine?.isChecked).map((machine) => ({ ...machine, isChecked: false }))
);

export const selectCheckedAvailableContractUsers = createSelector([selectFilteredAvailableContractUsers], (users) =>
  users.filter((user) => user?.isChecked).map((user) => ({ ...user, isChecked: false }))
);

export const selectSelectedContractContractPerson = createSelector(
  [selectSelectedContract],
  (contract) => contract?.contractOwner?.contactPerson
);

const selectSelectedContractContactPersonId = createSelector(
  [selectSelectedContract],
  (contract) => contract?.contractOwner?.contactPerson?.id
);

export const selectFormattedContractContactPersons = createSelector(
  [selectAvailableContactPersons, selectSelectedContractContactPersonId],
  (contactPersons, contactPersonId) =>
    contactPersons.map((contactPerson) => ({
      text: `${contactPerson.firstName} ${contactPerson.lastName} ${contactPerson.email}`,
      value: `${contactPerson.id}`,
      checked: contactPerson.id === contactPersonId,
    }))
);

export const selectHasProcessingContracts = createSelector([selectContracts], (contracts) =>
  contracts.some((contract) => !contract.isProcessed)
);

export const selectIsSelectedContractProcessed = createSelector(
  [selectSelectedContract],
  (contract) => contract?.id && !contract?.isProcessed
);

export const selectFormattedSelectedContract = createSelector(
  [selectSelectedContract, selectContractMachineIds, selectContractUserIds],
  (contract, machineIds, userIds) =>
    contract && {
      ...contract,
      contractOwner: {
        company: contract?.contractOwner?.company || contract?.contractOwner?.contactPerson?.company,
        firstName: contract?.contractOwner?.contactPerson?.firstName,
        lastName: contract?.contractOwner?.contactPerson?.lastName,
        email: contract?.contractOwner?.contactPerson?.email,
        id: contract?.contractOwner?.id,
      },
      startDate: dateUTC12AM(contract?.startDate),
      stopDate: dateUTC12AM(contract?.stopDate),
      machineIds,
      userIds,
    }
);
