import { createSlice, Slice, createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { SelectedGroupModel, UserGroupModel } from '../../models/data/group/userGroup.model';
import { AvailableMachineModel } from '../../models/data/machine/availableMachine.model';
import { addUserGroupMachine, removeUserGroupMachine } from './machinesSlice';
import {
  addUserMachineGroup,
  getUserMachineGroups,
  editUserMachineGroupFlag,
  removeUserMachineGroup,
  bulkRemoveUserMachineGroup,
  editUserMachineGroup,
} from '../api/userGroups.service';

export interface MachinesState {
  displayInfoMessage?: boolean;
  userGroups: UserGroupModel[];
  availableMachines: AvailableMachineModel[];
  selectedGroup: SelectedGroupModel;
}

const initialState: MachinesState = {
  displayInfoMessage: false,
  userGroups: [],
  availableMachines: [],
  selectedGroup: {
    name: '',
    isLocked: false,
    machines: [],
  },
};

export const userGroupsSlice: Slice = createSlice({
  name: 'userGroups',
  initialState,
  reducers: {
    setUserGroups(state, action) {
      state.userGroups = action.payload;
    },
    setAvailableMachines(state, action) {
      state.availableMachines = action.payload;
    },
    updateUserGroupChecked(state, action) {
      const group = state.userGroups.find((group) => group.id === action.payload);

      if (group) {
        group.isChecked = !group.isChecked;
      }
    },
    updateUserGroupsGlobalChecked(state, action) {
      state.userGroups = state.userGroups.map((group) => ({ ...group, isChecked: !!action.payload }));
    },
    toggleMachineChecked(state, action) {
      const machine = state.selectedGroup.machines.find((m) => m.id === +action.payload);

      if (machine) {
        machine.isChecked = !machine.isChecked;
      }
    },
    toggleGlobalMachinesChecked(state, action) {
      const { isChecked } = action.payload;

      state.selectedGroup.machines = state.selectedGroup.machines.map((m) => ({
        ...m,
        isChecked: isChecked,
      }));
    },
    toggleAvailableMachineChecked(state, action) {
      const machine = state.availableMachines.find((m) => m.id === +action.payload);

      if (machine) {
        machine.isChecked = !machine.isChecked;
      }
    },
    toggleAvailableGlobalMachinesChecked(state, action) {
      const { isChecked } = action.payload;

      state.availableMachines = state.availableMachines.map((m) => ({
        ...m,
        isChecked: isChecked,
      }));
    },
    setSelectedGroup(state, action) {
      const groupId = action.payload;

      const group = state.userGroups.find((g) => g.id === groupId);
      if (group) {
        state.selectedGroup = {
          id: groupId,
          name: group.name,
          isLocked: group.isLocked,
          machines: [...group.machines],
        };
      } else {
        state.selectedGroup = initialState.selectedGroup;
      }
    },
    clearSelectedGroup(state) {
      state.selectedGroup = initialState.selectedGroup;
    },
    setSelectedGroupName(state, action) {
      state.selectedGroup.name = action.payload;
    },
    addMachinesToUserGroup(state, action) {
      const machines = action.payload;

      state.selectedGroup.machines = [...state.selectedGroup.machines, ...machines];
      state.availableMachines = state.availableMachines.map((m) => ({
        ...m,
        isChecked: false,
      }));
    },
    removeMachinesFromUserGroup(state, action) {
      const machineIds = action.payload;

      state.selectedGroup.machines = state.selectedGroup.machines.filter((m) => !machineIds.includes(+m.id));
    },
    setDisplayInfoMessage(state, action) {
      state.displayInfoMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserMachineGroups.fulfilled, (state, action) => {
        const { groups, availableMachines } = action.payload;

        state.userGroups = groups;
        state.availableMachines = availableMachines;
      })
      .addCase(addUserMachineGroup.fulfilled, (state, action) => {
        const { groups } = action.payload;

        state.userGroups = groups;
      })
      .addCase(editUserMachineGroupFlag.fulfilled, (state, action) => {
        const { groups } = action.payload;

        state.userGroups = groups;
      })
      .addCase(editUserMachineGroup.fulfilled, (state, action) => {
        const { groups } = action.payload;

        state.userGroups = groups;
      })
      .addCase(removeUserMachineGroup.fulfilled, (state, action) => {
        const { groupId } = action.payload;

        state.userGroups = state.userGroups.filter((group) => group.id.toString() !== groupId);
      })
      .addCase(bulkRemoveUserMachineGroup.fulfilled, (state, action) => {
        let { groupIds } = action.payload;
        groupIds = groupIds.map((id) => id.toString());

        state.userGroups = state.userGroups.filter((group) => !groupIds.includes(group.id.toString()));
      })
      .addCase(removeUserGroupMachine.fulfilled, (state, action) => {
        const { machineId, groupId } = action.payload;
        const group = state.userGroups.find((group) => group.id.toString() === groupId);

        if (group) {
          group.machines = group.machines.filter((machine) => machine.id !== machineId);
        }
      })
      .addCase(addUserGroupMachine.fulfilled, (state, action) => {
        const { machineToEdit, groups } = action.payload;

        if (machineToEdit) {
          for (const group of state.userGroups) {
            const currentGroup = groups.find((g) => g.id === group.id);
            const containsMachine = group.machines.some((m) => m.id === machineToEdit.id);

            if (currentGroup?.isChecked && !containsMachine) {
              group.machines.push({ ...machineToEdit, isAvailable: true });
            } else if (!currentGroup?.isChecked && containsMachine) {
              group.machines = group.machines.filter((m) => m.id !== machineToEdit.id);
            }
          }
        }
      });
  },
});

export const {
  setUserGroups,
  setAvailableMachines,
  updateUserGroupChecked,
  updateUserGroupsGlobalChecked,
  toggleMachineChecked,
  toggleGlobalMachinesChecked,
  toggleAvailableMachineChecked,
  toggleAvailableGlobalMachinesChecked,
  setSelectedGroup,
  clearSelectedGroup,
  setSelectedGroupName,
  addMachinesToUserGroup,
  removeMachinesFromUserGroup,
  setDisplayInfoMessage,
} = userGroupsSlice.actions;

export default userGroupsSlice.reducer;

export const selectUserGroups = (state: RootState) => state.userGroups.userGroups;

export const selectSelectedGroup = (state: RootState) => state.userGroups.selectedGroup;

export const selectAvailableMachines = (state: RootState) => state.userGroups.availableMachines;

export const selectDisplayInfoMessage = (state: RootState) => state.userGroups.displayInfoMessage;

export const selectSelectedGroupMachines = createSelector([selectSelectedGroup], (group) => group.machines);

export const selectAvailableGroupMachines = createSelector(
  [selectSelectedGroup, selectAvailableMachines],
  (selectedGroup, availableMachines) =>
    availableMachines.filter((am) => !selectedGroup?.machines.map((m) => m.id).includes(am.id))
);

export const selectHasUserGroupsData = createSelector(
  [selectUserGroups, selectUserGroups],
  (userGroups, availableMachines) =>
    userGroups && userGroups.length > 0 && availableMachines && availableMachines.length > 0
);
