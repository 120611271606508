import { createSlice, Slice, createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface TabsState {
  mapTab: number;
  groupTab: number;
  detailsMapTab: number;
  detailsTableTab: number;
  profileTabs: number;
  profileGroupsTab: number;
  sensorGroupsTab: number;
}

const initialState: TabsState = {
  mapTab: 1,
  groupTab: 1,
  detailsMapTab: 1,
  detailsTableTab: 1,
  profileTabs: 1,
  profileGroupsTab: 1,
  sensorGroupsTab: 1,
};

export const tabsSlice: Slice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    updateActiveTabById: (state, action) => {
      state[action.payload.id] = action.payload.tabIndex;
    },
  },
});

export const { updateActiveTabById } = tabsSlice.actions;

export const selectTab = (state: RootState) => state.tabs;

export const selectTabById = (state: RootState, tabId: string) => state.tabs[tabId];

export const selectMapTab = createSelector(selectTab, (tabs) => tabs.mapTab);

export const selectGroupTab = createSelector(selectTab, (tabs) => tabs.groupTab);

export default tabsSlice.reducer;
