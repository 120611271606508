import axios from 'axios';
import { AdminSensorGroupsModel } from '../models/data/machine/sensorGroup.model';
import { AdminSensorGroupRequestPops } from '../models/props/profile/createSensorGroupRequest.props';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getAdminSensorGroups = async () => {
  const result = await axios.get<AdminSensorGroupsModel[]>(`${baseApiUrl}api/AdminSensorGroups`);

  return result?.data;
};

export const getAdminSensorGroupsDetails = async (machineId: number) => {
  const result = await axios.get<AdminSensorGroupsModel[]>(`${baseApiUrl}api/AdminSensorGroups/machine/${machineId}`);

  return result?.data;
};

export const getAdminSensorGroup = async (sensorGroupId: number) => {
  const result = await axios.get<AdminSensorGroupsModel>(`${baseApiUrl}api/AdminSensorGroups/${sensorGroupId}`);

  return result?.data;
};

export const createAdminSensorGroup = async (sensorGroup: AdminSensorGroupRequestPops) => {
  const result = await axios.post<AdminSensorGroupsModel>(`${baseApiUrl}api/AdminSensorGroups`, sensorGroup);
  return result.data;
};

export const updateAdminSensorGroup = async (sensorGroupId: number, sensorGroup: AdminSensorGroupRequestPops) => {
  const result = await axios.put<boolean>(`${baseApiUrl}api/AdminSensorGroups/${sensorGroupId}`, sensorGroup);

  return result?.data;
};

export const deleteAdminSensorGroups = async (sensorGroupIds: number[]) => {
  const result = await axios.delete<boolean>(`${baseApiUrl}api/AdminSensorGroups`, { data: sensorGroupIds });
  return result.data;
};
