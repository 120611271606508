export const validateId = (id: number | undefined): number | undefined => {
  if (id && id > 0) {
    return id;
  }
  return undefined;
};

export const createTemporaryId = (): number => {
  return -Math.random().toString().slice(2, 11) as number;
};
