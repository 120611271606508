import axios from 'axios';
import { TelemetryConstants } from '../constants/telemetry.constants';
import { MachineTrackHistoryModel } from '../models/data/machine/machineTrackHistory.model';
import { MachineTrackHistoryBindingModel } from '../models/data/machine/machineTrackHistoryBinding.model';
import { TelemetryBucketsRequestModel } from '../models/data/telemetry/telemetryBucketsRequest.model';
import { TelemetryEventsModel } from '../models/data/telemetry/telemetryEvents.model';
import { MachineStateType } from '../models/enum/machineStateType.enum';
import { converNegativeDegreesToPositive } from '../utils/map.utility';
import { MachineSetupTrackHistoryModel } from '../models/data/machine/machineSetupTrackHistory.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getMachineTrackHistoryData = async (
  machineUuid: string,
  timeRange: number | null,
  dateFrom: Date | null,
  dateTo: Date | null,
  optimizeGeoLocation: boolean,
  isBuckets: boolean
) => {
  let dateFromUTCString;
  let dateToUTCString;
  if (dateFrom && dateTo) {
    dateFromUTCString = new Date(dateFrom.getTime() - dateFrom.getTimezoneOffset() * 60000).toISOString();
    dateToUTCString = new Date(dateTo.getTime() - dateTo.getTimezoneOffset() * 60000).toISOString();
  }

  const machineTrackHistoryBindingModel: MachineTrackHistoryBindingModel = {
    machineUuid: machineUuid,
    optimizeGeoLocation,
    timeRange: timeRange,
    dateFrom: dateFromUTCString,
    dateTo: dateToUTCString,
    isBuckets: isBuckets,
  };

  const result = await axios.post<TelemetryEventsModel[]>(
    `${baseApiUrl}api/telemetry/track-history`,
    machineTrackHistoryBindingModel
  );

  return convertToMachineTrackHistoryModel(result?.data);
};

export const fetchMachineEvents = async (requestParams: TelemetryBucketsRequestModel) => {
  const result = await axios.post<TelemetryEventsModel>(`${baseApiUrl}api/telemetry/buckets`, requestParams);
  return result?.data;
};

const convertToMachineTrackHistoryModel = (
  telemetrySetupEventsModel: TelemetryEventsModel[]
): MachineSetupTrackHistoryModel[] => {
  let machineSetupTrackHistoryModels: MachineSetupTrackHistoryModel[] = [];

  for (const telemetryEventsModel of telemetrySetupEventsModel) {
    let machineTrackHistoryModel: MachineTrackHistoryModel[] = [];

    telemetryEventsModel.timeStamps.forEach((timeStamp, index) => {
      const longitude = telemetryEventsModel.events.find(
        (e) => e.attributeUuid === TelemetryConstants.AttributeUuidLongitude
      )?.values[index];

      const latitude = telemetryEventsModel.events.find(
        (e) => e.attributeUuid === TelemetryConstants.AttributeUuidLatitude
      )?.values[index];

      const altitude = telemetryEventsModel.events.find(
        (e) => e.attributeUuid === TelemetryConstants.AttributeUuidAltitude
      )?.values[index];

      const speed = telemetryEventsModel.events.find((e) => e.attributeUuid === TelemetryConstants.AttributeUuidSpeed)
        ?.values[index];

      const rotateAngle = telemetryEventsModel.events.find(
        (e) => e.attributeUuid === TelemetryConstants.AttributeUuidDirection
      )?.values[index];

      const compassDirection = telemetryEventsModel.events.find(
        (e) => e.attributeUuid === TelemetryConstants.AttributeUuidCompassDirection
      )?.values[index];

      const machineState = telemetryEventsModel.events.find(
        (e) => e.attributeUuid === TelemetryConstants.AttributeUuidMachineState
      )?.values[index];

      const power = telemetryEventsModel.events.find((e) => e.attributeUuid === TelemetryConstants.AttributeUuidPower)
        ?.values[index];

      const engine = telemetryEventsModel.events.find((e) => e.attributeUuid === TelemetryConstants.AttributeUuidEngine)
        ?.values[index];

      const brake = telemetryEventsModel.events.find((e) => e.attributeUuid === TelemetryConstants.AttributeUuidBrake)
        ?.values[index];

      const gear = telemetryEventsModel.events.find((e) => e.attributeUuid === TelemetryConstants.AttributeUuidGear)
        ?.values[index];

      const model: MachineTrackHistoryModel = {
        id: index,
        gpsTime: timeStamp,
        messageTime: timeStamp,
        fromRangeTime: telemetryEventsModel.fromRangeTimeStamps[index],
        longitude: longitude && longitude !== '' ? (180 / Math.PI) * +longitude : 0,
        latitude: latitude && latitude !== '' ? (180 / Math.PI) * +latitude : 0,
        altitude: altitude && altitude !== '' ? +altitude : null,
        rotateAngle: rotateAngle && rotateAngle !== '' ? (180 / Math.PI) * +rotateAngle : null,
        compassDirection:
          compassDirection && compassDirection !== ''
            ? converNegativeDegreesToPositive((180 / Math.PI) * +compassDirection)
            : null,
        speed: speed && speed !== '' ? +speed : null,
        machineState: machineState && machineState !== '' ? getMachineState(machineState) : MachineStateType.None,
        power: power && JSON.parse(power),
        engine: engine && JSON.parse(engine),
        brake: brake && JSON.parse(brake),
        gear: gear && JSON.parse(gear),
      };

      machineTrackHistoryModel.push(model);
    });

    let machineSetupTrackHistoryModel: MachineSetupTrackHistoryModel = {
      setupName: telemetryEventsModel.setupName,
      machineTrackHistoryModel: machineTrackHistoryModel,
    };

    machineSetupTrackHistoryModels.push(machineSetupTrackHistoryModel);
  }
  return machineSetupTrackHistoryModels.sort(
    (a, b) =>
      new Date(b.machineTrackHistoryModel[0]?.gpsTime)?.getTime() -
      new Date(a.machineTrackHistoryModel[0]?.gpsTime)?.getTime()
  );
};

const getMachineState = (machineStateAsString: string): MachineStateType => {
  switch (machineStateAsString.toLowerCase()) {
    case 'false': {
      return MachineStateType.Off;
    }
    case 'true': {
      return MachineStateType.On;
    }
    default: {
      return MachineStateType.None;
    }
  }
};
