export class TelemetryConstants {
  public static readonly FastSlicesOption = 100;
  public static readonly AccurateSlicesOption = 1000;
  public static readonly AttributeUuidLongitude = 'e01e7f7d-0b12-4478-a4e1-55bb8343246e';
  public static readonly AttributeUuidLatitude = '14297435-f3fd-4c68-a01a-c677443c27f2';
  public static readonly AttributeUuidAltitude = 'baf132eb-0df3-483b-b972-19cbf2525cfa';
  public static readonly AttributeUuidLongitudeTwo = 'da7b2d7e-e6b3-436f-88fe-23749c0c900a';
  public static readonly AttributeUuidLatitudeTwo = 'f548e875-1dda-4c59-9ad3-9b445ef11321';
  public static readonly AttributeUuidAltitudeTwo = '6e93e22f-88b2-4424-a1e0-60660eb7c145';
  public static readonly AttributeUuidMachineState = '7da8391c-1c34-46d2-9791-f95018da8906';
  public static readonly AttributeUuidSpeed = '68deaf63-aa36-413d-9dd6-6d4465395f07';
  public static readonly AttributeUuidDirection = '4595bc28-a1f1-4d73-8daa-e86a2a7ea9b5';
  public static readonly AttributeUuidCompassDirection = '967f95ae-e4b7-42a4-9bac-286dc59c4763';
  public static readonly AttributeUuidPower = '6edd1a72-79fa-4bf3-9f0d-877c5d8e0f7a';
  public static readonly AttributeUuidEngine = '0e296b4e-a38a-40d3-b359-f4d4b5b7e50c';
  public static readonly AttributeUuidBrake = '0bc51be7-66fe-4412-87c8-980d889dbb38';
  public static readonly AttributeUuidGear = 'a98e990b-89de-4fd4-91c8-f5ba08379fc2';
  public static readonly AttributeUuidGPSEvents = [
    TelemetryConstants.AttributeUuidLongitude,
    TelemetryConstants.AttributeUuidLatitude,
    TelemetryConstants.AttributeUuidAltitude,
    TelemetryConstants.AttributeUuidLongitudeTwo,
    TelemetryConstants.AttributeUuidLatitudeTwo,
    TelemetryConstants.AttributeUuidAltitudeTwo,
  ];
  public static readonly TrackHistoryAttributeUuids = [
    TelemetryConstants.AttributeUuidLatitude,
    TelemetryConstants.AttributeUuidLongitude,
    TelemetryConstants.AttributeUuidAltitude,
    TelemetryConstants.AttributeUuidLatitudeTwo,
    TelemetryConstants.AttributeUuidLongitudeTwo,
    TelemetryConstants.AttributeUuidAltitudeTwo,
    TelemetryConstants.AttributeUuidPower,
    TelemetryConstants.AttributeUuidEngine,
    TelemetryConstants.AttributeUuidBrake,
    TelemetryConstants.AttributeUuidGear,
    TelemetryConstants.AttributeUuidSpeed,
    TelemetryConstants.AttributeUuidDirection,
    TelemetryConstants.AttributeUuidCompassDirection,
  ];
}
