import axios from 'axios';

import { AvailableMachineModel } from '../models/data/machine/availableMachine.model';
import { UserGroupTableModel } from '../models/data/group/userGroupTable.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getAvailableMachines = async () => {
  const result = await axios.get<AvailableMachineModel[]>(`${baseApiUrl}api/groupMachines`);
  for (const machine of result?.data) {
    machine.isChecked = false;
  }

  return result?.data;
};

export const addMachinesToGroup = async (groupId: string, machineIds: number[]) => {
  const result = await axios.put<boolean>(`${baseApiUrl}api/groupMachines/add-to-group/${groupId}`, machineIds);
  return result?.data;
};

export const addMachineToUserGroups = async (machineId: number, groups: UserGroupTableModel[]) => {
  const result = await axios.put<boolean>(`${baseApiUrl}api/groupMachines/add-to-groups/${machineId}`, groups);
  return result?.data;
};

export const removeMachineFromGroup = async (groupId: string, machineIds: number[]) => {
  const result = await axios.put<boolean>(`${baseApiUrl}api/groupMachines/remove/${groupId}`, machineIds);
  return result?.data;
};
