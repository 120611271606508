import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBCol, MDBRow } from 'mdbreact';

import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import useModalState from '../../../../hooks/useModalState';
import { translate } from '../../../../services/translation.service';
import GenericTable from '../../../../components/genericTable/genericTable';
import useAttributeMappings from '../../../../hooks/useAttributeMappings';
import { uncheckTableGlobalCheckedById } from '../../../../store/slices/tableSlice';
import {
  getAllUserSensorGroups,
  deleteUserSensorGroupById,
  bulkDeleteSensorGroups,
} from '../../../../store/api/userSensorGroups.service';
import {
  toggleSensorGroupChecked,
  toggleGlobalSensorGroupsChecked,
  selectUserSensorGroups,
  selectCheckedUserSensorGroupIds,
  clearSelectedSensorGroup,
} from '../../SensorGroups/sensorGroups.slice';

const ProfileSensorGroups: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setModal } = useModalState();
  const { mapProfileSensorGroupAttributes } = useAttributeMappings();

  const sensorGroups = useAppSelector(selectUserSensorGroups);
  const checkedSensorGroupIds = useAppSelector(selectCheckedUserSensorGroupIds);

  useEffect(() => {
    dispatch(getAllUserSensorGroups());

    return () => {
      dispatch(uncheckTableGlobalCheckedById('profileSensorGroups'));
    };
  }, [dispatch]);

  const handleSensorGroupCheckedToggle = useCallback(
    (sensorGroupId: string | number | undefined) => {
      if (sensorGroupId) {
        dispatch(toggleSensorGroupChecked({ sensorGroupId: Number(sensorGroupId) }));
      }
    },
    [dispatch]
  );

  const handleSensorGroupGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;

      dispatch(toggleGlobalSensorGroupsChecked({ isChecked: !!checked }));
    },
    [dispatch]
  );

  const onUserSensorGroupDelete = useCallback(
    (sensorGroupId: number) => setModal(true, () => dispatch(deleteUserSensorGroupById({ sensorGroupId }))),
    [dispatch, setModal]
  );

  const onBulkDelete = useCallback(() => {
    if (!checkedSensorGroupIds || checkedSensorGroupIds.length === 0) {
      toast.info(translate('pages.profile.noSelectedGroups'));
      return;
    }

    setModal(true, () => {
      dispatch(bulkDeleteSensorGroups({ sensorGroupIds: checkedSensorGroupIds }));
      dispatch(uncheckTableGlobalCheckedById('profileSensorGroups'));
    });
  }, [checkedSensorGroupIds, dispatch, setModal]);

  const onEditSensorGroupClick = (groupId: number | string) => navigate(`/profile/group/${groupId}`);

  const onCreateSensorGroupClick = () => {
    dispatch(clearSelectedSensorGroup({}));
    navigate(`/profile/group`);
  };

  return (
    <MDBRow>
      <MDBCol md='12'>
        <GenericTable
          tableId={'profileSensorGroups'}
          items={mapProfileSensorGroupAttributes(sensorGroups || [])}
          hasSearch={true}
          hasCheckboxInput={true}
          handleItemToggle={handleSensorGroupCheckedToggle}
          onGlobalItemsChecked={handleSensorGroupGlobalCheckedToggle}
          onItemDeleteClick={onUserSensorGroupDelete}
          onBulkDeleteClick={onBulkDelete}
          onItemEditClick={onEditSensorGroupClick}
          onActionButtonClick={onCreateSensorGroupClick}
        />
      </MDBCol>
    </MDBRow>
  );
};

export default ProfileSensorGroups;
