import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../../locales/translation.en.json';
import translationDE from '../../locales/translation.de.json';
import translationFR from '../../locales/translation.fr.json';
import translationIT from '../../locales/translation.it.json';

const resources = {
  en: { translation: translationEN },
  de: { translation: translationDE },
  fr: { translation: translationFR },
  it: { translation: translationIT },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  keySeparator: '.', // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export const changeLanguage = (lang: string) => {
  i18n.changeLanguage(lang);
};

export const translate = (key: string) => {
  return i18n.t(key);
};
