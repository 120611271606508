import React, { FormEvent, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBRow, MDBSelect } from 'mdbreact';

import { translate } from '../../../../services/translation.service';
import { UserProfileProps } from '../../../../models/props/admin/userProfile.props';
import { UserProfileBindingModel } from '../../../../models/data/user/userProfileBinding.model';
import { SelectData } from '../../../../models/types/MDBtypes';

const UserProfile: React.FC<UserProfileProps> = ({ user, onSaveUserProfile }) => {
  const navigate = useNavigate();
  const [roleOptions, setRoleOptions] = useState<any[]>([]);
  const [userData, setUserData] = useState<UserProfileBindingModel | undefined>();

  useEffect(() => {
    if (user?.roles) {
      setRoleOptions(
        user.roles.map((role) => ({
          value: role.id,
          text: role.groupName,
          checked: role.isChecked,
          defaultSelected: role.isChecked,
        }))
      );
    }

    setUserData(user);
  }, [user]);

  const handleChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;

    setUserData((prevState) => ({ ...prevState!, [id]: value }));
  }, []);

  const handleSelectChange = useCallback((chosenRole: any) => {
    setUserData((prevState) => ({
      ...prevState!,
      roles: prevState!.roles.map((role) => ({
        ...role,
        isChecked: chosenRole.filter((obj) => obj.value === role.id).length > 0,
        isDefaultSelected: chosenRole.filter((obj) => obj.value === role.id).length > 0,
      })),
    }));
  }, []);

  const handleSave = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.currentTarget.className += ' was-validated';

      if (!userData || !userData?.company || !userData?.firstName || !userData?.lastName) {
        return;
      }

      onSaveUserProfile(userData as UserProfileBindingModel);
    },
    [onSaveUserProfile, userData]
  );

  const onCancel = () => navigate('/admin/users');

  return (
    <MDBCard>
      <MDBCardBody>
        <form className='needs-validation' onSubmit={handleSave} noValidate>
          <MDBRow className='pb-4'>
            <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
              <MDBIcon icon='user-edit' className='p-4 shadow-3-strong bg-warning rounded' />
              <h5 className='font-weight-bold ml-4'>{translate('pages.admin.userDetails')}</h5>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol md='4' className='mb-3'>
              <MDBInput
                id='firstName'
                className='form-control'
                type='text'
                required
                label={`${translate('common.firstName')}*`}
                value={userData?.firstName || ''}
                onChange={handleChange}
              >
                <div className='invalid-feedback error-message'>{translate('pages.admin.firstNameIsRequired')}</div>
              </MDBInput>
            </MDBCol>

            <MDBCol md='4'>
              <MDBInput
                id='lastName'
                className='form-control'
                type='text'
                required
                label={`${translate('common.lastName')}*`}
                value={userData?.lastName || ''}
                onChange={handleChange}
              >
                <div className='invalid-feedback error-message'>{translate('pages.admin.lastNameIsRequired')}</div>
              </MDBInput>
            </MDBCol>
          </MDBRow>

          <MDBRow className='my-4'>
            <MDBCol md='4' className='mb-3'>
              <MDBInput type='text' disabled label={translate('common.emailAddress')} value={user?.email || ''} />
            </MDBCol>

            <MDBCol md='4'>
              <MDBInput
                id='company'
                className='form-control'
                type='text'
                required
                label={`${translate('common.company')}*`}
                value={userData?.company || ''}
                onChange={handleChange}
              >
                <div className='invalid-feedback error-message'>{translate('pages.admin.companyNameIsRequired')}</div>
              </MDBInput>
            </MDBCol>
          </MDBRow>

          <MDBRow className='mt-4'>
            <MDBCol md='4'>
              <MDBSelect
                className='dropdown-warning'
                multiple
                data={roleOptions}
                label={translate('pages.profile.roles')}
                onValueChange={(e: SelectData[] | SelectData) => handleSelectChange(e)}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow sm='12' md='4'>
            <MDBCol className='d-flex justify-content-end mt-3'>
              <MDBBtn color='warning' className='text-white mr-3' type='submit' data-testid='UserProfile-save-btn'>
                {translate('common.save')}
              </MDBBtn>
              <MDBBtn
                outline
                color='warning'
                className='cancel-onHover'
                onClick={onCancel}
                data-testid='UserProfile-cancel-btn'
              >
                {translate('common.cancel')}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBCardBody>
    </MDBCard>
  );
};

export default UserProfile;
