import React, { memo, useMemo } from 'react';

import { MDBSelect } from 'mdbreact';

import './rowsCount.scss';
import { RowsCountProps } from '../../models/props/components/rowsCount.props';
import { getRowsTranslatedText, pageSizeOptions } from './pageOptions';

const RowsCount: React.FC<RowsCountProps> = memo(({ onItemsClick, itemsPerPage }) => {
  const rowsPerPage = useMemo(() => {
    return pageSizeOptions.map((rows) => {
      return {
        ...rows,
        text: getRowsTranslatedText(rows.value),
        defaultSelected: rows.value === itemsPerPage,
      };
    });
  }, [itemsPerPage]);

  return (
    <MDBSelect
      className='row-count-width float-left hidden-md-down dropdown-warning m-0'
      onValueChange={(e: any) => {
        onItemsClick(e.value);
      }}
      data={rowsPerPage}
    />
  );
});

export default RowsCount;
