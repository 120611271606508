import React, { memo } from 'react';

import { MDBBtn, MDBIcon } from 'mdbreact';

import './tableSearch.scss';
import { translate } from '../../services/translation.service';
import { TableSearchProps } from '../../models/props/components/tableSearch.props';

const TableSearch: React.FC<TableSearchProps> = memo(({ filterText, setFilterText, clearSearch, placeholder }) => {
  return (
    <form className='form-inline' onSubmit={(e) => e.preventDefault()}>
      <div className='form-group md-form py-0 my-0 w-100'>
        <input
          className='form-control float-left w-75 table-search-width'
          type='text'
          placeholder={translate(placeholder || 'common.search')}
          value={filterText}
          onChange={setFilterText}
        />
        <MDBBtn
          data-testid='TableSearch-Cancel-Btn'
          size='sm'
          className='d-inline ml-1 p-2 clear-search-btn'
          onClick={clearSearch}
        >
          <MDBIcon icon='times' />
        </MDBBtn>
      </div>
    </form>
  );
});

export default TableSearch;
