import React from 'react';

import { translate } from '../../services/translation.service';

const EmptyResultset: React.FC<{
  entitiesCount: number;
  children: JSX.Element;
}> = ({ entitiesCount: entities, children }) => {
  return entities > 0 ? (
    <div data-testid='tableBody' className='overflow-auto'>
      {children}
    </div>
  ) : (
    <div className='text-center py-4 my-4' data-testid='EmptyResultset-NoDataAvailable-TableBody'>
      {translate('common.noDataAvailable')}
    </div>
  );
};

export default EmptyResultset;
