import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBModalFooter, MDBRow, MDBSelect } from 'mdbreact';

import './alarmDetail.scss';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { translate } from '../../../../services/translation.service';
import { addAlarmData } from '../../Alarm/alarm.slice';
import { AlarmDetailProps } from '../../../../models/props/admin/alarmDetail.props';
import useAttributeMappings from '../../../../hooks/useAttributeMappings';
import { setDisplayInfoMessage } from '../../Machines/machines.slice';
import { SelectData } from '../../../../models/types/MDBtypes';

const AlarmDetail: React.FC<AlarmDetailProps> = ({ alarm, machineId, isEditMode, attributes, onSubmitAlarm }) => {
  const navigate = useNavigate();
  const [isSelectEmpty, setIsSelectEmpty] = useState(false);
  const [preferredAttribute, setPreferredAttribute] = useState<string>('0');
  const [selectedAttribute, setSelectedAttribute] = useState<any>([]);

  const dispatch = useAppDispatch();
  const { mapMachineConfigurationAlarmDropdownAttributes } = useAttributeMappings();

  useEffect(() => {
    const alreadyChecked = attributes.find((x) => x.checked === true && x.value !== '0');

    attributes.map((x) => (x.checked ? (x.defaultSelected = true) : (x.defaultSelected = false)));

    if (alreadyChecked) {
      setSelectedAttribute((prevState) => {
        const newState = prevState.map((obj) => {
          if (obj.checked) {
            return { ...obj, defaultSelected: true };
          }
          return { ...obj, defaultSelected: false };
        });
        return newState;
      });
    }

    attributes.unshift({
      uuid: '0',
      text: translate('common.selectAttribute'),
      value: '0',
      checked: alreadyChecked ? false : true,
      defaultSelected: alreadyChecked ? false : true,
      disabled: true,
    });
    setSelectedAttribute(mapMachineConfigurationAlarmDropdownAttributes(attributes));
  }, [attributes]); //eslint-disable-line

  useEffect(() => {
    const defaultAttribute = selectedAttribute.find((x) => x.value === preferredAttribute);
    const alreadyChecked = selectedAttribute.find((x) => x.checked === true && x.value !== '0');

    if (alreadyChecked) {
      setSelectedAttribute((prevState) => {
        const newState = prevState.map((obj) => {
          if (obj.checked) {
            return { ...obj, defaultSelected: true };
          }
          return { ...obj, defaultSelected: false };
        });
        return newState;
      });
    } else if (defaultAttribute) {
      setSelectedAttribute((prevState) => {
        const newState = prevState.map((obj) => {
          if (obj.value === preferredAttribute) {
            return { ...obj, defaultSelected: true };
          }
          return { ...obj, defaultSelected: false };
        });
        return newState;
      });
    }
  }, [preferredAttribute]); //eslint-disable-line

  const handleChange = (event: any) => {
    const value = event.target.value;
    const { id } = event.currentTarget;

    dispatch(addAlarmData({ id, value }));
  };

  const handleAttributeChange = (e: any) => {
    const value = e.value;

    if (value !== alarm?.attribute) {
      setPreferredAttribute(value);
      dispatch(addAlarmData({ id: 'attribute', value }));
    }
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.currentTarget.className += ' was-validated';
    dispatch(setDisplayInfoMessage(true));

    if (alarm?.attribute && alarm?.message) {
      onSubmitAlarm();
      return;
    }

    setIsSelectEmpty(!alarm?.attribute);
  };

  const backToDetailsPage = useCallback(() => {
    dispatch(setDisplayInfoMessage(true));
    navigate(`/admin/machine/${machineId}`);
  }, [machineId, navigate, dispatch]);

  return (
    <>
      <MDBCard>
        <MDBCardBody>
          <form className='needs-validation' onSubmit={submitForm} noValidate>
            <MDBRow className='pb-4'>
              <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
                <MDBIcon color='dark' icon='exclamation-triangle' className='p-4 shadow-3-strong bg-warning rounded' />
                <h5 className='font-weight-bold ml-4'>
                  {isEditMode ? translate('common.edit') : translate('common.create')} {translate('common.alarm')}
                </h5>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md='6' xl='3'>
                <MDBSelect
                  id='attribute'
                  search
                  searchLabel={translate('common.search')}
                  //options={mapMachineConfigurationAlarmDropdownAttributes(attributes)}
                  value={alarm?.attribute}
                  //selected={translate('common.chooseAlarmAttribute')}
                  data={selectedAttribute}
                  label={`${translate('common.attribute')}*`}
                  onValueChange={(e: SelectData | SelectData[]) => handleAttributeChange(e)}
                  required
                />
                {isSelectEmpty && <div className='error-message'>{translate('pages.admin.attributeIsRequired')}</div>}
              </MDBCol>

              <MDBCol md='6' xl='4'>
                <MDBInput
                  id='message'
                  className='form-control'
                  type='textarea'
                  label={`${translate('common.message')}*`}
                  value={alarm?.message}
                  onChange={handleChange}
                  required
                >
                  <div className='invalid-feedback error-message'>{translate('pages.admin.messageIsRequired')}</div>
                </MDBInput>
              </MDBCol>

              <MDBCol className='d-flex justify-content-end' xl='5'>
                <div className='d-flex align-items-start period-filter-message-panel'>
                  <MDBIcon icon='info-circle' color='warning' className='pl-2 pr-2 amber-text' size='2x' />
                  <div className='period-filter-message'>{translate('pages.admin.alarmNotificationMessage')}</div>
                </div>
              </MDBCol>
            </MDBRow>

            <MDBModalFooter>
              <MDBBtn color='warning' className='text-white' type='submit'>
                {translate('common.ok')}
              </MDBBtn>
              <MDBBtn outline color='warning' className='cancel-onHover' onClick={backToDetailsPage}>
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

export default AlarmDetail;
