import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBModalFooter } from 'mdbreact';

import { toast } from 'react-toastify';
import GenericTable from '../../../components/genericTable/genericTable';
import { translate } from '../../../services/translation.service';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { getSensorAvailableMachines } from '../../../store/api/adminSensorGroups.service';
import { updateTableGlobalCheckedById } from '../../../store/slices/tableSlice';
import {
  addMachines,
  selectAvailableMachines,
  setDisplayInfoMessage,
  toggleAvailableGlobalMachinesChecked,
  toggleAvailableMachineChecked,
} from './adminSensorGroups.slice';

const AddMachineAdminSensorGroup: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const machines = useAppSelector(selectAvailableMachines);

  useEffect(() => {
    dispatch(getSensorAvailableMachines());

    return () => {
      dispatch(
        updateTableGlobalCheckedById({
          id: 'profileGroupAddMachines',
          globalChecked: false,
        })
      );
    };
  }, [dispatch]);

  const handleMachineCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) dispatch!(toggleAvailableMachineChecked(id));
    },
    [dispatch]
  );

  const handleMachineGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;
      dispatch(toggleAvailableGlobalMachinesChecked({ isChecked: !!checked }));
    },
    [dispatch]
  );

  const { groupId: groupIdParam } = useParams<{ groupId: string }>();

  const groupId = useMemo(() => +groupIdParam!, [groupIdParam]);

  const addSelectedMachines = () => {
    const selectedMachines = machines.filter((m) => m.isChecked);
    if (selectedMachines.length === 0) {
      toast.info(translate('pages.admin.noSelectedMachine'));
      return;
    }

    dispatch(setDisplayInfoMessage(true));
    dispatch(addMachines({ selectedMachines: selectedMachines.map((a) => ({ ...a, isChecked: false })) }));
    navigate(`/admin/sensor-group/${groupId}`);
  };

  const onCancel = () => {
    dispatch(setDisplayInfoMessage(true));
    navigate(`/admin/sensor-group/${groupId}`);
  };

  return (
    <>
      <MDBRow className='mb-4'>
        <MDBCol md='12' className='px-4'>
          <MDBCard>
            <MDBCardBody>
              <MDBRow className='pb-4'>
                <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
                  <MDBIcon icon='file-signature' color='dark' className='p-4 shadow-3-strong bg-warning rounded' />
                  <h5 className='font-weight-bold ml-4'>{translate('pages.admin.addMachines')}</h5>
                </MDBCol>
              </MDBRow>

              <GenericTable
                tableId={'profileGroupAddMachines'}
                items={machines || []}
                hasSearch={true}
                hasCheckboxInput={true}
                hasItemsPerPageControl={true}
                handleItemToggle={handleMachineCheckedToggle}
                onGlobalItemsChecked={handleMachineGlobalCheckedToggle}
              />
            </MDBCardBody>

            <MDBModalFooter>
              <MDBBtn color='warning' className='text-white' onClick={addSelectedMachines} data-testid="AddMachineAdminGroup-addSelect-btn">
                {translate('common.addSelected')}
              </MDBBtn>
              <MDBBtn outline color='warning' className='cancel-onHover' onClick={onCancel} data-testid="AddMachineAdminGroup-cancelSelect-btn">
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default AddMachineAdminSensorGroup;
