import { UserGroupModel } from '../models/data/group/userGroup.model';
import { MachineModel } from '../models/data/machine/machine.model';
import { AdminGroupModel } from '../models/data/group/adminGroup.model';

export const enrichGroupMachines = (
  groups: any[],
  stateGroup: UserGroupModel[] | AdminGroupModel[],
  machineIds: number[]
) =>
  groups.map((group) => ({
    ...group,
    isChecked: stateGroup.some((g) => g.id === group.id && g.isChecked) || (stateGroup.length === 0 && group?.isLocked),
    machines: group.machines.map((machine) => ({
      ...machine,
      isAvailable: machineIds.includes(machine.id),
    })),
  }));

export const getVisibleMachines = (
  stateMachines: MachineModel[],
  machines: MachineModel[],
  userGroups: UserGroupModel[]
): { allMachines: MachineModel[] } => {
  if (stateMachines.length === 0) {
    const favoritesGroup = userGroups
      .filter((group) => group?.isLocked)
      .map((g) => g.machines)
      .reduce((x, y) => x.concat(y), [])
      .map((m) => m.id);

    const allMachines = machines.map((machine) =>
      favoritesGroup.includes(machine.id) ? { ...machine, isOnMap: true } : machine
    );

    return { allMachines };
  }

  const allMachines = machines.map((machine) => ({
    ...machine,
    isOnMap: stateMachines.some((m) => m.id === machine.id && m.isOnMap),
    isChecked: stateMachines.some((m) => m.id === machine.id && m.isChecked),
  }));

  return { allMachines };
};
