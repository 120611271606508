import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBModalFooter } from 'mdbreact';

import { toast } from 'react-toastify';
import ProtectedRoute from '../../hocs/ProtectedRoute';
import { AuthConstants } from '../../constants/auth.constants';
import { translate } from '../../services/translation.service';
import GenericTable from '../../components/genericTable/genericTable';
import { getAvailableMachines } from '../../store/api/contract.service';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { updateTableGlobalCheckedById } from '../../store/slices/tableSlice';
import {
  selectFormattedAvailableContractMachines,
  selectCheckedAvailableContractMachine,
  addItemsByFieldId,
  updateItemChecked,
  updateGlobalItemsChecked,
} from '../admin/Contracts/contracts.slice';

const AddContractMachine: React.FC = () => {
  const navigate = useNavigate();
  const { contractId } = useParams<{ contractId: string }>();

  const dispatch = useAppDispatch();

  const machines = useAppSelector(selectFormattedAvailableContractMachines);
  const checkedMachines = useAppSelector(selectCheckedAvailableContractMachine);

  useEffect(() => {
    dispatch(getAvailableMachines({ contractId: +contractId! }));

    return () => {
      dispatch(
        updateTableGlobalCheckedById({
          id: 'addContractMachinesTable',
          globalChecked: false,
        })
      );
    };
  }, [contractId, dispatch]);

  const backToDetailsPage = useCallback(() => navigate(`/admin/contract/${contractId}`), [contractId, navigate]);

  const addSelectedMachines = useCallback(() => {
    if (checkedMachines.length === 0) {
      toast.info(translate('pages.admin.noSelectedMachine'));
      return;
    }

    dispatch(addItemsByFieldId({ fieldId: 'machines', items: checkedMachines }));

    backToDetailsPage();
  }, [backToDetailsPage, checkedMachines, dispatch]);

  const handleMachineCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) dispatch!(updateItemChecked({ fieldId: 'availableMachines', itemId: id }));
    },
    [dispatch]
  );

  const onMachinesGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      dispatch(updateGlobalItemsChecked({ fieldId: 'availableMachines', isChecked: !!event.currentTarget.checked }));
    },
    [dispatch]
  );

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <MDBRow>
            <MDBCol md='12' className='px-4'>
              <MDBCard>
                <MDBCardBody>
                  <MDBRow className='pb-4'>
                    <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
                        <MDBIcon
                          icon='file-signature'
                          className='p-4 shadow-3-strong bg-warning rounded'
                        />
                        <h5 className='font-weight-bold ml-4'>
                          {translate('pages.admin.contractDetail')}
                        </h5>
                    </MDBCol>
                  </MDBRow>

                  <GenericTable
                    tableId={'addContractMachinesTable'}
                    items={machines}
                    handleItemToggle={handleMachineCheckedToggle}
                    onGlobalItemsChecked={onMachinesGlobalCheckedToggle}
                    hasSearch={true}
                    hasItemsPerPageControl={true}
                    hasCheckboxInput={true}
                  />
                </MDBCardBody>

                <MDBModalFooter>
                  <MDBBtn color='warning' className='text-white' onClick={addSelectedMachines}>
                    {translate('common.addSelected')}
                  </MDBBtn>
                  <MDBBtn outline color='warning' onClick={backToDetailsPage}>
                    {translate('common.cancel')}
                  </MDBBtn>
                </MDBModalFooter>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        );
      }}
    />
  );
};

export default AddContractMachine;
