import React, { useEffect, useMemo, useState } from 'react';

import { MDBCol, MDBRow, MDBSelect, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';

import './trackHistoryTable.scss';
import Pagination from '../../../../components/pagination/Pagination';
import TableHeader from '../../../../components/tableHeader/tableHeader';
import DateFormatter from '../../../../components/dateFormatter/DataFormatter';
import EmptyResultset from '../../../../components/emptyResultset/EmptyResultset';
import { SortCriteria } from '../../../../models/enum/sortCriteria.enum';
import { MachineStateType } from '../../../../models/enum/machineStateType.enum';
import { SortStateModel } from '../../../../models/data/components/sortState.model';
import { TrackHistoryTableProps } from '../../../../models/props/geo/trackHistoryTable.props';
import { translate } from '../../../../services/translation.service';
import { genericSort } from '../../../../utils/sort.utility';
import { formatMachineStateAttributes } from '../../../../utils/formatting.utility';
import { timeZoneTransform } from '../../../../transformers/timeZone.transform';
import useUserState from '../../../../hooks/useUserState';
import HeaderSection from '../../../../components/headerSection/HeaderSection';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { GoogleMapConstants } from '../../../../constants/googleMap.constants';

const TrackHistoryTable: React.FC<TrackHistoryTableProps> = ({
  setupNames,
  trackHistory,
  onSelectEventClick,
  onSetupChange,
}) => {
  const itemsPerPage = 10;

  const { userState } = useUserState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageToCount, setPageToCount] = useState<number>(10);
  const [sortCriteria, setSortCriteria] = useState<SortStateModel>({ column: 'time', order: SortCriteria.Descending });
  const { activePosition } = useAppSelector((state) => state.maps[GoogleMapConstants.geoMachineDetailsGoogleMapId]);

  const setups = useMemo(() => {
    return setupNames.map((setup, index) => {
      return {
        text: setup,
        value: index,
      };
    });
  }, [setupNames]);

  useEffect(() => {
    setCurrentPage(1);
    setPageToCount(10);
    setSortCriteria({ column: 'messageTime', order: SortCriteria.Descending });
  }, [trackHistory]);

  const onSortClick = (column: string) => {
    if (column === sortCriteria.column && sortCriteria.order === SortCriteria.Ascending) {
      setSortCriteria({ column: column, order: SortCriteria.Descending });
    } else {
      setSortCriteria({ column: column, order: SortCriteria.Ascending });
    }
  };

  const getMachineStateStyleClass = (state: MachineStateType | string) => {
    switch (state) {
      case translate('pages.geo.on'):
        return 'green-text-color';
      case translate('pages.geo.mostlyOn'):
        return 'green-text-color';
      case translate('pages.geo.off'):
        return 'red-text-color';
      case translate('pages.geo.mostlyOff'):
        return 'red-text-color';
      case translate('common.none'):
        return 'gray-text-color';
    }
  };

  return (
    <>
      <MDBRow>
        <MDBCol sm='12' md='3' xl='3'>
          <MDBSelect
            label={translate('pages.geo.selectSetup')}
            onValueChange={(item: any) => {
              onSetupChange(item.value);
            }}
            data={setups}
          />
        </MDBCol>
        <MDBCol sm='12' md='9' xl='9'>
          {trackHistory.length > 0 && (
            <Pagination
              currentPage={currentPage}
              itemsLength={trackHistory.length}
              itemsPerPage={itemsPerPage}
              pageToCount={pageToCount}
              setCurrentPage={setCurrentPage}
              setPageToCount={setPageToCount}
            ></Pagination>
          )}
        </MDBCol>
      </MDBRow>
      <HeaderSection text={translate('pages.geo.trackHistory')} />
      <EmptyResultset entitiesCount={trackHistory.length}>
        <MDBTable className='rsrg-table'>
          <MDBTableHead>
            <tr>
              <TableHeader
                headerText={translate('common.lastGpsPosition')}
                sortState={sortCriteria}
                propertyName='gpsTime'
                onSortClick={() => onSortClick('gpsTime')}
              ></TableHeader>
              <TableHeader
                headerText={translate('common.machinePower')}
                sortState={sortCriteria}
                propertyName='power'
                onSortClick={() => onSortClick('power')}
              ></TableHeader>
              <TableHeader
                headerText={translate('common.machineEngine')}
                sortState={sortCriteria}
                propertyName='engine'
                onSortClick={() => onSortClick('engine')}
              ></TableHeader>
              <TableHeader
                headerText={translate('common.machineBrake')}
                sortState={sortCriteria}
                propertyName='brake'
                onSortClick={() => onSortClick('brake')}
              ></TableHeader>
              <TableHeader
                headerText={translate('common.machineGear')}
                sortState={sortCriteria}
                propertyName='gear'
                onSortClick={() => onSortClick('gear')}
              ></TableHeader>
              <TableHeader
                headerText={translate('common.speed')}
                sortState={sortCriteria}
                propertyName='speed'
                onSortClick={() => onSortClick('speed')}
              ></TableHeader>
              <TableHeader
                headerText={translate('pages.geo.longitude')}
                sortState={sortCriteria}
                propertyName='longitude'
                onSortClick={() => onSortClick('longitude')}
              ></TableHeader>
              <TableHeader
                headerText={translate('pages.geo.latitude')}
                sortState={sortCriteria}
                propertyName='latitude'
                onSortClick={() => onSortClick('latitude')}
              ></TableHeader>
              <TableHeader
                headerText={translate('pages.geo.altitude')}
                sortState={sortCriteria}
                propertyName='altitude'
                onSortClick={() => onSortClick('altitude')}
              ></TableHeader>
              <TableHeader
                headerText={translate('common.rotateAngle')}
                sortState={sortCriteria}
                propertyName='rotateAngle'
                onSortClick={() => onSortClick('rotateAngle')}
              ></TableHeader>
              <TableHeader
                headerText={translate('pages.geo.compassDirection')}
                sortState={sortCriteria}
                propertyName='compassDirection'
                onSortClick={() => onSortClick('compassDirection')}
              ></TableHeader>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {[...trackHistory]
              .sort((x, y) => genericSort(sortCriteria.order, sortCriteria.column, x, y))
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((ev) => (
                <tr
                  key={`${ev.gpsTime.toString()} ${ev.messageTime.toString()}`}
                  className={'clickable ' + (activePosition === ev ? 'row-selected' : '')}
                  onClick={() => onSelectEventClick(ev)}
                >
                  <td className='track-history-time-column'>
                    <DateFormatter date={ev.gpsTime}></DateFormatter>
                    {ev.fromRangeTime && (
                      <span className='second-timestamp'>
                        {translate('common.since')}{' '}
                        {timeZoneTransform(ev.fromRangeTime, userState.timeZone, userState.timeZoneName)}
                      </span>
                    )}
                  </td>
                  <td className='text-left'>
                    <span
                      className={
                        'px-1 text-white font-weight-bold rounded shadow-box-example shadow-3 ' +
                        getMachineStateStyleClass(formatMachineStateAttributes(ev?.power))
                      }
                    >
                      {formatMachineStateAttributes(ev?.power)}
                    </span>
                  </td>
                  <td className='text-left'>
                    <span
                      className={
                        'px-1 text-white font-weight-bold rounded shadow-box-example shadow-3 ' +
                        getMachineStateStyleClass(formatMachineStateAttributes(ev?.engine))
                      }
                    >
                      {formatMachineStateAttributes(ev?.engine)}
                    </span>
                  </td>
                  <td className='text-left'>
                    <span
                      className={
                        'px-1 text-white font-weight-bold rounded shadow-box-example shadow-3 ' +
                        getMachineStateStyleClass(formatMachineStateAttributes(ev?.brake))
                      }
                    >
                      {formatMachineStateAttributes(ev?.brake)}
                    </span>
                  </td>
                  <td className='text-left'>
                    <span
                      className={
                        'px-1 text-white font-weight-bold rounded shadow-box-example shadow-3 ' +
                        getMachineStateStyleClass(formatMachineStateAttributes(ev?.gear))
                      }
                    >
                      {formatMachineStateAttributes(ev?.gear)}
                    </span>
                  </td>
                  <td className='text-left'>
                    {ev?.speed || ev?.speed === 0 ? ev?.speed?.toFixed(2) + 'km/h' : translate('common.none')}
                  </td>
                  <td className='text-left'>{ev?.longitude?.toFixed(6) + '°' || translate('common.none')}</td>
                  <td className='text-left'>{ev?.latitude?.toFixed(6) + '°' || translate('common.none')}</td>
                  <td className='text-left'>
                    {ev?.altitude ? ev?.altitude?.toFixed(2) + 'm' : translate('common.none')}
                  </td>
                  <td className='text-left'>
                    {!!ev?.rotateAngle || ev?.rotateAngle === 0
                      ? ev?.rotateAngle?.toFixed(2) + '°'
                      : translate('common.none')}
                  </td>
                  <td className='text-left'>
                    {!!ev?.compassDirection || ev?.compassDirection === 0
                      ? ev?.compassDirection?.toFixed(2) + '°'
                      : translate('common.none')}
                  </td>
                </tr>
              ))}
          </MDBTableBody>
        </MDBTable>
      </EmptyResultset>
      {trackHistory.length > 0 && (
        <Pagination
          currentPage={currentPage}
          itemsLength={trackHistory.length}
          itemsPerPage={itemsPerPage}
          pageToCount={pageToCount}
          setCurrentPage={setCurrentPage}
          setPageToCount={setPageToCount}
        ></Pagination>
      )}
    </>
  );
};

export default TrackHistoryTable;
