import React from 'react';

import Chart from '../chart/Chart';
import { ChartContainerProps } from '../../models/props/components/chartContainer.props';

const ChartContainer: React.FC<ChartContainerProps> = ({ handleChartCreated, charts }) => {
  return (
    <div>
      <div>
        {charts &&
          charts.map((chart) => (
            <Chart key={chart?.title?.text} onChartCreated={handleChartCreated} chartOptions={chart} />
          ))}
      </div>
    </div>
  );
};
export default ChartContainer;
