import axios from 'axios';

import { SensorGroupRequestPops } from '../models/props/profile/createSensorGroupRequest.props';
import { ProfileSensorGroupModel, SensorGroupsModel } from '../models/data/machine/sensorGroup.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getUserSensorGroups = async () => {
  const result = await axios.get<ProfileSensorGroupModel[]>(`${baseApiUrl}api/sensorGroups`);

  return result?.data;
};

export const getUserSensorGroupsDetails = async (machineId: number) => {
  const result = await axios.get<SensorGroupsModel[]>(`${baseApiUrl}api/sensorGroups/details/${machineId}`);

  return result?.data;
};

export const getUserSensorGroup = async (sensorGroupId: number) => {
  const result = await axios.get<SensorGroupsModel>(`${baseApiUrl}api/sensorGroups/${sensorGroupId}`);

  return result?.data;
};

export const createUserSensorGroup = async (sensorGroup: SensorGroupRequestPops) => {
  const result = await axios.post<SensorGroupsModel>(`${baseApiUrl}api/sensorGroups`, sensorGroup);
  return result.data;
};

export const updateUserSensorGroup = async (sensorGroupId: number, sensorGroup: SensorGroupRequestPops) => {
  const result = await axios.put<boolean>(`${baseApiUrl}api/sensorGroups/${sensorGroupId}`, sensorGroup);

  return result?.data;
};

export const deleteUserSensorGroups = async (sensorGroupIds: number[]) => {
  const result = await axios.delete<boolean>(`${baseApiUrl}api/sensorGroups`, { data: sensorGroupIds });
  return result.data;
};
