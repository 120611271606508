import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { getContactPersons } from '../../services/contactPersons.service';
import { ContactPersonModel } from '../../models/data/contract/contactPerson.model';

export const getAllContactPersons = createAsyncThunk<{ contactPersons: ContactPersonModel[] }, undefined>(
  'contactPersons/getContactPersons',
  async () => {
    const contactPersons = await trackPromise(getContactPersons());

    return { contactPersons };
  }
);
