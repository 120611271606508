import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { UserStatisticModel } from '../../models/data/user/userStatistic.model';
import { addUserStatistics } from '../../services/userStatistics.service';

export const addUserStatisticHistory = createAsyncThunk<boolean, { statistics: UserStatisticModel[] }>(
  'user/addUserStatisticHistory',
  async ({ statistics }) => {
    if (statistics?.length > 0) {
      const result = await trackPromise(addUserStatistics(statistics));

      return result;
    }
    
    return false;
  }
);
