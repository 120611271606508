import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtn,
  MDBTooltip,
} from 'mdbreact';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

import '../../geo/geoMachineDetails.scss';
import './dataDetails.scss';
import TabsHeader from '../../../components/tabsHeader/TabsHeader';
import TableSearch from '../../../components/tableSearch/TableSearch';
import HeaderSection from '../../../components/headerSection/HeaderSection';
import SensorFilter from '../components/sensorFilter/SensorFilter';
import BackToPage from '../../../components/backToPage/BackToPage';
import { GoogleMapConstants } from '../../../constants/googleMap.constants';
import { OpenRailwayMapConstants } from '../../../constants/openRailwayMap.constants';
import { translate } from '../../../services/translation.service';
import useActiveTabState from '../../../hooks/useActiveTabState';
import MapGoogle from '../../geo/components/mapGoogle/MapGoogle';
import MapOpenRailway from '../../geo/components/openRailwayMap/MapOpenRailway';
import { Chart as HighchartsChart } from 'highcharts';

import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  getMachineSensorGroupsDetails,
  getMachineSensorEvents,
} from '../../../store/api/userSensorGroups.service';
import {
  selectMachineUuid,
  selectSensorGroups,
  selectAdminSensorGroups,
  selectTimeStamps,
  selectAlarms,
  selectAllUniqueSensorUuids,
  selectEventTrackHistory,
  clearMachineSensorGroups,
  toggleCollapseSensorGroup,
  toggleCollapseAdminSensorGroup,
  toggleCollapseSensorGroups,
  toggleCollapseAdminSensorGroups,
  updateSelectedEvent,
  selectEvent,
  removeSelectedEvent,
  selectRefreshChartTooltip,
  updateChartTooltip,
  updateIsFirstRun,
  restoreEventTrackHistory,
  selectVisibleTrackHistory,
  selectSensorUuids,
  selectAdminSensorUuids,
  selectSlicesOptions,
  updateSlicesOptions,
  selectIsFirstRun,
  selectAverageDataOptions,
  updateAverageDataOptions,
} from './dataDetails.slice';
import { filterSensorOptions } from '../../../models/props/data/sensorFilter.props';
import { AdminSensorGroupModel, SensorGroupsModel } from '../../../models/data/machine/sensorGroup.model';
import GenericTable from '../../../components/genericTable/genericTable';
import { getMachineSensorAlarmsByMachineUuuid } from '../../../store/api/alarms.service';
import { toast } from 'react-toastify';
import {
  convertChartSensorRadiansToDegrees,
  removeEmptySensorData,
  roundChartSensorDataValues,
} from '../../../utils/sensors.utility';
import { getBucketGpsEvents } from '../../../store/api/telemetry.service';
import { TelemetryConstants } from '../../../constants/telemetry.constants';
import useAttributeMappings from '../../../hooks/useAttributeMappings';
import { clearMachineFilter, selectMachineFilter, setMachineFilter } from '../../../store/slices/machineFilterSlice';
import { useQueryValue } from '../../../utils/route.utility';
import useUserState from '../../../hooks/useUserState';
import { getUTC, getUtcFromLocalDate, timeZoneTransformToNumber } from '../../../transformers/timeZone.transform';
import { roundSensorValue } from '../../../utils/roundSensorValue.utility';
import { BackToTopBtn } from '../components/backToTop/BackToTopButton';
import { GroupChart } from '../components/dataDetails/GroupChart';
import moment from 'moment';
import {
  removeActivePositionByMapId,
  resetMapState,
  setActivePositionByMapId,
  setPositionsByMapId,
  updateFitBounds,
  updateMapAndActivePositionByMapId,
  updateMapCenterByMapId,
  updateTooltipByMapId,
} from '../../../store/slices/mapsSlice';
import { EventTrackHistoryModel } from '../../../models/data/machine/eventTrackHistory.model';

NoDataToDisplay(Highcharts);

interface filtersState {
  timeRange?: number;
  dateFrom?: string | null;
  dateTo?: string | null;
  getAlarms: boolean;
  isFastSlicesOptions: boolean;
  isAverageDataOptions: boolean;
}

interface filterByUserSelection {
  timeRangeInitialChoise?: number;
  dateFromInitialChoise?: string | null;
  dateToInitialChoise?: string | null;
}

const eventNames = ['mousemove', 'touchmove', 'touchstart'];

const pageMapIds = [
  GoogleMapConstants.dataMachineDetailsGoogleMapId,
  OpenRailwayMapConstants.dataMachineDetailsOpenRailwayMapId,
];

const DataDetails: React.FC = () => {
  const { machineId: machineIdParam } = useParams<{ machineId: string }>();
  const machineId = useMemo(() => +machineIdParam!, [machineIdParam]);
  const containerStyles = useMemo(
    () => ({
      width: '100%',
      height: '378px',
    }),
    []
  );

  const sensorUuids = useAppSelector(selectSensorUuids);
  const adminSensorUuids = useAppSelector(selectAdminSensorUuids);

  const preserveFilterState = useQueryValue('preserveState');
  const { mapAlarmAttributes, mapSensorGroupAttributes } = useAttributeMappings();
  const { timeRange, duration, dateFromString, dateToString } = useAppSelector(selectMachineFilter);
  const [zoomBackPeriod, setZoomBackPeriod] = useState<{ difference: number; middleExtreme: Date }>();
  const [filterText, setFilterText] = useState('');

  const selectedEvent = useAppSelector(selectEvent);
  const refreshChartTooltip = useAppSelector(selectRefreshChartTooltip);
  const isFastSlicesOptions = useAppSelector(selectSlicesOptions);
  const isAverageDataOptions = useAppSelector(selectAverageDataOptions);
  const isFirstRun = useAppSelector(selectIsFirstRun);
  const { tabsState, setActiveTab } = useActiveTabState();
  const { userState } = useUserState();
  const { timeZone, timeZoneName } = userState;
  const { detailsMapTab, sensorGroupsTab } = tabsState;
  const allCharts = useMemo(() => [] as HighchartsChart[], []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const allUniqueSensorUuids = useAppSelector(selectAllUniqueSensorUuids);
  const machineUuid = useAppSelector(selectMachineUuid);
  const sensorGroups = useAppSelector(selectSensorGroups);
  const adminSensorGroups = useAppSelector(selectAdminSensorGroups);
  const timeStamps = useAppSelector(selectTimeStamps);
  const timeStampsTransformed = useMemo(
    () => timeStamps.map((ts) => timeZoneTransformToNumber(ts, timeZone, timeZoneName)),
    [timeStamps, timeZone, timeZoneName]
  );
  const alarms = useAppSelector(selectAlarms);
  const eventTrackHistory = useAppSelector(selectEventTrackHistory);
  const visibleTrackHistory = useAppSelector(selectVisibleTrackHistory);

  const hasSensorUuids = allUniqueSensorUuids.length > 0;

  const [filterOptions, setFilterOptions] = useState<filtersState>({
    timeRange: !preserveFilterState ? 2 : dateFromString && dateToString ? null : timeRange || 2,
    dateFrom: (preserveFilterState && dateFromString) || null,
    dateTo: (preserveFilterState && dateToString) || null,
    getAlarms: true,
    isFastSlicesOptions: isFastSlicesOptions,
    isAverageDataOptions: isAverageDataOptions,
  });

  const [userChoiseOptions, setUserChoiseOptions] = useState<filterByUserSelection>({
    dateFromInitialChoise: (preserveFilterState && dateFromString) || null,
    dateToInitialChoise: (preserveFilterState && dateToString) || null,
    timeRangeInitialChoise: (preserveFilterState && timeRange) || 2,
  });

  const [breakWidth] = useState<number>(991);
  const chartSection = useRef<HTMLDivElement | null>(null);
  const backToTopBtnWrapper = useRef<HTMLDivElement | null>(null);

  const scrollIntoView = () => {
    chartSection.current?.scrollIntoView();
  };

  const clearChartTooltips = useCallback(() => {
    Highcharts.each(Highcharts.charts, (chart) => {
      if (chart && chart.tooltip) {
        removeTooltipFromChart(chart);
      }
    });

    dispatch(removeSelectedEvent({}));
    dispatch(resetMapState({}));
  }, [dispatch]);

  useEffect(() => {
    clearChartTooltips();
  }, [sensorGroupsTab, clearChartTooltips]);

  useEffect(() => {
    if (chartSection.current && backToTopBtnWrapper.current && backToTopBtnWrapper.current !== null) {
      const observer = new IntersectionObserver(([entry]) => {
        backToTopBtnWrapper!.current!.style.display = entry.boundingClientRect.top < 0 ? 'block' : 'none';
      });

      observer.observe(chartSection.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [backToTopBtnWrapper]);

  const getSensorEvents = useCallback(() => {
    if (isFirstRun || !machineUuid) {
      return;
    }

    const currentAttributeUuids = sensorGroupsTab === 1 ? sensorUuids : adminSensorUuids;
    if (currentAttributeUuids && currentAttributeUuids.length > 0) {
      dispatch(
        getMachineSensorEvents({
          machineUuid: machineUuid,
          attributeUuids: currentAttributeUuids,
          timeRange: filterOptions.timeRange as number,
          dateFrom: filterOptions?.dateFrom,
          dateTo: filterOptions?.dateTo,
          mapSensorSetupData: true,
          getAlarms: filterOptions.getAlarms,
          slicesMax: filterOptions.isFastSlicesOptions
            ? TelemetryConstants.FastSlicesOption
            : TelemetryConstants.AccurateSlicesOption,
          includeRangeTelemetryData: filterOptions.isAverageDataOptions === false,
          isAdminSensorGroupTab: sensorGroupsTab === 2,
        })
      );
    }

    clearChartTooltips();
  }, [dispatch, machineUuid, hasSensorUuids, filterOptions, sensorGroupsTab, isFirstRun, clearChartTooltips]); // eslint-disable-line

  const getSensorMachineAlarms = useCallback(() => {
    if (isFirstRun || !machineUuid) {
      return;
    }

    if (!hasSensorUuids) {
      dispatch(
        getMachineSensorAlarmsByMachineUuuid({
          machineUuid,
          timeRange: filterOptions.timeRange as number,
          dateFrom: filterOptions?.dateFrom,
          dateTo: filterOptions?.dateTo,
        })
      );
    }
  }, [dispatch, machineUuid, hasSensorUuids, filterOptions, isFirstRun]); // eslint-disable-line

  const getGpsEvents = useCallback(() => {
    if (isFirstRun || !machineUuid) {
      return;
    }

    dispatch(
      getBucketGpsEvents({
        machineUuid,
        attributeUuids: TelemetryConstants.AttributeUuidGPSEvents,
        timeRange: filterOptions.timeRange as number,
        dateFrom: filterOptions?.dateFrom,
        dateTo: filterOptions?.dateTo,
        mapSensorSetupData: false,
        getEventsCount: false,
        slicesMax: filterOptions.isFastSlicesOptions
          ? TelemetryConstants.FastSlicesOption
          : TelemetryConstants.AccurateSlicesOption,
        includeRangeTelemetryData: false,
      })
    );
  }, [dispatch, machineUuid, filterOptions, sensorGroupsTab, isFirstRun]); // eslint-disable-line

  useEffect(() => {
    if (!preserveFilterState) {
      setFilterOptions((prevState) => {
        return {
          ...prevState,
          timeRange: !preserveFilterState ? 2 : dateFromString && dateToString ? null : timeRange || 2,
          dateFrom: (preserveFilterState && dateFromString) || null,
          dateTo: (preserveFilterState && dateToString) || null,
          getAlarms: true,
        };
      });
      dispatch(clearMachineFilter({}));
    }

    dispatch(resetMapState({}));
  }, [machineId, dispatch]); // eslint-disable-line

  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  useEffect(() => {
    if (eventTrackHistory.length === 0 && !isFirstRun) {
      toast.warn(translate('pages.geo.noTrackHistory'), { autoClose: false });
    }

    return () => toast.dismiss();
  }, [eventTrackHistory]); // eslint-disable-line

  useEffect(() => {
    async function fetchData() {
      const resultAction = await dispatch(getMachineSensorGroupsDetails(machineId));

      if (isFirstRun && resultAction.payload) {
        const { adminSensorGroups, sensorGroups } = resultAction.payload as any;

        if (sensorGroups && adminSensorGroups) {
          const hasSensorGroupSensors = sensorGroups?.some((group) =>
            group.sensorGroupCharts.some((chart) => chart.sensors && chart.sensors.length > 0)
          );

          const hasSensorsAdminSensorGroup = adminSensorGroups?.some((group) =>
            group.sensorGroupCharts.some((chart) => chart.sensors && chart.sensors.length > 0)
          );

          if (
            (!sensorGroups.length && adminSensorGroups.length) ||
            (!sensorGroups.length && !adminSensorGroups.length)
          ) {
            setActiveTab('sensorGroupsTab', 2);
          } else if (sensorGroups.length && !hasSensorGroupSensors) {
            setActiveTab('sensorGroupsTab', 2);
          } else if (adminSensorGroups.length && !hasSensorsAdminSensorGroup) {
            setActiveTab('sensorGroupsTab', 1);
          } else {
            setActiveTab('sensorGroupsTab', 1);
          }
        }

        dispatch(updateIsFirstRun(false));
      }
    }

    if (isFirstRun) {
      fetchData();
    }

    return () => {
      dispatch(clearMachineSensorGroups({}));
      dispatch(removeSelectedEvent({}));
    };
  }, [dispatch, machineId]); // eslint-disable-line

  useEffect(() => {
    getSensorEvents();
  }, [getSensorEvents]);

  useEffect(() => {
    getSensorMachineAlarms();
  }, [getSensorMachineAlarms]);

  useEffect(() => {
    getGpsEvents();
  }, [getGpsEvents]);

  const handleMouseEvent = useCallback(
    (e: MouseEvent | PointerEvent | TouchEvent | any) => {
      const currentChart = allCharts.find((x) => x.container?.id === e?.currentTarget?.id);
      const timestamp = currentChart?.hoverPoint?.category;
      if (allCharts && allCharts.length > 1) {
        for (const chart of allCharts) {
          const hasDataForTimestamp =
            chart?.series &&
            chart.series.every((series) => series.visible === true) &&
            chart?.series.map((x) => x.points?.find((s) => s.category === timestamp)).some((v) => v?.y != null);
          if (hasDataForTimestamp) {
            const serie = chart?.series?.find((s) => s.points?.length > 0);
            const point = serie?.points?.find((s) => s.category === timestamp);

            if (point && point?.onMouseOver) {
              point?.onMouseOver();
            }
          }
        }
      }
    },
    [allCharts]
  );

  const handleChartCreated = useCallback(
    (chart: HighchartsChart) => {
      allCharts.push(chart);

      eventNames.forEach((eventType) => {
        chart.container.addEventListener(
          eventType as keyof HTMLElementEventMap,
          handleMouseEvent as EventListenerOrEventListenerObject
        );
      });
    },
    [allCharts, handleMouseEvent]
  );

  useEffect(() => {
    if (selectedEvent && refreshChartTooltip) {
      const timeStamp = timeZoneTransformToNumber(
        new Date(selectedEvent.messageTime),
        userState.timeZone,
        userState.timeZoneName
      );

      Highcharts.each(Highcharts.charts, (chart) => {
        if (chart && chart.tooltip) {
          removeTooltipFromChart(chart);
          if (chart.series) {
            let points: any[] = [];
            for (let i = 0; i < chart.series.length; i++) {
              if (chart.series[i]) {
                const serie = chart.series[i];
                const point = serie.points.find((s) => s.category === timeStamp);
                if (point) {
                  points.push(point);
                }
              }
            }
            if (points && points.length > 0) {
              chart?.tooltip?.refresh([...points]);
              chart?.update({ tooltip: addTooltipOnChart(chart, timeStamp) });
            }
          }
        }
      });
    }
  }, [selectedEvent]); // eslint-disable-line

  const addTooltipOnChart = (chart, timeStamp) => {
    const plotOption = {
      id: chart.container.id + '-plotLine',
      color: '#ff6f00',
      dashStyle: 'LongDash',
      width: 2,
      value: timeStamp,
      zIndex: 0,
    };
    chart?.xAxis[0]?.addPlotLine(plotOption);
    const cloneToolTip = chart?.tooltip?.label?.element?.cloneNode(true);
    if (cloneToolTip) {
      cloneToolTip.id = chart?.container?.id + '-tooltip';
      chart?.container?.firstChild.appendChild(cloneToolTip);
    }
  };

  const removeTooltipFromChart = (chart) => {
    const chartId = chart.container.id;
    let existingTooltip = document.getElementById(chartId + '-tooltip');
    if (existingTooltip) {
      chart.container.firstChild.removeChild(existingTooltip);
    }
    chart.xAxis[0].removePlotLine(chartId + '-plotLine');
  };

  useEffect(() => {
    const machine = (visibleTrackHistory as EventTrackHistoryModel[]).find(
      (m) => m?.id === (selectedEvent as EventTrackHistoryModel)?.id
    );

    if (machine) {
      pageMapIds.forEach((id) => {
        dispatch(setActivePositionByMapId({ id, activePosition: machine }));
        dispatch(updateMapCenterByMapId({ id, center: { lat: machine.latitude, lng: machine.longitude } }));
      });
    }

    pageMapIds.forEach((id) => {
      dispatch(updateFitBounds({ id, preventFitBounds: false }));
      dispatch(
        setPositionsByMapId({
          id,
          positions: visibleTrackHistory,
        })
      );
    });
  }, [dispatch, selectedEvent, visibleTrackHistory]);

  const syncTooltips = useCallback(
    (isTooltipOpen, machine?) => {
      pageMapIds.forEach((id) => {
        dispatch(
          updateTooltipByMapId({
            id,
            isTooltipOpen,
          })
        );
      });

      if (machine) {
        dispatch(updateChartTooltip(true));
        dispatch(updateSelectedEvent(machine?.messageTime));
      }
    },
    [dispatch]
  );

  const onEventClick = useCallback(
    (e) => {
      const timeStampUTC = getUTC(new Date(e.point.category), timeZone, timeZoneName);
      const timeStampUTCFormat = timeStampUTC.toISOString().split('.')[0] + 'Z';
      if (selectedEvent && selectedEvent.messageTime === timeStampUTCFormat) {
        return;
      }

      const timeStampAsNumber = timeZoneTransformToNumber(new Date(timeStampUTCFormat), timeZone, timeZoneName);
      dispatch(updateChartTooltip(false));

      const selectedEventTrackHistory = eventTrackHistory.find((e) => e.messageTime === timeStampUTCFormat);
      if (selectedEventTrackHistory) {
        dispatch(updateSelectedEvent(timeStampUTCFormat));
        syncTooltips(true);
      } else {
        dispatch(removeSelectedEvent({}));
        toast.warn(translate('pages.geo.noTrackHistory'));
      }

      Highcharts.each(Highcharts.charts, (chart) => {
        if (chart && chart.tooltip && chart.tooltip.label) {
          removeTooltipFromChart(chart);
          addTooltipOnChart(chart, timeStampAsNumber);
        }
      });
    },
    [dispatch, syncTooltips, selectedEvent, eventTrackHistory, timeZone, timeZoneName]
  );

  const groupByKey = (list, key) =>
    list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

  const getYAxis = (data: any[], unit: string): number => {
    return data.map((d) => d[0]).findIndex((d) => d.unit === unit);
  };

  const mapChartSensorToSetup = (chart: any): any => {
    for (const sensor of chart.sensors) {
      if (sensor.setupName) {
        sensor.name += `-${sensor.setupName}`;
        sensor.id = Math.max(...chart.sensors.map((s) => +s.id)) + 1;
      }
    }

    return chart;
  };

  const handleLegendItemClick = useCallback(
    (event) => {
      event.preventDefault();
      const clickedSeries = event.target;
      clickedSeries.setVisible(!clickedSeries.visible);

      const chart = event.target.chart;

      const allDeselected = chart.series.every((series) => !series.visible);

      if (allDeselected) {
        allCharts.forEach((chart) => {
          if (chart !== undefined && chart.tooltip) {
            chart.tooltip.hide();
            chart.xAxis.map((x) => x.hideCrosshair());
            clearChartTooltips();
          }
        });
      }
    },
    [allCharts, clearChartTooltips]
  );

  const calculateDateDiffDays = (fromDate: Date, toDate: Date) => {
    const dateDiff = toDate.getTime() - fromDate.getTime();
    return dateDiff / (1000 * 3600 * 24);
  };

  const createChart = useCallback(
    (chart) => {
      chart = mapChartSensorToSetup(chart);

      const groupedDataByAttributeUnit = Object.values(groupByKey(chart.sensors, 'unit')) as Array<any>;

      function syncExtremes(e) {
        e.preventDefault();
        const thisChart = chart;

        if (e.trigger !== 'syncExtremes') {
          Highcharts.each(Highcharts.charts, (chart) => {
            if (chart !== thisChart) {
              if (chart?.xAxis[0].setExtremes) {
                chart?.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                  trigger: 'syncExtremes',
                });
              }
            }
          });
        }

        if (e.trigger === 'zoom') {
          Highcharts.each(Highcharts.charts, (chart) => {
            if (chart && chart.tooltip) {
              removeTooltipFromChart(chart);
            }
          });

          if (e.min !== undefined && e.max !== undefined) {
            const minDate = getUTC(new Date(e.min), timeZone, timeZoneName);
            const maxDate = getUTC(new Date(e.max), timeZone, timeZoneName);
            const dateFromString = minDate.toISOString();
            const dateToString = maxDate.toISOString();
            const diff = calculateDateDiffDays(minDate, maxDate);
            const middleDateToString = new Date((maxDate.getTime() + minDate.getTime()) / 2);
            setZoomBackPeriod({ difference: diff, middleExtreme: middleDateToString });

            setFilterOptions((prevState) => {
              return {
                ...prevState,
                timeRange: undefined,
                dateFrom: dateFromString,
                dateTo: dateToString,
                getAlarms: false,
              };
            });

            setZoomBackPeriod((prevState) => {
              return { ...prevState, difference: diff, middleExtreme: middleDateToString };
            });
          } else {
            dispatch(restoreEventTrackHistory({}));
          }
        }
      }

      return {
        accessibility: {
          enabled: false,
        },
        chart: {
          resetZoomButton: {
            theme: {
              style: {
                display: 'none',
              },
            },
          },
          alignTicks: true,
          zoomType: 'x', //Comment: HAVE TO CHECK FOR CAST
          valueDecimals: 1,
        },
        title: {
          text: chart.name,
          align: 'left',
          margin: 0,
          x: 30,
          style: {
            fontSize: '24px',
          },
        },
        xAxis: {
          min: timeStampsTransformed[0],
          max: timeStampsTransformed[timeStampsTransformed.length - 1],
          type: 'datetime',
          visibility: true,
          tickInterval: 1,
          minRange: 30,
          crosshair: {
            width: 2,
          },
          events: {
            setExtremes: (e) => {
              syncExtremes(e);
            },
          },
        },
        yAxis: groupedDataByAttributeUnit.map((data, index) => ({
          labels: {
            format: `{value} ${data[0].unit}`,
          },
          title: {
            text: data.map((d) => d.name).join(', '),
          },
          opposite: index % 2,
        })),
        exporting: {
          scale: 1,
          sourceWidth: 2560,
          sourceHeight: 640,
        },
        tooltip: {
          cursor: 'pointer',
          formatter: function () {
            let that: any = this;
            const utcDate = getUtcFromLocalDate(new Date(that.x));
            const secondsMillisecondsFormat = 'ss.SSS';
            const secondsMilliseconds = moment(utcDate.getTime()).format(secondsMillisecondsFormat);
            const tooltipDate = `${moment(utcDate).format('LLLL')}:${secondsMilliseconds}`;
            return that.points
              .filter((d) => d.y !== null && d.y !== undefined)
              .filter((d) => !d.series?.name?.toString().startsWith('Range '))
              .reduce((pd, d) => {
                const options = d.series.userOptions;
                const rangeAsString =
                  options.minValues && options.maxValues
                    ? ` (${options.minValues[d.point.index]} - ${options.maxValues[d.point.index]})`
                    : '';
                return `${pd} </br> <span style="color: ${d.color}; font-size: 15px;">&#9679;</span> ${d.series.name}: <b> ${d.y} ${options.unit} ${rangeAsString} </b>`;
              }, `${tooltipDate}`);
          },
          shared: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          area: {
            fillOpacity: 0.5,
          },
          spline: {
            turboThreshold: 100000,
          },
          series: {
            animation: {
              duration: 1200,
              defer: 1200,
            },
            connectNulls: true,
            cursor: 'pointer',
            turboThreshold: 100000,
            point: {
              events: {
                click: onEventClick,
              },
            },
            events: {
              legendItemClick: handleLegendItemClick,
            },
          },
        },
        series: chart.sensors
          .map((s, index) => [
            {
              name: s.name,
              unit: s.unit,
              minValues: s.range?.length === s.data?.length && s.range.map((d) => d[0]),
              maxValues: s.range?.length === s.data?.length && s.range.map((d) => d[1]),
              data:
                s.data && s.data.length
                  ? s.data.map((d, i) => ({
                      x: timeStampsTransformed[i],
                      y: d,
                    }))
                  : [],
              type: 'line',
              cursor: 'pointer',
              yAxis: getYAxis(groupedDataByAttributeUnit, s.unit),
              zIndex: 1,
              marker: {
                lineWidth: 2,
                lineColor: Highcharts.getOptions().colors![index],
              },
            },
            {
              name: `Range ${s.name}`,
              data:
                s.range?.length === s.data?.length
                  ? s.range.map((d, i) => ({
                      x: timeStampsTransformed[i],
                      low: d[0],
                      high: d[1],
                    }))
                  : [],
              enableMouseTracking: false,
              yAxis: getYAxis(groupedDataByAttributeUnit, s.unit),
              type: 'arearange',
              linkedTo: ':previous',
              lineWidth: 0,
              fillOpacity: 0.3,
              zIndex: 0,
              color: Highcharts.getOptions().colors![index],
              marker: {
                enabled: false,
              },
            },
          ])
          .flatMap((s) => JSON.parse(JSON.stringify([...s]))),
        lang: {
          noData: translate('common.noDataAvailableForPeriod'),
          viewFullscreen: translate('common.viewFullscreen'),
          printChart: translate('common.printChart'),
          downloadPNG: translate('common.downloadPNG'),
          downloadJPEG: translate('common.downloadJPEG'),
          downloadPDF: translate('common.downloadPDF'),
          downloadSVG: translate('common.downloadSVG'),
          downloadCSV: translate('common.downloadCSV'),
          downloadXLS: translate('common.downloadXLS'),
          viewData: translate('common.viewData'),
        },
        noData: {
          style: {
            fontSize: '15px',
            color: '#000',
          },
        },
      };
    },
    [timeStampsTransformed, timeZone, timeZoneName, handleLegendItemClick, onEventClick, dispatch]
  );

  const handleSensorOptions = useCallback(
    (groupCharts) =>
      groupCharts
        .filter((c) => c.sensors.length)
        .map((chart) => removeEmptySensorData(chart))
        .map((chart) => convertChartSensorRadiansToDegrees(chart))
        .map((chart) => roundChartSensorDataValues(chart))
        .map((chart) => mapSensorGroupAttributes(chart))
        .map((chart) => createChart(chart)),
    [mapSensorGroupAttributes, createChart]
  );

  const handleFilterOptions = useCallback(
    ({
      timeRange,
      dateFrom,
      dateFromString,
      dateTo,
      dateToString,
      duration,
      isFastSlicesOptions,
      isAverageDataOptions,
    }: filterSensorOptions) => {
      setUserChoiseOptions({
        dateFromInitialChoise: dateFromString,
        dateToInitialChoise: dateToString,
        timeRangeInitialChoise: timeRange,
      });
      dispatch(updateSlicesOptions(isFastSlicesOptions));
      dispatch(updateAverageDataOptions(isAverageDataOptions));
      dispatch(
        setMachineFilter({
          timeRange,
          duration,
          dateFrom,
          dateFromString,
          dateTo,
          dateToString,
          isFastSlicesOptions,
          isAverageDataOptions,
        })
      );
      setFilterOptions({
        timeRange,
        dateFrom: dateFromString,
        dateTo: dateToString,
        getAlarms: true,
        isFastSlicesOptions: isFastSlicesOptions,
        isAverageDataOptions: isAverageDataOptions,
      });
    },
    [dispatch] // eslint-disable-line
  );

  const toggleCollapse = useCallback(
    (event: any, isAdmin: boolean) => {
      const { id, name } = event.currentTarget;

      if (isAdmin) {
        dispatch(toggleCollapseAdminSensorGroup(+id));
      } else {
        dispatch(toggleCollapseSensorGroup(+id));
      }

      if (!name) {
        setTimeout(() => {
          document
            ?.getElementById(`header-${id}`)
            ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }, 250);
      }
    },
    [dispatch]
  );

  const handleFilterText = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const changedSearchText = event.target.value;
    setFilterText(changedSearchText);
  }, []);

  const filterItems = useCallback(
    (item: AdminSensorGroupModel | SensorGroupsModel) => {
      let result;

      if (!filterText) {
        return true;
      }

      const searchText = filterText.toLowerCase();

      const sensors = item['sensorGroupCharts']
        .flatMap((chart) => [...chart.sensors.map((attribute) => attribute.name)])
        .join(', ');

      if (item.name.toLowerCase().includes(searchText) || sensors.toLowerCase().includes(searchText)) {
        return (result = item);
      }

      return result;
    },
    [filterText]
  );

  const handleToggleCollapseAll = (collapse: boolean) => {
    if (sensorGroupsTab === 2) {
      dispatch(toggleCollapseAdminSensorGroups(collapse));
      return;
    }
    dispatch(toggleCollapseSensorGroups(collapse));
  };

  const onClearSearch = useCallback(() => setFilterText(''), []);

  const resetZoom = () => {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        timeRange: userChoiseOptions.timeRangeInitialChoise,
        dateFrom: userChoiseOptions.dateFromInitialChoise,
        dateTo: userChoiseOptions.dateToInitialChoise,
        getAlarms: false,
      };
    });

    dispatch(restoreEventTrackHistory({}));
    dispatch(removeSelectedEvent({}));
    pageMapIds.forEach((id) => {
      dispatch(removeActivePositionByMapId({ id }));
    });
  };

  const zoomOut = () => {
    const middleExtreme = zoomBackPeriod?.middleExtreme;
    let timeBetweenExtremes = zoomBackPeriod?.difference;

    if (!middleExtreme || !timeBetweenExtremes) {
      return;
    }

    let maxDate: Date;
    let minDate: Date;

    if (timeBetweenExtremes < 1) {
      timeBetweenExtremes *= 8.64e7;
      maxDate = moment(new Date(middleExtreme)).add(timeBetweenExtremes, 'milliseconds').toDate();
      minDate = moment(new Date(middleExtreme)).subtract(timeBetweenExtremes, 'milliseconds').toDate();
    } else {
      maxDate = moment(new Date(middleExtreme)).add(timeBetweenExtremes, 'days').toDate();
      minDate = moment(new Date(middleExtreme)).subtract(timeBetweenExtremes, 'days').toDate();
    }

    const dateFromString = minDate.toISOString();
    const dateToString = maxDate.toISOString();

    const diff = calculateDateDiffDays(minDate, maxDate);
    const middleDateToString = new Date((maxDate.getTime() + minDate.getTime()) / 2);

    setZoomBackPeriod({ difference: diff, middleExtreme: middleDateToString });

    setFilterOptions((prevState) => {
      return {
        ...prevState,
        timeRange: undefined,
        dateFrom: dateFromString,
        dateTo: dateToString,
        getAlarms: false,
      };
    });
  };

  const onNavigateToGeoDetails = () => {
    navigate(`/geo/machine/${machineId}?preserveState=true`);
  };

  const syncMaps = useCallback(
    (activePosition) => {
      pageMapIds.forEach((id) => {
        dispatch(setActivePositionByMapId({ id, activePosition }));
      });
    },
    [dispatch]
  );

  const onZoomToPosition = useCallback(() => {
    const zoomSettings = {
      dataMachineDetailsGoogleMap: GoogleMapConstants.zoomedMapDetails,
      dataMachineDetailsOpenRailwayMap: OpenRailwayMapConstants.zoomedMapDetails,
    };

    pageMapIds.forEach((id) => {
      dispatch(
        updateMapAndActivePositionByMapId({
          id: id,
          center: { lat: selectedEvent.latitude, lng: selectedEvent.longitude },
          zoom: zoomSettings[id],
          preventFitBounds: false,
          activePosition: selectedEvent,
        })
      );
    });
  }, [dispatch, selectedEvent]);

  return (
    <MDBContainer fluid>
      <MDBRow className='d-flex justify-content-between'>
        <MDBCol size='auto'>
          <BackToPage text={translate('backToLink.machines')} navigateTo='/data/machines' />
        </MDBCol>
        <MDBCol md='6' xl='3'>
          <MDBBtn id='data-details-btn' className='btn text-white w-100 m-0 mb-2 ' onClick={onNavigateToGeoDetails}>
            <MDBIcon icon='map-marker-alt' className='mr-2 icon-font-size' />
            {translate('backToLink.viewGeoDetails')}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol xl='12'>
          <MDBCard className='mt-3 mb-3'>
            <HeaderSection text={translate('pages.geo.machineHistoryFilter')} />
            <MDBCardBody className='text-center'>
              <SensorFilter
                machineId={machineId}
                duration={duration}
                startDateUtcString={dateFromString}
                endDateUtcString={dateToString}
                timeRange={timeRange || (filterOptions.timeRange as number)}
                isFastSlicesOptions={isFastSlicesOptions}
                isAverageDataOptions={isAverageDataOptions}
                onViewSensorData={handleFilterOptions}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xl='6'>
          <MDBCard className='mb-3'>
            <HeaderSection text={translate('pages.data.sensorAlarms')} />
            <MDBCardBody className='text-center w-100 section-body-long'>
              <GenericTable
                tableId={'sensorTableDetails'}
                items={mapAlarmAttributes(alarms || []).map((a) => ({
                  ...a,
                  value: roundSensorValue(a.attributeUuid, a.value),
                }))}
                hasSearch={true}
                disableTopPagination={true}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xl='6'>
          <MDBCard className='mb-3'>
            <TabsHeader tabId='detailsMapTab' tabNames={['Google Maps', 'Open Railway']} />
            <MDBCardBody className='text-center w-100 section-body-long'>
              {detailsMapTab === 1 && (
                <MapGoogle
                  mapId={GoogleMapConstants.dataMachineDetailsGoogleMapId}
                  hasLastPositionButton={true}
                  containerStyles={containerStyles}
                  zoomToPositionLabel={translate('common.zoomToPoint')}
                  cleanTooltip={clearChartTooltips}
                  syncMaps={syncMaps}
                  syncTooltips={syncTooltips}
                  triggerZoomToPosition={onZoomToPosition}
                ></MapGoogle>
              )}
              {detailsMapTab === 2 && (
                <MapOpenRailway
                  mapId={OpenRailwayMapConstants.dataMachineDetailsOpenRailwayMapId}
                  hasLastPositionButton={true}
                  containerStyles={containerStyles}
                  zoomToPositionLabel={translate('common.zoomToPoint')}
                  cleanTooltip={clearChartTooltips}
                  syncMaps={syncMaps}
                  syncTooltips={syncTooltips}
                  triggerZoomToPosition={onZoomToPosition}
                ></MapOpenRailway>
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <div ref={chartSection} />
      <MDBCard>
        <MDBCardBody>
          <MDBRow xs='12' sm='12' md='12' lg='12' xl='12'>
            <MDBCol xs='12' sm='12' md='3' lg='2' xl='2' className='filter-container-size'>
              <TableSearch
                filterText={filterText}
                setFilterText={handleFilterText}
                clearSearch={onClearSearch}
                placeholder='common.search_sensor'
              />
            </MDBCol>
            <MDBCol xs='12' sm='7' md='6' lg='7' xl='7' className='chart-buttons-container-size'>
              <MDBRow className='chart-buttons-container'>
                <MDBCol size='auto' xs='6' className='p-0 mx-1 mb-2 chart-tooltip-mobile'>
                  <MDBTooltip
                    className='chart-buttons-tooltip tooltip-text'
                    title={translate('pages.data.zoomOut')}
                    tag='span'
                  >
                    <MDBBtn
                      id='zoom-out'
                      noRipple
                      outline
                      color='warning'
                      className='button-collapse-spacing chartBtn-size cancel-onHover'
                      onClick={zoomOut}
                    >
                      <MDBIcon icon='fa fa-search-minus' className='button-To-Icon' />
                      <span className='button-To-Text'>{translate('pages.data.zoomOut')}</span>
                    </MDBBtn>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol size='auto' xs='6' className='p-0 mx-1 mb-2 chart-tooltip-mobile'>
                  <MDBTooltip
                    className='chart-buttons-tooltip tooltip-text'
                    title={translate('pages.data.resetZoom')}
                    tag='span'
                  >
                    <MDBBtn
                      id='reset-zoom'
                      noRipple
                      outline
                      color='warning'
                      className='button-collapse-spacing chartBtn-size cancel-onHover'
                      onClick={resetZoom}
                    >
                      <MDBIcon icon='fa fa-retweet' className='button-To-Icon w-24' />
                      <span className='button-To-Text'>{translate('pages.data.resetZoom')}</span>
                    </MDBBtn>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol size='auto' xs='6' className='p-0 mx-1 mb-2 chart-tooltip-mobile'>
                  <MDBTooltip
                    className='chart-buttons-tooltip tooltip-text'
                    title={translate('pages.data.clearChartSelections')}
                    tag='span'
                  >
                    <MDBBtn
                      id='clear-chart-selection'
                      noRipple
                      outline
                      color='warning'
                      className='button-collapse-spacing chartBtn-size cancel-onHover padding-for-icon'
                      onClick={clearChartTooltips}
                    >
                      <img
                        className='custom-icon'
                        src='/assets/images/line-drawn-stroke-and-fill.svg'
                        alt='Icon for clearing selections'
                      />
                      <span className='button-To-Text'>{translate('pages.data.clearChartSelections')}</span>
                    </MDBBtn>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol size='auto' xs='6' className='p-0 mx-1 mb-2 chart-tooltip-mobile'>
                  <MDBTooltip
                    className='chart-buttons-tooltip tooltip-text'
                    title={translate('common.expandAll')}
                    tag='span'
                  >
                    <MDBBtn
                      id='expand charts'
                      noRipple
                      outline
                      color='warning'
                      className='button-collapse-spacing chartBtn-size cancel-onHover'
                      onClick={() => handleToggleCollapseAll(true)}
                    >
                      <MDBIcon icon='fa fa-angle-double-down' className='button-To-Icon' />
                      <span className='button-To-Text'>{translate('common.expandAll')}</span>
                    </MDBBtn>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol size='auto' xs='6' className='p-0 mx-1 mb-2 chart-tooltip-mobile'>
                  <MDBTooltip
                    placement='top'
                    className='chart-buttons-tooltip tooltip-text'
                    title={translate('common.collapseAll')}
                    tag='span'
                  >
                    <MDBBtn
                      id='collapse charts'
                      noRipple
                      outline
                      color='warning'
                      className='button-collapse-spacing chartBtn-size cancel-onHover'
                      onClick={() => handleToggleCollapseAll(false)}
                    >
                      <MDBIcon icon='fa fa-angle-double-up' className='button-To-Icon' />
                      <span className='button-To-Text'>{translate('common.collapseAll')}</span>
                    </MDBBtn>
                  </MDBTooltip>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol xs='12' sm='5' md='3' lg='3' xl='3' className='sensorGroups-btn'>
              <MDBRow className='chart-buttons-container'>
                <MDBDropdown dropleft>
                  <MDBDropdownToggle
                    color='warning'
                    className='text-white'
                    disabled={
                      (sensorGroupsTab === 1 && sensorGroups?.length === 0) ||
                      (sensorGroupsTab === 2 && adminSensorGroups?.length === 0)
                    }
                    style={{ fontSize: '14px', marginRight: '8px' }}
                  >
                    {translate('pages.data.exploreSensorGroups')}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu
                    responsive='start'
                    color='warning'
                    className='min-w-200 dropdown-sensors dropdown-sensors-groups'
                  >
                    {sensorGroupsTab === 1
                      ? sensorGroups &&
                        sensorGroups.length > 0 &&
                        sensorGroups.map((group) => (
                          <React.Fragment key={`${group.id}-explore`}>
                            <MDBDropdownItem
                              active={group?.isOpen}
                              id={group?.id}
                              name={group?.isOpen ? group?.name : undefined}
                              className={`${group?.isOpen ? 'active dropdown-item-active ' : ''}`}
                              link
                              onClick={(e) => toggleCollapse(e, false)}
                            >
                              {group?.name}
                            </MDBDropdownItem>
                            {group?.sensorGroupCharts &&
                              group?.sensorGroupCharts
                                .map((sensor) => mapSensorGroupAttributes(sensor))
                                .map((sensor) =>
                                  sensor?.sensors?.map((s) => (
                                    <MDBDropdownItem
                                      className='dropdown-inner-item'
                                      header
                                      key={`${s?.id}-explore-sensor ${s?.name}`}
                                    >
                                      {s?.name}
                                    </MDBDropdownItem>
                                  ))
                                )}
                          </React.Fragment>
                        ))
                      : adminSensorGroups &&
                        adminSensorGroups?.length > 0 &&
                        timeStamps &&
                        timeStamps?.length > 0 &&
                        adminSensorGroups?.map((group) => (
                          <React.Fragment key={`${group?.id}-explore-admin`}>
                            <MDBDropdownItem
                              active={group?.isOpen}
                              id={group?.id}
                              name={group?.isOpen ? group?.name : undefined}
                              className={`${group?.isOpen ? 'active ' : ''} dropdown-item `}
                              onClick={(e) => toggleCollapse(e, true)}
                            >
                              {group?.name}
                            </MDBDropdownItem>
                            {group?.sensorGroupCharts &&
                              group?.sensorGroupCharts
                                .map((chart) => mapSensorGroupAttributes(chart))
                                .map((chart) =>
                                  chart?.sensors?.map((s) => (
                                    <MDBDropdownItem header key={`${chart?.id}-explore-chart ${s?.name}`}>
                                      {s?.name}
                                    </MDBDropdownItem>
                                  ))
                                )}
                          </React.Fragment>
                        ))}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow xs='12' sm='12' md='12' lg='12' xl='12'>
            <MDBCol sm='12' md='12' xl='12' xxl='12'>
              <MDBCard>
                <TabsHeader
                  tabId='sensorGroupsTab'
                  tabNames={[translate('common.userSensorGroups'), translate('common.adminSensorGroups')]}
                />
                <GroupChart
                  sensorGroups={sensorGroupsTab === 1 ? sensorGroups : adminSensorGroups}
                  isAdminSensorGroup={sensorGroupsTab === 2}
                  filterItems={filterItems}
                  toggleCollapse={toggleCollapse}
                  handleChartCreated={handleChartCreated}
                  handleSensorOptions={handleSensorOptions}
                ></GroupChart>
                <div ref={backToTopBtnWrapper}>
                  <BackToTopBtn breakWidth={breakWidth} handleClick={scrollIntoView} />
                </div>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
};

export default DataDetails;
