import { createSlice, Slice, createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../store/store';
import { MachineOwnerModel, MachineOwnerResponseModel } from '../../../models/data/machine/machineOwner.model';
import {
  getAllMachineOwners,
  getMachineOwner,
  createMachineOwner,
  updateMachineOwner,
  getMachineOwnerPolling,
  getAllMachineOwnersPolling,
  deleteMachineOwnerById,
  bulkDeleteMachineOwners,
} from '../../../store/api/machineOwners.service';
import { setSelectedItemFieldById } from '../../../store/reducers/reducers';

export interface machineOwnersState {
  machineOwners: MachineOwnerModel[];
  selectedMachineOwner?: MachineOwnerModel | MachineOwnerResponseModel;
}

const initialState: machineOwnersState = {
  machineOwners: [],
  selectedMachineOwner: undefined,
};

export const machineOwnersSlice: Slice = createSlice({
  name: 'machineOwners',
  initialState,
  reducers: {
    toggleMachineOwnerChecked(state, action) {
      state.machineOwners = state.machineOwners.map((machineOwner) => ({
        ...machineOwner,
        isChecked: machineOwner.id.toString() === action.payload && !machineOwner.isChecked,
      }));
    },
    updateMachineOwnerChecked(state, action) {
      const machineOwner = state.machineOwners.find((machineOwner) => machineOwner.id.toString() === action.payload);

      if (machineOwner) {
        machineOwner.isChecked = !machineOwner.isChecked;
      }
    },
    updateGlobalMachineOwnerChecked(state, action) {
      const { isChecked } = action.payload;

      state.machineOwners = state.machineOwners.map((owner) => ({
        ...owner,
        isChecked: owner.isProcessed && isChecked,
      }));
    },
    setSelectedItemField: setSelectedItemFieldById,

    clearSelectedMachineOwner(state, action) {
      state.selectedMachineOwner = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMachineOwners.fulfilled, (state, action) => {
        const { machineOwners } = action.payload;

        state.machineOwners = machineOwners;
      })
      .addCase(getAllMachineOwnersPolling.fulfilled, (state, action) => {
        const { machineOwners } = action.payload;

        state.machineOwners = machineOwners;
      })
      .addCase(getMachineOwner.fulfilled, (state, action) => {
        const { machineOwner } = action.payload;

        state.selectedMachineOwner = machineOwner;
      })
      .addCase(getMachineOwnerPolling.fulfilled, (state, action) => {
        const { machineOwner } = action.payload;

        state.selectedMachineOwner = machineOwner;
      })
      .addCase(createMachineOwner.fulfilled, (state, action) => {
        const { machineOwner } = action.payload;

        state.selectedMachineOwner = machineOwner;
      })
      .addCase(updateMachineOwner.fulfilled, (state, action) => {
        const { machineOwner } = action.payload;

        state.selectedMachineOwner = machineOwner;
      })
      .addCase(deleteMachineOwnerById.fulfilled, (state, action) => {
        const { deletedMachineOwnerIds } = action.payload;

        state.machineOwners = state.machineOwners.filter((owner) => !deletedMachineOwnerIds.includes(owner.id));
      })
      .addCase(bulkDeleteMachineOwners.fulfilled, (state, action) => {
        const { deletedMachineOwnerIds } = action.payload;

        state.machineOwners = state.machineOwners.filter((owner) => !deletedMachineOwnerIds.includes(owner.id));
      });
  },
});

export const {
  updateMachineOwnerChecked,
  updateGlobalMachineOwnerChecked,
  toggleMachineOwnerChecked,
  setSelectedItemField,
  clearSelectedMachineOwner,
} = machineOwnersSlice.actions;

export default machineOwnersSlice.reducer;

const selectSelectedMachineMachineOwner = (state: RootState) => state.adminMachines.machineOwner;

export const selectMachineOwners = (state: RootState) => state.machineOwners.machineOwners;

export const selectSelectedMachineOwner = (state: RootState) => state.machineOwners.selectedMachineOwner;

export const selectHasProcessingMachineOwner = createSelector([selectMachineOwners], (machineOwners) =>
  machineOwners.some((machineOwner) => !machineOwner.isProcessed)
);

export const selectAvailableMachineOwners = createSelector(
  [selectMachineOwners, selectSelectedMachineMachineOwner],
  (machineOwners, machineMachineOwner) =>
    machineOwners.filter((machineOwner) => machineOwner.id !== machineMachineOwner?.id && machineOwner.isProcessed)
);

export const selectCheckedMachineOwner = createSelector([selectAvailableMachineOwners], (machineOwners) =>
  machineOwners.filter((machineOwner) => machineOwner?.isChecked).shift()
);

export const selectSelectedMachineOwnerHasValidFormData = createSelector(
  [selectSelectedMachineOwner],
  (machineOwner) =>
    machineOwner && machineOwner?.company && machineOwner?.firstName && machineOwner?.lastName && machineOwner?.email
);

export const selectCheckedMachineOwnerIds = createSelector([selectMachineOwners], (machineOwners) =>
  machineOwners.filter((machineOwner) => machineOwner?.isChecked).map((mo) => mo.id)
);
