import axios from 'axios';
import { toast } from 'react-toastify';

import { ExceptionDetailModel } from '../models/data/common/exceptionDetail.model';
import { authPolicy, getCachedAccessToken, logoutUser } from '../services/auth.service';
import { getExceptionMessage } from '../utils/exceptionMessageTranslate.utility';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const requestInterceptor = axios.interceptors.request.use(async (config) => {
  if (!config.url?.startsWith(baseApiUrl!)) {
    return config;
  }

  const accessToken = await getCachedAccessToken();
  const token = `Bearer ${accessToken}`;
  const subscriptionKey = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;

  config.headers = {
    Authorization: token,
    'Ocp-Apim-Subscription-Key': subscriptionKey,
  } as any; //COMMENT: https://github.com/axios/axios/issues/5034

  return config;
});

export const responseInterceptor = axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.config.url.endsWith(authPolicy)) {
      logoutUser(true);
    }

    if (err.response && err.response.data) {
      const error = err.response.data as ExceptionDetailModel;
      toast.error(getExceptionMessage(error.messageCode));
    }

    return Promise.reject(err);
  }
);
