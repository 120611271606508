import React from 'react';

import { translate } from '../../services/translation.service';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { DateTimePickerProps } from '../../models/props/components/dateTimePicker.props';

const DateTimePicker: React.FC<DateTimePickerProps> = ({ label, value, disabled, onChange }) => {
  return (
    <MobileDateTimePicker
      label={label}
      value={value}
      onChange={onChange}
      views={['year', 'month', 'day', 'hours', 'minutes']}
      disabled={disabled}
      className='w-100'
      orientation={window.innerHeight >= 400 ? 'portrait' : 'landscape'}
      localeText={{
        cancelButtonLabel: translate('common.cancel'),
        okButtonLabel: translate('common.ok'),
        toolbarTitle: translate('common.selectDateAndTime'),
      }}
    />
  );
};

export default DateTimePicker;
