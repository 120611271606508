import { createSlice, Slice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { SortCriteria } from '../../models/enum/sortCriteria.enum';
import { getDataSensorAlarms } from '../api/alarms.service';

export interface TableStateProps {
  filterText?: string;
  pageToCount: number;
  currentPage: number;
  itemsPerPage?: number;
  globalChecked?: boolean;
  sortCriteria: {
    column: string;
    order: SortCriteria;
  };
}

export type TableIds =
  | 'usersTable'
  | 'sensorTable'
  | 'sensorTableDetails'
  | 'allGeoMachines'
  | 'addContractUser'
  | 'contractMachines'
  | 'alarmRulesDetail'
  | 'stateHistoryTable'
  | 'addThemeAttribute'
  | 'availableMachines'
  | 'dataMachinesTable'
  | 'adminSensorGroups'
  | 'contractUsersTable'
  | 'adminGroupMachines'
  | 'alarmNotifications'
  | 'groupMachinesTable'
  | 'profileSensorGroups'
  | 'profileGroupSensors'
  | 'machineDetailThemes'
  | 'machineDetailAlarms'
  | 'addMachineAdminGroup'
  | 'adminSensorGroupSensors'
  | 'profileGroupAddSensors'
  | 'contractsTableSettings'
  | 'attributesTableSettings'
  | 'adminSensorGroupMachines'
  | 'userGroupsTableSettings'
  | 'machineMachineOwnerTable'
  | 'machineDetailAdminGroups'
  | 'adminGroupsTableSettings'
  | 'addContractMachinesTable'
  | 'profileUserGroupMachines'
  | 'profileGroupAddMachines'
  | 'profileSensorGroupMachines'
  | 'userDetailsContractsTable'
  | 'machineOwnerMachinesTable'
  | 'machineOwnersTableSettings'
  | 'machinesAdminTableSettings'
  | 'profileUserGroupAddMachines'
  | 'machineAttributesTableSettings';

const tableDefaultState: TableStateProps = {
  currentPage: 1,
  filterText: '',
  pageToCount: 10,
  itemsPerPage: 10,
  globalChecked: false,
  sortCriteria: { column: 'name', order: SortCriteria.Ascending },
};

const { filterText, ...stateWithoutFilterText } = tableDefaultState;

export const initialState: { [key in TableIds]: TableStateProps } = {
  allGeoMachines: tableDefaultState,
  adminGroupMachines: tableDefaultState,
  addMachineAdminGroup: tableDefaultState,
  profileSensorGroups: tableDefaultState,
  adminSensorGroups: tableDefaultState,
  adminSensorGroupMachines: tableDefaultState,
  alarmNotifications: tableDefaultState,
  dataMachinesTable: tableDefaultState,
  profileGroupAddSensors: tableDefaultState,
  contractsTableSettings: tableDefaultState,
  attributesTableSettings: tableDefaultState,
  userGroupsTableSettings: tableDefaultState,
  adminGroupsTableSettings: tableDefaultState,
  addThemeAttribute: tableDefaultState,
  machineOwnerMachinesTable: tableDefaultState,
  machinesAdminTableSettings: tableDefaultState,
  profileUserGroupMachines: stateWithoutFilterText,
  profileGroupAddMachines: stateWithoutFilterText,
  profileSensorGroupMachines: stateWithoutFilterText,
  profileUserGroupAddMachines: stateWithoutFilterText,
  profileGroupSensors: {
    ...tableDefaultState,
    sortCriteria: { column: 'chart', order: SortCriteria.Ascending },
  },
  adminSensorGroupSensors: {
    ...tableDefaultState,
    sortCriteria: { column: 'chart', order: SortCriteria.Ascending },
  },
  alarmRulesDetail: {
    ...tableDefaultState,
    sortCriteria: { column: 'symbol', order: SortCriteria.Ascending },
  },
  usersTable: {
    ...tableDefaultState,
    sortCriteria: { column: 'firstName', order: SortCriteria.Ascending },
  },
  contractUsersTable: {
    ...tableDefaultState,
    sortCriteria: { column: 'firstName', order: SortCriteria.Ascending },
  },
  userDetailsContractsTable: {
    ...tableDefaultState,
    sortCriteria: { column: 'contractStart', order: SortCriteria.Ascending },
  },
  groupMachinesTable: {
    ...tableDefaultState,
    sortCriteria: { column: 'id', order: SortCriteria.Ascending },
  },
  addContractUser: {
    ...tableDefaultState,
    sortCriteria: { column: 'firstName', order: SortCriteria.Ascending },
  },
  machineAttributesTableSettings: {
    ...tableDefaultState,
    sortCriteria: { column: 'name', order: SortCriteria.Ascending },
  },
  machineDetailAdminGroups: {
    ...tableDefaultState,
    sortCriteria: { column: 'name', order: SortCriteria.Ascending },
  },
  machineDetailAlarms: {
    ...tableDefaultState,
    sortCriteria: { column: 'attribute', order: SortCriteria.Ascending },
  },
  machineDetailThemes: {
    ...tableDefaultState,
    sortCriteria: { column: 'attribute', order: SortCriteria.Ascending },
  },
  availableMachines: {
    ...stateWithoutFilterText,
    sortCriteria: { column: 'id', order: SortCriteria.Ascending },
  },
  contractMachines: {
    ...stateWithoutFilterText,
    sortCriteria: { column: 'id', order: SortCriteria.Ascending },
  },
  addContractMachinesTable: {
    ...tableDefaultState,
    sortCriteria: { column: 'machineId', order: SortCriteria.Ascending },
  },
  stateHistoryTable: {
    ...tableDefaultState,
    sortCriteria: { column: 'time', order: SortCriteria.Ascending },
  },
  sensorTable: {
    ...tableDefaultState,
    sortCriteria: { column: 'time', order: SortCriteria.Descending },
    itemsPerPage: 5,
  },
  sensorTableDetails: {
    ...tableDefaultState,
    sortCriteria: { column: 'time', order: SortCriteria.Descending },
    itemsPerPage: 6,
  },
  machineOwnersTableSettings: {
    ...tableDefaultState,
    sortCriteria: { column: 'firstName', order: SortCriteria.Ascending },
  },
  machineMachineOwnerTable: {
    ...tableDefaultState,
    sortCriteria: { column: 'firstName', order: SortCriteria.Ascending },
  },
};

export const tablesSlice: Slice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    updateTableFilterTextById: (state, action) => {
      state[action.payload.id].filterText = action.payload.filterText;
    },
    updateTableSortCriteriaById: (state, action) => {
      state[action.payload.id].sortCriteria = action.payload.sortCriteria;
    },
    updateTableCurrentPageById: (state, action) => {
      state[action.payload.id].currentPage = action.payload.currentPage;
    },
    updateTablePageToCountById: (state, action) => {
      state[action.payload.id].pageToCount = action.payload.pageToCount;
    },
    updateTableGlobalCheckedById: (state, action) => {
      state[action.payload.id].globalChecked = action.payload.globalChecked;
    },
    uncheckTableGlobalCheckedById: (state, action) => {
      state[action.payload].globalChecked = false;
    },
    updateBulkTablePropsById: (state, action) => {
      state[action.payload.id] = { ...state[action.payload.id], ...action.payload.props };
    },
    updateItemsPerPageById: (state, action) => {
      state[action.payload.id].itemsPerPage = action.payload.itemsPerPage;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataSensorAlarms.fulfilled, (state) => {
      state.sensorTable = {
        ...state.sensorTable,
        currentPage: 1,
        filterText: '',
        pageToCount: 10,
        globalChecked: false,
        sortCriteria: { column: 'time', order: SortCriteria.Descending },
        itemsPerPage: 5,
      };
    });
  },
});

export const {
  updateTableFilterTextById,
  updateTableSortCriteriaById,
  updateBulkTablePropsById,
  updateTableCurrentPageById,
  updateTableGlobalCheckedById,
  uncheckTableGlobalCheckedById,
  updateItemsPerPageById,
  updateTablePageToCountById,
} = tablesSlice.actions;

export const selectTable = (state: RootState, tableId: string) => state.tables[tableId];

export default tablesSlice.reducer;
