import React, { useMemo } from 'react';

import { MDBSelect } from 'mdbreact';

import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { selectAlarmsTimeRange, setTimeRange } from '../../DataMachines/dataMachines.slice';
import { durations, getDurationTranslatedText } from './DurationOptions';

const SensorAlarmsFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const alarmsTimeRange = useAppSelector(selectAlarmsTimeRange);

  const currentDurations = useMemo(() => {
    return durations.map((duration) => {
      return {
        ...duration,
        text: getDurationTranslatedText(duration.value),
        defaultSelected: alarmsTimeRange === duration.value,
      };
    });
  }, [alarmsTimeRange]);

  const handleDurationChange = (chosenDuration: any) => {
    dispatch(setTimeRange(chosenDuration.value));
  };

  return (
    <MDBSelect
      className='dropdown-warning marginTop-unset'
      onValueChange={(e: any) => handleDurationChange(e)}
      data={currentDurations}
    />
  );
};

export default SensorAlarmsFilter;
