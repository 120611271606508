import axios from 'axios';

import { MachineAlarmModel, AlarmModel, DataAlarmModel } from '../models/data/machine/alarms.model';
import { TimeRangeEnum } from '../models/enum/timeRange.enum';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getAlarmsByMachine = async (id: number) => {
  const result = await axios.get<MachineAlarmModel[]>(`${baseApiUrl}api/alarms/machine/${id}`);
  return result?.data;
};

export const getAlarmById = async (id: number) => {
  const result = await axios.get<AlarmModel>(`${baseApiUrl}api/alarms/${id}`);
  return result?.data;
};

export const getSensorAlarms = async (alarmsTimeRange: TimeRangeEnum) => {
  const result = await axios.get<DataAlarmModel[]>(`${baseApiUrl}api/Alarms/sensor?timeRange=${alarmsTimeRange}`);

  return result?.data;
};

export const getSensorAlarmsByMachineUuid = async (uuid: string, alarmsPeriodConfig: any) => {
  const result = await axios.post<DataAlarmModel[]>(`${baseApiUrl}api/Alarms/sensor/${uuid}`, alarmsPeriodConfig);

  return result?.data;
};
