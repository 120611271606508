import React, { useState } from 'react';

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBTable,
  MDBTableBody,
  MDBModalDialog,
  MDBModalContent,
} from 'mdbreact';

import './createContactPersonPopup.scss';
import { translate } from '../../../../services/translation.service';
import { createTemporaryId } from '../../../../utils/adminPages.utility';
import { CreateContactPersonProps } from '../../../../models/props/admin/createContactPersonPopup.props';
import { ContactPersonModel } from '../../../../models/data/contract/contactPerson.model';

const CreateContactPersonPopup: React.FC<CreateContactPersonProps> = ({ isOpen, onShowModal, onClose, onSubmit }) => {
  const [contactPersonData, setContactPersonData] = useState<Partial<ContactPersonModel>>(() => ({
    firstName: '',
    lastName: '',
    email: '',
  }));

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;

    setContactPersonData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleSubmit = (event) => {
    if (!event.target.checkValidity()) {
      event.target.className += ' was-validated';
      event.preventDefault();
      return;
    }
    const contactPersonObject = { ...contactPersonData, id: createTemporaryId() } as ContactPersonModel;

    onSubmit(contactPersonObject);
    onClose();
  };

  return (
    <MDBContainer className='center-modal-page'>
      <MDBModal
        animationDirection='top'
        backdrop={true}
        show={isOpen}
        setShow={onShowModal}
        className='modal-notify modal-warning'
      >
        <MDBModalDialog centered={true}>
          <MDBModalContent>
            <MDBModalHeader>{translate('pages.admin.createContactPerson')}</MDBModalHeader>
            <form className='needs-validation' onSubmit={handleSubmit} noValidate>
              <MDBModalBody>
                <MDBTable className='rsrg-table dense-inputs' hover responsive>
                  <MDBTableBody>
                    {contactPersonData &&
                      Object.keys(contactPersonData).map((key) => (
                        <tr key={key}>
                          <td className='align-middle text-left' style={{ border: 'none' }}>
                            <MDBInput
                              id={key}
                              key={`${key} input`}
                              label={translate(`common.${key}`)}
                              value={contactPersonData[key]}
                              onChange={handleChange}
                              required
                              type={key === 'email' ? 'email' : 'text'}
                            >
                              <div style={{ margin: '-10px' }} className='invalid-feedback error-message'>
                                {key === 'email'
                                  ? translate('pages.admin.emailNotInTheCorrectFormat')
                                  : translate(`pages.admin.${key}IsRequired`)}
                              </div>
                            </MDBInput>
                          </td>
                        </tr>
                      ))}
                  </MDBTableBody>
                </MDBTable>
              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn color='warning' className='text-white' type='submit'>
                  {translate('common.save')}
                </MDBBtn>
                <MDBBtn outline color='warning' className='cancel-onHover' onClick={onClose}>
                  {translate('common.cancel')}
                </MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
  );
};

export default CreateContactPersonPopup;
