import { SortCriteria } from '../models/enum/sortCriteria.enum';

export const genericSort = (sortCriteria: SortCriteria, propertyName: any, x: any, y: any) => {
  if (!!x[propertyName] && !y[propertyName]) {
    return -1;
  }

  if (!x[propertyName] && !!y[propertyName]) {
    return 1;
  }

  if (typeof x[propertyName] === 'string') {
    if (sortCriteria === SortCriteria.Ascending) {
      return x[propertyName].localeCompare(y[propertyName]);
    }

    return y[propertyName].localeCompare(x[propertyName]);
  }

  if (sortCriteria === SortCriteria.Ascending) {
    return x[propertyName] >= y[propertyName] ? 1 : -1;
  }

  return x[propertyName] > y[propertyName] ? -1 : 1;
};
