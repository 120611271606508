import React from 'react';

import './dateFormatter.scss';
import { DateFormatterProps } from '../../models/props/components/dateFormatter.props';
import { timeZoneTransform } from '../../transformers/timeZone.transform';
import { getDateColor } from '../../utils/formatting.utility';
import { translate } from '../../services/translation.service';
import useUserState from '../../hooks/useUserState';

const DateFormatter: React.FC<DateFormatterProps> = ({ date }) => {
  const { userState } = useUserState();

  return (
    <td className='align-middle text-left'>
      <span className={getDateColor(date)}>
        {date ? timeZoneTransform(date, userState.timeZone, userState.timeZoneName) : translate('common.none')}
      </span>
    </td>
  );
};

export default DateFormatter;
