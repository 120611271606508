import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import { MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';

import { toast } from 'react-toastify';
import ProfileInfo from './components/profileInfo/ProfileInfo';
import ProfileGroups from './components/profileGroups/ProfileGroups';
import ProfileSensorGroups from './components/profileSensorGroups/ProfileSensorGroups';
import ProfilePreferences from './components/profilePreferences/ProfilePreferences';
import ProfileTogglePanel from './components/profileTogglePanel/ProfileTogglePanel';
import { TimeZone } from '../../models/data/common/timeZone.model';
import { getCustomTimeZones } from '../../services/timeZone.service';
import { translate } from '../../services/translation.service';
import { updateUserPreferences } from '../../services/userSettings.service';
import useUserState from '../../hooks/useUserState';
import useActiveTabState from '../../hooks/useActiveTabState';
import useUserGroupState from '../../hooks/useUserMachineGroups';

const Profile: React.FC = () => {
  const { userState, setUserPreferences } = useUserState();
  const { tabsState, setActiveTab } = useActiveTabState();
  const { profileTabs } = tabsState;

  const { hasUserMachineGroupsData, getUserMachineGroupsData } = useUserGroupState();

  const [zones, setZones] = useState<TimeZone[]>([]);
  const [language, setLanguage] = useState(userState.language);
  const [timeZone, setTimeZone] = useState(userState.timeZone);
  const [timeZoneName, setTimeZoneName] = useState(userState.timeZoneName);

  useEffect(() => {
    const setCustomTimeZones = async () => {
      const res = await trackPromise(getCustomTimeZones());
      setZones(res);
    };

    if (profileTabs === 2) {
      setCustomTimeZones();
    }

    if (profileTabs === 3 && !hasUserMachineGroupsData) {
      getUserMachineGroupsData();
    }
  }, [profileTabs]); // eslint-disable-line

  const saveUserPreferences = async () => {
    const userBindingModel = {
      language,
      timeZone,
      timeZoneName,
    };

    await trackPromise(updateUserPreferences(userBindingModel));

    setUserPreferences(userBindingModel);
    toast.success(translate('pages.profile.saveProfile'));
  };

  const onTabChange = (id: number) => {
    setActiveTab('profileTabs', id);
  };

  return (
    <MDBRow className='text-center'>
      <MDBCol md='12' xs='5'>
        <MDBCard shadow='5'>
          <ProfileTogglePanel activeTab={profileTabs} triggerActiveTab={onTabChange} />
          <MDBCardBody>
            {profileTabs === 1 && <ProfileInfo user={userState} />}
            {profileTabs === 2 && (
              <ProfilePreferences
                zones={zones}
                timeZone={timeZone}
                setTimeZone={setTimeZone}
                setTimeZoneName={setTimeZoneName}
                selectLanguage={setLanguage}
                saveUserPreferences={saveUserPreferences}
              />
            )}
            {profileTabs === 3 && <ProfileGroups />}
            {profileTabs === 4 && <ProfileSensorGroups />}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
};

export default Profile;
