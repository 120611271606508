import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { customHistory } from './browserHistory.utility';

const instrumentationKey = process.env.REACT_APP_APP_INSIGHTS_KEY;

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    loggingLevelConsole: 2,
    loggingLevelTelemetry: 2,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: customHistory }, //COMMENT: Do we need it after BrowserRouter
    },
  },
});

if (process.env.NODE_ENV === 'production') {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export { reactPlugin, appInsights };
