import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBModalFooter, MDBRow } from 'mdbreact';

import './adminGroupDetail.scss';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { translate } from '../../../../services/translation.service';
import { setAdminGroupName } from '../../AdminGroups/adminGroups.slice';
import { AdminGroupDetailProps } from '../../../../models/props/admin/adminGroupDetail.props';
import SynchronizingStatus from '../SynchronizingStatus/SynchronizingStatus';

const AdminGroupDetail: React.FC<AdminGroupDetailProps> = ({ adminGroup, onSubmitAdminGroup }) => {
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState<boolean>(() => !!adminGroup && !!adminGroup?.id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setEditMode(!!adminGroup && !!adminGroup?.id);
  }, [adminGroup]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    dispatch(setAdminGroupName(value));
  };

  const submitForm = (event) => {
    event.preventDefault();
    event.target.className += ' was-validated';

    if (adminGroup?.name) {
      onSubmitAdminGroup(!editMode);
    }
  };

  const handleCancelClick = () => navigate('/admin/admin-groups');

  return (
    <>
      <MDBCard>
        <MDBCardBody>
          <form className='needs-validation' onSubmit={submitForm} noValidate>
            <MDBRow className='pb-4' between>
              <MDBCol md='4' xl='4' className='text-left d-flex align-items-center'>
                <MDBIcon icon='folder-open' color='dark' className='p-4 shadow-3-strong bg-warning rounded' />
                <h5 className='font-weight-bold ml-4'>
                  {editMode ? translate('common.edit') : translate('common.create')} {translate('common.adminGroup')}
                </h5>
              </MDBCol>
              {editMode && !adminGroup?.isProcessed && <SynchronizingStatus />}
            </MDBRow>

            <MDBRow>
              <MDBCol md='6' xl='3'>
                <MDBInput
                  id='name'
                  className='form-control'
                  required
                  type='text'
                  label={`${translate('common.name')}*`}
                  value={adminGroup?.name}
                  onChange={handleChange}
                  disabled={editMode && !adminGroup?.isProcessed}
                >
                  <div className='invalid-feedback error-message'>
                    {translate('pages.admin.adminGroupNameIsRequired')}
                  </div>
                </MDBInput>
              </MDBCol>
            </MDBRow>

            <MDBModalFooter className='pr-0'>
              <MDBBtn
                color='warning'
                className='text-white'
                type='submit'
                disabled={editMode && !adminGroup?.isProcessed}
              >
                {translate('common.save')}
              </MDBBtn>
              <MDBBtn outline color='warning' className='cancel-onHover' onClick={handleCancelClick}>
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

export default AdminGroupDetail;
