import React, { useState, useEffect, useMemo } from 'react';

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBTable,
  MDBTableBody,
  MDBSelect,
  MDBModalDialog,
  MDBModalContent,
} from 'mdbreact';

import './rulePopups.scss';
import { toast } from 'react-toastify';
import { translate } from '../../../../../services/translation.service';
import { CreateRulePopupProps, RuleProps } from '../../../../../models/props/admin/createRulePopup.props';
import { SelectData } from '../../../../../models/types/MDBtypes';

const symbolValues = ['<', '>', '=>', '<=', '==', '!='];

const defaultRuleConfiguration = {
  symbol: '',
  value: undefined,
};

const AddSensorChartModal: React.FC<CreateRulePopupProps> = ({
  isOpen,
  defaultRule,
  onShowModal,
  onSaveRule,
  onEditRule,
  onCloseModal,
}) => {
  const [selectedRule, setSelectedRule] = useState<RuleProps>(defaultRule || defaultRuleConfiguration);

  const symbolDropdownData = useMemo(() => {
    return [translate('common.selectSymbol')].concat(symbolValues).map((value) => {
      return {
        text: value,
        value,
        defaultSelected: value === (selectedRule?.symbol || translate('common.selectSymbol')),
        disabled: value === translate('common.selectSymbol'),
      };
    });
  }, [selectedRule?.symbol]);

  useEffect(() => {
    setSelectedRule({ ...defaultRule } || defaultRuleConfiguration);
  }, [defaultRule]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;

    setSelectedRule((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleDropdown = (symbol: any) => {
    setSelectedRule((prevState) => ({ ...prevState, symbol: symbol.value }));
  };

  const handleSubmit = () => {
    const isValid = !!selectedRule?.symbol && !!selectedRule?.value;

    if (!isValid) {
      toast.warn(translate('pages.profile.noSelectedSymbol'));
      return;
    }

    if (defaultRule) {
      onEditRule(selectedRule);
      onCloseModal();
      return;
    }

    onSaveRule(selectedRule);
    onCloseModal();
  };

  return (
    <MDBContainer className='center-modal-page'>
      <MDBModal
        animationDirection='top'
        backdrop={true}
        show={isOpen}
        setShow={onShowModal}
        className='modal-notify modal-warning'
      >
        <MDBModalDialog centered={true}>
          <MDBModalContent>
            <MDBModalHeader>
              {defaultRule ? translate('pages.admin.editRule') : translate('pages.admin.createRule')}
            </MDBModalHeader>

            <MDBModalBody>
              <MDBTable className='rsrg-table' hover responsive>
                <MDBTableBody>
                  <tr>
                    <td className='align-middle text-left no-border'>
                      <MDBSelect
                        id='symbol'
                        data={symbolDropdownData}
                        label={`${translate('common.symbol')}*`}
                        value={selectedRule?.symbol}
                        onValueChange={(e: SelectData | SelectData[]) => {
                          handleDropdown(e);
                        }}
                        required
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className='align-middle text-left no-border'>
                      <MDBInput id='value' label={translate('common.value')} value={selectedRule?.value} onChange={handleChange} />
                    </td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='warning' className='text-white' onClick={handleSubmit}>
                {translate('common.ok')}
              </MDBBtn>
              <MDBBtn outline color='warning' className='cancel-onHover' onClick={onCloseModal}>
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
  );
};

export default AddSensorChartModal;
