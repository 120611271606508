import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import { toast } from 'react-toastify';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer, MDBBtn, MDBIcon } from 'mdbreact';

import useActiveTabState from '../../hooks/useActiveTabState';

import { translate } from '../../services/translation.service';
import { GoogleMapConstants } from '../../constants/googleMap.constants';
import { getMachineGeoDetails } from '../../services/machine.service';

import useUserState from '../../hooks/useUserState';

import MapGoogle from './components/mapGoogle/MapGoogle';
import TabsHeader from '../../components/tabsHeader/TabsHeader';
import BackToPage from '../../components/backToPage/BackToPage';
import MachineDetails from './components/machineDetails/machineDetails';
import MapOpenRailway from './components/openRailwayMap/MapOpenRailway';
import HeaderSection from '../../components/headerSection/HeaderSection';
import TrackHistoryTable from './components/trackHistoryTable/TrackHistoryTable';
import { TimeRangeEnum } from '../../models/enum/timeRange.enum';
import { CommonConstants } from '../../constants/common.constants';
import { OpenRailwayMapConstants } from '../../constants/openRailwayMap.constants';
import { MachineDetailsModel } from '../../models/data/machine/machineDetails.model';
import { MachineTrackHistoryModel } from '../../models/data/machine/machineTrackHistory.model';

import { PeriodFilter } from '../data/components/periodFilter/PeriodFilter';

import './geoMachineDetails.scss';
import useInterval from '../../hooks/useInterval';
import { getMachineTrackHistoryData } from '../../services/telemetry.service';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { clearMachineFilter, selectMachineFilter, setMachineFilter } from '../../store/slices/machineFilterSlice';
import { useQueryValue } from '../../utils/route.utility';
import {
  resetMapState,
  setActivePositionByMapId,
  setPathByMapId,
  setPositionsByMapId,
  setUserLocationByMapId,
  updateMapAndActivePositionByMapId,
  updateMapCenterByMapId,
  updateTooltipByMapId,
} from '../../store/slices/mapsSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { MachineSetupTrackHistoryModel } from '../../models/data/machine/machineSetupTrackHistory.model';

const pageMapIds = [
  GoogleMapConstants.geoMachineDetailsGoogleMapId,
  OpenRailwayMapConstants.geoMachineDetailsOpenRailwayMapId,
];

const zoomSettings = {
  geoMachineDetailsGoogleMap: GoogleMapConstants.zoomedMapDetails,
  geoMachineDetailsOpenRailwayMap: OpenRailwayMapConstants.zoomedMapDetails,
};

const GeoMachineDetails: React.FC = () => {
  const { tabsState } = useActiveTabState();
  const { detailsMapTab } = tabsState;
  const { isAdmin } = useUserState();
  const { timeRange, duration, dateFrom, dateTo, dateFromString, dateToString } = useAppSelector(selectMachineFilter);

  const preserveFilterState = useQueryValue('preserveState');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [machine, setMachine] = useState<MachineDetailsModel | null>(null);

  const { machineId: machineIdParam } = useParams<{ machineId: string }>();
  const machineId = useMemo(() => +machineIdParam!, [machineIdParam]);

  const [machineUuid, setMachineUuid] = useState<string>('');
  const [machineSetupTrackHistory, setMachineSetupTrackHistory] = useState<MachineSetupTrackHistoryModel[]>([]);
  const [machineTrackHistory, setMachineTrackHistory] = useState<MachineTrackHistoryModel[]>([]);
  const [optimizeGeoLocation, setOptimizeGeoLocation] = useState<boolean>(true);
  const [setupNames, setSetupNames] = useState<string[]>([]);

  const { activePosition } = useAppSelector((state) => state.maps[GoogleMapConstants.geoMachineDetailsGoogleMapId]);

  const getGeoMachineDetails = useCallback(async () => {
    const machineGeoDetails = await trackPromise(getMachineGeoDetails(machineId));
    if (!machineGeoDetails) {
      toast.warn(translate('pages.geo.noLastEvent'), { autoClose: false });
    } else {
      setMachine(machineGeoDetails);
      setMachineUuid(machineGeoDetails.uuid);
    }
  }, [machineId]);

  useEffect(() => {
    (async () => await getGeoMachineDetails())();
    return () => toast.dismiss();
  }, [machineId, getGeoMachineDetails]);

  useEffect(() => {
    setOptimizeGeoLocation(true);
  }, [machineId]);

  useInterval(async () => {
    await getGeoMachineDetails();
    await trackPromise(getMachineHistory(machineUuid, timeRange, dateFrom, dateTo, optimizeGeoLocation));
  }, CommonConstants.refreshMachineGeoTime);

  useEffect(() => {
    async function setMachineHistoryState() {
      if (preserveFilterState) {
        await trackPromise(getMachineHistory(machineUuid, timeRange, dateFrom, dateTo, optimizeGeoLocation));
      } else {
        dispatch(clearMachineFilter({}));
        await trackPromise(getMachineHistory(machineUuid, TimeRangeEnum.LastDay, null, null, optimizeGeoLocation));
      }
    }

    if (machineUuid !== '') {
      setMachineHistoryState();
      return () => toast.dismiss();
    }
  }, [machineUuid, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (machineTrackHistory.length === 0) {
      pageMapIds.forEach((id) => dispatch(setPathByMapId({ id, path: [] })));
      dispatch(resetMapState({}));
      return;
    }

    if (!activePosition) {
      dispatch(resetMapState({}));
    }

    const machineTrackHistoryPath = machineTrackHistory
      .filter((machine) => machine.longitude && machine.latitude)
      .map(({ latitude, longitude }) => ({ lat: latitude, lng: longitude }))
      .reverse();

    pageMapIds.forEach((id) => dispatch(setPathByMapId({ id, path: machineTrackHistoryPath })));
  }, [dispatch, machineTrackHistory]); // eslint-disable-line

  useEffect(() => {
    setTimeout(() => {
      if (machineTrackHistory && machineTrackHistory.length > 0) {
        dispatch(resetMapState({}));
        onSelectEventClick(machineTrackHistory[0], true);
      }
    }, 250);
  }, [machineTrackHistory, dispatch]); // eslint-disable-line

  const getMachineHistory = async (
    uuid: string,
    timeRange: TimeRangeEnum | null,
    dateFrom: Date | null,
    dateTo: Date | null,
    isOptimized: boolean
  ) => {
    toast.dismiss();

    let getTrackHistoryFromBuckets = false;
    if (timeRange && timeRange > 4) {
      getTrackHistoryFromBuckets = true;
    }

    if (dateFrom && dateTo) {
      const diffdays = calculateDateDiffDays(dateFrom, dateTo);
      getTrackHistoryFromBuckets = diffdays > 7;
    }

    const history = await getMachineTrackHistoryData(
      uuid,
      timeRange,
      dateFrom,
      dateTo,
      isOptimized,
      getTrackHistoryFromBuckets
    );

    setMachineSetupTrackHistory(history);
    setSetupNames(history.map((h) => h.setupName));
    const machinePositionHistory =
      history && history.length > 0
        ? history[0].machineTrackHistoryModel.filter((machine) => machine.longitude && machine.latitude)
        : [];

    setMachineTrackHistory(machinePositionHistory);
    pageMapIds.forEach((id) => dispatch(setPositionsByMapId({ id, positions: machinePositionHistory })));

    if (!history || history.length === 0) {
      toast.warn(translate('pages.geo.noTrackHistory'), { autoClose: false });
    }
  };

  const calculateDateDiffDays = (fromDate: Date, toDate: Date) => {
    const dateDiff = toDate.getTime() - fromDate.getTime();
    return dateDiff / (1000 * 3600 * 24);
  };

  const onSelectEventClick = (trackEvent: MachineTrackHistoryModel, isFirstRender: boolean = false) => {
    if (!trackEvent?.latitude || !trackEvent?.longitude) {
      return;
    }

    if (trackEvent === activePosition) {
      dispatch(resetMapState({}));
    } else if (!isFirstRender) {
      syncTooltips(false);
      pageMapIds.forEach((id) => {
        dispatch(setActivePositionByMapId({ id, activePosition: trackEvent }));
        dispatch(updateMapCenterByMapId({ id, center: { lat: trackEvent.latitude, lng: trackEvent.longitude } }));
      });
    } else {
      selectTrackEvent(trackEvent);
      syncTooltips(false);
    }
  };

  const onSetupChange = (setupIndex: number) => {
    const machiePositionHistory = machineSetupTrackHistory[setupIndex].machineTrackHistoryModel.filter(
      (machine) => machine.longitude && machine.latitude
    );

    setMachineTrackHistory(machiePositionHistory);
    pageMapIds.forEach((id) => dispatch(setPositionsByMapId({ id, positions: machiePositionHistory })));
  };

  const onViewTrackHistoryClick = async (
    timeRange: TimeRangeEnum | null,
    duration: string | null,
    dateFrom: Date | null,
    dateFromString: string | null,
    dateTo: Date | null,
    dateToString: string | null,
    isOptimized: boolean
  ) => {
    setOptimizeGeoLocation(isOptimized);
    dispatch(setMachineFilter({ timeRange, duration, dateFrom, dateFromString, dateTo, dateToString }));

    await getGeoMachineDetails();
    await trackPromise(getMachineHistory(machineUuid, timeRange, dateFrom, dateTo, isOptimized));
  };

  const selectTrackEvent = useCallback(
    (trackEvent: MachineTrackHistoryModel) => {
      for (const mapId of pageMapIds) {
        dispatch(
          updateMapAndActivePositionByMapId({
            id: mapId,
            center: { lat: trackEvent.latitude, lng: trackEvent.longitude },
            zoom: zoomSettings[mapId],
            preventFitBounds: true,
            activePosition: trackEvent,
          })
        );
      }
    },
    [dispatch]
  );

  const onNavigateToDataDetails = () => {
    navigate(`/data/analytics/${machineId}?preserveState=true`);
  };

  const syncTooltips = useCallback(
    (isTooltipOpen) => {
      pageMapIds.forEach((id) => {
        dispatch(
          updateTooltipByMapId({
            id,
            isTooltipOpen,
          })
        );
      });
    },
    [dispatch]
  );

  const syncMaps = useCallback(
    (activePosition) => {
      pageMapIds.forEach((id) => {
        dispatch(
          setActivePositionByMapId({
            id,
            activePosition,
          })
        );
      });
    },
    [dispatch]
  );

  const onSelectUserLocation = useCallback(() => {
    for (const mapId of pageMapIds) {
      dispatch(updateMapAndActivePositionByMapId({ id: mapId }));
    }

    setTimeout(() => {
      for (const mapId of pageMapIds) {
        dispatch(setUserLocationByMapId({ id: mapId, zoom: zoomSettings[mapId] }));
      }
    }, 0);
  }, [dispatch]);

  const onZoomToPosition = useCallback(() => {
    selectTrackEvent(activePosition);
  }, [activePosition, selectTrackEvent]);

  return (
    <MDBContainer fluid>
      <MDBRow className='d-flex justify-content-between'>
        <MDBCol size='auto'>
          <BackToPage text={translate('backToLink.machines')} navigateTo='/geo/machines' />
        </MDBCol>
        {(isAdmin || machine?.isDataModuleActive) && (
          <MDBCol md='6' xl='3'>
            <MDBBtn id='geo-details-btn' className='btn text-white w-100 m-0 mb-2' onClick={onNavigateToDataDetails}>
              <MDBIcon icon='chart-line' className='mr-2 icon-font-size' />
              {translate('backToLink.viewDataDetails')}
            </MDBBtn>
          </MDBCol>
        )}
      </MDBRow>

      <MDBRow className='d-flex'>
        <MDBCol xl='12' className='reorder1'>
          <MDBCard className='mt-3 mb-lg-4 mb-sm-1'>
            <HeaderSection text={translate('pages.geo.machineHistoryFilter')} />
            <MDBCardBody className='text-center'>
              <PeriodFilter
                items={machineTrackHistory}
                duration={duration}
                machineId={machineId}
                timeRange={timeRange}
                endDateUtcString={dateToString}
                startDateUtcString={dateFromString}
                onViewClick={onViewTrackHistoryClick}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol xl='4' className='reorder2'>
          <MDBCard className='mt-3 mb-lg-4 mb-sm-1'>
            <HeaderSection text={translate('pages.geo.details')} />
            <MDBCardBody className='text-left w-100 section-body overflow-auto'>
              <MachineDetails machine={machine} />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol xl='8' className='reorder3'>
          <MDBCard className='mt-3 mb-lg-4 mb-sm-1'>
            <TabsHeader tabId='detailsMapTab' tabNames={['Google Maps', 'Open Railway']} />
            <MDBCardBody className='text-center w-100 section-body'>
              {detailsMapTab === 1 && (
                <MapGoogle
                  mapId={GoogleMapConstants.geoMachineDetailsGoogleMapId}
                  hasUserLocationButton={true}
                  hasLastPositionButton={true}
                  zoomToPositionLabel={translate('common.zoomToPosition')}
                  syncMaps={syncMaps}
                  syncTooltips={syncTooltips}
                  triggerZoomToPosition={onZoomToPosition}
                  triggerSelectUserLocation={onSelectUserLocation}
                />
              )}
              {detailsMapTab === 2 && (
                <MapOpenRailway
                  mapId={OpenRailwayMapConstants.geoMachineDetailsOpenRailwayMapId}
                  hasUserLocationButton={true}
                  hasLastPositionButton={true}
                  zoomToPositionLabel={translate('common.zoomToPosition')}
                  syncMaps={syncMaps}
                  syncTooltips={syncTooltips}
                  triggerZoomToPosition={onZoomToPosition}
                  triggerSelectUserLocation={onSelectUserLocation}
                />
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol xl='12' className='reorder4'>
          <MDBCard className='mt-3 mb-lg-4 mb-sm-1'>
            <MDBCardBody className='text-center geo-trackhistory' style={{ overflow: 'auto' }}>
              <TrackHistoryTable
                setupNames={setupNames}
                trackHistory={machineTrackHistory}
                onSelectEventClick={onSelectEventClick}
                onSetupChange={onSetupChange}
              ></TrackHistoryTable>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default GeoMachineDetails;
