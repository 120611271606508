import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBContainer } from 'mdbreact';

import '../../geo/geoMachines.scss';
import { toast } from 'react-toastify';
import TabsHeader from '../../../components/tabsHeader/TabsHeader';
import HeaderSection from '../../../components/headerSection/HeaderSection';
import MachineGroups from '../../geo/components/machineGroups/MachineGroups';
import AddMachineToGroupModal from '../../geo/components/addMachineToGroupModal/AddMachineToGroupModal';
import { translate } from '../../../services/translation.service';
import useActiveTabState from '../../../hooks/useActiveTabState';
import useModalState from '../../../hooks/useModalState';
import GenericTable from '../../../components/genericTable/genericTable';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import useMachinesState from '../../../hooks/useMachinesState';
import { getDataSensorAlarms } from '../../../store/api/alarms.service';
import { selectVisibleAlarms, selectAlarmsTimeRange, clearMachineSensorGroups } from './dataMachines.slice';
import { UserGroupTableModel } from '../../../models/data/group/userGroupTable.model';
import SensorAlarmsFilter from '../components/sensorAlarmsFilter/SensorAlarmsFilter';
import useUserState from '../../../hooks/useUserState';
import useAttributeMappings from '../../../hooks/useAttributeMappings';
import useInterval from '../../../hooks/useInterval';
import { CommonConstants } from '../../../constants/common.constants';
import { updateTableGlobalCheckedById } from '../../../store/slices/tableSlice';
import { roundSensorValue } from '../../../utils/roundSensorValue.utility';

const DataMachines: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setModal } = useModalState();
  const { tabsState } = useActiveTabState();
  const { isAdmin } = useUserState();

  const {
    hasMachineData,
    userGroups,
    adminGroups,
    allMachines: machines,
    selectedMachineId,
    getGeoData,
    handleDataMachineSelect,
    removeUserGroupMachineClick,
    handleMachineCheckedToggle,
    handleGlobalItemsChecked,
    handleGroupToggle,
    addUserGroupMachineClick,
    contractPermissions,
  } = useMachinesState();

  const machinesToDisplay = isAdmin ? machines : contractPermissions();

  const { groupTab } = tabsState;
  const { mapAlarmAttributes } = useAttributeMappings();

  const alarms = useAppSelector(selectVisibleAlarms);
  const alarmsTimeRange = useAppSelector(selectAlarmsTimeRange);

  const [filteredDataGroups, setFilteredDataGroups] = useState();
  const [filteredUserAlarms, setFilteredUserAlarms] = useState();
  const [userGroupMachines, setUserGroupMachines] = useState<UserGroupTableModel[]>([]);
  const [machineGroupModalState, setMachineGroupModalState] = useState<{ isOpen: boolean; machineId: number }>({
    isOpen: false,
    machineId: -1,
  });

  const getUserSensorAlarms = () => {
    const filteredAlarms = alarms.filter((alarm) =>
      machinesToDisplay.find((machine) => machine.uuid === alarm.machineUuid)
    );

    setFilteredUserAlarms(filteredAlarms);
  };

  const getModuleActiveMachines = () => {
    const filteredGroup = userGroups.map((group) => ({
      ...group,
      machines: group.machines.filter((m) => m.isDataModuleActive),
    }));

    setFilteredDataGroups(filteredGroup);
  };

  useEffect(() => {
    getModuleActiveMachines();
  }, [userGroups]); // eslint-disable-line

  useEffect(() => {
    getUserSensorAlarms();
  }, [alarms]); // eslint-disable-line

  useEffect(() => {
    if (contractPermissions().length === 0 && !isAdmin) {
      navigate('/geo/machines');
    }
  }, [contractPermissions, isAdmin, navigate]);

  useEffect(() => {
    if (!hasMachineData) {
      getGeoData();
    }

    dispatch(getDataSensorAlarms({ alarmsTimeRange }));
  }, [dispatch, getGeoData, hasMachineData, alarmsTimeRange]);

  useEffect(() => {
    return () => {
      toast.dismiss();
      dispatch(clearMachineSensorGroups({}));
      dispatch(
        updateTableGlobalCheckedById({
          id: 'dataMachinesTable',
          globalChecked: false,
        })
      );
    };
  }, [dispatch]);

  useInterval(() => {
    getGeoData();
  }, CommonConstants.refreshMapTime);

  const onItemGeoLocationClick = useCallback(
    (machineId: string | number) => navigate(`/geo/machine/${machineId}`),
    [navigate]
  );

  const onUserGroupsClick = useCallback(
    (machineId: number) => {
      const userGroupTableModel: UserGroupTableModel[] = userGroups.map((userGroup) => ({
        id: userGroup.id,
        name: userGroup.name,
        isChecked: userGroup.machines.some((m) => m.id === machineId),
      }));

      setMachineGroupModalState({ isOpen: true, machineId });

      setUserGroupMachines(userGroupTableModel);
    },
    [userGroups]
  );

  const onDeleteClick = (callback: () => void) => {
    setModal(true, callback);
  };

  const modalSetState = (value: boolean) => {
    if (!value) {
      setMachineGroupModalState({ isOpen: false, machineId: -1 });
    }
  };

  const onCloseModal = () => {
    setMachineGroupModalState({ isOpen: false, machineId: -1 });
  };

  const onToggleGroupModalClick = (value: string | number) => {
    setUserGroupMachines((prevState) =>
      prevState.map((groupMachine) =>
        value === groupMachine.id.toString()
          ? {
              ...groupMachine,
              isChecked: !groupMachine.isChecked,
            }
          : groupMachine
      )
    );
  };

  const onSaveUserGroupMachines = async () => {
    await addUserGroupMachineClick(machineGroupModalState.machineId, userGroupMachines);
    setMachineGroupModalState({ isOpen: false, machineId: -1 });
  };

  const onMachineDataDetailsClick = useCallback(
    (machineId: string | number) => navigate(`/data/analytics/${machineId}`),
    [navigate]
  );

  const onMachineGeoDetailsClick = useCallback(
    (machineId: string | number) => navigate(`/geo/machine/${machineId}`),
    [navigate]
  );

  const onSensorAlarmDetailsClick = useCallback(
    (alarmId: string | number) => {
      const selectedAlarm = alarms.find((alarm) => alarm.id === alarmId);

      if (selectedAlarm) {
        const { machineUuid } = selectedAlarm;
        const selectedMachine = machinesToDisplay.find((machine) => machine.uuid === machineUuid);

        if (selectedMachine) {
          navigate(`/data/analytics/${selectedMachine.id}`);
        }
      }
    },
    [alarms, machinesToDisplay, navigate]
  );

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol xl='4'>
          <MDBCard className='mb-4'>
            <TabsHeader tabId='groupTab' tabNames={[translate('common.myGroups'), translate('common.adminGroups')]} />
            <MDBCardBody className='card-body-groups text-center w-100 px-4 section-body-data p-0'>
              <MachineGroups
                displayed={groupTab === 1}
                groups={isAdmin ? userGroups : filteredDataGroups}
                selectedMachineId={selectedMachineId}
                onToggleGroupClick={handleGroupToggle}
                onSelectedMachineClick={handleDataMachineSelect}
                onMachineRemoveClick={(machineId: number, groupId: string) =>
                  onDeleteClick(() => removeUserGroupMachineClick(machineId, groupId))
                }
                onMachineDataDetailsClick={onMachineDataDetailsClick}
                onMachineGeoDetailsClick={onMachineGeoDetailsClick}
              />
              <MachineGroups
                displayed={groupTab === 2}
                groups={adminGroups.filter((ag) => ag.machines.length > 0)}
                selectedMachineId={selectedMachineId}
                onToggleGroupClick={handleGroupToggle}
                onSelectedMachineClick={handleDataMachineSelect}
                onMachineDataDetailsClick={onMachineDataDetailsClick}
                onMachineGeoDetailsClick={onMachineGeoDetailsClick}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol xl='8'>
          <MDBCard className='mb-4'>
            <HeaderSection text={translate('pages.data.sensorAlarms')} />
            <MDBCardBody className='card-body-groups text-center w-100 section-body-long'>
              <GenericTable
                tableId={'sensorTable'}
                items={mapAlarmAttributes(isAdmin ? alarms || [] : filteredUserAlarms || []).map((a) => ({
                  ...a,
                  value: roundSensorValue(a.attributeUuid, a.value),
                }))}
                hasSearch={true}
                onItemDetailsClick={onSensorAlarmDetailsClick}
                customTableControl={<SensorAlarmsFilter />}
                disableTopPagination={true}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md='12'>
          <MDBCard>
            <MDBCardBody>
              <GenericTable
                tableId={'dataMachinesTable'}
                items={machinesToDisplay || []}
                selectedItemId={selectedMachineId}
                hasSearch={true}
                hasItemsPerPageControl={true}
                hasCheckboxInput={true}
                handleItemToggle={handleMachineCheckedToggle}
                onGlobalItemsChecked={handleGlobalItemsChecked}
                onItemGeoLocationClick={onItemGeoLocationClick}
                onItemDetailsClick={onMachineDataDetailsClick}
                onItemAddToGroupsClick={onUserGroupsClick}
                onItemSelectClick={handleDataMachineSelect}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      {machineGroupModalState.isOpen && (
        <AddMachineToGroupModal
          isOpen={machineGroupModalState.isOpen}
          onShowModal={modalSetState}
          groups={userGroupMachines}
          onCloseModal={onCloseModal}
          onToggleGroupModalClick={onToggleGroupModalClick}
          onSaveUserGroupMachines={onSaveUserGroupMachines}
        />
      )}
    </MDBContainer>
  );
};

export default DataMachines;
