import React from 'react';

import { MDBCol, MDBRow, MDBTable, MDBTableBody, MDBIcon, MDBTooltip, MDBCheckbox, MDBBtn } from 'mdbreact';

import './genericTable.scss';
import Pagination from '../pagination/Pagination';
import TableSearch from '../tableSearch/TableSearch';
import TableHeader from '../tableHeader/tableHeader';
import HeaderSection from '../headerSection/HeaderSection';
import EmptyResultset from '../emptyResultset/EmptyResultset';
import { GenericTableProps, TableItemProps } from '../../models/data/components/genericTable.model';
import { genericSort } from '../../utils/sort.utility';
import { translate } from '../../services/translation.service';
import useTablesState from '../../hooks/useTablesState';
import CsvExport from '../../components/csvExport/CsvExport';
import {
  TableActionButton,
  ActionButton,
  MobileActionPopover,
  ClickableTableRow,
} from '../tableFragments/tableFragments';
import RowsCount from '../rowsCount/RowsCount';
import useUserState from '../../hooks/useUserState';

const GenericTable: React.FC<GenericTableProps> = ({
  items,
  setItems,
  selectedItemId,
  hasSearch,
  hasExport,
  hasItemsPerPageControl,
  hasCheckboxInput,
  handleItemToggle,
  onGlobalItemsChecked,
  onItemSelectClick,
  onBulkDeleteClick,
  onItemDeleteClick,
  onItemEditClick,
  onItemDetailsClick,
  onActionButtonClick,
  onBulkAddClick,
  onItemAddClick,
  onItemGeoLocationClick,
  onItemAddToGroupsClick,
  onItemVisibilityClick,
  tableId,
  isDisabled,
  customTableControl,
  disableTopPagination,
}) => {
  const {
    tableState,
    tableData,
    setFilterText,
    setCurrentPage,
    setSortCriteria,
    clearFilterText,
    setGlobalItemsChecked,
    setPageToCount,
    setItemsPerPage,
  } = useTablesState({
    tableId,
    onBulkAddClick,
    onItemAddClick,
    onBulkDeleteClick,
    onItemDeleteClick,
    onItemDetailsClick,
    onItemEditClick,
    onItemAddToGroupsClick,
    onItemVisibilityClick,
    onItemGeoLocationClick,
  });

  const { isAdmin } = useUserState();
  const { itemsPerPage, filterText, pageToCount, sortCriteria, currentPage, globalChecked } = tableState;

  const { headers, tableHeader, searchFields, buttonTitle, itemActions, bulkActions } = tableData;

  const filterItems = (item: TableItemProps, filterText: string) => {
    let result;

    if (!filterText) {
      return true;
    }

    const searchText = filterText.toLowerCase();

    for (const searchField of searchFields) {
      if (item[searchField] && item[searchField].toLowerCase().includes(searchText)) {
        return (result = item);
      }
    }

    return result;
  };

  const handleGlobalItemsChecked = (event: React.FormEvent<HTMLInputElement>) => {
    if (onGlobalItemsChecked) {
      onGlobalItemsChecked(event);
    } else if (setItems) {
      setItems((prevState) => prevState.map((item) => ({ ...item, isChecked: !!event.currentTarget.checked })));
    }
    setGlobalItemsChecked(!!event.currentTarget.checked);
  };

  const onItemToggleClick = (itemId: string | number | undefined) => {
    if (setItems) {
      setItems((prevState) =>
        prevState.map((item) =>
          (item.id || item.machineId).toString() === itemId
            ? {
                ...item,
                isChecked: !item.isChecked,
              }
            : item
        )
      );
    }
  };

  return (
    <>
      {buttonTitle && onActionButtonClick && (
        <TableActionButton
          title={buttonTitle}
          handleClick={onActionButtonClick}
          disabled={isDisabled}
          data-testid={`genericTable-${buttonTitle}-btn`}
        />
      )}
      <MDBRow between className='mb-2'>
        {hasSearch && (
          <MDBCol sm='12' md={disableTopPagination ? '4' : '3'} lg={disableTopPagination ? '4' : '3'}>
            <TableSearch filterText={filterText} setFilterText={setFilterText} clearSearch={clearFilterText} />
          </MDBCol>
        )}
        {hasItemsPerPageControl && (
          <MDBCol sm='12' md='2' lg='2' className='my-3 my-md-0'>
            <RowsCount onItemsClick={setItemsPerPage} itemsPerPage={itemsPerPage} />
          </MDBCol>
        )}
        {hasExport && items.length > 0 && (
          <MDBCol sm='12' md='3' lg='1'>
            <MDBBtn color='warning' className='export-btn link-decoration'>
              <CsvExport id='geoMachinesCsvHeaders' items={items} fileName='geo-machines' classes='text-white' />
            </MDBBtn>
          </MDBCol>
        )}
        {customTableControl && (
          <MDBCol md='4' sm='12' className='my-3 my-md-0'>
            {customTableControl}
          </MDBCol>
        )}
        {!disableTopPagination && (
          <MDBCol
            lg={
              customTableControl
                ? '7'
                : hasSearch && !hasExport
                ? !hasItemsPerPageControl
                  ? '9'
                  : '7'
                : hasExport
                ? '4'
                : '12'
            }
            md='4'
            sm='12'
          >
            {items.length > 0 && (
              <Pagination
                currentPage={currentPage}
                itemsLength={items.filter((item) => filterItems(item, filterText)).length}
                itemsPerPage={itemsPerPage}
                pageToCount={pageToCount}
                setCurrentPage={setCurrentPage}
                setPageToCount={setPageToCount}
              ></Pagination>
            )}
          </MDBCol>
        )}
      </MDBRow>

      {tableHeader && <HeaderSection text={translate(tableHeader)} />}
      <EmptyResultset entitiesCount={items.length}>
        <MDBTable className='rsrg-table' hover responsive>
          <thead>
            <tr data-testid={`genericTable-${tableId}-head`}>
              {hasCheckboxInput !== undefined && (
                <th className='align-bottom text-left'>
                  <MDBCheckbox
                    data-testid={`genericTable-${tableId}-checkboxHead`}
                    type='checkbox'
                    inline
                    className='pr-0 mr-0'
                    id={`checkboxHeader- ${tableId}`}
                    checked={globalChecked}
                    value={globalChecked.toString()}
                    onChange={handleGlobalItemsChecked}
                    disabled={isDisabled}
                  />
                </th>
              )}

              <th className='th-xs text-left d-table-cell d-md-none'>
                {bulkActions && bulkActions.length > 0 && <MobileActionPopover itemActions={bulkActions} />}
              </th>

              {headers &&
                headers.map((header) => (
                  <TableHeader
                    key={`${header.i18name}-tb-header`}
                    headerText={translate(header.i18name)}
                    sortState={sortCriteria}
                    propertyName={header.propertyName}
                    onSortClick={setSortCriteria}
                  />
                ))}

              {(bulkActions || itemActions) && (
                <th className='text-right d-none d-md-table-cell'>
                  {bulkActions &&
                    bulkActions.map((bulkAction) => (
                      <ActionButton
                        key={bulkAction.title}
                        icon={bulkAction.icon}
                        handleClick={bulkAction.handleClick}
                        title={bulkAction.title}
                        disabled={isDisabled}
                      />
                    ))}
                </th>
              )}
            </tr>
          </thead>

          <MDBTableBody data-testid={`genericTable-${tableId}-tableBody`}>
            {items
              .filter((item) => filterItems(item, filterText))
              .sort((x, y) => genericSort(sortCriteria.order, sortCriteria.column, x, y))
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((sortedItem) => (
                <ClickableTableRow
                  id={sortedItem?.id || sortedItem?.name}
                  key={`${sortedItem?.id || sortedItem.name} ${sortedItem?.name || sortedItem?.time}`}
                  handleClick={onItemSelectClick}
                  isRowSelected={selectedItemId !== undefined && selectedItemId === sortedItem?.id}
                >
                  {hasCheckboxInput !== undefined &&
                    sortedItem?.isProcessed !== undefined &&
                    !sortedItem?.isProcessed && (
                      <td className='align-middle text-left'>
                        <MDBIcon icon='spinner' className='mt-0 orange-icon' />
                      </td>
                    )}
                  {hasCheckboxInput !== undefined &&
                    !(sortedItem?.isProcessed !== undefined && !sortedItem?.isProcessed) && (
                      <td className='align-middle text-left'>
                        <MDBCheckbox
                          data-testid='genericTable-innerInput-checkbox'
                          id={`checkbox${sortedItem.name || sortedItem.id}`}
                          className='pr-0'
                          inline
                          checked={sortedItem.isChecked}
                          value={sortedItem?.id?.toString()}
                          onChange={(e: any) => {
                            if (handleItemToggle) {
                              handleItemToggle(e.currentTarget.value);
                            } else {
                              onItemToggleClick(e.currentTarget.value);
                            }
                          }}
                          disabled={isDisabled}
                        />
                      </td>
                    )}
                  <td className='text-left d-table-cell d-md-none align-middle'>
                    {itemActions && itemActions.length > 0 && (
                      <MobileActionPopover
                        itemActions={itemActions}
                        id={sortedItem?.id}
                        validFrom={sortedItem?.validFrom}
                      />
                    )}
                  </td>

                  {headers &&
                    headers.map(({ propertyName, Component }) => (
                      <Component
                        key={propertyName}
                        rowId={sortedItem?.id}
                        keyId={propertyName}
                        item={sortedItem[propertyName]}
                        date={sortedItem[propertyName]}
                        state={sortedItem.state}
                      />
                    ))}

                  {itemActions && (
                    <td
                      className={`align-middle text-right d-none d-md-table-cell ${
                        itemActions.length === 1
                          ? 'one-action-button'
                          : itemActions.length === 4
                          ? 'four-action-buttons'
                          : 'three-action-buttons'
                      }`}
                    >
                      {sortedItem?.isProcessed !== undefined && !sortedItem?.isProcessed && (
                        <MDBTooltip placement='bottom' tag='span'>
                          <span>
                            <MDBIcon icon='spinner' className='mt-0 orange-icon' />
                          </span>
                          <span>{translate('common.dataSynchronization')}</span>
                        </MDBTooltip>
                      )}

                      {itemActions &&
                        !(sortedItem?.isProcessed !== undefined && !sortedItem?.isProcessed) &&
                        itemActions
                          .filter(
                            (current) =>
                              (current.icon === 'chart-line' && (isAdmin || sortedItem.isDataModuleActive)) ||
                              current.icon !== 'chart-line'
                          )
                          .map((itemAction) => (
                            <ActionButton
                              key={itemAction.title}
                              icon={itemAction.icon}
                              handleClick={() => itemAction.handleClick(sortedItem?.id, sortedItem?.validFrom)}
                              title={itemAction.title}
                              color={itemAction.icon === 'eye' && sortedItem?.isOnMap ? 'grey' : 'orange'}
                              disabled={isDisabled}
                            />
                          ))}
                    </td>
                  )}
                </ClickableTableRow>
              ))}
          </MDBTableBody>
        </MDBTable>
      </EmptyResultset>
      <hr className='my-0' />

      {items.length > 0 && (
        <Pagination
          currentPage={currentPage}
          itemsLength={items.filter((item) => filterItems(item, filterText)).length}
          itemsPerPage={itemsPerPage}
          pageToCount={pageToCount}
          setCurrentPage={setCurrentPage}
          setPageToCount={setPageToCount}
        />
      )}
    </>
  );
};

export default GenericTable;
