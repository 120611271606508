import moment from 'moment';

import { MachineStateType } from '../models/enum/machineStateType.enum';
import { translate } from '../services/translation.service';

export const formatMachineState = (machineState: number | undefined) => {
  return machineState ? MachineStateType[machineState] : MachineStateType[3];
};

export const getDateColor = (date: Date | null | undefined): string => {
  if (!date) {
    return '';
  }

  const currentLocalDate = moment().format();
  const currentParsedDate = Date.parse(currentLocalDate);
  const machineLastDate = Date.parse(date?.toString() || '');
  const differenceInHours = Math.abs(currentParsedDate - machineLastDate) / 36e5;

  if (differenceInHours <= 4) {
    return 'green-text-color';
  }

  if (differenceInHours > 4 && differenceInHours <= 8) {
    return 'orange-text-color';
  }

  return 'red-text-color';
};

export const formatMachineStateAttributes = (machineStateValue: boolean | number | null | undefined): string => {
  if (machineStateValue === false || machineStateValue === 0) {
    return translate('pages.geo.off');
  } else if (machineStateValue === true || machineStateValue === 1) {
    return translate('pages.geo.on');
  } else if (typeof machineStateValue == 'number' && machineStateValue > 0 && machineStateValue < 0.5) {
    return translate('pages.geo.mostlyOn');
  } else if (typeof machineStateValue == 'number' && machineStateValue >= 0.5 && machineStateValue < 1) {
    return translate('pages.geo.mostlyOff');
  } else {
    return translate('common.none');
  }
};

export const formatGpsTime = (gpsTime: Date, fromRangeTime: Date | null) => {
  if (!fromRangeTime) {
    return gpsTime;
  }

  return `${gpsTime} - ${fromRangeTime}`;
};
