import React, { useCallback, useEffect, useState } from 'react';

import { GoogleMap, InfoWindow, LoadScriptNext, Marker, MarkerClusterer, Polyline } from '@react-google-maps/api';

import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';

import './mapGoogle.scss';
import PopupWindow from '../../../../components/popupWindow/PopupWindow';
import MapSettings from '../../../../components/mapSettings/MapSettings';
import { GoogleMapConstants } from '../../../../constants/googleMap.constants';
import { MapMachinesProps } from '../../../../models/props/geo/mapMachines.props';
import {
  getGoogleMapMarkerIcon,
  getGoogleMapUserLocationMarkerIcon,
  getIconAngle,
  googleMapFitBounds,
} from '../../../../utils/map.utility';
import { MachineTrackHistoryModel } from '../../../../models/data/machine/machineTrackHistory.model';
import { MachineModel } from '../../../../models/data/machine/machine.model';
import PopupWindowDetail from '../../../../components/popupWindowDetail/PopupWindowDetail';
import { EventTrackHistoryModel } from '../../../../models/data/machine/eventTrackHistory.model';
import { resetMapState, selectUserLocation, updateMapZoomAndCenterByMapId } from '../../../../store/slices/mapsSlice';
import { getDateColor } from '../../../../utils/formatting.utility';
import { translate } from '../../../../services/translation.service';
import { CommonConstants } from '../../../../constants/common.constants';

const MapGoogle: React.FC<MapMachinesProps> = ({
  mapId,
  selectedMachineId,
  containerStyles,
  zoomToPositionLabel,
  hasUserLocationButton,
  hasLastPositionButton,
  cleanTooltip,
  syncMaps,
  syncTooltips,
  triggerZoomToPosition,
  triggerSelectUserLocation,
}) => {
  const dispatch = useAppDispatch();
  const userLocation = useAppSelector(selectUserLocation);

  const mapStateById = useAppSelector((state) => state.maps[mapId]);
  const { center, zoom, path, positions, activePosition, preventFitBounds, isTooltipOpen } = mapStateById;

  const [forceRerender, setForceRerender] = useState(1);
  const [mapRef, setMapRef] = useState<any>();
  const [forcePolylineRerender, setForcePolylineRerender] = useState(1);
  const [forceInfoWindowRerender, setForceInfoWindowRerender] = useState(1);

  const [polylineSymbol, setPolylineSymbol] = useState<any>();

  const getMachineVisibility = useCallback(() => {
    if (path) {
      return true;
    }

    return !!(positions as any).find((m) => m?.id.toString() === activePosition?.id.toString())?.isOnMap;
  }, [positions, activePosition, path]);

  const handleMapRef = useCallback((map) => {
    setMapRef(map);
  }, []);

  const fitBounds = useCallback(() => {
    if (
      mapRef &&
      !preventFitBounds &&
      (activePosition === undefined ||
        activePosition === null ||
        activePosition?.id < 0 ||
        activePosition?.id === undefined)
    ) {
      googleMapFitBounds(positions, mapId, dispatch, mapRef);
    }
  }, [dispatch, mapId, positions, mapRef, preventFitBounds, activePosition]);

  useEffect(() => {
    if (mapRef && !preventFitBounds && (center === undefined || zoom === undefined)) {
      googleMapFitBounds(positions, mapId, dispatch, mapRef);
    } else if (mapRef && activePosition && zoom === undefined) {
      googleMapFitBounds(positions, mapId, dispatch, mapRef, {
        lat: activePosition.latitude,
        lng: activePosition.longitude,
      });
    }
    setForceInfoWindowRerender((prevState) => prevState + 1);
  }, [isTooltipOpen, activePosition, dispatch, mapId, positions, center, zoom, preventFitBounds, mapRef]);

  useEffect(() => {
    fitBounds();
  }, [fitBounds]);

  const onMarkerClick = (machine) => {
    if (syncTooltips) {
      syncTooltips(true, machine);
    }

    if (syncMaps) {
      syncMaps(machine);
    }
  };

  const onAutoZoomClick = () => {
    syncTooltips(false, -1);
    handleClose();
    dispatch(resetMapState({}));
    fitBounds();
  };

  const onSelectUserLocation = () => {
    triggerSelectUserLocation!();
  };

  const onOpenLastLocationInApp = () => {
    let lastPosition = [...positions].sort((x, y) => Date.parse(y.messageTime) - Date.parse(x.messageTime))[0];
    window.open(`${CommonConstants.GoogleMapsUrl}${lastPosition?.latitude}, ${lastPosition?.longitude}`);
  };

  const handleClose = () => {
    if (cleanTooltip) {
      cleanTooltip();
    }

    syncTooltips(false);
  };

  const handleGoogleMapZoom = useCallback(() => {
    if (!!mapRef?.getZoom() && mapRef?.getZoom() !== zoom) {
      let mapCenter = mapRef.getCenter();
      if (activePosition && activePosition.id === selectedMachineId) {
        mapCenter = { lat: activePosition.latitude, lng: activePosition.longitude };
      }

      dispatch(updateMapZoomAndCenterByMapId({ id: mapId, zoom: mapRef.getZoom(), center: mapCenter }));
      if (mapRef.getZoom() > zoom) {
        setForceRerender((prevState) => prevState + 1);
      }
    }
  }, [mapRef, mapId, zoom, activePosition, dispatch]);

  const handlePolylineRerender = () => {
    setForcePolylineRerender((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (path && mapRef) {
      const { maps } = (window as any).google;
      setPolylineSymbol({
        ...GoogleMapConstants.polylineArrowStyle,
        path: maps.SymbolPath.FORWARD_OPEN_ARROW,
      });
    }
  }, [path, mapRef]);

  const hasAnimation = (machineId: number) => {
    if (mapId === GoogleMapConstants.geoMachinesGoogleMapId) {
      return selectedMachineId === machineId;
    }

    return activePosition?.id === machineId;
  };

  const hasActivePosition = () => {
    if (mapId === GoogleMapConstants.geoMachinesGoogleMapId) {
      return selectedMachineId && selectedMachineId > -1;
    }

    return !!activePosition;
  };

  return (
    <>
      <LoadScriptNext id='google-map' googleMapsApiKey={GoogleMapConstants.apiKey}>
        <GoogleMap
          mapContainerStyle={containerStyles || GoogleMapConstants.containerStyle}
          center={center}
          zoom={zoom}
          onLoad={handleMapRef}
          onZoomChanged={handleGoogleMapZoom}
        >
          <MarkerClusterer onClusteringEnd={handlePolylineRerender} options={GoogleMapConstants.markerClusterOptions}>
            {(clusterer) => (
              <>
                {(positions as (MachineModel | MachineTrackHistoryModel | EventTrackHistoryModel)[]).map(
                  (machine, index) => (
                    <Marker
                      label={
                        (machine as MachineModel)?.nameShort && (machine as MachineModel)?.nameShort !== ''
                          ? {
                              text: (machine as MachineModel)?.nameShort,
                              className: `marker-label ${getDateColor(
                                (machine as MachineModel)?.gpsTime
                              )}-marker-label`,
                            }
                          : undefined
                      }
                      key={`${machine.id} - ${machine.messageTime} - ${
                        activePosition === machine ? forceRerender : ''
                      }`}
                      animation={hasAnimation(machine?.id) && positions.length > 1 ? 1 : 0}
                      icon={getGoogleMapMarkerIcon(
                        machine?.machineState,
                        getIconAngle(machine, mapId),
                        mapId === GoogleMapConstants.geoMachineDetailsGoogleMapId && index === 0
                      )}
                      position={{ lat: machine.latitude, lng: machine.longitude }}
                      onClick={() => onMarkerClick(machine)}
                      clusterer={clusterer}
                    >
                      <div className='marker'></div>
                    </Marker>
                  )
                )}
              </>
            )}
          </MarkerClusterer>

          {hasUserLocationButton && userLocation && (
            <Marker
              icon={getGoogleMapUserLocationMarkerIcon()}
              position={{ lat: userLocation.lat, lng: userLocation.lng }}
            ></Marker>
          )}

          {path && (
            <Polyline
              key={forcePolylineRerender}
              path={path}
              options={{
                ...GoogleMapConstants.polylineStyle,
                icons: [
                  {
                    ...GoogleMapConstants.polylineArrowOptions,
                    icon: polylineSymbol,
                  },
                ],
              }}
            />
          )}

          {isTooltipOpen &&
            activePosition &&
            activePosition.latitude &&
            activePosition.longitude &&
            getMachineVisibility() && (
              <InfoWindow
                key={forceInfoWindowRerender}
                position={{
                  lat: activePosition.latitude,
                  lng: activePosition.longitude,
                }}
                onCloseClick={handleClose}
              >
                <>
                  {path && path.length > 0 ? (
                    <PopupWindowDetail
                      selectedMachine={activePosition}
                      isEventTrackHistoryMap={mapId === GoogleMapConstants.dataMachineDetailsGoogleMapId}
                    />
                  ) : (
                    <PopupWindow selectedMachine={activePosition} />
                  )}
                </>
              </InfoWindow>
            )}
        </GoogleMap>
      </LoadScriptNext>

      <div className='d-flex flex-wrap'>
        <div className='d-flex map-settings-button-wrapper'>
          <MapSettings label={translate('common.autoZoom')} onButtonClick={onAutoZoomClick} />
          {hasActivePosition() && <MapSettings label={zoomToPositionLabel} onButtonClick={triggerZoomToPosition} />}
        </div>
        <div className='d-flex map-settings-button-wrapper'>
          {hasUserLocationButton && userLocation && (
            <MapSettings label={translate('common.userLocation')} onButtonClick={onSelectUserLocation} />
          )}
          {hasLastPositionButton && positions && positions.length > 0 && (
            <MapSettings label={translate('common.viewInApp')} onButtonClick={onOpenLastLocationInApp} />
          )}
        </div>
      </div>
    </>
  );
};

export default MapGoogle;
