import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from './reduxHooks';
import {
  updateTableSortCriteriaById,
  updateBulkTablePropsById,
  updateTableCurrentPageById,
  updateTableGlobalCheckedById,
  updateTablePageToCountById,
  selectTable,
} from '../store/slices/tableSlice';
import { SortCriteria } from '../models/enum/sortCriteria.enum';
import { generateTableData } from '../constants/generateTableData';

export interface GeneratedTableFunctionProps {
  tableId: string;
  onBulkDeleteClick?: () => void;
  onItemDeleteClick?(id: number | string, validFrom?: Date | undefined): void;
  onItemEditClick?: (id: number | string) => void;
  onItemDetailsClick?: (id: number | string) => void;
  onItemAddClick?: (id: number) => void;
  onBulkAddClick?: () => void;
  onItemAddToGroupsClick?: (id: number) => void;
  onItemVisibilityClick?: (id: number) => void;
  onItemGeoLocationClick?: (id: number | string) => void;
}

export default function useTablesState({
  tableId,
  onBulkAddClick,
  onItemAddClick,
  onBulkDeleteClick,
  onItemDeleteClick,
  onItemDetailsClick,
  onItemEditClick,
  onItemAddToGroupsClick,
  onItemVisibilityClick,
  onItemGeoLocationClick,
}: GeneratedTableFunctionProps) {
  const tableState = useAppSelector((state) => selectTable(state, tableId));
  const dispatch = useAppDispatch();

  const tableData = useMemo(() => {
    const generatedTableData = generateTableData({
      onBulkAddClick,
      onItemAddClick,
      onBulkDeleteClick,
      onItemDeleteClick,
      onItemDetailsClick,
      onItemEditClick,
      onItemAddToGroupsClick,
      onItemVisibilityClick,
      onItemGeoLocationClick,
    });
    return generatedTableData[tableId];
  }, [
    onBulkAddClick,
    onBulkDeleteClick,
    onItemAddClick,
    onItemDeleteClick,
    onItemDetailsClick,
    onItemEditClick,
    onItemAddToGroupsClick,
    onItemVisibilityClick,
    onItemGeoLocationClick,
    tableId,
  ]);

  const setFilterText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const changedSearchText = event.target.value;
      dispatch!(updateBulkTablePropsById({ id: tableId, props: { filterText: changedSearchText, currentPage: 1 } }));
    },
    [dispatch, tableId]
  );

  const setSortCriteria = useCallback(
    (event: React.MouseEvent) => {
      const column = event.currentTarget.id;

      dispatch!(
        updateTableSortCriteriaById({
          id: tableId,
          sortCriteria: {
            column: column,
            order: tableState.sortCriteria.order > 1 ? SortCriteria.Ascending : SortCriteria.Descending,
          },
        })
      );
    },
    [dispatch, tableId, tableState.sortCriteria.order]
  );

  const setCurrentPage = useCallback(
    (page: number) => {
      dispatch!(
        updateTableCurrentPageById({
          id: tableId,
          currentPage: page,
        })
      );
    },
    [dispatch, tableId]
  );

  const setPageToCount = useCallback(
    (page: number) => {
      dispatch!(
        updateTablePageToCountById({
          id: tableId,
          pageToCount: page,
        })
      );
    },
    [dispatch, tableId]
  );

  const clearFilterText = useCallback(() => {
    dispatch!(updateBulkTablePropsById({ id: tableId, props: { filterText: '', currentPage: 1, pageToCount: 10 } }));
  }, [dispatch, tableId]);

  const setGlobalItemsChecked = useCallback(
    (isChecked: boolean) => {
      dispatch!(
        updateTableGlobalCheckedById({
          id: tableId,
          globalChecked: isChecked,
        })
      );
    },
    [dispatch, tableId]
  );

  const setItemsPerPage = useCallback(
    (item: number) => {
      dispatch!(
        updateBulkTablePropsById({ id: tableId, props: { currentPage: 1, pageToCount: 10, itemsPerPage: item } })
      );
    },
    [dispatch, tableId]
  );

  return {
    tableState,
    tableData,
    setFilterText,
    setSortCriteria,
    clearFilterText,
    setCurrentPage,
    setGlobalItemsChecked,
    setPageToCount,
    setItemsPerPage,
  };
}
