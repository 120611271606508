import React from 'react';

import { MDBFooter } from 'mdbreact';

const Footer: React.FC<{ style: any; className: string }> = ({ style, className }) => {
  const date = new Date().getFullYear();
  const version = process.env.REACT_APP_APP_PRODUCT_VERSION;

  return (
    <MDBFooter className={`${className} footer-position`} style={{ ...style }}>
      <div className='mb-0 py-3 text-center footer-colors'>
        Machine BI v{version} &copy; {date} Copyright:{' '}
        <a href='https://rsrg.com' className='text-white'>
          {' '}
          Rhomberg Sersa Rail Group{' '}
        </a>
      </div>
    </MDBFooter>
  );
};

export default Footer;
