import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { translate } from '../../services/translation.service';

import {
  getMachineOwners,
  getMachineOwnerById,
  addMachineOwner,
  editMachineOwner,
  getMachineOwnerByMachine,
  deleteMachineOwner,
} from '../../services/machineOwners.service';
import { MachineOwnerModel, MachineOwnerResponseModel } from '../../models/data/machine/machineOwner.model';

export const getAllMachineOwners = createAsyncThunk<{ machineOwners: MachineOwnerModel[] }, undefined>(
  'machineOwners/getAllMachineOwners',
  async () => {
    const allMachineOwners = await trackPromise(getMachineOwners());
    const machineOwners = allMachineOwners.map((machineOwner) => ({
      ...machineOwner?.contactPerson,
      ...machineOwner,
      isChecked: false,
    }));

    return { machineOwners };
  }
);

export const getMachineOwner = createAsyncThunk<{ machineOwner: MachineOwnerModel }, { machineOwnerId: number }>(
  'machineOwners/getMachineOwner',
  async ({ machineOwnerId }) => {
    const machineOwner = await trackPromise(getMachineOwnerById(machineOwnerId));

    for (const machine of machineOwner.machines!) {
      let i = 0;
      machine.id = i;
      i++;
    }

    return { machineOwner };
  }
);

export const getMachineOwnerByMachineId = createAsyncThunk<{ machineOwner: MachineOwnerModel }, { machineId: number }>(
  'machineOwners/getMachineOwnerByMachineId',
  async ({ machineId }) => {
    const machineOwner = await trackPromise(getMachineOwnerByMachine(machineId));

    for (const machine of machineOwner.machines!) {
      let i = 0;
      machine.id = i;
      i++;
    }

    return { machineOwner };
  }
);

export const createMachineOwner = createAsyncThunk<
  { machineOwner: MachineOwnerResponseModel },
  { machineOwnerData: MachineOwnerResponseModel }
>('machineOwners/createMachineOwner', async ({ machineOwnerData }) => {
  const machineOwner = await trackPromise(addMachineOwner(machineOwnerData));

  toast.success(translate('pages.admin.createMachineOwners'));

  return { machineOwner };
});

export const updateMachineOwner = createAsyncThunk<
  { machineOwner: MachineOwnerResponseModel },
  { machineOwnerData: MachineOwnerResponseModel }
>('machineOwners/updateMachineOwner', async ({ machineOwnerData }) => {
  const machineOwner = await trackPromise(editMachineOwner(machineOwnerData));

  toast.success(translate('pages.admin.editMachineOwnerSuccessfully'));

  return { machineOwner };
});

export const getMachineOwnerPolling = createAsyncThunk<{ machineOwner: MachineOwnerModel }, { machineOwnerId: number }>(
  'machineOwners/getMachineOwnerPolling',
  async ({ machineOwnerId }) => {
    const machineOwner = await getMachineOwnerById(machineOwnerId);

    return { machineOwner };
  }
);

export const getAllMachineOwnersPolling = createAsyncThunk<{ machineOwners: MachineOwnerModel[] }, undefined>(
  'machineOwners/getAllMachineOwnersPolling',
  async () => {
    const allMachineOwners = await getMachineOwners();
    const machineOwners = allMachineOwners.map((machineOwner) => ({
      ...machineOwner?.contactPerson,
      ...machineOwner,
      isChecked: false,
    }));

    return { machineOwners };
  }
);

export const deleteMachineOwnerById = createAsyncThunk<
  { deletedMachineOwnerIds: number[] },
  { machineOwnerId: number }
>('machineOwners/deleteMachineOwner', async ({ machineOwnerId }) => {
  const deletedMachineOwnerIds = await trackPromise(deleteMachineOwner([machineOwnerId]));

  if (deletedMachineOwnerIds.length === 0) {
    toast.error(translate('pages.admin.deleteMachineOwnerFailed'));
  } else {
    toast.success(translate('pages.admin.deleteMachineOwnerSuccess'));
  }

  return { deletedMachineOwnerIds };
});

export const bulkDeleteMachineOwners = createAsyncThunk<
  { deletedMachineOwnerIds: number[] },
  { machineOwnerIds: number[] }
>('machineOwners/bulkDeleteMachineOwners', async ({ machineOwnerIds }) => {
  const deletedMachineOwnerIds = await trackPromise(deleteMachineOwner(machineOwnerIds));

  if (deletedMachineOwnerIds.length === 0) {
    toast.error(translate('pages.admin.noMachineOwnersDeleted'));
  } else if (deletedMachineOwnerIds.length !== machineOwnerIds.length) {
    toast.warn(translate('pages.admin.notAllMachineOwnersDeleted'));
  } else {
    toast.success(translate('pages.admin.allMachineOwnersDeleted'));
  }

  return { deletedMachineOwnerIds };
});
