import React, { useEffect } from 'react';

import { logoutUser } from '../services/auth.service';
import useUserState from '../hooks/useUserState';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { addUserStatisticHistory } from '../store/api/userStatistics.service';
import { selectUserStatistics } from '../store/slices/userStatisticsSlice';

const ProtectedRoute: React.FC<{ role: string; render: () => any }> = ({ role, render }) => {
  const dispatch = useAppDispatch();
  const userStatisticHistory = useAppSelector(selectUserStatistics);
  const { userState } = useUserState();

  useEffect(() => {
    const isInRole = userState.roles?.map((r) => r.toLowerCase()).includes(role);
    if (!isInRole) {
      const addUserStatisticHistoryAsync = async () => {
        await dispatch(addUserStatisticHistory(userStatisticHistory));
      };

      addUserStatisticHistoryAsync().then(() => logoutUser(true));
    }
  }, [role, userState.roles]); // eslint-disable-line

  return render();
};

export default ProtectedRoute;
