import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBBtn } from 'mdbreact';

import './popupWindow.scss';
import { translate } from '../../services/translation.service';
import DateFormatter from '../dateFormatter/DataFormatter';
import { formatMachineStateAttributes } from '../../utils/formatting.utility';

const PopupWindow: React.FC<{ selectedMachine: any; activeMap?: number }> = ({ selectedMachine, activeMap = 1 }) => {
  const navigate = useNavigate();

  const goToMachineDetails = (machineId: number) => {
    if (activeMap === 2) {
      navigate('/geo/machine/' + machineId + '?activeMap=' + activeMap);
    } else {
      navigate('/geo/machine/' + machineId);
    }
  };

  return (
    <div className='text-left'>
      <div>
        {translate('common.name')}: {selectedMachine.name}
      </div>
      <div className='d-flex'>
        <span className='mr-2'>{translate('common.lastMessage')}:</span>{' '}
        <DateFormatter date={selectedMachine?.messageTime} />
      </div>
      <div className='d-flex'>
        <span className='mr-2'> {translate('common.lastGpsPosition')}:</span>{' '}
        <DateFormatter date={selectedMachine?.gpsTime} />
      </div>
      <div>
        {translate('common.machinePower')}: {formatMachineStateAttributes(selectedMachine?.power)}
      </div>{' '}
      <div>
        {translate('common.machineEngine')}: {formatMachineStateAttributes(selectedMachine?.engine)}
      </div>{' '}
      <div>
        {translate('common.machineBrake')}: {formatMachineStateAttributes(selectedMachine?.brake)}
      </div>{' '}
      <div>
        {translate('common.machineGear')}: {formatMachineStateAttributes(selectedMachine?.gear)}
      </div>
      <div>
        {translate('pages.geo.systemName')}: {selectedMachine.systemName}
      </div>
      <hr className='mt-2 mb-2' />
      <div className='w-100 p-1'>
        <MDBBtn
          className='btn-go-to-details w-100'
          color='warning'
          onClick={() => goToMachineDetails(selectedMachine.id)}
        >
          {translate('common.viewDetails')}
        </MDBBtn>
      </div>
      <div className='w-100 p-1'>
        <MDBBtn
          className='btn-go-to-details w-100'
          color='warning'
          href={`https://www.google.com/maps/search/?api=1&query=${selectedMachine?.latitude}, ${selectedMachine?.longitude}`}
          target='_blank'
        >
          {translate('common.viewInApp')}
        </MDBBtn>
      </div>
    </div>
  );
};

export default PopupWindow;
