import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBContainer,
  MDBRow,
} from 'mdbreact';

import './topNavigation.scss';
import { CommonConstants } from '../../constants/common.constants';
import { TopNavigationProps } from '../../models/props/common/topNavigation.props';
import { logoutUser } from '../../services/auth.service';
import { translate } from '../../services/translation.service';
import useUserState from '../../hooks/useUserState';
import { mapNumberToLangCode } from '../../utils/language.utility';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { selectUserStatistics } from '../../store/slices/userStatisticsSlice';
import { addUserStatisticHistory } from '../../store/api/userStatistics.service';

const TopNavigation: React.FC<TopNavigationProps> = ({ toggle, routeName, setTriggerOpening, machineName }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userState } = useUserState();
  const userStatisticHistory = useAppSelector(selectUserStatistics);

  const navStyle = {
    paddingLeft: toggle ? '16px' : '240px',
    transition: 'padding-left .3s',
  };
  const handleDownloadUserGuide = () => {
    const userLanguage = mapNumberToLangCode(userState.language);
    const pdfUrl = `/pdf/${userLanguage}/userGuide.pdf`;

    window.open(pdfUrl, '_blank');
  };

  const navigateToUserDetails = () => {
    navigate('/profile');
  };

  const logout = async () => {
    await dispatch(addUserStatisticHistory(userStatisticHistory));
    logoutUser(true);
  };

  return (
    <MDBNavbar className='flexible-navbar gradient-card-header peach-gradient' fixed='top'>
      <MDBNavbarItem className='d-flex'>
        <div
          key='sideNavToggleA'
          className='clickable side-nav-toggle-a'
          onClick={() => setTriggerOpening((state) => !state)}
          color='none'
          data-testid='TopNavigation-HamburgerToggle-Btn'
        >
          <MDBIcon icon='bars' />
        </div>

        <MDBNavbarBrand style={navStyle}>
          <MDBContainer className='p-0 d-flex d-sm-flex d-md-flex flow-column align-items-start align-items-lg-start'>
            <MDBRow className='p-0 m-0 fw-bold machine-name-wrap'>{routeName}</MDBRow>
            <MDBRow className='p-0 m-0 fw-bold machine-name-wrap'>{machineName}</MDBRow>
          </MDBContainer>
        </MDBNavbarBrand>
      </MDBNavbarItem>

      <MDBNavbarNav right={true} className='justify-content-end flex-row align-items-center'>
        <MDBNavbarItem className='mx-3 mailto'>
          <MDBNavbarLink className='text-dark' href={`mailto:${CommonConstants.supportEmail}`}>
            <MDBIcon className='mx-2' icon='envelope' color='dark' />
            <span className='d-none d-md-inline'>{CommonConstants.supportEmail}</span>
          </MDBNavbarLink>
        </MDBNavbarItem>

        {pathname !== '/user-not-synchronized' && (
          <MDBDropdown animation className='clickable'>
            <MDBDropdownToggle tag='section'>
              <MDBIcon icon='user' />
              <span className='d-none d-md-inline mx-2'>{userState.email}</span>
            </MDBDropdownToggle>
            <MDBDropdownMenu className='p-2'>
              <MDBDropdownItem className='p-2 yellow-on-hover' childTag='button' onClick={logout}>
                {translate('common.logout')}
              </MDBDropdownItem>
              <MDBDropdownItem className='p-2 yellow-on-hover' childTag='button' onClick={navigateToUserDetails}>
                {translate('common.myAccount')}
              </MDBDropdownItem>
              <MDBDropdownItem className='p-2 yellow-on-hover' childTag='button' onClick={handleDownloadUserGuide}>
                {translate('common.userGuide')}
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        )}
      </MDBNavbarNav>
    </MDBNavbar>
  );
};

export default TopNavigation;
