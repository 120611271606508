import React from 'react';

import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter } from 'mdbreact';

import { translate } from '../../services/translation.service';

const AppGenericPopup: React.FC<{ isOpen: boolean; text: string; onClose: () => void }> = ({
  isOpen,
  text,
  onClose,
}) => {
  return (
    <MDBModal animationDirection='top' backdrop={true} show={isOpen}>
      <MDBModalDialog centered={true}>
        <MDBModalContent>
          <MDBModalBody className='modal-body modal-text pt-4'>{text}</MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color='warning' className='text-white' onClick={onClose}>
              {translate('common.ok')}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default AppGenericPopup;
