import { TimeRangeEnum } from '../models/enum/timeRange.enum';

export const DURATION_FILTER_OPTIONS = [
  {
    value: '0',
    text: 'pages.geo.selectFilter',
    checked: false,
    disabled: true,
  },
  {
    value: TimeRangeEnum.Last12Hours.toString(),
    text: 'pages.geo.last12Hours',
    checked: false,
  },
  {
    value: TimeRangeEnum.LastDay.toString(),
    text: 'pages.geo.lastDay',
    checked: true,
  },
  {
    value: TimeRangeEnum.LastTwoDays.toString(),
    text: 'pages.geo.lastTwoDays',
    checked: false,
  },
  {
    value: TimeRangeEnum.LastWeek.toString(),
    text: 'pages.geo.lastWeek',
    checked: false,
  },
  {
    value: TimeRangeEnum.LastMonth.toString(),
    text: 'pages.geo.lastMonth',
    checked: false,
  },
  {
    value: TimeRangeEnum.Last2Months.toString(),
    text: 'pages.geo.last2Months',
    checked: false,
  },
  {
    value: TimeRangeEnum.Last3Months.toString(),
    text: 'pages.geo.last3Months',
    checked: false,
  },
  {
    value: TimeRangeEnum.Last6Months.toString(),
    text: 'pages.geo.last6Months',
    checked: false,
  },
  {
    value: TimeRangeEnum.Last1Year.toString(),
    text: 'pages.geo.Last1Year',
    checked: false,
  },
  {
    value: TimeRangeEnum.ThisYear.toString(),
    text: 'pages.geo.thisYear',
    checked: false,
  },
];
