import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';

import { toast } from 'react-toastify';
import { RootState } from '../store';
import { addMachinesToGroup, removeMachineFromGroup } from '../../services/groupMachine.service';
import { getAvailableMachines } from '../../services/groupMachine.service';
import { translate } from '../../services/translation.service';
import { UserGroupModel } from '../../models/data/group/userGroup.model';
import { AvailableMachineModel } from '../../models/data/machine/availableMachine.model';
import {
  addUserGroup,
  deleteUserGroups,
  editFlagUserGroup,
  getUserGroups,
  editUserGroup,
} from '../../services/group.service';

export const editUserMachineGroupFlag = createAsyncThunk<{ groups: UserGroupModel[] }, { groupId: string }>(
  'userGroups/editUserMachineGroupFlag',
  async ({ groupId }) => {
    const groups = await trackPromise(editFlagUserGroup(groupId));
    toast.success(translate('pages.profile.editGroupFlag'));

    return { groups };
  }
);

export const addUserMachineGroup = createAsyncThunk<
  { groups: UserGroupModel[] },
  { groupName: string; machineIds: number[] }
>('userGroups/addUserMachineGroup', async ({ groupName, machineIds }) => {
  const groups = await trackPromise(addUserGroup(groupName, machineIds));
  toast.success(translate('pages.profile.addGroup'));

  return { groups };
});

export const editUserMachineGroup = createAsyncThunk<
  { groups: UserGroupModel[] },
  { groupId: string; groupName: string; machineIds: number[] }
>('userGroups/editUserMachineGroup', async ({ groupId, groupName, machineIds }) => {
  const groups = await trackPromise(editUserGroup(groupId, groupName, machineIds));
  toast.success(translate('pages.profile.editMachineGroup'));

  return { groups };
});

export const removeUserMachineGroup = createAsyncThunk<{ groupId: string }, { groupId: string }>(
  'userGroups/removeUserMachineGroup',
  async ({ groupId }) => {
    await trackPromise(deleteUserGroups([groupId]));
    toast.success(translate('pages.profile.deleteGroup'));

    return { groupId };
  }
);

export const bulkRemoveUserMachineGroup = createAsyncThunk<{ groupIds: string[] }, { groupIds: string[] }>(
  'userGroups/bulkRemoveUserMachineGroup',
  async ({ groupIds }) => {
    await trackPromise(deleteUserGroups(groupIds));
    toast.success(translate('pages.profile.deleteGroup'));

    return { groupIds };
  }
);

export const getUserMachineGroups = createAsyncThunk<
  { groups: UserGroupModel[]; availableMachines: AvailableMachineModel[] },
  undefined
>('userGroups/getUserMachineGroups', async () => {
  const [groups, availableMachines] = await trackPromise(Promise.all([getUserGroups(), getAvailableMachines()]));

  for (const machine of availableMachines) {
    machine.isChecked = false;
  }

  return { groups, availableMachines };
});

export const addMachinesToUserGroup = createAsyncThunk<
  { groupId: string; machinesToAdd: AvailableMachineModel[] },
  { groupId: string; machineIds: number[] },
  { state: RootState }
>('userGroups/addMachinesToUserGroup', async ({ groupId, machineIds }, thunkApi) => {
  await trackPromise(addMachinesToGroup(groupId, machineIds));

  toast.success(translate('pages.profile.addMachine'));

  const { availableMachines } = thunkApi.getState().userGroups;

  const machinesToAdd = availableMachines?.filter((machine) => machineIds.includes(machine.id));

  return { groupId, machinesToAdd };
});

export const removeMachinesFromUserGroup = createAsyncThunk<
  { groupId: string; machineIds: number[] },
  { groupId: string; machineIds: number[] }
>('userGroups/removeMachinesFromUserGroup', async ({ groupId, machineIds }) => {
  await trackPromise(removeMachineFromGroup(groupId, machineIds));
  toast.success(translate('pages.profile.removeMachine'));

  return { groupId, machineIds };
});
