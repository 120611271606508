import { translate } from '../../../../services/translation.service';

export let languages = [
  {
    text: 'Select language',
    value: '0',
    disabled: true,
    defaultSelected: false,
  },
  {
    text: 'German',
    value: 2,
    icon: '/assets/flags/de.png',
    defaultSelected: false,
  },
  {
    text: 'English',
    value: 1,
    icon: '/assets/flags/gb.png',
    defaultSelected: false,
  },
  {
    text: 'French',
    value: 4,
    icon: '/assets/flags/fr.png',
    defaultSelected: false,
  },
  {
    text: 'Italian',
    value: 3,
    icon: '/assets/flags/it.png',
    defaultSelected: false,
  },
];

export const getLanguageTranslatedText = (value: string | number): string => {
  switch (+value) {
    case 0:
      return translate('pages.profile.selectLanguage');
    case 1:
      return translate('translation.en');
    case 2:
      return translate('translation.de');
    case 3:
      return translate('translation.it');
    case 4:
      return translate('translation.fr');
    default:
      return translate('translation.en');
  }
};
