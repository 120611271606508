import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';

import {
  getAllContracts,
  getContract,
  getContractMachines,
  getContractUsers,
  getAvailableContractMachines,
  getAvailableContractUsers,
  addContract,
  editContract,
  deleteContracts,
} from '../../services/contract.service';
import { getContactPersons } from '../../services/contactPersons.service';
import { translate } from '../../services/translation.service';
import { ContractModel, CreateContractModel } from '../../models/data/contract/contract.model';
import { ContactPersonModel } from '../../models/data/contract/contactPerson.model';
import { ContractMachineModel } from '../../models/data/contract/contractMachine.model';
import { ContractUserModel } from '../../models/data/contract/contractUser.model';

export const getContracts = createAsyncThunk<{ contracts: ContractModel[] }, undefined>(
  'contracts/getContracts',
  async () => {
    const contracts = await trackPromise(getAllContracts());

    for (const contract of contracts) {
      contract.isChecked = false;
    }

    return { contracts };
  }
);

export const getContractById = createAsyncThunk<
  {
    contract: ContractModel;
    contractMachines: ContractMachineModel[];
    users: ContactPersonModel[];
    contactPersons: ContactPersonModel[];
  },
  { contractId: number }
>('contracts/getContractById', async ({ contractId }) => {
  const [contract, contractMachines, users, contactPersons] = await trackPromise(
    Promise.all([
      getContract(contractId),
      getContractMachines(contractId),
      getContractUsers(contractId),
      getContactPersons(),
    ])
  );

  return { contract, contractMachines, users, contactPersons };
});

export const getAvailableMachines = createAsyncThunk<
  { availableMachines: ContractMachineModel[] },
  { contractId: number }
>('contracts/getAvailableMachines', async ({ contractId }) => {
  const availableMachines = await trackPromise(getAvailableContractMachines(contractId));

  return { availableMachines };
});

export const getAvailableUsers = createAsyncThunk<{ availableUsers: ContractUserModel[] }, { contractId: number }>(
  'contracts/getAvailableUsers',
  async ({ contractId }) => {
    const availableUsers = await trackPromise(getAvailableContractUsers(contractId));

    return { availableUsers };
  }
);

export const createContract = createAsyncThunk<
  { contract: CreateContractModel },
  { contractData: CreateContractModel }
>('contracts/createContract', async ({ contractData }) => {
  const contract = await trackPromise(addContract(contractData));

  toast.success(translate('pages.admin.createContractSuccessfully'));

  return { contract };
});

export const updateContract = createAsyncThunk<
  { updatedContract: ContractModel; machineIds?: number[]; userIds?: number[]; contactPersons: ContactPersonModel[] },
  { contractData: CreateContractModel }
>('contracts/updateContract', async ({ contractData }) => {
  const contract = await trackPromise(editContract(contractData));
  const { machineIds, userIds, ...updatedContract } = contract;

  const contactPersons = await trackPromise(getContactPersons());

  toast.success(translate('pages.admin.editContractSuccessfully'));

  return { updatedContract: updatedContract as ContractModel, machineIds, userIds, contactPersons };
});

export const removeContract = createAsyncThunk<{ response: boolean; contractId: number }, { contractId: number }>(
  'contracts/removeContract',
  async ({ contractId }) => {
    const response = await trackPromise(deleteContracts([contractId]));

    toast.success(translate('pages.admin.deleteContract'));

    return { response, contractId };
  }
);

export const removeContracts = createAsyncThunk<
  { response: boolean; contractIds: number[] },
  { contractIds: number[] }
>('contracts/removeContracts', async ({ contractIds }) => {
  const response = await trackPromise(deleteContracts(contractIds));

  toast.success(translate('pages.admin.bulkDeleteContracts'));

  return { response, contractIds };
});

export const getContractsPolling = createAsyncThunk<{ contracts: ContractModel[] }, undefined>(
  'contracts/getContractsPolling',
  async () => {
    const contracts = await getAllContracts();

    return { contracts };
  }
);

export const getContractByIdPolling = createAsyncThunk<{ contract: ContractModel }, { contractId: number }>(
  'contracts/getContractByIdPolling',
  async ({ contractId }) => {
    const contract = await getContract(contractId);

    return { contract };
  }
);
