import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBModalFooter } from 'mdbreact';

import { translate } from '../../../services/translation.service';
import GenericTable from '../../../components/genericTable/genericTable';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { getAttributesByMachines } from '../../../store/api/attributes.service';
import { updateAttributeChecked, updateGlobalAttributeChecked } from '../../admin/Attributes/attributes.slice';
import { addSensors, selectAvailableSensors, selectMachines, setDisplayInfoMessage } from './sensorGroups.slice';
import useAttributeMappings from '../../../hooks/useAttributeMappings';
import { updateTableGlobalCheckedById } from '../../../store/slices/tableSlice';
import { getUserSensorGroupById } from '../../../store/api/userSensorGroups.service';

const AddProfileGroupSensor: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const machines = useAppSelector(selectMachines);
  const sensors = useAppSelector(selectAvailableSensors);
  const { mapAttributes } = useAttributeMappings();

  const { groupId: groupIdParam } = useParams<{ groupId: string }>();

  const groupId = useMemo(() => +groupIdParam!, [groupIdParam]);

  useEffect(() => {
    if (machines.length === 0 && groupId) {
      dispatch(getUserSensorGroupById({ sensorGroupId: groupId }));
    }

    if (machines) {
      dispatch(
        getAttributesByMachines({
          getOnlyNumericAttributes: true,
          machineIds: machines.map((m) => m.id),
        })
      );
    }

    return () => {
      dispatch(
        updateTableGlobalCheckedById({
          id: 'profileGroupAddSensors',
          globalChecked: false,
        })
      );
    };
  }, [machines, groupId, dispatch]);

  const handleAttributeCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) {
        dispatch!(updateAttributeChecked(id));
      }
    },
    [dispatch]
  );

  const handleAttributeGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;

      dispatch(updateGlobalAttributeChecked({ isChecked: !!checked }));
    },
    [dispatch]
  );

  const addSelectedSensors = () => {
    if (!sensors) {
      toast.info(translate('pages.admin.noSelectedThemeAttributesForAdd'));
      return;
    }

    const selectedSensors = sensors.filter((m) => m.isChecked);

    if (selectedSensors.length === 0) {
      toast.info(translate('pages.admin.noSelectedThemeAttributesForAdd'));
      return;
    }

    dispatch(setDisplayInfoMessage(true));
    dispatch(addSensors({ selectedSensors: selectedSensors.map((a) => ({ ...a, isChecked: false })) }));
    navigate(`/profile/group/${groupId}`);
  };

  const onCancel = () => {
    dispatch(setDisplayInfoMessage(true));
    navigate(`/profile/group/${groupId}`);
  };

  return (
    <>
      <MDBRow>
        <MDBCol md='12'>
          <MDBCard>
            <MDBCardBody>
              <MDBRow className='pb-4'>
                <MDBCol md='4' className='text-left d-flex align-items-center'>
                  <MDBIcon icon='file-signature' color='dark' className='p-4 shadow-3-strong bg-warning rounded' />
                  <h5 className='font-weight-bold dark-grey-text ml-4'>{translate('pages.profile.addSensors')}</h5>
                </MDBCol>
              </MDBRow>

              <GenericTable
                tableId={'profileGroupAddSensors'}
                items={mapAttributes(sensors || [])}
                hasSearch={true}
                hasItemsPerPageControl={true}
                hasCheckboxInput={true}
                handleItemToggle={handleAttributeCheckedToggle}
                onGlobalItemsChecked={handleAttributeGlobalCheckedToggle}
              />
            </MDBCardBody>

            <MDBModalFooter>
              <MDBBtn color='warning' className='text-white' onClick={addSelectedSensors} data-testid='AddProfileGroupSensor-addSelected-btn'>
                {translate('common.addSelected')}
              </MDBBtn>
              <MDBBtn
                outline
                color='warning'
                className='cancel-onHover'
                onClick={onCancel}
                data-testid='AddProfileGroupSensor-cancel-btn'
              >
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default AddProfileGroupSensor;
