import React, { useEffect, useRef } from 'react';

import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsDataExporting from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';

import './chart.scss';
import { ChartProps } from '../../models/props/components/chart.props';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectTimeStamps } from '../../pages/data/Details/dataDetails.slice';

Highcharts.Pointer.prototype.reset = function () {
  return undefined;
};

HighchartsExporting(Highcharts);
HighchartsDataExporting(Highcharts);
HighchartsMore(Highcharts);

const Chart: React.FC<ChartProps> = ({ chartOptions, onChartCreated }) => {
  const chartRef = useRef<Highcharts.Chart>();
  const timeStamps = useAppSelector(selectTimeStamps);

  useEffect(() => {
    if (timeStamps.length && chartRef.current && chartRef.current.renderer) {
      chartRef.current.tooltip.hide();
      chartRef.current.hideData();
    }
  }, [timeStamps.length, chartOptions]);

  const afterChartCreated = (chart) => {
    chartRef.current = chart;
    onChartCreated(chart);
  };

  return (
    <div>
      <HighchartsReact options={chartOptions} highcharts={Highcharts} callback={afterChartCreated} />
    </div>
  );
};

export default Chart;
