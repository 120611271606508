import React from 'react';

import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter } from 'mdbreact';

import { translate } from '../../services/translation.service';
import useModalState from '../../hooks/useModalState';

const AppModal: React.FC<{ render: () => any }> = ({ render }) => {
  const { modalState, setModal } = useModalState();
  const closeModal = () => {
    setModal(false, null);
  };

  const confirmModal = () => {
    modalState.modal.callbackFunc();
    closeModal();
  };

  return (
    <>
      {render()}
      <MDBModal
        tabIndex='-1'
        show={modalState.modal.isOpen}
        setShow={() => closeModal}
        data-testid='AppModal-Wrapper-Modal'
      >
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalBody className='modal-text pt-4' data-testid='AppModal-Body-Modal'>
              {translate('common.modalText')}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='warning' className='text-white' onClick={confirmModal} data-testid='AppModal-Continue-Btn'>
                {translate('common.continue')}
              </MDBBtn>
              <MDBBtn
                outline
                color='warning'
                className='cancel-onHover'
                onClick={closeModal}
                data-testid='AppModal-Cancel-Btn'
              >
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default AppModal;
