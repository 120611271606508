import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBCol, MDBRow, MDBCard, MDBCardBody } from 'mdbreact';

import { toast } from 'react-toastify';
import { translate } from '../../services/translation.service';
import useModalState from '../../hooks/useModalState';
import useUserGroupState from '../../hooks/useUserMachineGroups';
import BackToPage from '../../components/backToPage/BackToPage';
import GenericTable from '../../components/genericTable/genericTable';
import MachineGroupDetail from './components/machineGroupDetail/MachineGroupDetail';
import { uncheckTableGlobalCheckedById } from '../../store/slices/tableSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import {
  clearSelectedGroup,
  selectSelectedGroup,
  selectSelectedGroupMachines,
  setDisplayInfoMessage,
  setSelectedGroup,
  toggleGlobalMachinesChecked,
  toggleMachineChecked,
} from '../../store/slices/userGroupsSlice';

const MachineGroupDetails: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setModal } = useModalState();
  const { groupId: groupIdParam } = useParams<{ groupId: string }>();
  const groupId = useMemo(() => +groupIdParam!, [groupIdParam]);
  const selectedGroup = useAppSelector(selectSelectedGroup);
  const selectedGroupMachines = useAppSelector(selectSelectedGroupMachines);

  
  const { userGroups, handleAddUserMachineGroup, handleEditUserMachineGroup, handleRemoveGroupMachines } =
    useUserGroupState();

  useEffect(() => {
    if (groupId && groupId !== selectedGroup.id) {
      dispatch(setSelectedGroup(+groupId));
    }

    return () => {
      dispatch(uncheckTableGlobalCheckedById('profileUserGroupMachines'));
    };
  }, [groupId, selectedGroup.id, dispatch]);

  const onSubmitGroup = async (name: string, groupIsNew: boolean) => {
    const userGroup = userGroups.find((g) => g.name.toLowerCase().trim() === name.toLowerCase().trim());

    if (userGroup && (groupIsNew || userGroup.id !== +groupId)) {
      toast.warn(translate('common.nameAlreadyExists'));
      return;
    }

    const machineIds = selectedGroupMachines.map((m) => +m.id);
    if (groupIsNew) {
      const updatedUserGroups = await handleAddUserMachineGroup(name, machineIds);
      const newGroup = updatedUserGroups.groups.find((group) => group.name === name);

      navigate(`/profile/machine-group/${newGroup?.id}`);
    } else {
      handleEditUserMachineGroup(groupId.toString(), name, machineIds);
    }
  };

  const handleMachineCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) {
        dispatch!(toggleMachineChecked(+id));
      }
    },
    [dispatch]
  );

  const handleMachineGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;

      dispatch(toggleGlobalMachinesChecked({ isChecked: !!checked }));
    },
    [dispatch]
  );

  const onMachineDeleteClick = (machineId: number) => {
    setModal(true, () => {
      handleRemoveGroupMachines([machineId]);
      dispatch(setDisplayInfoMessage(true));
    });
  };

  const onMachineBulkDeleteClick = () => {
    const selectedMachineIds = selectedGroup.machines.filter((m) => m.isChecked).map((m) => +m.id);

    if (selectedMachineIds.length === 0) {
      toast.info(translate('pages.profile.noSelectedMachinesRemove'));
      return;
    }

    setModal(true, () => {
      handleRemoveGroupMachines(selectedMachineIds);
      dispatch(setDisplayInfoMessage(true));
      dispatch(uncheckTableGlobalCheckedById('profileUserGroupMachines'));
    });
  };

  const onMachineAddClick = () => {
    navigate(`/profile/machine-group/${groupId}/add-machine`);
  };

  return (
    <>
      <MDBRow className='mb-3'>
        <MDBCol tag='span' md='12' className='px-4'>
          <BackToPage
            text={translate('backToLink.groups')}
            navigateTo='/profile'
            extraAction={() => {
              dispatch(clearSelectedGroup({}));
              dispatch(setDisplayInfoMessage(false));
            }}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md='12' className='px-4'>
          <MachineGroupDetail
            group={selectedGroup}
            disabled={!!selectedGroup?.isLocked}
            onSubmitGroup={onSubmitGroup}
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md='12' className='px-4'>
          <MDBCard className='mt-5'>
            <MDBCardBody>
              <GenericTable
                tableId={'profileUserGroupMachines'}
                items={selectedGroupMachines}
                hasSearch={true}
                hasCheckboxInput={true}
                hasItemsPerPageControl={true}
                onActionButtonClick={onMachineAddClick}
                handleItemToggle={handleMachineCheckedToggle}
                onGlobalItemsChecked={handleMachineGlobalCheckedToggle}
                onItemDeleteClick={(machineId: number) => onMachineDeleteClick(+machineId)}
                onBulkDeleteClick={onMachineBulkDeleteClick}
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default MachineGroupDetails;
