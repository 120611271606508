import React, { memo } from 'react';

import { MDBIcon } from 'mdbreact';

import './tableHeader.scss';
import { SortCriteria } from '../../models/enum/sortCriteria.enum';
import { TableHeaderProps } from '../../models/props/components/tableHeader.props';

const TableHeader: React.FC<TableHeaderProps> = memo(({ headerText, propertyName, sortState, onSortClick }) => {
  const getSortIcon = () => {
    if (propertyName !== sortState.column) {
      return 'sort';
    }

    if (sortState.order === SortCriteria.Ascending) {
      return 'sort-up';
    }

    if (sortState.order === SortCriteria.Descending) {
      return 'sort-down';
    }

    return 'sort';
  };

  return (
    <th id={propertyName} className='th-xs clickable table-header text-left' onClick={onSortClick}>
      <span className={propertyName === sortState.column ? 'font-weight-bold' : ''}>{headerText}</span>
      <MDBIcon icon={getSortIcon()} className='ml-1' />
    </th>
  );
});

export default TableHeader;
