import axios from 'axios';

import { UserBindingModel } from '../models/data/user/userBinding.model';
import { UserPreferencesModel } from '../models/data/user/userPreferences.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const updateUserPreferences = async (body: UserBindingModel) => {
  const result = await axios.put<UserPreferencesModel>(`${baseApiUrl}api/userSettings`, body);
  return result?.data;
};

export const getUserPreferences = async () => {
  const language = navigator.language.split('-')[0].toLowerCase() || 'de';
  const result = await axios.get<UserPreferencesModel>(`${baseApiUrl}api/userSettings?language=${language}`);
  return result?.data;
};
