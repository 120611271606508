import React, { useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBCol, MDBRow, MDBCard, MDBCardBody } from 'mdbreact';

import { toast } from 'react-toastify';
import ProtectedRoute from '../../../hocs/ProtectedRoute';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import useInterval from '../../../hooks/useInterval';
import useModalState from '../../../hooks/useModalState';
import { translate } from '../../../services/translation.service';
import {
  deleteMachineConfiguration,
  getMachineById,
  getMachineByIdPolling,
  updateMachineConfiguration,
} from '../../../store/api/machines.service';
import {
  updateItemChecked,
  updateGlobalItemsChecked,
  deleteItem,
  bulkDeleteItems,
  selectSelectedMachine,
  selectSelectedMachineAdminGroups,
  selectIsSelectedMachineProcessing,
  selectSelectedMachineUpdateData,
  selectEnrichedAlarms,
  setDisplayInfoMessage,
} from './machines.slice';
import { AuthConstants } from '../../../constants/auth.constants';
import { AdminConstants } from '../../../constants/admin.constants';
import GenericTable from '../../../components/genericTable/genericTable';
import BackToPage from '../../../components/backToPage/BackToPage';
import MachineMachineOwnerDetail from '../components/MachineMachineOwnerDetail/MachineMachineOwnerDetail';
import MachineDetail from '../components/machineDetail/MachineDetail';
import { getAllAttributes } from '../../../store/api/attributes.service';
import { selectAttributes } from '../Attributes/attributes.slice';
import useAttributeMappings from '../../../hooks/useAttributeMappings';
import { uncheckTableGlobalCheckedById } from '../../../store/slices/tableSlice';

const MachineDetails: React.FC = () => {
  const { machineId: machineIdParam } = useParams<{ machineId: string }>();
  const machineId = useMemo(() => +machineIdParam!, [machineIdParam]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { setModal } = useModalState();
  const { mapMachineConfigurationAlarmAttributes } = useAttributeMappings();

  const attributesData = useAppSelector(selectAttributes);
  const machine = useAppSelector(selectSelectedMachine);
  const machineData = useAppSelector(selectSelectedMachineUpdateData);
  const isMachineProcessing = useAppSelector(selectIsSelectedMachineProcessing);
  const adminGroups = useAppSelector(selectSelectedMachineAdminGroups);
  const alarms = useAppSelector(selectEnrichedAlarms);

  useEffect(() => {
    return () => {
      dispatch(updateGlobalItemsChecked({ fieldId: 'alarms', isChecked: false }));
      dispatch(updateGlobalItemsChecked({ fieldId: 'adminGroups', isChecked: false }));
      dispatch(uncheckTableGlobalCheckedById('machineDetailAdminGroups'));
      dispatch(uncheckTableGlobalCheckedById('machineDetailAlarms'));
    };
  }, [dispatch]);

  useEffect(() => {
    if (machineId && machine?.id !== machineId) {
      dispatch(getMachineById({ machineId }));
      dispatch(setDisplayInfoMessage(false));
    }
  }, [machineId, machine, dispatch]);

  useEffect(() => {
    if (attributesData.length === 0) {
      dispatch(getAllAttributes());
    }
  }, [attributesData.length, dispatch]);

  // Polling request for processing/synchronizing Machines Configuration
  useInterval(
    () => dispatch(getMachineByIdPolling({ machineId })),
    isMachineProcessing ? AdminConstants.pollingInterval : null
  );

  const handleSubmitMachine = useCallback(() => {
    dispatch(updateMachineConfiguration({ machineId, machineData }));
  }, [dispatch, machineData, machineId]);

  const handleItemAddClick = useCallback(
    (fieldId: string) => navigate(`/admin/machine/${machineId}/${fieldId}`),
    [machineId, navigate]
  );

  const handleItemToggleChecked = useCallback(
    (itemId: string | number | undefined, fieldId: string) => dispatch(updateItemChecked({ fieldId, itemId })),
    [dispatch]
  );

  const handleEditItemClick = useCallback(
    (itemId: number | string, sectionItemKey: string) =>
      navigate(`/admin/machine/${machineId}/${sectionItemKey}/${itemId}`),
    [machineId, navigate]
  );

  const handleDeleteItemClick = useCallback(
    (itemId: number | string, fieldId: string) =>
      setModal(true, () => {
        dispatch(setDisplayInfoMessage(true));
        dispatch(deleteItem({ fieldId, itemId }));
      }),
    [dispatch, setModal]
  );

  const handleBulkDeleteItemsClick = useCallback(
    (fieldId: string) => {
      if (fieldId === 'alarms') {
        const checkedAlarms = alarms.filter((a) => a.isChecked);

        if (checkedAlarms.length === 0) {
          toast.info(translate('pages.admin.noSelectedAlarms'));
          return;
        }
      } else if (fieldId === 'adminGroups') {
        const checkedAdminGroups = adminGroups.filter((ag) => ag.isChecked);

        if (checkedAdminGroups.length === 0) {
          toast.info(translate('pages.admin.noSelectedAdminGroupsRemoval'));
          return;
        }
      }

      setModal(true, () => {
        dispatch(setDisplayInfoMessage(true));
        dispatch(bulkDeleteItems({ fieldId }));
        if (fieldId === 'alarms') {
          dispatch(uncheckTableGlobalCheckedById('machineDetailAlarms'));
        } else if (fieldId === 'adminGroups') {
          dispatch(uncheckTableGlobalCheckedById('machineDetailAdminGroups'));
        }
      });
    },
    [setModal, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleItemGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>, fieldId) =>
      dispatch(updateGlobalItemsChecked({ fieldId, isChecked: !!event.currentTarget.checked })),
    [dispatch]
  );

  const handleDeleteMachineConfiguration = useCallback(
    (machineId: number) =>
      setModal(true, () => {
        dispatch(setDisplayInfoMessage(false));
        dispatch(deleteMachineConfiguration({ machineId, machineData }));
      }),
    [dispatch, setModal, machineData]
  );

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <>
            <MDBRow className='mb-3'>
              <MDBCol md='12' className='px-4'>
                <BackToPage
                  text={translate('backToLink.machines')}
                  navigateTo='/admin/machines'
                  extraAction={() => dispatch(setDisplayInfoMessage(false))}
                ></BackToPage>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='12' className='px-4'>
                <MachineDetail
                  machine={machine}
                  onSubmitMachine={handleSubmitMachine}
                  onDeleteMachineConfiguration={handleDeleteMachineConfiguration}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='12' className='px-4'>
                <MachineMachineOwnerDetail
                  onEditClick={handleItemAddClick}
                  isEdit={!!machineId}
                  isProcessing={isMachineProcessing}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='12' className='px-4'>
                <MDBCard className='mt-5'>
                  <MDBCardBody>
                    <GenericTable
                      tableId={'machineDetailAdminGroups'}
                      items={adminGroups}
                      hasSearch={true}
                      hasCheckboxInput={true}
                      hasItemsPerPageControl={true}
                      isDisabled={isMachineProcessing}
                      handleItemToggle={(id) => handleItemToggleChecked(id, 'adminGroups')}
                      onGlobalItemsChecked={(event) => handleItemGlobalCheckedToggle(event, 'adminGroups')}
                      onItemDeleteClick={(id) => handleDeleteItemClick(id, 'adminGroups')}
                      onBulkDeleteClick={() => handleBulkDeleteItemsClick('adminGroups')}
                      onActionButtonClick={() => handleItemAddClick('add-admin-group')}
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol md='12' className='px-4'>
                <MDBCard className='mt-5'>
                  <MDBCardBody>
                    <GenericTable
                      tableId={'machineDetailAlarms'}
                      items={mapMachineConfigurationAlarmAttributes(alarms || [])}
                      hasSearch={true}
                      hasCheckboxInput={true}
                      hasItemsPerPageControl={true}
                      isDisabled={isMachineProcessing}
                      onItemEditClick={(id) => handleEditItemClick(id, 'alarm')}
                      handleItemToggle={(id) => handleItemToggleChecked(id, 'alarms')}
                      onGlobalItemsChecked={(event) => handleItemGlobalCheckedToggle(event, 'alarms')}
                      onItemDeleteClick={(id) => handleDeleteItemClick(id, 'alarms')}
                      onBulkDeleteClick={() => handleBulkDeleteItemsClick('alarms')}
                      onActionButtonClick={() => handleItemAddClick('alarm')}
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </>
        );
      }}
    />
  );
};

export default MachineDetails;
