import { translate } from '../../../../services/translation.service';

import { TimestampType } from '../../../../models/enum/timestampType.enum';

export const getTimestampType = (timestamp: number) => {
  const timezoneOffset = -(new Date().getTimezoneOffset() / 60);
  let timezonePrefix = timezoneOffset > 0 ? '+' : '';
  timezonePrefix += timezoneOffset < 10 ? '0' : '';

  switch (timestamp) {
    case 0:
      return translate('pages.profile.selectTimeZone');
    case 1:
      return translate('pages.profile.default') + ' (UTC)';
    case 2:
      return translate('pages.profile.system') + ` (UTC${timezonePrefix}${timezoneOffset}:00)`;
    case 3:
      return translate('pages.profile.custom');
    default:
      return '';
  }
};

export const timeZones = [
  { text: translate('pages.profile.selectTimeZone'), value: 0, disabled: true, defaultSelected: false },
  { text: getTimestampType(TimestampType.Default), value: 1, defaultSelected: false },
  { text: getTimestampType(TimestampType.Localized), value: 2, defaultSelected: false },
  { text: getTimestampType(TimestampType.Custom), value: 3, defaultSelected: false },
];
