import { updateModal } from '../store/slices/modalSlice';
import { useAppDispatch, useAppSelector } from './reduxHooks';

export default function useModalState() {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector((state) => state.modal);

  const setModal = (isOpen: boolean, callbackFunc: (() => void) | null) => {
    dispatch!(updateModal({ isOpen: isOpen, callbackFunc: callbackFunc }));
  };

  return {
    modalState,
    setModal,
  };
}
