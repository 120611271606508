import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import {
  getAlarmById,
  getAlarmsByMachine,
  getSensorAlarms,
  getSensorAlarmsByMachineUuid,
} from '../../services/alarams.service';
import { MachineAlarmModel, AlarmModel, DataAlarmModel } from '../../models/data/machine/alarms.model';
import { TimeRangeEnum } from '../../models/enum/timeRange.enum';
import { SensorAlarmsRequestModel } from '../../models/data/sensor/sensorAlarmsRequest.model';

export const getAlarm = createAsyncThunk<{ alarm: AlarmModel }, { alarmId: number }>(
  'alarms/getAlarm',
  async ({ alarmId }) => {
    const alarm = await trackPromise(getAlarmById(alarmId));

    return { alarm };
  }
);

export const getAlarmByMachineId = createAsyncThunk<{ machineAlarms: MachineAlarmModel[] }, { machineId: number }>(
  'alarms/getAlarmByMachineId',
  async ({ machineId }) => {
    const machineAlarms = await trackPromise(getAlarmsByMachine(machineId));

    return { machineAlarms };
  }
);

export const getDataSensorAlarms = createAsyncThunk<{ alarms: DataAlarmModel[] }, { alarmsTimeRange: TimeRangeEnum }>(
  'dataMachines/getDataSensorAlarms',
  async ({ alarmsTimeRange }) => {
    const alarms = await trackPromise(getSensorAlarms(alarmsTimeRange));

    return { alarms };
  }
);

export const getMachineSensorAlarmsByMachineUuuid = createAsyncThunk<
  { alarms: DataAlarmModel[] },
  {
    machineUuid: string;
    timeRange?: number;
    dateFrom?: string | null;
    dateTo?: string | null;
  }
>('alarms/getMachineSensorAlarmsByMachineUuuid', async (eventData: SensorAlarmsRequestModel) => {
  const { machineUuid, timeRange, dateFrom, dateTo } = eventData;

  const alarms = await trackPromise(getSensorAlarmsByMachineUuid(machineUuid, { timeRange, dateFrom, dateTo }));

  return { alarms };
});
