export enum TimeRangeEnum {
  Last12Hours = 1,
  LastDay = 2,
  LastTwoDays = 3,
  LastWeek = 4,
  LastMonth = 5,
  Last2Months = 6,
  Last3Months = 7,
  Last6Months = 8,
  Last1Year = 9,
  ThisYear = 10,
}
