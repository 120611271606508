import axios from 'axios';

import { SystemModel } from '../models/data/machine/system.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getSystems = async () => {
  const result = await axios.get<SystemModel[]>(`${baseApiUrl}api/systems`);
  return result?.data;
};
