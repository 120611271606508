import { createSlice, Slice, createSelector, current } from '@reduxjs/toolkit';

import { RootState } from '../../../store/store';
import {
  getMachineSensorGroupsDetails,
  getMachineSensorEvents,
} from '../../../store/api/userSensorGroups.service';

import { getMachineSensorAlarmsByMachineUuuid } from '../../../store/api/alarms.service';

import { AdminSensorGroupsModel, SensorGroupsModel } from '../../../models/data/machine/sensorGroup.model';
import { MachineModel } from '../../../models/data/machine/machine.model';
import { DataAlarmModel } from '../../../models/data/machine/alarms.model';
import { EventTrackHistoryModel } from '../../../models/data/machine/eventTrackHistory.model';
import { MapPosition } from '../../../models/data/common/mapPosition.model';
import { getBucketGpsEvents } from '../../../store/api/telemetry.service';
import { SensorModel } from '../../../models/data/machine/sensor.model';

export interface dataDetailsState {
  adminSensorGroups: AdminSensorGroupsModel[];
  sensorGroups: SensorGroupsModel[];
  timeStamps: (Date | string)[];
  machine?: MachineModel;
  alarms?: DataAlarmModel[];
  eventTrackHistory?: EventTrackHistoryModel[];
  eventTrackHistoryPath?: MapPosition[];
  selectedEvent?: EventTrackHistoryModel;
  refreshChartTooltip: boolean;
  eventsCount?: number;
  isFastSlicesOptions: boolean;
  isAverageDataOptions: boolean;
  isFirstRun: boolean;
}

const initialState: dataDetailsState = {
  adminSensorGroups: [],
  sensorGroups: [],
  timeStamps: [],
  machine: undefined,
  alarms: [],
  eventTrackHistory: [],
  eventTrackHistoryPath: [],
  selectedEvent: undefined,
  refreshChartTooltip: false,
  eventsCount: undefined,
  isFastSlicesOptions: true,
  isAverageDataOptions: true,
  isFirstRun: true,
};

export const dataDetailsSlice: Slice = createSlice({
  name: 'dataDetails',
  initialState,
  reducers: {
    toggleCollapseSensorGroup(state, action) {
      const sensorGroup = state.sensorGroups.find((sensorGroup) => sensorGroup.id === action.payload);

      if (sensorGroup) {
        sensorGroup.isOpen = !sensorGroup?.isOpen;
      }
    },
    toggleCollapseSensorGroups(state, action) {
      state.sensorGroups = state.sensorGroups.map((sensorGroup) => ({ ...sensorGroup, isOpen: action.payload }));
    },
    toggleCollapseAdminSensorGroups(state, action) {
      state.adminSensorGroups = state.adminSensorGroups.map((adminSensorGroup) => ({
        ...adminSensorGroup,
        isOpen: action.payload,
      }));
    },
    toggleCollapseAdminSensorGroup(state, action) {
      const adminSensorGroup = state.adminSensorGroups.find(
        (adminSensorGroup) => adminSensorGroup.id === action.payload
      );

      if (adminSensorGroup) {
        adminSensorGroup.isOpen = !adminSensorGroup?.isOpen;
      }
    },
    updateSelectedEvent(state, action) {
      const timestamp = action.payload;
      state.selectedEvent = state.eventTrackHistory.find((e) => e.messageTime === timestamp);
    },
    removeSelectedEvent(state) {
      state.selectedEvent = undefined;
    },
    updateChartTooltip(state, action) {
      state.refreshChartTooltip = action.payload;
    },
    updateSlicesOptions(state, action) {
      state.isFastSlicesOptions = action.payload;
    },
    updateAverageDataOptions(state, action) {
      state.isAverageDataOptions = action.payload;
    },
    updateIsFirstRun(state, action) {
      state.isFirstRun = action.payload;
    },
    clearMachineSensorGroups(state) {
      return {
        ...initialState,
        isFastSlicesOptions: state.isFastSlicesOptions,
        isAverageDataOptions: state.isAverageDataOptions,
      };
    },
    updateEventTrackHistory(state, action) {
      const { minDate, maxDate } = action.payload;
      const zoomedEventTrackHistory = state.eventTrackHistory.map((e) => ({
        ...e,
        isOnMap: new Date(e.messageTime) > new Date(minDate) && new Date(e.messageTime) < new Date(maxDate),
      }));
      state.eventTrackHistory = zoomedEventTrackHistory;
      state.eventTrackHistoryPath = zoomedEventTrackHistory
        .filter((e) => e.isOnMap)
        .map((e) => ({ lat: e.latitude, lng: e.longitude }));
    },
    restoreEventTrackHistory(state) {
      state.eventTrackHistory = state.eventTrackHistory.map((e) => ({
        ...e,
        isOnMap: true,
      }));
      state.eventTrackHistoryPath = state.eventTrackHistory.map((e) => ({ lat: e.latitude, lng: e.longitude }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMachineSensorGroupsDetails.fulfilled, (state, action) => {
        const { adminSensorGroups, sensorGroups, machine } = action.payload;
        state.adminSensorGroups = adminSensorGroups.map((adminSensorGroup, index) => ({
          ...adminSensorGroup,
          isOpen: index === 0,
        }));

        state.sensorGroups = sensorGroups.map((sensorGroup, index) => ({
          ...sensorGroup,
          isOpen: index === 0,
        }));
        state.machine = machine;
      })
      .addCase(getMachineSensorEvents.fulfilled, (state, action) => {
        const { timeStamps, events, eventsCount, alarms, getAlarms, isAdminSensorGroupTab } = action.payload;

        state.timeStamps = timeStamps;
        if (getAlarms) {
          state.alarms = alarms;
        }

        state.eventsCount = eventsCount;

        if (!isAdminSensorGroupTab) {
          state.sensorGroups = state.sensorGroups.map((sg) => ({
            ...sg,
            sensorGroupCharts: sg.sensorGroupCharts.map((sgc) => ({
              ...sgc,
              sensors: mapSensorSetupData(sgc, events),
            })),
          }));
        } else {
          state.adminSensorGroups = state.adminSensorGroups.map((asg) => ({
            ...asg,
            sensorGroupCharts: asg.sensorGroupCharts.map((asgc) => ({
              ...asgc,
              sensors: mapSensorSetupData(asgc, events),
            })),
          }));
        }
      })
      .addCase(getMachineSensorAlarmsByMachineUuuid.fulfilled, (state, action) => {
        const { alarms } = action.payload;
        state.alarms = alarms;
      })
      .addCase(getBucketGpsEvents.fulfilled, (state, action) => {
        const { eventTrackHistory } = action.payload;
        state.eventTrackHistory = eventTrackHistory;
        state.eventTrackHistoryPath = eventTrackHistory.map((e) => ({ lat: e.latitude, lng: e.longitude }));
      });
  },
});

export const {
  clearSelectedAdminSensorGroup,
  addAttributes,
  addAdminSensorGroupName,
  setAdminSensorGroup,
  addTemporaryAdminSensorGroupId,
  addChart,
  moveAttributeToChart,
  toggleAttributeChecked,
  toggleGlobalAttributeChecked,
  deleteAttribute,
  bulkDeleteAttributes,
  clearMachineSensorGroups,
  toggleCollapseSensorGroup,
  toggleCollapseAdminSensorGroup,
  toggleCollapseSensorGroups,
  toggleCollapseAdminSensorGroups,
  updateSelectedEvent,
  updateSlicesOptions,
  updateAverageDataOptions,
  updateIsFirstRun,
  removeSelectedEvent,
  updateChartTooltip,
  updateEventTrackHistory,
  restoreEventTrackHistory,
} = dataDetailsSlice.actions;

export default dataDetailsSlice.reducer;

export const selectSensorGroups = (state: RootState) => state.dataDetails.sensorGroups;

export const selectAdminSensorGroups = (state: RootState) => state.dataDetails.adminSensorGroups;

export const selectTimeStamps = (state: RootState) => state.dataDetails.timeStamps;

export const selectAlarms = (state: RootState) => state.dataDetails.alarms;

export const selectMachine = (state: RootState) => state.dataDetails.machine;

export const selectEventTrackHistory = (state: RootState) => state.dataDetails.eventTrackHistory;

export const selectEventTrackHistoryPath = (state: RootState) => state.dataDetails.eventTrackHistoryPath;

export const selectEvent = (state: RootState) => state.dataDetails.selectedEvent;

export const selectEventsCount = (state: RootState) => state.dataDetails.eventsCount;

export const selectRefreshChartTooltip = (state: RootState) => state.dataDetails.refreshChartTooltip;

export const selectSlicesOptions = (state: RootState) => state.dataDetails.isFastSlicesOptions;

export const selectAverageDataOptions = (state: RootState) => state.dataDetails.isAverageDataOptions;

export const selectIsFirstRun = (state: RootState) => state.dataDetails.isFirstRun;

export const selectVisibleTrackHistory = createSelector([selectEventTrackHistory], (machines) =>
  machines.filter((machine) => machine.longitude && machine.latitude && machine.isOnMap)
);

export const selectMachineUuid = createSelector([selectMachine], (machine) => machine?.uuid);

export const selectSensorUuids = createSelector(
  [selectSensorGroups],
  (sensorGroups) =>
    sensorGroups.length > 0 &&
    sensorGroups.reduce((prevSensorGroup: string[], currentSensorGroup: SensorGroupsModel) => {
      if (currentSensorGroup?.sensorGroupCharts?.length > 0) {
        const currentSensorGroupChart = currentSensorGroup.sensorGroupCharts.flatMap((sensorGroupChart) => [
          ...sensorGroupChart.sensors.map((sensor) => sensor.uuid),
        ]);

        prevSensorGroup.push(...currentSensorGroupChart);
      }

      return Array.from(new Set(prevSensorGroup));
    }, [])
);

export const selectAdminSensorUuids = createSelector(
  [selectAdminSensorGroups],
  (adminSensorGroups) =>
    adminSensorGroups.length > 0 &&
    adminSensorGroups.reduce((prevAdminSensorGroup: string[], currentAdminSensorGroup: AdminSensorGroupsModel) => {
      if (currentAdminSensorGroup?.sensorGroupCharts?.length > 0) {
        const currentAdminSensorGroupChart = currentAdminSensorGroup.sensorGroupCharts.flatMap(
          (adminSensorGroupChart) => [...adminSensorGroupChart.sensors.map((sensor) => sensor.uuid)]
        );

        prevAdminSensorGroup.push(...currentAdminSensorGroupChart);
      }

      return Array.from(new Set(prevAdminSensorGroup));
    }, [])
);

export const selectAllUniqueSensorUuids = createSelector(
  [selectSensorUuids, selectAdminSensorUuids],
  (userSensorGroupUuids, adminSensorGroupUuids) => {
    const usg = userSensorGroupUuids ? userSensorGroupUuids : [];
    const asg = adminSensorGroupUuids ? adminSensorGroupUuids : [];

    return Array.from(new Set([...usg, ...asg]));
  }
);

const convertSensorDataValues = (data: string) => {
  switch (data.toLowerCase()) {
    case 'true':
      return 1;
    case 'false':
      return 0;
    default:
      return +data;
  }
};

const getLastAttributeUuidPart = (attributeUuid: string): string => {
  const attrbiuteUuidParts = attributeUuid.split('-');

  return attrbiuteUuidParts[attrbiuteUuidParts.length - 1];
};

const mapSensorSetupData = (sgc, events) => {
  const sensorsData: SensorModel[] = [];
  const sensorAttributeUuids = Array.from(new Set(sgc.sensors.map((sensor) => sensor.uuid))) as string[];

  for (const attributeUuid of sensorAttributeUuids) {
    const eventsStartsWithAttrbiuteUuid = events.filter((ev) => ev.attributeUuid.startsWith(attributeUuid));
    const sensor = current(sgc.sensors).find((s) => s.uuid === attributeUuid);

    if (eventsStartsWithAttrbiuteUuid.length === 0) {
      sensorsData.push({
        ...sensor,
        data: [],
      });

      continue;
    }

    for (const event of eventsStartsWithAttrbiuteUuid) {
      const eventUuidLastPart = getLastAttributeUuidPart(event.attributeUuid);
      const attributeUuidLastPart = getLastAttributeUuidPart(attributeUuid);

      const setupName = eventUuidLastPart !== attributeUuidLastPart ? eventUuidLastPart : null;

      sensorsData.push({
        ...sensor,
        data: event?.values.map((v) => (v ? convertSensorDataValues(v) : null)),
        range: event?.ranges.map((r) => r?.map((v) => (v ? convertSensorDataValues(v) : null))),
        setupName: setupName,
      });
    }
  }

  return sensorsData;
};
