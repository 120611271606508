import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBModalFooter } from 'mdbreact';

import { toast } from 'react-toastify';
import { translate } from '../../services/translation.service';
import GenericTable from '../../components/genericTable/genericTable';
import useUserMachineGroups from '../../hooks/useUserMachineGroups';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { uncheckTableGlobalCheckedById } from '../../store/slices/tableSlice';
import {
  addMachinesToUserGroup,
  selectAvailableGroupMachines,
  setDisplayInfoMessage,
  toggleAvailableGlobalMachinesChecked,
  toggleAvailableMachineChecked,
} from '../../store/slices/userGroupsSlice';

const AddUserGroupMachine: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const availableUserMachines = useAppSelector(selectAvailableGroupMachines);
  const { hasUserMachineGroupsData, getUserMachineGroupsData } = useUserMachineGroups();

  const { groupId: groupIdParam } = useParams<{ groupId: string }>();
  const groupId = useMemo(() => +groupIdParam!, [groupIdParam]);

  useEffect(() => {
    if (!hasUserMachineGroupsData) {
      getUserMachineGroupsData();
    }
  }, [hasUserMachineGroupsData, getUserMachineGroupsData]);

  useEffect(() => {
    return () => {
      dispatch(toggleAvailableGlobalMachinesChecked({ isChecked: false }));
      dispatch(uncheckTableGlobalCheckedById('profileUserGroupAddMachines'));
    };
  }, [dispatch]);

  const handleMachineCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) {
        dispatch!(toggleAvailableMachineChecked(+id));
      }
    },
    [dispatch]
  );

  const handleMachineGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;

      dispatch(toggleAvailableGlobalMachinesChecked({ isChecked: !!checked }));
    },
    [dispatch]
  );

  const addSelectedMachines = () => {
    const selectedMachines = availableUserMachines.filter((m) => m.isChecked);

    if (selectedMachines.length === 0) {
      toast.info(translate('pages.profile.noSelectedMachine'));
      return;
    }

    dispatch(setDisplayInfoMessage(true));
    dispatch(addMachinesToUserGroup(selectedMachines.map((m) => ({ ...m, isChecked: false }))));
    navigate(`/profile/machine-group/${groupId}`);
  };

  const onCancel = () => {
    dispatch(setDisplayInfoMessage(true));
    navigate(`/profile/machine-group/${groupId}`);
  };

  return (
    <>
      <MDBRow>
        <MDBCol md='12'>
          <MDBCard>
            <MDBCardBody>
              <MDBRow className='mb-4'>
                <MDBCol md='4' className='text-left d-flex align-items-center'>
                  <MDBIcon icon='file-signature' color='dark' className='p-4 shadow-3-strong bg-warning rounded' />
                  <h5 className='font-weight-bold ml-4'>{translate('pages.admin.addMachines')}</h5>
                </MDBCol>
              </MDBRow>

              <GenericTable
                tableId={'profileUserGroupAddMachines'}
                items={availableUserMachines}
                hasSearch={true}
                hasItemsPerPageControl={true}
                hasCheckboxInput={true}
                handleItemToggle={handleMachineCheckedToggle}
                onGlobalItemsChecked={handleMachineGlobalCheckedToggle}
              />
            </MDBCardBody>

            <MDBModalFooter>
              <MDBBtn color='warning' className='text-white' onClick={addSelectedMachines}>
                {translate('common.addSelected')}
              </MDBBtn>
              <MDBBtn outline color='warning' className='cancel-onHover' onClick={onCancel}>
                {translate('common.cancel')}
              </MDBBtn>
            </MDBModalFooter>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default AddUserGroupMachine;
