import axios from 'axios';

import { ContractModel, CreateContractModel } from '../models/data/contract/contract.model';
import { ContractUserModel } from '../models/data/contract/contractUser.model';
import { ContractMachineModel } from '../models/data/contract/contractMachine.model';

const baseApiUrl = process.env.REACT_APP_API_URL;

export const getAllContracts = async () => {
  const result = await axios.get<ContractModel[]>(`${baseApiUrl}api/contracts`);
  for (const contract of result?.data) {
    contract.isChecked = false;
  }

  return result?.data;
};

export const getContract = async (contractId: number) => {
  const result = await axios.get<ContractModel>(`${baseApiUrl}api/contracts/${contractId}`);
  return result?.data;
};

export const getContractMachines = async (contractId: number) => {
  const result = await axios.get<ContractMachineModel[]>(`${baseApiUrl}api/contracts/machines/${contractId}`);
  for (const machine of result?.data) {
    machine.isChecked = false;
  }

  return result?.data;
};

export const getContractUsers = async (contractId: number) => {
  const result = await axios.get<ContractUserModel[]>(`${baseApiUrl}api/contracts/users/${contractId}`);

  return result?.data;
};

export const getAvailableContractMachines = async (contractId: number) => {
  const result = await axios.get<ContractMachineModel[]>(`${baseApiUrl}api/contracts/machines/available/${contractId}`);
  for (const machine of result?.data) {
    machine.isChecked = false;
  }

  return result?.data;
};

export const getAvailableContractUsers = async (contractId: number) => {
  const result = await axios.get<ContractUserModel[]>(`${baseApiUrl}api/contracts/users/available/${contractId}`);

  return result?.data;
};

export const addContract = async (contract: CreateContractModel) => {
  const result = await axios.post<CreateContractModel>(`${baseApiUrl}api/contracts`, contract);
  return result?.data;
};

export const editContract = async (contract: CreateContractModel) => {
  const result = await axios.put<CreateContractModel>(`${baseApiUrl}api/contracts/${contract.id}`, contract);
  return result?.data;
};

export const deleteContracts = async (contractIds: number[]) => {
  const result = await axios.delete<boolean>(`${baseApiUrl}api/contracts`, { data: contractIds });
  return result.data;
};

export const deleteContractMachines = async (machineIds: number[], contractId: number) => {
  const result = await axios.delete<boolean>(`${baseApiUrl}api/contracts/machines/${contractId}`, { data: machineIds });
  return result.data;
};

export const addContractMachines = async (machineIds: number[], contractId: number) => {
  const result = await axios.post<ContractMachineModel[]>(
    `${baseApiUrl}api/contracts/machines/${contractId}`,
    machineIds
  );

  return result?.data;
};
