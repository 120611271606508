import React, { useEffect, useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';

import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact';

import UserProfile from './components/userProfile/UserProfile';
import ProtectedRoute from '../../hocs/ProtectedRoute';
import AddUserContractModal from './components/addUserContractModal/AddUserContractModal';
import BackToPage from '../../components/backToPage/BackToPage';
import { AuthConstants } from '../../constants/auth.constants';
import { UserProfileBindingModel } from '../../models/data/user/userProfileBinding.model';
import { UserDetailsModel } from '../../models/data/user/userDetails.model';
import { UserContractModel } from '../../models/data/contract/userContract.model';
import { UserContractBindingModel } from '../../models/data/contract/userContractBinding.model';
import { editUserProfile, getUserDetails } from '../../services/user.service';
import { translate } from '../../services/translation.service';
import { addUserContract, deleteUserContracts, getUserContracts } from '../../services/userContract.service';
import useModalState from '../../hooks/useModalState';
import GenericTable from '../../components/genericTable/genericTable';
import { uncheckTableGlobalCheckedById } from '../../store/slices/tableSlice';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useParams } from 'react-router-dom';

const UserDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setModal } = useModalState();
  const { userId: userIdParam } = useParams<{ userId: string }>();
  const userId = useMemo(() => userIdParam!, [userIdParam]);

  const [user, setUser] = useState<UserDetailsModel>();
  const [contracts, setContracts] = useState<UserContractModel[]>([]);
  const [contractModalOpen, setContractModalOpen] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(uncheckTableGlobalCheckedById('userDetailsContractsTable'));
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const userDetails = await trackPromise(getUserDetails(userId));
      setUser(userDetails);
    })();

    (async () => {
      const contracts = await trackPromise(getUserContracts(userId));
      setContracts(
        contracts.map((c) => ({
          ...c,
          responsible: `${c.contractOwner.contactPerson.firstName} ${c.contractOwner.contactPerson.lastName}`,
        }))
      );
    })();
  }, [userId]);

  const onAddUserContractClick = () => {
    setContractModalOpen(true);
  };

  const onSubmitUserContract = async (contract: UserContractModel) => {
    closeModal();

    const mappedContract: UserContractBindingModel = {
      id: contract.uuid,
      objectId: userId,
    };

    await trackPromise(addUserContract(mappedContract));

    setContracts((prevState) => [...prevState, contract]);
    toast.success(translate('pages.admin.addContractSuccessfully'));
  };

  const closeModal = () => {
    setContractModalOpen(false);
  };

  const onDeleteClick = (callback: () => void) => {
    setModal(true, callback);
  };

  const onContractDeleteClick = async (contractId: string, validFrom: Date | undefined) => {
    const contractForDelete: UserContractBindingModel[] = [
      {
        id: contractId,
        objectId: userId,
        validFrom: validFrom,
      },
    ];

    await trackPromise(deleteUserContracts(contractForDelete));

    setContracts((prevState) => prevState.filter((contract) => contract.id !== contractId));
    toast.success(translate('pages.admin.deleteContract'));
  };

  const onBulkDeleteClick = () => {
    const contractsForDelete = contracts
      .filter((c) => c.isChecked)
      .map((c) => ({ id: c.id, objectId: userId, validFrom: c.validFrom }));

    if (contractsForDelete.length === 0) {
      toast.info(translate('pages.admin.noSelectedContractsRemoval'));
      return;
    }

    setModal(true, async () => {
      await trackPromise(deleteUserContracts(contractsForDelete));

      dispatch(uncheckTableGlobalCheckedById('userDetailsContractsTable'));
      setContracts(
        contracts.filter((c) => !contractsForDelete.find((x) => x.id === c.id && x.validFrom === c.validFrom))
      );

      toast.success(translate('pages.admin.bulkDeleteContracts'));
    });
  };

  const onSaveUserProfile = async (userProfileBindingModel: UserProfileBindingModel) => {
    const updatedUser = await trackPromise(editUserProfile(userProfileBindingModel));

    setUser(updatedUser);
    toast.success(translate('pages.admin.editUserProfileSuccessfully'));
  };

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <>
            <MDBRow className='mb-3'>
              <MDBCol md='12' className='px-4'>
                <BackToPage text={translate('backToLink.users')} navigateTo='/admin/users'></BackToPage>
              </MDBCol>
            </MDBRow>

            <MDBRow className='text-center'>
              <MDBCol md='12' className='px-4'>
                <MDBRow>
                  <MDBCol md='12' className='mb-4'>
                    <UserProfile user={user} onSaveUserProfile={onSaveUserProfile}></UserProfile>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md='12'>
                    <MDBCard>
                      <MDBCardBody>
                        <GenericTable
                          tableId={'userDetailsContractsTable'}
                          items={contracts}
                          setItems={setContracts}
                          hasCheckboxInput={true}
                          onBulkDeleteClick={onBulkDeleteClick}
                          onItemDeleteClick={(contractId: string, validFrom: Date) =>
                            onDeleteClick(() => onContractDeleteClick(contractId, validFrom))
                          }
                          onActionButtonClick={onAddUserContractClick}
                        />
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>

            {contractModalOpen && (
              <AddUserContractModal
                isOpen={contractModalOpen}
                onShowModal={setContractModalOpen}
                userContracts={contracts}
                onCloseModal={closeModal}
                onSubmitUserContract={onSubmitUserContract}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default UserDetails;
