import L, { Point } from 'leaflet';
import { GoogleMapConstants } from '../constants/googleMap.constants';
import { OpenRailwayMapConstants } from '../constants/openRailwayMap.constants';
import { LongLatModel } from '../models/data/common/longLat.model';
import { MapPosition } from '../models/data/common/mapPosition.model';
import { MachineStateType } from '../models/enum/machineStateType.enum';
import { MachineModel } from '../models/data/machine/machine.model';
import { MachineTrackHistoryModel } from '../models/data/machine/machineTrackHistory.model';
import { EventTrackHistoryModel } from '../models/data/machine/eventTrackHistory.model';
import { updateFitBounds, updateMapZoomAndCenterByMapId } from '../store/slices/mapsSlice';

const getIconColor = (_, isLastTrackHistoryLocation: boolean): string => {
  if (isLastTrackHistoryLocation) {
    return '#000000';
  }

  return '#e01802'; //#b3b3b3
};

const getRotationIconSrc = (rotateAngle: number | null, isCompassDirection: boolean): string => {
  let path = '-';
  if (rotateAngle === 0 || rotateAngle) {
    path = '-rotation-';
  }
  if (!isCompassDirection) {
    return `/assets/images/machine${path}off.svg`; //`/assets/images/machine${path}off.svg`
  } else {
    return `/assets/images/machine-compass-rotation-off.svg`; //`/assets/images/machine-compass-rotation-on.svg`
  }
};

const getBounceClassName = (bounce: boolean) => {
  if (bounce) {
    return 'bounce-marker';
  }

  return '';
};

export const getIconAngle = (
  machine: MachineModel | MachineTrackHistoryModel | EventTrackHistoryModel,
  mapId: string
): number | null => {
  const isGeoMachinesPage = [
    GoogleMapConstants.geoMachinesGoogleMapId,
    OpenRailwayMapConstants.geoMachinesOpenRailwayMapId,
  ].includes(mapId);
  const hasRotationAngle = machine?.rotateAngle !== null && machine?.rotateAngle !== undefined;
  const isUpToDate = (machine as MachineModel).gpsTime === (machine as MachineModel).rotateAngleTime;

  if (isGeoMachinesPage) {
    if (hasRotationAngle && isUpToDate) {
      return machine.rotateAngle as number;
    }

    return null;
  } else {
    if (hasRotationAngle) {
      return machine.rotateAngle as number;
    }

    return null;
  }
};

export const getGoogleMapUserLocationMarkerIcon = () => {
  return {
    path: 'M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z',
    fillColor: '#4286f4',
    fillOpacity: 1,
    scale: 0.05,
    anchor: new Point(250, 250),
  } as any;
};

export const getGoogleMapMarkerIcon = (
  machineState: MachineStateType,
  rotateAngle: number | null,
  isLastTrackHistoryLocation: boolean
) => {
  //COMMENT : WHAT SHOULD IT RETURN
  let machineIcon =
    'M256 504c137 0 248-111 248-248S393 8 256 8 8 119 8 256s111 248 248 248zm0-448c110.5 0 200 89.5 200 200s-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56zm20 328h-40c-6.6 0-12-5.4-12-12V256h-67c-10.7 0-16-12.9-8.5-20.5l99-99c4.7-4.7 12.3-4.7 17 0l99 99c7.6 7.6 2.2 20.5-8.5 20.5h-67v116c0 6.6-5.4 12-12 12z';
  if (!rotateAngle && rotateAngle !== 0) {
    machineIcon =
      'M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z';
  }

  return {
    path: machineIcon,
    fillOpacity: 1,
    scale: 0.05,
    rotation: rotateAngle,
    fillColor: getIconColor(machineState, isLastTrackHistoryLocation),
    anchor: new Point(250, 250),
    labelOrigin: new Point(250, 250),
  } as any;
};

export const getOpenRailwayUserLocationMarkerIcon = () => {
  return L.divIcon({
    iconAnchor: new L.Point(20, 20),
    html: `<div>
        <img src='/assets/images/user-location.svg'>
      </div>`,
  });
};

export const getOpenRailwayMarkerIcon = (
  rotateAngle: number | null,
  compassDirection: number | null,
  bounce: boolean,
  isLastTrackHistoryLocation: boolean
): L.DivIcon => {
  return L.divIcon({
    iconAnchor: new L.Point(20, 20),
    html: `<div class='${getBounceClassName(bounce)}'>
        <img class='${
          isLastTrackHistoryLocation === true ? 'last-track-history-location' : ''
        }' style='transform: rotate(${rotateAngle}deg); position:absolute' src='${getRotationIconSrc(
      rotateAngle,
      false
    )}'>
        ${
          compassDirection
            ? `<img class='${
                isLastTrackHistoryLocation === true ? 'last-track-history-location' : ''
              }' style='transform: rotate(${compassDirection}deg);' src='${getRotationIconSrc(
                compassDirection,
                true
              )}'>`
            : ''
        }
      </div>`,
  });
};

export const calculateMapCenter = (machines: LongLatModel[]): MapPosition => {
  const machineMinLatitude = machines.reduce((machine1, machine2) => {
    return machine1.latitude < machine2.latitude ? machine1 : machine2;
  });
  const machineMaxLatitude = machines.reduce((machine1, machine2) => {
    return machine1.latitude > machine2.latitude ? machine1 : machine2;
  });
  const machineMinLongitude = machines.reduce((machine1, machine2) => {
    return machine1.longitude < machine2.longitude ? machine1 : machine2;
  });
  const machineMaxLongitude = machines.reduce((machine1, machine2) => {
    return machine1.longitude > machine2.longitude ? machine1 : machine2;
  });

  const result: MapPosition = {
    lat: (machineMinLatitude.latitude + machineMaxLatitude.latitude) / 2,
    lng: (machineMinLongitude.longitude + machineMaxLongitude.longitude) / 2,
  };

  return result;
};

export const googleMapFitBounds = (machines: any, mapId: any, dispatch: any, mapRef: any, center?: any) => {
  const { maps } = (window as any).google;
  const bounds = new maps.LatLngBounds();

  if (machines.length > 0 && mapRef !== null) {
    machines.forEach((m) => bounds.extend({ lat: m.latitude, lng: m.longitude }));
    mapRef.fitBounds(bounds);

    const updatedCenter = mapRef.getCenter();
    const updatedZoom = mapRef.getZoom();

    dispatch(
      updateMapZoomAndCenterByMapId({
        id: mapId,
        center: center || updatedCenter,
        zoom: updatedZoom || GoogleMapConstants.defaultZoom,
      })
    );
    dispatch(updateFitBounds({ id: mapId, preventFitBounds: true }));
  } else {
    dispatch(
      updateMapZoomAndCenterByMapId({
        id: mapId,
        center: GoogleMapConstants.defaultCenter,
        zoom: GoogleMapConstants.defaultZoom,
      })
    );
  }
};

export const openRailwayMapFitBounds = (
  machines: any,
  mapRef: any,
  markersRef: any,
  dispatch: any,
  mapId: any,
  center?: any
) => {
  if (machines.length > 0 && mapRef !== null) {
    mapRef?.fitBounds(markersRef?.getBounds());
    const updatedCenter = mapRef.getCenter();
    const updatedZoom = mapRef.getZoom();

    dispatch(updateMapZoomAndCenterByMapId({ id: mapId, center: center || updatedCenter, zoom: updatedZoom }));
    dispatch(updateFitBounds({ id: mapId, preventFitBounds: true }));
  } else if (mapRef !== null) {
    dispatch(
      updateMapZoomAndCenterByMapId({
        id: mapId,
        center: OpenRailwayMapConstants.defaultCenter,
        zoom: OpenRailwayMapConstants.defaultZoom,
      })
    );
  }
};

export const converNegativeDegreesToPositive = (value: number): number => {
  if (value < 0) {
    return value + 360;
  }

  return value;
};
