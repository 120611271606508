import { WritableDraft } from 'immer/dist/internal';
import { contractsState } from '../../pages/admin/Contracts/contracts.slice';
import { machinesState } from '../../pages/admin/Machines/machines.slice';
import { machineOwnersState } from '../../pages/admin/MachineOwners/machineOwners.slice';

const sliceNames = {
  machineOwners: 'selectedMachineOwner',
  contracts: 'selectedContract',
};

type StateProps = WritableDraft<contractsState | machinesState | machineOwnersState>;

interface ActionPayloadProps {
  fieldId: string;
  itemId?: number;
  isChecked?: boolean;
  items?: any[];
  item?: any;
  value?: any;
}

interface ActionProps {
  payload: ActionPayloadProps;
  type: string;
}

export const addItemByField = (state: StateProps, action: ActionProps): void => {
  const { fieldId, item } = action.payload;

  state[fieldId].push(item);
};

export const addItemsByField = (state: StateProps, action: ActionProps): void => {
  const { fieldId, items } = action.payload;

  if (items) {
    state[fieldId].push(...items);
  }
};

export const setSelectedItemFieldById = (state: StateProps, action: ActionProps): void => {
  const { fieldId, value } = action.payload;

  const sliceName = action.type.replace('/setSelectedItemField', '');
  const parentFieldId = sliceNames[sliceName];

  if (state[parentFieldId]) {
    state[parentFieldId][fieldId] = value;
    return;
  }

  state[parentFieldId] = { [fieldId]: value };
};

export const updateItemById = (state: StateProps, action: ActionProps): void => {
  const { fieldId, item } = action.payload;

  state[fieldId] = state[fieldId].map((t) => (t.id !== item.id ? t : item));
};

export const toggleItemChecked = (state: StateProps, action: ActionProps): void => {
  const { fieldId, itemId } = action.payload;

  const item = state[fieldId].find((item) => item?.id?.toString() === itemId || item?.machineId?.toString() === itemId);

  if (item) {
    item.isChecked = !item?.isChecked;
  }
};

export const toggleGlobalItemsChecked = (state: StateProps, action: ActionProps): void => {
  const { fieldId, isChecked } = action.payload;

  state[fieldId] = state[fieldId].map((item) => ({ ...item, isChecked }));
};

export const deleteItemById = (state: StateProps, action: ActionProps): void => {
  const { fieldId, itemId } = action.payload;

  state[fieldId] = state[fieldId].filter((item) => (item?.id || item?.machineId) !== itemId);
};

export const bulkDeleteItemsById = (state: StateProps, action: ActionProps): void => {
  const { fieldId } = action.payload;

  state[fieldId] = state[fieldId].filter((item) => !item.isChecked);
};
