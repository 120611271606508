import { useCallback } from 'react';

import { updateVisibility } from '../store/slices/spinnerSlice';
import { useAppDispatch, useAppSelector } from './reduxHooks';

export default function useSpinnerState() {
  const dispatch = useAppDispatch();
  const spinnerState = useAppSelector((state) => state.spinner);

  const setSpinnerState = useCallback(
    (isVisible: boolean) => {
      dispatch(updateVisibility(isVisible));
    },
    [dispatch]
  );

  return {
    spinnerState,
    setSpinnerState,
  };
}
