import { Language } from '../models/enum/language.enum';

export const mapLangCodeToNumber = (code: string) => {
  switch (code) {
    case 'en':
      return 1;
    case 'de':
      return 2;
    case 'it':
      return 3;
    case 'fr':
      return 4;
    default:
      return 1;
  }
};

export const mapNumberToLangCode = (language?: Language) => {
  switch (language) {
    case 1:
      return 'en';
    case 2:
      return 'de';
    case 3:
      return 'it';
    case 4:
      return 'fr';
    default:
      return 'en';
  }
};
