import { MDBIcon } from 'mdbreact';
import './backToTopButton.scss';

interface BackToTopBtnProps {
  breakWidth: number;
  handleClick(): void;
}

export const BackToTopBtn: React.FC<BackToTopBtnProps> = ({ breakWidth, handleClick }) => {
  return (
    <button
      className={`myBtn ${window.innerWidth <= breakWidth ? 'bottomWithoutFooter' : 'bottomWithFooter'}`}
      onClick={handleClick}
    >
      <MDBIcon icon='fa fa-arrow-up fa-lg' className='faIcon' />
    </button>
  );
};
