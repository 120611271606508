import React, { memo } from 'react';

import { MDBCol, MDBIcon, MDBTooltip } from 'mdbreact';
import { translate } from '../../../../services/translation.service';

const SynchronizingStatus: React.FC = memo(() => (
  <MDBCol xl='4' md='4' className='mt-2'>
    <MDBTooltip placement='bottom' tag='span'>
      <div className='d-flex flex-row align-items-center'>
        <div className='clickable processing'>
          {translate('pages.admin.statusSynchronizing')} <MDBIcon icon='spinner' className='mt-0 orange-icon' />
        </div>
      </div>
      <span className='fs-14'>{translate('common.dataSynchronization')}</span>
    </MDBTooltip>
  </MDBCol>
));

export default SynchronizingStatus;
