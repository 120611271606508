import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDBCard, MDBCardBody, MDBCol, MDBRow, MDBBtn } from 'mdbreact';

import { toast } from 'react-toastify';
import ProtectedRoute from '../../../hocs/ProtectedRoute';
import { AuthConstants } from '../../../constants/auth.constants';
import { AdminConstants } from '../../../constants/admin.constants';
import { translate } from '../../../services/translation.service';
import useModalState from '../../../hooks/useModalState';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import GenericTable from '../../../components/genericTable/genericTable';
import useInterval from '../../../hooks/useInterval';
import { uncheckTableGlobalCheckedById } from '../../../store/slices/tableSlice';
import {
  selectAdminGroups,
  selectHasProcessingAdminGroups,
  updateAdminGroupChecked,
  updateGlobalAdminGroupChecked,
  selectCheckedAdminGroupIds,
} from './adminGroups.slice';
import {
  getAllAdminGroups,
  deleteAdminGroupById,
  bulkDeleteAdminGroups,
  getAllAdminGroupsPolling,
} from '../../../store/api/adminGroups.service';

const AdminGroupsList: React.FC = () => {
  const { setModal } = useModalState();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const adminGroups = useAppSelector(selectAdminGroups);
  const hasProcessingGroups = useAppSelector(selectHasProcessingAdminGroups);
  const selectedAdminGroupIds = useAppSelector(selectCheckedAdminGroupIds);

  const getAdminGroups = useCallback(() => dispatch(getAllAdminGroups()), [dispatch]);

  useEffect(() => {
    getAdminGroups();

    return () => {
      dispatch(uncheckTableGlobalCheckedById('adminGroupsTableSettings'));
    };
  }, [dispatch, getAdminGroups]);

  // Polling for processing/synchronizing Admin Groups
  useInterval(() => dispatch(getAllAdminGroupsPolling()), hasProcessingGroups ? AdminConstants.pollingInterval : null);

  const onEditAdminGroupClick = useCallback(
    (adminGroupId: number | string) => navigate(`/admin/admin-group/${adminGroupId}`),
    [navigate]
  );

  const onAdminGroupDeleteClick = useCallback(
    (adminGroupId: number) => setModal(true, () => dispatch(deleteAdminGroupById({ adminGroupId }))),
    [dispatch, setModal]
  );

  const onBulkDeleteClick = useCallback(() => {
    if (selectedAdminGroupIds.length === 0) {
      toast.info(translate('pages.admin.noSelectedAdminGroups'));
      return;
    }

    setModal(true, () => {
      dispatch(bulkDeleteAdminGroups({ adminGroupIds: selectedAdminGroupIds }));
      dispatch(uncheckTableGlobalCheckedById('adminGroupsTableSettings'));
    });
  }, [selectedAdminGroupIds, dispatch, setModal]);

  const handleAdminGroupCheckedToggle = useCallback(
    (id: string | number | undefined) => {
      if (id) dispatch!(updateAdminGroupChecked(id));
    },
    [dispatch]
  );

  const onAdminGroupGlobalCheckedToggle = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      dispatch(updateGlobalAdminGroupChecked({ isChecked: !!event.currentTarget.checked }));
    },
    [dispatch]
  );

  const onCreateAdminGroupClick = useCallback(() => navigate(`/admin/admin-group`), [navigate]);

  return (
    <ProtectedRoute
      role={AuthConstants.adminRoleName}
      render={() => {
        return (
          <MDBRow>
            <MDBCol md='12' className='px-4'>
              <MDBCard>
                <MDBCardBody>
                  {hasProcessingGroups && (
                    <MDBBtn color='warning' className='text-white' onClick={getAdminGroups}>
                      {translate('common.refresh')}
                    </MDBBtn>
                  )}
                  <GenericTable
                    tableId={'adminGroupsTableSettings'}
                    items={adminGroups}
                    hasSearch={true}
                    hasItemsPerPageControl={true}
                    hasCheckboxInput={true}
                    handleItemToggle={handleAdminGroupCheckedToggle}
                    onGlobalItemsChecked={onAdminGroupGlobalCheckedToggle}
                    onItemDeleteClick={onAdminGroupDeleteClick}
                    onItemEditClick={onEditAdminGroupClick}
                    onBulkDeleteClick={onBulkDeleteClick}
                    onActionButtonClick={onCreateAdminGroupClick}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        );
      }}
    />
  );
};

export default AdminGroupsList;
