import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';

import { translate } from '../../services/translation.service';
import { AdminGroupModel } from '../../models/data/group/adminGroup.model';
import {
  getAdminGroup,
  getAdminGroups,
  addAdminGroup,
  editAdminGroup,
  deleteAdminGroup,
} from '../../services/adminGroup.service';

export const getAllAdminGroups = createAsyncThunk<{ adminGroups: AdminGroupModel[] }, undefined>(
  'adminGroups/getAllAdminGroups',
  async () => {
    const adminGroups = await trackPromise(getAdminGroups());

    return { adminGroups };
  }
);

export const getAdminGroupById = createAsyncThunk<{ adminGroup: AdminGroupModel }, { adminGroupId: number }>(
  'adminGroups/getAdminGroup',
  async ({ adminGroupId }) => {
    const adminGroup = await trackPromise(getAdminGroup(adminGroupId));

    return { adminGroup };
  }
);

export const createAdminGroup = createAsyncThunk<
  { adminGroup: AdminGroupModel },
  { adminGroupData: Partial<AdminGroupModel> }
>('adminGroups/createAdminGroup', async ({ adminGroupData }) => {
  const adminGroup = await trackPromise(addAdminGroup(adminGroupData));

  toast.success(translate('pages.admin.createAdminGroupSuccessfully'));

  return { adminGroup };
});

export const updateAdminGroup = createAsyncThunk<
  { adminGroup: AdminGroupModel },
  { adminGroupData: Partial<AdminGroupModel> }
>('adminGroups/updateAdminGroup', async ({ adminGroupData }) => {
  const adminGroup = await trackPromise(editAdminGroup(adminGroupData));

  toast.success(translate('pages.admin.editAdminGroupSuccessfully'));

  return { adminGroup };
});

export const deleteAdminGroupById = createAsyncThunk<{ deletedAdminGroupIds: number[] }, { adminGroupId: number }>(
  'adminGroups/deleteAdminGroup',
  async ({ adminGroupId }) => {
    const deletedAdminGroupIds = await trackPromise(deleteAdminGroup([adminGroupId]));

    if (deletedAdminGroupIds.length === 0) {
      toast.error(translate('pages.admin.deleteAdminGroupFailed'));
    } else {
      toast.success(translate('pages.admin.deleteAdminGroupSuccess'));
    }

    return { deletedAdminGroupIds };
  }
);

export const bulkDeleteAdminGroups = createAsyncThunk<{ deletedAdminGroupIds: number[] }, { adminGroupIds: number[] }>(
  'adminGroups/bulkDeleteAdminGroups',
  async ({ adminGroupIds }) => {
    const deletedAdminGroupIds = await trackPromise(deleteAdminGroup(adminGroupIds));

    if (deletedAdminGroupIds.length === 0) {
      toast.error(translate('pages.admin.noAdminGroupsDeleted'));
    } else if (deletedAdminGroupIds.length !== adminGroupIds.length) {
      toast.warn(translate('pages.admin.notAllAdminGroupsDeleted'));
    } else {
      toast.success(translate('pages.admin.allAdminGroupsDeleted'));
    }

    return { deletedAdminGroupIds };
  }
);

// Polling Async Thunks
export const getAdminGroupByIdPolling = createAsyncThunk<{ adminGroup: AdminGroupModel }, { adminGroupId: number }>(
  'adminGroups/getAdminGroupByIdPolling',
  async ({ adminGroupId }) => {
    const adminGroup = await getAdminGroup(adminGroupId);

    return { adminGroup };
  }
);

export const getAllAdminGroupsPolling = createAsyncThunk<{ adminGroups: AdminGroupModel[] }, undefined>(
  'adminGroups/getAllAdminGroupsPolling',
  async () => {
    const adminGroups = await getAdminGroups();

    return { adminGroups };
  }
);
