import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';

import { fetchMachineEvents } from '../../services/telemetry.service';
import { getMachineDetails } from '../../services/machine.service';
import {
  getUserSensorGroup,
  getUserSensorGroupsDetails,
  getUserSensorGroups,
  createUserSensorGroup,
  updateUserSensorGroup,
  deleteUserSensorGroups,
} from '../../services/sensorGroup.service';
import { translate } from '../../services/translation.service';

import { TelemetryEventModel } from '../../models/data/telemetry/telemetryEvent.model';
import { MachineModel } from '../../models/data/machine/machine.model';
import {
  AdminSensorGroupsModel,
  ProfileSensorGroupModel,
  SensorGroupsModel,
} from '../../models/data/machine/sensorGroup.model';
import { SensorGroupRequestPops } from '../../models/props/profile/createSensorGroupRequest.props';
import { getSensorAlarmsByMachineUuid } from '../../services/alarams.service';
import { DataAlarmModel } from '../../models/data/machine/alarms.model';
import { AvailableMachineModel } from '../../models/data/machine/availableMachine.model';
import { getAvailableMachines } from '../../services/groupMachine.service';
import { getAdminSensorGroupsDetails } from '../../services/adminSensorGroups.service';
import { TelemetryBucketsRequestThunkModel } from '../../models/data/telemetry/telemetryBucketsRequestThunk.model';

export const getAllUserSensorGroups = createAsyncThunk<{ sensorGroups: ProfileSensorGroupModel[] }, undefined>(
  'userSensorGroups/getAllUserSensorGroups',
  async () => {
    const sensorGroups = await trackPromise(getUserSensorGroups());

    return { sensorGroups };
  }
);

export const createSensorGroup = createAsyncThunk<
  { newSensorGroup: SensorGroupsModel },
  { sensorGroup: SensorGroupRequestPops }
>('userSensorGroups/createSensorGroup', async ({ sensorGroup }) => {
  const newSensorGroup = await trackPromise(createUserSensorGroup(sensorGroup));

  toast.success(translate('pages.profile.createUserSensorGroupSuccessfully'));

  return { newSensorGroup };
});

export const updateSensorGroup = createAsyncThunk<
  { response: boolean; sensorGroup: SensorGroupsModel },
  { sensorGroupId: number; sensorGroup: SensorGroupRequestPops }
>('userSensorGroups/updateSensorGroup', async ({ sensorGroupId, sensorGroup }) => {
  const response = await trackPromise(updateUserSensorGroup(sensorGroupId, sensorGroup));

  toast.success(translate('pages.profile.editUserSensorGroupSuccessfully'));

  const updatedSensorGroup = await getUserSensorGroup(sensorGroupId);

  return { response, sensorGroup: updatedSensorGroup };
});

export const getUserSensorGroupById = createAsyncThunk<{ sensorGroup: SensorGroupsModel }, { sensorGroupId: number }>(
  'userSensorGroups/getUserSensorGroupById',

  async ({ sensorGroupId }) => {
    const sensorGroup = await trackPromise(getUserSensorGroup(sensorGroupId));

    for (const machine of sensorGroup.machines) {
      machine.isChecked = false;
    }

    for (const chart of sensorGroup.sensorGroupCharts) {
      for (const sensor of chart.sensors) {
        sensor.isChecked = false;
      }
    }

    return { sensorGroup };
  }
);

export const deleteUserSensorGroupById = createAsyncThunk<
  { response: boolean; sensorGroupId: number },
  { sensorGroupId: number }
>('userSensorGroups/deleteUserSensorGroup', async ({ sensorGroupId }) => {
  const response = await trackPromise(deleteUserSensorGroups([sensorGroupId]));

  toast.success(translate('pages.profile.deleteSensorGroup'));

  return { response, sensorGroupId };
});

export const bulkDeleteSensorGroups = createAsyncThunk<
  { response: boolean; sensorGroupIds: number[] },
  { sensorGroupIds: number[] }
>('userSensorGroups/bulkDeleteSensorGroups', async ({ sensorGroupIds }) => {
  const response = await trackPromise(deleteUserSensorGroups(sensorGroupIds));

  toast.success(translate('pages.profile.deleteSensorGroups'));

  return { response, sensorGroupIds };
});

export const getMachineSensorGroupsDetails = createAsyncThunk<
  {
    adminSensorGroups: AdminSensorGroupsModel[];
    sensorGroups: SensorGroupsModel[];
    machine: MachineModel;
  },
  number
>('userSensorGroups/getMachineSensorGroupsDetails', async (machineId) => {
  const [adminSensorGroups, sensorGroups, machine] = await trackPromise(
    Promise.all([
      getAdminSensorGroupsDetails(machineId),
      getUserSensorGroupsDetails(machineId),
      getMachineDetails(machineId),
    ])
  );

  return {
    adminSensorGroups,
    sensorGroups,
    machine,
  };
});

export const getMachineSensorEvents = createAsyncThunk<
  {
    timeStamps: Date[];
    events: TelemetryEventModel[];
    eventsCount?: number;
    alarms: DataAlarmModel[] | undefined;
    getAlarms: boolean;
    isAdminSensorGroupTab: boolean;
  },
  {
    machineUuid: string;
    attributeUuids: string[];
    timeRange?: number;
    dateFrom?: string | null;
    dateTo?: string | null;
    mapSensorSetupData: boolean;
    slicesMax: number;
    includeRangeTelemetryData: boolean;
    getAlarms: boolean;
    isAdminSensorGroupTab: boolean;
  }
>('userSensorGroups/getMachineSensorEvents', async (eventData: TelemetryBucketsRequestThunkModel) => {
  const { getAlarms, machineUuid, dateFrom, dateTo, timeRange, isAdminSensorGroupTab } = eventData;

  let timeStamps: Date[];
  let events: TelemetryEventModel[];
  let alarms: DataAlarmModel[] | undefined;

  if (getAlarms) {
    [{ timeStamps, events }, alarms] = await trackPromise(
      Promise.all([
        fetchMachineEvents(eventData),
        getSensorAlarmsByMachineUuid(machineUuid, {
          timeRange,
          dateFrom,
          dateTo,
        }),
      ])
    );
  } else {
    const machineEventsFromOneEndpoint = await trackPromise(fetchMachineEvents(eventData));
    alarms = undefined;
    timeStamps = machineEventsFromOneEndpoint.timeStamps;
    events = machineEventsFromOneEndpoint.events;
  }

  return { timeStamps, events, alarms, getAlarms, isAdminSensorGroupTab };
});

export const getSensorAvailableMachines = createAsyncThunk<{ machines: AvailableMachineModel[] }, undefined>(
  'userSensorGroups/getSensorAvailableMachines',
  async () => {
    const machines = await trackPromise(getAvailableMachines());

    return { machines };
  }
);
