import { createSlice, Slice, createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../store/store';
import { ProfileSensorGroupModel, SensorGroupsModel } from '../../../models/data/machine/sensorGroup.model';
import {
  getAllUserSensorGroups,
  getUserSensorGroupById,
  updateSensorGroup,
  deleteUserSensorGroupById,
  bulkDeleteSensorGroups,
  getSensorAvailableMachines,
} from '../../../store/api/userSensorGroups.service';
import { validateId } from '../../../utils/adminPages.utility';
import { AvailableMachineModel } from '../../../models/data/machine/availableMachine.model';
import { updateSensorGroupAvailableAttributes } from '../../../store/api/adminSensorGroups.service';

export interface userSensorGroupsState {
  displayInfoMessage?: boolean;
  sensorGroups: ProfileSensorGroupModel[];
  availableMachines: AvailableMachineModel[];
  selectedSensorGroup?: SensorGroupsModel;
}

const initialState: userSensorGroupsState = {
  displayInfoMessage: false,
  sensorGroups: [],
  availableMachines: [],
  selectedSensorGroup: {
    name: '',
    description: '',
    sensorGroupCharts: [],
    machines: [],
  },
};

export const userSensorGroupsSlice: Slice = createSlice({
  name: 'userSensorGroups',
  initialState,
  reducers: {
    addSensorGroupInformation(state, action) {
      const { fieldId, value } = action.payload;

      state.selectedSensorGroup[fieldId] = value;
    },
    addSensors(state, action) {
      const { selectedSensors } = action.payload;
      const defaultSensorChart = state.selectedSensorGroup.sensorGroupCharts.find(
        (chart) => chart.name === 'Default Chart'
      );

      if (defaultSensorChart) {
        defaultSensorChart.sensors.push(...selectedSensors);
        return;
      }

      state.selectedSensorGroup.sensorGroupCharts.push({ name: 'Default Chart', id: -1, sensors: selectedSensors });
    },
    addMachines(state, action) {
      const { selectedMachines } = action.payload;

      state.selectedSensorGroup.machines = [...state.selectedSensorGroup.machines, ...selectedMachines];
    },
    addChart(state, action) {
      const { name, id } = action.payload;
      const chartExists = state.selectedSensorGroup.sensorGroupCharts.find(
        (chart) => chart.name === name || chart.id === id
      );

      if (!chartExists) {
        state.selectedSensorGroup.sensorGroupCharts.push({ name, id, sensors: [] });
      }
    },
    moveSensorToChart(state, action) {
      const { currentChartId, nextChartId, sensorId } = action.payload;

      const currentChart = state.selectedSensorGroup.sensorGroupCharts.find((ch) => ch.id === currentChartId);

      if (currentChart) {
        const sensor = currentChart.sensors.find((a) => a.id === sensorId);

        currentChart.sensors = currentChart.sensors.filter((a) => a.id !== sensorId);

        const nextChart = state.selectedSensorGroup.sensorGroupCharts.find((ch) => ch.id === nextChartId);

        if (nextChart && sensor) {
          nextChart.sensors.push(sensor);
        }
      }
    },
    toggleSensorGroupChecked(state, action) {
      const { sensorGroupId } = action.payload;
      const selectedUserSensorGroup = state.sensorGroups.find((group) => group.id === sensorGroupId);

      if (selectedUserSensorGroup) {
        selectedUserSensorGroup.isChecked = !selectedUserSensorGroup.isChecked;
      }
    },
    toggleGlobalSensorGroupsChecked(state, action) {
      const { isChecked } = action.payload;

      state.sensorGroups = state.sensorGroups.map((group) => ({
        ...group,
        isChecked,
      }));
    },
    toggleSensorChecked(state, action) {
      const { chartId, sensorId } = action.payload;
      const selectedChart = state.selectedSensorGroup.sensorGroupCharts.find((chart) => chart.id === chartId);

      if (selectedChart) {
        const selectedSensor = selectedChart.sensors.find((att) => att.id === sensorId);

        if (selectedSensor) {
          selectedSensor.isChecked = !selectedSensor.isChecked;
        }
      }
    },
    toggleGlobalSensorsChecked(state, action) {
      const { isChecked } = action.payload;

      state.selectedSensorGroup.sensorGroupCharts = state.selectedSensorGroup.sensorGroupCharts.map((chart) => ({
        ...chart,
        sensors: chart.sensors.map((sensor) => ({ ...sensor, isChecked })),
      }));
    },
    deleteSensor(state, action) {
      const { chartId, sensorId } = action.payload;

      const selectedChart = state.selectedSensorGroup.sensorGroupCharts.find((chart) => chart.id === chartId);

      if (selectedChart) {
        selectedChart.sensors = selectedChart.sensors.filter((att) => att.id !== sensorId);
      }
    },
    bulkDeleteSensors(state, action) {
      const { checkedSensorIds } = action.payload;

      state.selectedSensorGroup.sensorGroupCharts = state.selectedSensorGroup.sensorGroupCharts.map((chart) => ({
        ...chart,
        sensors: chart.sensors.filter((a) => !checkedSensorIds.includes(a.id)),
      }));
    },
    clearSelectedSensorGroup(state) {
      state.selectedSensorGroup = initialState.selectedSensorGroup;
    },
    deleteMachine(state, action) {
      const { machineId } = action.payload;

      state.selectedSensorGroup.machines = state.selectedSensorGroup.machines.filter((m) => m.id !== machineId);
    },
    bulkDeleteMachines(state, action) {
      const { checkedMachineIds } = action.payload;

      state.selectedSensorGroup.machines = state.selectedSensorGroup.machines.filter(
        (m) => !checkedMachineIds.includes(m.id)
      );
    },
    toggleMachineChecked(state, action) {
      const machine = state.selectedSensorGroup.machines.find((m) => m.id.toString() === action.payload);

      if (machine) {
        machine.isChecked = !machine.isChecked;
      }
    },
    toggleGlobalMachinesChecked(state, action) {
      const { isChecked } = action.payload;

      state.selectedSensorGroup.machines = state.selectedSensorGroup.machines.map((machine) => ({
        ...machine,
        isChecked: isChecked,
      }));
    },
    toggleAvailableMachineChecked(state, action) {
      const machine = state.availableMachines.find((m) => m.id.toString() === action.payload);

      if (machine) {
        machine.isChecked = !machine.isChecked;
      }
    },
    toggleAvailableGlobalMachinesChecked(state, action) {
      const { isChecked } = action.payload;

      state.availableMachines = state.availableMachines.map((machine) => ({
        ...machine,
        isChecked: isChecked,
      }));
    },
    setDisplayInfoMessage(state, action) {
      state.displayInfoMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserSensorGroups.fulfilled, (state, action) => {
        const { sensorGroups } = action.payload;
        if (sensorGroups && sensorGroups?.length > 0) {
          state.sensorGroups = sensorGroups;
        }
      })
      .addCase(getUserSensorGroupById.fulfilled, (state, action) => {
        const { sensorGroup } = action.payload;

        state.selectedSensorGroup = sensorGroup;
      })
      .addCase(updateSensorGroup.fulfilled, (state, action) => {
        const { sensorGroup } = action.payload;
        state.selectedSensorGroup = sensorGroup;
      })
      .addCase(deleteUserSensorGroupById.fulfilled, (state, action) => {
        const { sensorGroupId } = action.payload;

        state.sensorGroups = state.sensorGroups.filter((group) => group.id !== sensorGroupId);
      })
      .addCase(bulkDeleteSensorGroups.fulfilled, (state, action) => {
        const { sensorGroupIds } = action.payload;

        state.sensorGroups = state.sensorGroups.filter((contract) => !sensorGroupIds.includes(contract.id));
      })
      .addCase(getSensorAvailableMachines.fulfilled, (state, action) => {
        const { machines } = action.payload;

        const selectedMachineIds = state.selectedSensorGroup?.machines.map((m) => m.id) || [];
        state.availableMachines = machines.filter((m) => !selectedMachineIds.includes(m.id));
      })
      .addCase(updateSensorGroupAvailableAttributes.fulfilled, (state, action) => {
        const { updatedSensorGroupCharts } = action.payload;

        state.selectedSensorGroup!.sensorGroupCharts = updatedSensorGroupCharts;
      });
  },
});

export const {
  addSensorGroupInformation,
  addSensors,
  addMachines,
  addChart,
  toggleSensorGroupChecked,
  toggleGlobalSensorGroupsChecked,
  toggleSensorChecked,
  toggleGlobalSensorsChecked,
  deleteSensor,
  bulkDeleteSensors,
  moveSensorToChart,
  clearSelectedSensorGroup,
  deleteMachine,
  bulkDeleteMachines,
  toggleMachineChecked,
  toggleGlobalMachinesChecked,
  toggleAvailableMachineChecked,
  toggleAvailableGlobalMachinesChecked,
  setDisplayInfoMessage,
} = userSensorGroupsSlice.actions;

export default userSensorGroupsSlice.reducer;

export const selectAttributes = (state: RootState) => state.attributes.attributes;

export const selectUserSensorGroupsSlice = (state: RootState) => state.userSensorGroups;

export const selectDisplayInfoMessage = (state: RootState) => state.userSensorGroups.displayInfoMessage;

export const selectUserSensorGroups = createSelector([selectUserSensorGroupsSlice], (slice) => slice.sensorGroups);

export const selectCheckedUserSensorGroups = createSelector(
  [selectUserSensorGroups],
  (sensorGroups) => sensorGroups && sensorGroups.length > 0 && sensorGroups.filter((group) => group.isChecked)
);

export const selectCheckedUserSensorGroupIds = createSelector(
  [selectCheckedUserSensorGroups],
  (sensorGroups) => sensorGroups && sensorGroups.map((group) => group.id)
);

export const selectSelectedSensorGroup = createSelector(
  [selectUserSensorGroupsSlice],
  (slice) => slice.selectedSensorGroup
);

export const selectSelectedSensorGroupCharts = createSelector(
  [selectSelectedSensorGroup],
  (selectedGroup) => selectedGroup.sensorGroupCharts
);

export const selectSensorChartsOptions = createSelector([selectSelectedSensorGroupCharts], (charts) =>
  charts.map((chart) => ({ id: chart.id, name: chart.name }))
);

export const selectFormattedSensors = createSelector(
  [selectSelectedSensorGroupCharts],
  (charts) =>
    charts &&
    charts.length > 0 &&
    charts.reduce((previousChart, currentChart) => {
      if (currentChart.sensors.length > 0) {
        const currentCharSensors = currentChart.sensors.map((sensor) => ({
          ...sensor,
          chart: currentChart?.name,
          chartId: currentChart?.id,
        }));
        previousChart.push(...currentCharSensors);
      }
      return previousChart;
    }, [])
);

export const selectCheckedSensorsIds = createSelector(
  [selectFormattedSensors],
  (sensors) => sensors && sensors.filter((sensor) => sensor.isChecked).map((s) => s.id)
);

export const selectSensorsIds = createSelector(
  [selectFormattedSensors],
  (sensors) => sensors && sensors.map((s) => s.id)
);

export const selectSelectedGroupSubmitFormat = createSelector(
  [selectSelectedSensorGroup],
  (group) =>
    group && {
      name: group.name,
      description: group.description,
      machineIds: group.machines.map((m) => m.id),
      sensorCharts: group.sensorGroupCharts.map((sensorChart) => ({
        id: validateId(sensorChart?.id),
        chartName: sensorChart.name,
        sensorIds: sensorChart.sensors.map((sensor) => sensor.id),
      })),
    }
);

export const selectAvailableSensors = createSelector(
  [selectAttributes, selectSensorsIds],
  (attributes, formattedSensors) =>
    attributes &&
    attributes.length > 0 &&
    attributes.filter((attribute) => (formattedSensors ? !formattedSensors.includes(attribute.id) : attribute))
);

export const selectMachines = createSelector([selectSelectedSensorGroup], (group) => group.machines);

export const selectCheckedMachineIds = createSelector(
  [selectSelectedSensorGroup],
  (group) => group.machines && group.machines.filter((machine) => machine.isChecked).map((machine) => machine.id)
);

export const selectAvailableMachines = createSelector(
  [selectUserSensorGroupsSlice],
  (slice) => slice.availableMachines
);
