import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import GeoMachines from '../../pages/geo/GeoMachines';

import DataMachines from '../../pages/data/DataMachines/DataMachines';

import DataDetails from '../../pages/data/Details/DataDetails';
import GeoMachineDetails from '../../pages/geo/GeoMachineDetails';
import Profile from '../../pages/profile/Profile';
import SensorGroupDetails from '../../pages/profile/SensorGroups/SensorGroupDetails';
import MachineGroupDetails from '../../pages/profile/MachineGroupDetails';
import AddProfileGroupSensor from '../../pages/profile/SensorGroups/AddProfileGroupSensor';
import AddProfileGroupMachine from '../../pages/profile/SensorGroups/AddProfileGroupMachine';
import AddUserGroupMachine from '../../pages/profile/AddUserGroupMachine';
import UsersList from '../../pages/admin/UsersList';
import AdminGroupsList from '../../pages/admin/AdminGroups/AdminGroupsList';
import AttributesList from '../../pages/admin/Attributes/AttributesList';
import MachineOwnersList from '../../pages/admin/MachineOwners/MachineOwnersList';
import MachinesList from '../../pages/admin/Machines/MachinesList';
import UserDetails from '../../pages/admin/UserDetails';
import ContractsList from '../../pages/admin/Contracts/ContractsList';
import ContractDetails from '../../pages/admin/Contracts/ContractDetails';
import AdminGroupDetails from '../../pages/admin/AdminGroups/AdminGroupDetails';
import AddContractMachine from '../../pages/admin/AddContractMachine';
import AddMachineMachineOwner from '../../pages/admin/AddMachineMachineOwner';
import MachineOwnerDetails from '../../pages/admin/MachineOwners/MachineOwnerDetails';
import MachineDetails from '../../pages/admin/Machines/MachineDetails';
import AddAdminGroupMachine from '../../pages/admin/AddAdminGroupMachine';
import AddContractUsers from '../../pages/admin/AddContractUsers';
import AlarmDetails from '../../pages/admin/Alarm/AlarmDetails';
import UserNotSynched from '../../pages/user/UserNotSynched';
import AdminSensorGroupsList from '../../pages/admin/AdminSensorGroups/AdminSensorGroupsList';
import AdminSensorGroupDetails from '../../pages/admin/AdminSensorGroups/AdminSensorGroupDetails';
import AddSensorAdminGroup from '../../pages/admin/AdminSensorGroups/AddSensorAdminGroup';
import AddMachineAdminSensorGroup from '../../pages/admin/AdminSensorGroups/AddMachineAdminSensorGroup';
import './routes.scss';

const NotFound: React.FC = () => {
  return <h1 className='d-block fixed-centered'>404</h1>;
};

const RoutesCurrent: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/geo/machines' />} />
      <Route path='/profile' element={<Profile />} />
      <Route path='/profile/machine-group/:groupId?' element={<MachineGroupDetails />} />
      <Route path='/profile/group/:groupId?' element={<SensorGroupDetails />} />
      <Route path='/profile/group/:groupId/add-sensor' element={<AddProfileGroupSensor />} />
      <Route path='/profile/group/:groupId/add-machine' element={<AddProfileGroupMachine />} />
      <Route path='/profile/machine-group/:groupId/add-machine' element={<AddUserGroupMachine />} />
      <Route path='/geo/machines' element={<GeoMachines />} />
      <Route path='/data/machines' element={<DataMachines />} />
      <Route path='/geo/machine/:machineId' element={<GeoMachineDetails />} />
      <Route path='/data/analytics/:machineId' element={<DataDetails />} />
      <Route path='/admin/admin-groups' element={<AdminGroupsList />} />
      <Route path='/admin/admin-group/:adminGroupId?' element={<AdminGroupDetails />} />
      <Route path='/admin/attributes' element={<AttributesList />} />
      <Route path='/admin/machine-owners' element={<MachineOwnersList />} />
      <Route path='/admin/machine-owner/:machineOwnerId?' element={<MachineOwnerDetails />} />
      <Route path='/admin/machines' element={<MachinesList />} />
      <Route path='/admin/machine/:machineId?' element={<MachineDetails />} />
      <Route path='/admin/machine/:machineId/add-admin-group' element={<AddAdminGroupMachine />} />
      <Route path='/admin/machine/:machineId/add-machine-owner' element={<AddMachineMachineOwner />} />
      <Route path='/admin/machine/:machineId/alarm/:alarmId?' element={<AlarmDetails />} />
      <Route path='/admin/users' element={<UsersList />} />
      <Route path='/admin/user/:userId' element={<UserDetails />} />
      <Route path='/admin/contracts' element={<ContractsList />} />
      <Route path='/admin/contract/:contractId?' element={<ContractDetails />} />
      <Route path='/admin/contract/:contractId/add-machine' element={<AddContractMachine />} />
      <Route path='/admin/contract/:contractId/add-user' element={<AddContractUsers />} />
      <Route path='/admin/sensor-groups' element={<AdminSensorGroupsList />} />
      <Route path='/admin/sensor-group/:groupId?' element={<AdminSensorGroupDetails />} />
      <Route path='/admin/sensor-group/:groupId/add-sensor' element={<AddSensorAdminGroup />} />
      <Route path='/admin/sensor-group/:groupId/add-machine' element={<AddMachineAdminSensorGroup />} />
      <Route path='/user-not-synchronized' element={<UserNotSynched />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default RoutesCurrent;
